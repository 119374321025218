import React, { useEffect, useState } from "react";
import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";
import "./HomeMain.css";
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import CardComponent from "../cards/CardComponent";
import IconBox from "../cards/IconBox";
import TotalSpent from "../cards/TotalSpent";
import PieChatRevenueBilling from "../cards/PieChartRevenueBillling.jsx";
import RevenueModality from "../cards/RevenueModality.jsx";
import RevenueTable from "../cards/RevenueTable";
import axios from "axios";
import GmStudyOverall from "../cards/GmStudyOverall";
import GmStudyWise from "../cards/GmStudyWise";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import RadWiseGm from "../cards/RadWiseGm";
import InhouseRadpay from "../cards/InhouseRadpay";
import * as XLSX from 'xlsx'
function HomeMain({
  startDate,
  endDate,
  modalityName,
  setApiState,
  apiState,
  tag,
}) {
  const brandColor = "#422AFB";
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [revenue, setRevenue] = useState(null);
  const [revision, setRevision] = useState(null);
  const [billedClients, setBilledClients] = useState(null);
  const [payable, setPayable] = useState(null);
  const [billedReport, setBilledReport] = useState(null);
  const [gmData, setGmData] = useState([]);
  const [mapGmData, setMapGmData] = useState([]);
  const [value, setValue] = React.useState("by_date");
  const [clientRad, setClientRad] = React.useState("margin_client");
  const [radWiseGm, setRadWiseGm] = useState([]);

  const [revenueData, setRevenueData] = useState([]);

  const RevenueTableAPI = async () => {
    setRevenueData([]);
    setApiState((prevData) => ({
      ...prevData,
      clientgm: "fetch",
    }));
    try {
      const { REACT_APP_CLIENT_HOME_OVERALL_REVENUE_CLIENT_WISE } = process.env;
      const res = await axios.get(
        REACT_APP_CLIENT_HOME_OVERALL_REVENUE_CLIENT_WISE +
          `?start_date=${startDate}&end_date=${endDate}`
      );
      //console.log(res);
      if (res.data.data) {
        setRevenueData(res?.data.data);
        setBilledClients(res?.data.data.length);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setApiState((prevData) => ({
        ...prevData,
        clientgm: null,
      }));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDownload=(data,filename)=>{
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${filename}.xlsx`);
  }

  const handleCLientRad = (event, newValue) => {
    setClientRad(newValue);
  };

  const handleRevenueAPI = async () => {
    try {
      const month = new Date(endDate).getMonth() + 1;
      const year = new Date(endDate).getFullYear();
      // //console.log(month,year)
      const { REACT_APP_CLIENT_SHOW_INVOICE_URL } = process.env;
      const res = await axios.get(
        REACT_APP_CLIENT_SHOW_INVOICE_URL + `?month=${month}&year=${year}`
      );
      if (res.data.show_invoice_data.length >= 0) {
        const rev = res.data.show_invoice_data.reduce(
          (total, row) => total + row.last_invoice_amount,
          0
        );
        setRevenue(rev);
        const revisions = res.data.show_invoice_data.reduce(
          (total, row) => total + row.revision,
          0
        );
        setRevision(revisions);
      }
      //  //console.log(res)
    } catch (err) {
      //console.log(err);
    }
  };

  const handleBilledClientsAPI = async () => {
    try {
      setApiState((prevData) => ({
        ...prevData,
        billedApi: "fetch",
      }));
      const { REACT_APP_CLIENT_HOME_BILLED_CLIENTS } = process.env;
      const res = await axios.get(
        REACT_APP_CLIENT_HOME_BILLED_CLIENTS +
          `start_date=${startDate}&end_date=${endDate}`
      );
      //console.log(res.data.data);
      if (res.data.data) {
        // setBilledClients(res.data.data);
      }
    } catch (err) {
    } finally {
      setApiState((prevData) => ({
        ...prevData,
        billedApi: null,
      }));
    }
  };

  useEffect(() => {
    if (gmData) {
      const filteredData = gmData.filter((item) =>
        modalityName.some((name) => item.grouped_study_name.startsWith(name))
      );
      //console.log(filteredData);
      setMapGmData(filteredData);
      const report = filteredData?.reduce(
        (total, row) => total + row.No_of_report,
        0
      );
      const revenue = filteredData?.reduce(
        (total, row) => total + row.Revenue,
        0
      );
      const payable = filteredData?.reduce(
        (total, row) => total + row["Rad Payable"],
        0
      );
      setBilledReport(report);
      setRevenue(revenue);
      setPayable(payable);
    }
  }, [modalityName, gmData]);

  useEffect(() => {
    if (apiState.gmApi === "fetch" || apiState.billedApi === "fetch") {
      return;
    }
    handleClearAll();
    if (startDate !== null && endDate!==null) {
      // handleRevenueAPI()
      // handleGmData();
      // handleBilledClientsAPI();
      RevenueTableAPI();
      handleRadWiseGm();

      // handlePayableBilledReport()
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (apiState.gmApi === "fetch" || apiState.billedApi === "fetch") {
      return;
    }
    handleClearAll();
    if (startDate !== null && endDate!==null) {
      handleGmData();
    }
  }, [startDate, endDate, tag]);

  const handleRadWiseGm = async () => {
    try {
      setRadWiseGm([]);

      setApiState((prevData) => ({
        ...prevData,
        radgm: "fetch",
      }));
      const { REACT_APP_CLIENT_HOME_RAD_WISE_GM_STUDY } = process.env;
      const res = await axios.get(
        REACT_APP_CLIENT_HOME_RAD_WISE_GM_STUDY +
          `?start_date=${startDate}&end_date=${endDate}`
      );
      if (res?.data?.data?.length > 0) {
        setRadWiseGm(res?.data?.data);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setApiState((prevData) => ({
        ...prevData,
        radgm: null,
      }));
    }
  };

  const handleGmData = async () => {
    try {
      setGmData([]);
      setApiState((prevData) => ({
        ...prevData,
        gmApi: "fetch",
      }));
      const { REACT_APP_CLIENT_HOME_OVERALL_GM_STUDY } = process.env;
      const res = await axios.get(
        REACT_APP_CLIENT_HOME_OVERALL_GM_STUDY +
          `?start_date=${startDate}&end_date=${endDate}&tag=${tag}`
      );
      if (res?.data?.data?.length > 0) {
        setGmData(res?.data?.data);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setApiState((prevData) => ({
        ...prevData,
        gmApi: null,
      }));
    }
  };

  const handlePayableBilledReport = async () => {
    try {
      const { REACT_APP_CLIENT_HOME_PAYABLE_AND_BILLED } = process.env;
      const res = await axios.get(
        REACT_APP_CLIENT_HOME_PAYABLE_AND_BILLED +
          `?start_date=${startDate}&end_date=${endDate}`
      );
      //console.log(res.data);
      if (res.data.length > 0) {
        setPayable(parseInt(res.data[0]?.rad_pay));
        setBilledReport(res.data[0]?.billed_reports);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const handleClearAll = () => {
    setRevenue("");
    setRevision("");
    // setBilledClients("");
    setPayable("");
    setBilledReport("");
  };

  return (
    <div style={{ paddingTop: "150px", paddingBottom: "50px" }}>
      <SimpleGrid
        className="card-grid"
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <CardComponent
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="No of Reports"
          value={billedReport}
          loader={billedReport}
        />

        <CardComponent
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Reveune"
          value={new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(revenue)}
          loader={revenue}
        />

        <CardComponent
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Payable"
          loader={payable}
          value={new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(payable)}
        />

        <CardComponent
          growth={`+{${parseFloat(((revenue - payable) / revenue) * 100).toFixed(2)}}%`}
          name="Margin"
          value={new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(revenue - payable)}
          loader={revenue - payable > 0 ? revenue - payable : 0}
        />
        <CardComponent
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          // growth=" "
          name="Billed Clients"
          value={billedClients}
          loader={billedClients}
        />
        {/* <CardComponent
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Revision'
          loader={revision}
          value={new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(revision)}
        />
        <CardComponent
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Billed Reports'
          value={billedReport}
          loader={billedReport}
        /> */}
      </SimpleGrid>

      {/* <SimpleGrid className='card-grid-2' columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'> */}
      {/* <div className='revenue-div'> */}
      {/* <TotalSpent /> */}
      {/* <TotalSpent /> */}
      {/* <RevenueTable startDate={startDate} endDate={endDate} />
        <PieChatRevenueBilling startDate={startDate} endDate={endDate} />
        </div> */}
      {/* </SimpleGrid> */}

      {/* <div className='revenue-div'>
        <RevenueModality startDate={startDate} endDate={startDate} />
    
        <TotalSpent />
        
        </div> */}

      <TabContext value={value}>
        <TabList
          style={{ marginLeft: "30px" }}
          className="notice-tab"
          onChange={handleChange}
          aria-label="lab API tabs example"
        >
          <Tab label="By Date" value="by_date" />
          <Tab label="By Modality" value="by_modality" />
        </TabList>
      </TabContext>

      <div style={{ marginTop: "15px" }} className="revenue-div">
        {mapGmData.length === 0 ? (
          <div style={{ margin: "auto" }}>
            <ReactLoading
              type={"bars"}
              color={"#0059B2"}
              height={100}
              width={100}
            />
          </div>
        ) : value === "by_modality" ? (
          <GmStudyOverall gmData={mapGmData} handleDownload={handleDownload} />
        ) : (
          <GmStudyWise gmData={mapGmData}  handleDownload={handleDownload} />
        )}
      </div>

      <TabContext value={clientRad}>
        <TabList
          style={{ marginLeft: "30px", marginTop: "80px" }}
          className="notice-tab"
          onChange={handleCLientRad}
          aria-label="lab API tabs example"
        >
          <Tab label="Margin By Client" value="margin_client" />
          <Tab label="Margin By Rad" value="margin_rad" />
          <Tab label="Margin By Inhouse Rad" value="margin_inhouse" />
        </TabList>
      </TabContext>

      <div style={{ marginTop: "15px" }} className="revenue-div">
        {clientRad === "margin_client" && (
          <RevenueTable revenueData={revenueData}  handleDownload={handleDownload} />
        )}

        {clientRad === "margin_rad" && <RadWiseGm gmData={radWiseGm}  handleDownload={handleDownload} />}

        {clientRad === "margin_inhouse" && <InhouseRadpay gmData={radWiseGm} handleDownload={handleDownload}  />}
      </div>
    </div>
  );
}

export default HomeMain;
