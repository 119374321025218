import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import './HospitalTable.css';
import { Link } from 'react-router-dom';


const TableContainerWrapper = styled(TableContainer)({
  marginBottom: '10px',
  borderRadius: '10px',
  overflow: 'auto',
});

// const TableRowWrapper = (props) => {
//   const { index, handleMouseEnter, handleMouseLeave, children } = props;
//   return (
//     <TableRow
//     sx={{height:'10px'}}
//       key={index}
//       className="tableRow"
//       onMouseEnter={() => handleMouseEnter(index)}
//       onMouseLeave={handleMouseLeave}
//     >
//       {children}
//     </TableRow>
//   );
// };

const ViewMoreButton = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

const HoverIcon = styled(ArrowCircleRightIcon)({
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

const HospitalTable = ({ searchText, selectedBillingCategory,apiState }) => {
  const [mail,setMail] = useState('');
  const columns = [
    { title: 'Unique ID', dataIndex: 'unique_id', key: 'unique_id' },
    { title: 'Client Name', dataIndex: 'client_name', key: 'client_name' },
    { title: 'City', dataIndex: 'city', key: 'city' },
    { title: 'State', dataIndex: 'state', key: 'state' },
    { title: 'Billing Type', dataIndex: 'billing_type', key: 'billing_type' },
    { title: 'SaaS Only', dataIndex: 'saas_only', key: 'saas_only' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    {
      title: 'Read More',
      dataIndex: 'readMore',
      key: 'readMore',
      align: 'center',
      render: (_, record) => renderReadMoreColumn(record.unique_id),
    },
  ];

  const handleReadMoreClick = (uniqueId) => {
    // localStorage.setItem('unique_id',uniqueId);
    // localStorage.setItem('hostpital_mail',)
    // Programmatically navigate to the hospital details page using window.location
    window.location.href = `/hospitals/${uniqueId}/details`;
  };

  const renderReadMoreColumn = (uniqueId) => {

    return (
      <div className="viewMoreButton">
        <Link to={`/hospitals/${uniqueId}/details`} className="linkStyle">
          <ArrowCircleRightIcon
            className={`hover-icon arrow-icon ${hoveredRow === uniqueId ? 'visible' : 'hidden'}`}
            style={{
              fontSize: '48px',
              color: hoveredRow === uniqueId ? 'blue' : 'inherit',
            }}
          />
          {hoveredRow !== uniqueId && (
            <ReadMoreIcon
              className={`readMoreIcon visible`}
              style={{
                fontSize: '36px',
                display: 'block',
                margin: 'auto',
                color: 'inherit',
              }}
            />
          )}
        </Link>
      </div>
    );
  };

  // const rowsPerPage = 20;
  const [data, setData] = useState([]);
  const [mainData,setMainData]=useState([])
  const [totalRows, setTotalRows] = useState(0);
  // const [page, setPage] = useState(0);

  useEffect(() => {
    // console.log(apiState)
    if(apiState?.hospitallist==='fetch'){
      return
    }
    fetchData(1, searchText, selectedBillingCategory); // Fetch initial data for the first page
  // }, [searchText, selectedBillingCategory]);
  },[]);

  useEffect(()=>{
        if(selectedBillingCategory){
           const data = mainData?.filter(data=>selectedBillingCategory===data?.billing_type)
           setData(data)
        }else{
          setData(mainData)
        }
  },[selectedBillingCategory,mainData])

  const fetchData = async (pageNumber, searchTerm, billingCategory) => {
    let search=searchTerm;
    
    try {
      // const {REACT_APP_CLIENT_HOSPITAL_LIST_URL} = process.env
      // const response = await axios.get(
      //   REACT_APP_CLIENT_HOSPITAL_LIST_URL+`?page=${pageNumber}&pageSize=${rowsPerPage}&search_term=${searchTerm}&billing_type=${billingCategory}`
      // );

      const {REACT_APP_CLIENT_LISTING_FOR_SEND_INVOICE_URL} =process.env
      const response=await axios.get(REACT_APP_CLIENT_LISTING_FOR_SEND_INVOICE_URL)
      
      // setData(response.data.data);
       setMainData(response.data[0])
      //console.log(response);
      // setTotalRows(response.data[0].length);
      setPage(pageNumber - 1); // Set the current page
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   fetchData(newPage + 1, searchText, selectedBillingCategory); // Fetch data for the new page
  // };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleMouseEnter = (uniqueId) => {
    setHoveredRow(uniqueId);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  

  return (
    <div className="tableContainerWrapper">
      <TableContainerWrapper component={Paper} className="tableContainer">
        <Table stickyHeader sx={{height:'100px'}}>
          <TableHead className="tableHead" >
            <TableRow sx={{backgroundColor:'#3C48AD'}}>
              {columns.map((column) => (
                <TableCell sx={{backgroundColor:'#3C48AD',height:'10px'}} key={column.key}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {data?.filter((data, id) => {
                        if (
                          data?.client_name
                            ?.toString()
                            .toLowerCase()
                            .includes(searchText?.toString().toLowerCase())||

                            data?.unique_id
                            ?.toString()
                            .toLowerCase()
                            .includes(searchText?.toString().toLowerCase())||

                            data?.city
                            ?.toString()
                            .toLowerCase()
                            .includes(searchText?.toString().toLowerCase())||

                            data?.state
                            ?.toString()
                            .toLowerCase()
                            .includes(searchText?.toString().toLowerCase())
                        ) {
                          return data;
                        }
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).map((item, index) => (
             
              <TableRow
                  sx={{height:'10px'}}
                    key={index}
                    className="tableRow"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                {columns.map((column) => (
                  <TableCell sx={{fontSize:'13px',height:'10px'}} key={column.key} align={column.align || 'left'} className="tableCell">
                    {column.dataIndex === 'date'
                      ? item.date
                      : column.dataIndex === 'saas_only'
                      ? !item.saas_only
                        ? 'Regular'
                        : 'IRAD'
                      : item[column.dataIndex]}
                    {column.key === 'readMore' && renderReadMoreColumn(item.unique_id)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerWrapper>
      <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
    </div>
  );
};

export default HospitalTable;
