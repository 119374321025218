import React, { useState,useRef, useEffect } from 'react'
import axios from "axios";
import {
    Modal,
    Box,
    Button,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from "@mui/material";
import { MdPreview } from "react-icons/md";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { MdOutlineFileUpload } from "react-icons/md";
import dayjs from 'dayjs'
import { TbEdit } from "react-icons/tb";
import DocumentViewer from './DocumentViewer';
import './VendorInvoice.css'
import { GreenSwitch } from '../Hospital/HospitalContact';
import * as XLSX from 'xlsx'


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    height:'80%',
    overflowY:'scroll',
    bgcolor: "background.paper",
    border: "2px solid lightgray",
    boxShadow: 24,
    borderRadius: "30px",
    p: 4,
};


function VendorInvoice({setAlert,setWarning,email,vendorid,vendorname, setAuthPop, handleAccess}) {
    const [addPop,setAddPop]=useState('')
    const [invoiceDate,setInvoiceeDate]=useState(null)
    const [dueDate,setDueDate]=useState(null)
    const [paymentDate,setPaymentDate] = useState(null)
    const [invoicePeriod,setInvoicePeriod]=useState([dayjs(),dayjs()])
    const [invoiceAmount,setInvoiceAmount]=useState(null)
    const errorRef = useRef();
    const [category,setCategory]=useState(null)
    const [selectedFile, setSelectedFile] = useState(null);
    const [vendorDocument,setDocument]=useState([])
    const [invoiceNum,setInvoiceNum]=useState(null)
    const [documentOpen,setDocumentOpen]=useState('')
    const [paidStatus,setPaidStatus]=useState(null)
    const [utrDetails,setUtrDetails]=useState(null)
    const [editPop,setEditPop]=useState(null)

    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        //console.log("Selected file:", selectedFile);
        setSelectedFile(selectedFile);

      };

      const funDate = (date1) => {
        const date = new Date(date1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      };

      const getVendorDocument=async()=>{
        try{
            const {REACT_APP_GET_VENDORS_INVOICE_DOCUMENT}=process.env
            const res = await axios.get(REACT_APP_GET_VENDORS_INVOICE_DOCUMENT+`?vendor_id=${vendorid}`)
            if(res?.data?.data?.length>0){
                setDocument(res?.data?.data)
            }

        }catch(err){

        }
      }

      useEffect(()=>{
        if(vendorid){
            getVendorDocument()
        }
      },[vendorid])


      const handleUpload =async()=>{
        errorRef.current.innerHTML=''
        if(invoiceDate===null){
            errorRef.current.innerHTML='Please Select the invoice date'
            return
        }
        if(selectedFile===null){
            errorRef.current.innerHTML='Please Select the file'
            return
        }

        if(invoiceAmount===null){
            errorRef.current.innerHTML='Please Enter Invoice Amount'
            return
        }
        if(invoiceAmount===null){
            errorRef.current.innerHTML='Please Enter Invoice Num'
            return
        }

        if(paidStatus==='PAID'){
            if(paymentDate===null){
                errorRef.current.innerHTML='Please Select Payment Date'
                return
            }
           
        }

        const invoicedate=funDate(invoiceDate)
        const due_date=funDate(dueDate)
        const startDate=funDate(invoicePeriod[0])
        const endDate=funDate(invoicePeriod[1])
        setAddPop(false)
        try{
            
            const {REACT_APP_VENDORS_INVOICE_DOCUMENT}=process.env
            const formData = new FormData();
            formData.append("file", selectedFile);
            // const res = await axios.post(REACT_APP_DELETE_VENDORS_INVOICE_DOCUMENT+`?email=${email}&vendor_fk=${vendorid}&invoice_amount=${invoiceAmount}&start_date=${startDate}&end_date=${endDate}&invoice_date=${invoiceDate}`)
            const response = await fetch(
                REACT_APP_VENDORS_INVOICE_DOCUMENT+`?email=${email}&vendor_fk=${vendorid}&invoice_amount=${invoiceAmount}
                &start_date=${startDate}&end_date=${endDate}&invoice_date=${invoicedate}&due_date=${due_date}&invoice_num=${invoiceNum}
                &expenses_category=${category}&payment_status=${paidStatus}&payment_date=${paidStatus==='PAID'?funDate(paymentDate):''}&utr_details=${paidStatus==='PAID'?utrDetails:''}`,
                {
                  method: "POST",
                  body: formData,
                }
              );
              if (response.ok) {
                //console.log("File Updated Successfully");
                setAlert('File Upload Successfully')
              } else {
                setWarning('File not Uploaded')
              }

        }catch(err){

        }

      }


      const handleDownloadInvoices=()=>{
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(vendorDocument);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${vendorname}_Invoices.xlsx`);
      }


      const handleEditDocument = async(id)=>{
        errorRef.current.innerHTML=''
        if(invoiceDate===null){
            errorRef.current.innerHTML='Please Select the invoice date'
            return
        }

        if(invoiceAmount===null){
            errorRef.current.innerHTML='Please Enter Invoice Amount'
            return
        }
        if(invoiceAmount===null){
            errorRef.current.innerHTML='Please Enter Invoice Num'
            return
        }
        console.log(dueDate)
        // if(dueDate?.$d==='InValid Date'){
        //     errorRef.current.innerHTML='Please Enter Invoice Num'
        //     return
        // }

        if(paidStatus==='PAID'){
            if(paymentDate===null){
                errorRef.current.innerHTML='Please Select Payment Date'
                return
            }
           
        }
        const invoicedate=funDate(invoiceDate)
        const due_date=funDate(dueDate)
        const startDate=funDate(invoicePeriod[0])
        const endDate=funDate(invoicePeriod[1])
        const data={
            id:id,
            email:email,
            vendor_fk:vendorid,
            invoice_amount:invoiceAmount,
            start_date:startDate,
            end_date:endDate,
            invoice_date:invoicedate,
            due_date:due_date,
            invoice_num:invoiceNum,
            expenses_category:category,
            payment_status:paidStatus,
            payment_date: paidStatus==='PAID'?funDate(paymentDate):null,
            utr_details:utrDetails
        }
        console.log(paymentDate)
        try{
        const {REACT_APP_EDIT_INVOICE_UPDATE_VENDORS_DOCUMENT}=process.env
        const res = await axios.put(REACT_APP_EDIT_INVOICE_UPDATE_VENDORS_DOCUMENT,data)
        if(res?.data?.detail?.length>0){
            setAlert('Updated Sucessfully')
            getVendorDocument()
        }else{
         setWarning('Failed')
        }
        }catch(err){
            setWarning('Failed')
        }

      }

      const handleEditPayment=async(id)=>{
        try{
            const {REACT_APP_EDIT_INVOICE_VENDORS_DOCUMENT}=process.env
            const res = await axios.put(REACT_APP_EDIT_INVOICE_VENDORS_DOCUMENT+`?email=${email}&invoice_id=${id}&payment_date=${funDate(paymentDate)}&utr_details=${utrDetails}`)
            if(res?.data==='Edited Successfully'){
                setAlert('Payment Status Changed')
                getVendorDocument()
            }else{
             setWarning('Failed')
            }
        }catch(err){
         setWarning('Failed')
        }
      }

      const dateBodyTemplate = (rowData) => {
        const formattedDate = new Date(rowData).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });
        const datePart = formattedDate.slice(0, -8);
        const timePart = formattedDate.slice(-8);
        return (
            <div>
                <div className="date-part">{datePart} </div>
                <div className="time-part">{timePart}</div>
            </div>
        );
    };


  return (
    <div>
            <div style={{ display: "flex", justifyContent: "end" }}>
                    <div style={{ float: "right", paddingRight: "20px" }}>
                       <Button
                            onClick={() => {
                                // // if (userAccess?.access === 'admin') {
                                // //     setAddContactPop('Add Contact')
                                // // }
                                if (handleAccess("Vendor", "Invoice") === true) {
                                    // setAddPop(true)
                                    handleDownloadInvoices()
                                } else {
                                    setAuthPop(true);
                                }

                            }}
                            style={{marginRight:'10px'}}
                            size="small"
                            variant="contained"
                        >
                            {" "}
                            Download Invoices {" "}
                        </Button>
                        <Button
                            onClick={() => {
                                // // if (userAccess?.access === 'admin') {
                                // //     setAddContactPop('Add Contact')
                                // // }
                                if (handleAccess("Vendor", "Invoice") === true) {
                                    setAddPop(true)
                                } else {
                                    setAuthPop(true);
                                }

                            }}
                            size="small"
                            variant="contained"
                        >
                            {" "}
                            Add Document{" "}
                        </Button>
                    </div>
                </div>


        <div className="vendor-invoice-table">
                    <div>
                        <div>Invoice Date</div>
                        <div>Invoice Period</div>
                        <div>Invoice Num</div> 
                        <div>Invoice Amount</div> 
                        <div>Due Date</div>
                        <div>Expenses Category</div>
                        <div>Status</div>
                        <div>Payment Date</div>
                        <div>UTR Details</div>
                        <div>Action</div>
                    </div>

                    {vendorDocument?.map((data, id) => (
                        <div key={id} className="vendor-invoice-table-body">
                            <div>{data?.invoice_date?.split('T')[0]}</div>
                            <div>{data?.start_date?.split('T')[0]} - {data?.end_date?.split('T')[0]}</div>
                            <div>{data?.invoice_num}</div>
                            <div>{data?.invoice_amount}</div>
                            <div>{data?.due_date?.split('T')[0]}</div>
                            <div>{data?.expenses_category}</div>
                            <div>
                            <GreenSwitch
                                onClick={()=>{
                                    if(data?.payment_status!=='PAID'){
                                          setEditPop(data?.id)
                                    }
                                }}
                                checked={data?.payment_status === "PAID" ? true : false}
                                color="secondary"
                            />
                                </div>
                            <div>{data?.payment_date?.split('T')[0]}</div>
                            <div>{data?.utr_details}</div>
                            <div style={{display:'flex',justifyContent:'center'}}  >

                                <MdPreview onClick={()=>{
                                    setDocumentOpen(data?.url)
                                    }}  style={{cursor:'pointer'}} 
                                    color='blue' size={25} />

                            <TbEdit
                                onClick={() => {
                                    // if (userAccess?.access === 'admin') {
                                    if (handleAccess("Vendor", "Invoice") === true) {
                                        // handleEditContact(data);
                                        setAddPop({method:'edit',id:data?.id})
                                        setInvoiceeDate(data?.invoice_date?dayjs(data?.invoice_date):null)
                                        setInvoicePeriod([dayjs(data?.start_date),dayjs(data?.end_date)])
                                        setDueDate(data?.due_date?dayjs(data?.due_date):null)
                                        setInvoiceNum(data?.invoice_num)
                                        setCategory(data?.expenses_category)
                                        setInvoiceAmount(data?.invoice_amount)
                                        setPaidStatus(data?.payment_status)
                                        setPaymentDate(data?.due_date?dayjs(data?.payment_date):null)
                                        setUtrDetails(data?.utr_details)
                                    } else {
                                        setAuthPop(true);
                                    }
                                }}
                                size={16}
                                style={{
                                    color: "white",
                                    backgroundColor: "blue",
                                    padding: "3px",
                                    
                                    borderRadius: "50%",
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                }}
                            />
                            </div>
                        </div>
                    ))}
                </div>

            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={addPop}
                onClose={() => {
                    setAddPop(false)
                }}
            >
                <Box sx={style}>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid gray",
                                paddingBottom: "10px",
                                fontSize: "20px",
                            }}
                        >
                            <div>Upload Document</div>
                            <AiOutlineCloseCircle
                                onClick={() => {
                                    setAddPop(false)
                                }}
                                size={25}
                                style={{ color: "red", cursor: "pointer" }}
                            />
                        </div>

                        <div 
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "20px",
                            }}
                            
                        >
                          
                            <div style={{width:'300px !important',margin:'20px 0px'}} className='vendor-date-filter' >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker format="DD/MM/YYYY" label="select Invoice Date" value={invoiceDate} onChange={(newValue) => setInvoiceeDate(newValue)} />
                                </DemoContainer>
                                </LocalizationProvider>
                            </div>

                            <div style={{margin:'20px 0px'}}>
                                 <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["SingleInputDateRangeField"]}>
                                    <DateRangePicker
                                        label='Select Invoice Period'
                                        slots={{ field: SingleInputDateRangeField }}
                                        format="DD/MM/YYYY"
                                        value={invoicePeriod}
                                        onChange={(newValue) => setInvoicePeriod(newValue)}
                                        calendars={1}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>

                            <div style={{width:'300px !important',margin:'20px 0px'}} className='vendor-date-filter' >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker format="DD/MM/YYYY" label="select Due Date" value={dueDate} onChange={(newValue) => setDueDate(newValue)} />
                                </DemoContainer>
                                </LocalizationProvider>
                            </div>

                            <TextField
                                style={{ marginBottom: "20px", marginTop: "20px" }}
                                id="standard-basic"
                                label="Enter Invoice Number"
                                variant="standard"
                                value={invoiceNum}
                                onChange={(e)=>setInvoiceNum(e.target.value)}
                            />

<TextField
                                style={{ marginBottom: "20px", marginTop: "20px" }}
                                id="standard-basic"
                                label="Enter Expenses Category"
                                variant="standard"
                                value={category}
                                onChange={(e)=>setCategory(e.target.value)}
                            />
                           
                            <TextField
                             type='number'
                                style={{ marginBottom: "20px", marginTop: "20px" }}
                                id="standard-basic"
                                label="Enter Invoice Amount"
                                variant="standard"
                                value={invoiceAmount}
                                onChange={(e)=>setInvoiceAmount(e.target.value)}
                            />

                            <FormControl
                                style={{ marginBottom: "20px", marginTop: "20px",height:'40px' }}
                                sx={{ m: 1, minWidth: 310 }}
                                size="small"
                            >
                                <InputLabel id="demo-select-small-label">
                                    Select Payment Status
                                </InputLabel>
                                <Select
                                    style={{height:'40px'}}
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={paidStatus}
                                    label="Select Payment Status"
                                    onChange={(e) => setPaidStatus(e.target.value)}
                                >
                                    
                                    <MenuItem value="PAID">PAID</MenuItem>
                                    <MenuItem value="UNPAID">UNPAID</MenuItem>
                                </Select>
                            </FormControl>

                            {
                               paidStatus==='PAID' &&
                               <div>

                                    <div style={{width:'300px !important',margin:'20px 0px'}} className='vendor-date-filter' >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker format="DD/MM/YYYY" label="select Payment Date" value={paymentDate} onChange={(newValue) => setPaymentDate(newValue)} />
                                    </DemoContainer>
                                    </LocalizationProvider>
                                    </div>

                                    <TextField
                                        style={{ marginBottom: "20px", marginTop: "20px" }}
                                        id="standard-basic"
                                        label="Enter UTR Details"
                                        variant="standard"
                                        value={utrDetails}
                                        onChange={(e)=>setUtrDetails(e.target.value)}
                                    />

                                </div>
                            }

                            
                            <input
                                type="file"
                                onChange={handleFileSelect}
                                style={{ display: "none" }}
                                id="file-input"
                            />
                            { addPop?.method!=='edit' &&
                            <div style={{display:'flex',justifyContent:'space-between',marginTop:'10px',marginBottom:'10px',alignItems:'center'}}> 
                            <label htmlFor="file-input">
                            <div style={{display:'flex', padding:'5px', border:'1px solid lightgray',cursor:'pointer'}}>
                                <MdOutlineFileUpload style={{marginRight:'10px'}}/> 
                                <div>Upload File </div>
                            </div>
                            </label>
                            <div style={{marginLeft:'10px',fontWeight:'bold'}}>
                                {selectedFile?.name}
                            </div>

                            

                            </div>
                            }


                        
                          

                            <div style={{ color: "red",marginTop:'10px' }} ref={errorRef}>
                                {" "}
                            </div>
                            <div style={{display:'flex',justifyContent:'end',width:'100%'}}>
                            <button 
                            onClick={()=>{
                                if(addPop?.method==='edit'){
                                    handleEditDocument(addPop?.id)
                                }else{
                                     handleUpload()
                                }
                                } }
                                className="custom-btn btn-3"><span>Confirm</span></button>
             

                                </div>
                            
                        </div>
                    </div>
                </Box>
            </Modal>


            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={editPop}
                onClose={() => {
                    setEditPop(false)
                }}
            >
                <Box sx={{...style,height:'400'}}>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid gray",
                                paddingBottom: "10px",
                                fontSize: "20px",

                            }}
                        >
                            <div>Edit Payment Status</div>
                            <AiOutlineCloseCircle
                                onClick={() => {
                                    setEditPop(false)
                                }}
                                size={25}
                                style={{ color: "red", cursor: "pointer" }}
                            />
                        </div>

                        <div 
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "20px",
                            }}
                            
                        >
                          
                        
                               <div>

                                    <div style={{width:'300px !important',margin:'20px 0px'}} className='vendor-date-filter' >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker format="DD/MM/YYYY" label="select Payment Date" value={paymentDate} onChange={(newValue) => setPaymentDate(newValue)} />
                                    </DemoContainer>
                                    </LocalizationProvider>
                                    </div>

                                    <TextField
                                        style={{ marginBottom: "20px", marginTop: "20px" }}
                                        id="standard-basic"
                                        label="Enter UTR Details"
                                        variant="standard"
                                        value={utrDetails}
                                        onChange={(e)=>setUtrDetails(e.target.value)}
                                    />

                                </div>
                            

                            
            
                            <div style={{display:'flex',justifyContent:'end',width:'100%'}}>
                            <button 
                            onClick={()=>{
                                     handleEditPayment(editPop)
                                } }
                                className="custom-btn btn-3"><span>Confirm</span></button>
             

                                </div>
                            
                        </div>
                    </div>
                </Box>
            </Modal>


            <DocumentViewer open={documentOpen} setOpen={setDocumentOpen} />

    </div>
  )
}

export default VendorInvoice
