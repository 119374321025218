import React,{useContext} from 'react'
import { UserContext } from '../App'

const permissionCheck=(level)=>{
    
    if(level==='Dashboard'){
        return false
    }else{
        return true
    }
}

export const HomePermission=({children,level})=> {
  return ( 
   <>
      {children}
   </>
  )
}

export const HospitalPermission=({children,level})=> {
    console.log(level)
    return (
     <>
     { permissionCheck(level) && children}
     </>
    )
  }

export const RadiologistsPermission=({children,level})=> {
    return (
     <>
   { permissionCheck(level) && children}
     </>
    )
  }



  export const InvoicesPermission=({children,level})=> {
    return (
     <>
    { permissionCheck(level) && children}
     </>
    )
  }


export const ReportsPermission=({children,level})=> {
    return (
     <>
      { permissionCheck(level) && children}
     </>
    )
  }

  export const NoticePermission=({children,level})=> {
    return (
     <>
      { permissionCheck(level) && children}
     </>
    )
  }


  export const CollectionsPermission=({children,level})=> {
    return (
     <>
     { permissionCheck(level) && children}
     </>
    )
  }


  export const Vendor=({children,level})=> {
    return (
     <>
     { permissionCheck(level) && children}
     </>
    )
  }

  export const TicketMain=({children,level})=> {
    return (
     <>
     { permissionCheck(level) && children}
     </>
    )
  }
