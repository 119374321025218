import React, { useEffect, useRef, useState } from "react";
import { Button, TextField } from "@mui/material";
import "./HospitalContact.css";
import axios from "axios";
import {
    Switch,
    Modal,
    Box,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin5Fill } from "react-icons/ri";
import MuiAlert from "@mui/material/Alert";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Slide from "@mui/material/Slide";
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: green[400],
        "&:hover": {
            backgroundColor: alpha(green[400], theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: green[400],
    },
}));

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    // height:'500px',
    bgcolor: "background.paper",
    border: "2px solid lightgray",
    boxShadow: 24,
    borderRadius: "30px",
    p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function HospitalContact({ clientid, userAccess, setAuthPop, handleAccess }) {
    const [contacts, setContacts] = useState([]);
    const [addContactPop, setAddContactPop] = useState(false);
    const [contactName, setContactName] = useState(null);
    const [designation, setDesignation] = useState(null);
    const [contact, setContact] = useState(null);
    const [email, setEmail] = useState(null);
    const [alert, setAlert] = useState("");
    const [warning, setWarning] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [editId, setEditId] = useState(null);
    const [popUpdelete, setPopupDelete] = useState(false);
    const navigate=useNavigate()

    const errorRef = useRef();
    const apiContact = async () => {
        try {
            const { REACT_APP_CLIENT_CONTACT_DETAIL_URL } = process.env;
            const res = await axios.get(
                REACT_APP_CLIENT_CONTACT_DETAIL_URL + clientid
            );
            if (res.status === 200) {
                const data = res.data.sort(function (a, b) {
                    return new Date(b.updated_at) - new Date(a.updated_at);
                });
                setContacts(data);
                //   console.log(res?.data.contacts)
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        apiContact();
    }, [clientid, refresh]);

    const handleValidation = () => {
        errorRef.current.innerHTML = "";
        if (contactName === null || contactName === "") {
            errorRef.current.innerHTML = "Please fill the contact Name";
        } else if (designation === null || designation === "") {
            errorRef.current.innerHTML = "Please fill the Designation";
        } else if (contact === null || contact === "") {
            errorRef.current.innerHTML = "Please fill the Contact Number";
        } else if (contact.length != 10) {
            errorRef.current.innerHTML = "Phone Number should be 10 digit";
        } else {
            if (email !== null) {
                if (!isEmailValid(email)) {
                    errorRef.current.innerHTML = "Please enter a valid email";
                    return;
                }
            }
            handleAddContactApi();
        }
    };

    const handleAddContactApi = async () => {
        try {
            const { REACT_APP_CLIENT_CONTACT_DETAIL_URL } = process.env;
            const body = {
                client_id: clientid,
                name: contactName,
                email: email,
                phone: contact,
                type: designation,
            };
            const res = await axios.post(REACT_APP_CLIENT_CONTACT_DETAIL_URL, body);
            //console.log(res);
            if (res.status == 200) {
                setAlert("Contact Added Successfully");
                setRefresh((prev) => !prev);
            } else {
                setWarning("Add Contact Failed");
            }
        } catch (err) {
            //console.log(err);
            setWarning("Add Contact Failed");
        } finally {
            handleClearAll();
        }
    };

    const handleEditContact = (data) => {
        setContact(data?.phone);
        setEditId(data?.id);
        setEmail(data?.email);
        setContactName(data?.name);
        setDesignation(data?.type);
        setAddContactPop("Edit Contact");
    };

    const handleClearAll = () => {
        setContact(null);
        setEditId(null);
        setEmail(null);
        setContactName(null);
        setDesignation(null);
        setAddContactPop();
    };

    const handleEditApi = async () => {
        errorRef.current.innerHTML = "";
        if (contactName === null || contactName === "") {
            errorRef.current.innerHTML = "Please fill the contact Name";
            return;
        }
        if (contact.length != 10) {
            errorRef.current.innerHTML = "Phone Number should be 10 digit";
            return;
        }
        if (email !== null) {
            if (!isEmailValid(email)) {
                errorRef.current.innerHTML = "Please enter a valid email";
                return;
            }
        }
        try {
            const { REACT_APP_CLIENT_CONTACT_DETAIL_URL } = process.env;
            const body = {
                client_id: clientid,
                name: contactName,
                email: email,
                phone: contact,
                type: designation,
                id: editId,
            };
            const res = await axios.put(REACT_APP_CLIENT_CONTACT_DETAIL_URL, body);
            //console.log(res);
            if (res.status == 200) {
                setAlert("Contact Edited Successfully");
                setRefresh((prev) => !prev);
            } else {
                setWarning("Edit Contact Failed");
            }
        } catch (err) {
            console.log(err);
            setWarning("Add Edit Failed");
        } finally {
            handleClearAll();
        }
    };

    function isEmailValid(email) {
        // Regular expression pattern for a basic email validation
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    }

    const dateBodyTemplate = (rowData) => {
        const formattedDate = new Date(rowData).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });
        const datePart = formattedDate.slice(0, -8);
        const timePart = formattedDate.slice(-8);
        return (
            <div>
                <div className="date-part">{datePart} </div>
                <div className="time-part">{timePart}</div>
            </div>
        );
    };

    const handleDelete = async (data) => {
        setPopupDelete("");
        try {
            const { REACT_APP_CLIENT_CONTACT_DETAIL_URL } = process.env;
            const body = {
                client_id: clientid,
                id: data?.id,
                name: data?.name,
            };
            //console.log(body);

            const response = await axios.delete(REACT_APP_CLIENT_CONTACT_DETAIL_URL, {
                data: body,
            });
            if (response.status == 200) {
                setAlert("Contact Deleted Successfully");
                setRefresh((prev) => !prev);
            } else {
                setWarning("Delete Contact Failed");
            }
        } catch (err) {
            console.log(err);
            setWarning("Delete Contact Failed");
        }
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "end" }}>
                <div style={{ float: "right", paddingRight: "20px" }}>
                    <Button
                        onClick={() => {
                            // if (userAccess?.access === 'admin') {
                            //     setAddContactPop('Add Contact')
                            // }
                            if (handleAccess("Hospital", "Contact") === true) {
                                setAddContactPop("Add Contact");
                            } else {
                                setAuthPop(true);
                            }
                        }}
                        size="small"
                        variant="contained"
                    >
                        {" "}
                        Add Contact{" "}
                    </Button>
                </div>
            </div>
            <div className="hospital-contact-table">
                <div>
                    <div>Name</div>
                    <div>Person</div>
                    <div>Contact Number</div>
                    <div>Email Address</div>
                    <div>WhatsApp</div>
                    <div>Created On</div>
                    <div>Action</div>
                </div>

                {contacts?.map((data, id) => (
                    <div key={id} className="hospital-contact-table-body">
                        <div  style={{cursor:'pointer'}}
                          onClick={()=>{
                             navigate(`/Collections/payer/${data?.id}`)
                          }}
                        >{data?.name}</div>
                        <div>{data?.type}</div>
                        <div>{data?.phone}</div>
                        <div>{data?.email}</div>
                        <div>
                            <GreenSwitch
                                checked={data?.type === "PAYER" ? true : false}
                                color="secondary"
                            />
                        </div>
                        <div>{dateBodyTemplate(data?.created_at)}</div>
                        <div>
                            <TbEdit
                                onClick={() => {
                                    // if (userAccess?.access === 'admin') {
                                    if (handleAccess("Hospital", "Contact") === true) {
                                        handleEditContact(data);
                                    } else {
                                        setAuthPop(true);
                                    }
                                }}
                                size={20}
                                style={{
                                    color: "white",
                                    backgroundColor: "blue",
                                    padding: "3px",
                                    borderRadius: "50%",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                }}
                            />
                            <RiDeleteBin5Fill
                                onClick={() => {
                                    // if (userAccess?.access === 'admin') {
                                    if (handleAccess("Hospital", "Contact") === true) {
                                        setPopupDelete(data);
                                    } else {
                                        setAuthPop(true);
                                    }
                                }}
                                size={20}
                                style={{
                                    color: "white",
                                    backgroundColor: "red",
                                    padding: "3px",
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={addContactPop}
                onClose={() => {
                    handleClearAll();
                    setAddContactPop(false);
                }}
            >
                <Box sx={style}>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid gray",
                                paddingBottom: "10px",
                                fontSize: "20px",
                            }}
                        >
                            <div>{addContactPop}</div>
                            <AiOutlineCloseCircle
                                onClick={() => {
                                    handleClearAll();
                                    setAddContactPop("");
                                }}
                                size={25}
                                style={{ color: "red", cursor: "pointer" }}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "20px",
                            }}
                        >
                            <TextField
                                value={contactName}
                                onChange={(e) => setContactName(e.target.value)}
                                style={{ marginBottom: "20px", marginTop: "20px" }}
                                id="standard-basic"
                                label="Type Contact Person Name"
                                variant="standard"
                            />

                            <FormControl
                                style={{ marginBottom: "20px", marginTop: "20px" }}
                                sx={{ m: 1, minWidth: 310 }}
                                size="small"
                            >
                                <InputLabel id="demo-select-small-label">
                                    Select Designation
                                </InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={designation}
                                    label="Select Designation"
                                    onChange={(e) => setDesignation(e.target.value)}
                                >
                                    <MenuItem value="PAYER">Payer</MenuItem>
                                    <MenuItem value="ACCOUNTANT">Accountant</MenuItem>
                                    <MenuItem value="REFERRING DOCTOR">Referring Doctor</MenuItem>
                                    <MenuItem value="OWNER">Owner</MenuItem>
                                    <MenuItem value="TECHNICIAN">Technician</MenuItem>
                                    <MenuItem value="RADIOLOGIST">Radiologist</MenuItem>
                                    <MenuItem value="MANAGER">Manager</MenuItem>
                                    <MenuItem value="OTHERS">Others</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                inputProps={{
                                    inputMode: "numeric",
                                }}
                                value={contact}
                                onChange={(event) => {
                                    const numericValue = event.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                    );

                                    // Update the state with the cleaned integer input

                                    setContact(numericValue);
                                }}
                                style={{ marginBottom: "20px", marginTop: "20px" }}
                                id="standard-basic"
                                label="Enter Your Contact Number"
                                variant="standard"
                            />
                            {/* </form> */}

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    color: "gray",
                                    marginTop: "10px",
                                }}
                            >
                                <div>WhatsApp Number</div> <GreenSwitch color="secondary" />
                            </div>

                            <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{ marginBottom: "20px", marginTop: "20px" }}
                                id="standard-basic"
                                label="Enter Email Address"
                                variant="standard"
                            />

                            <div style={{ color: "red" }} ref={errorRef}>
                                {" "}
                            </div>
                            {addContactPop === "Add Contact" ? (
                                <Button
                                    onClick={() => handleValidation()}
                                    style={{ marginBottom: "20px", marginTop: "20px" }}
                                    variant="contained"
                                >
                                    Save Contact
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => handleEditApi()}
                                    style={{ marginBottom: "20px", marginTop: "20px" }}
                                    variant="contained"
                                >
                                    Edit Contact
                                </Button>
                            )}
                        </div>
                    </div>
                </Box>
            </Modal>

            <Snackbar
                sx={{ top: "50px !important" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={warning}
                autoHideDuration={6000}
                onClose={() => setWarning("")}
            >
                <Alert
                    onClose={() => setWarning("")}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {warning}
                </Alert>
            </Snackbar>

            <Snackbar
                sx={{ top: "50px !important" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={alert}
                autoHideDuration={6000}
                onClose={() => setAlert("")}
            >
                <Alert
                    onClose={() => setAlert("")}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    {alert}
                </Alert>
            </Snackbar>

            <Dialog
                open={popUpdelete}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setPopupDelete("")}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Confirmation !!!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Do you want to delete the contact of {popUpdelete?.name}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPopupDelete("")}>Disagree</Button>
                    <Button
                        onClick={() => {
                            handleDelete(popUpdelete);
                        }}
                    >
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default HospitalContact;
