import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Modal,
  Box,
  TextField,
  Button,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { ModelStyle } from "../Util/style";

function CaseActivatioPop({ Case, setCase,updateStatus,sendAlertEmail}) {
    const [caseReason,setCaseReason]=useState('')
    const [emailReason,setEmailReason]=useState(null)
    const [amount,setAmount]=useState(0)
    const [monthDate,setMonthDate]=useState(null)
    const [error,setError]=useState([])
  return (
    <div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={Case}
        onClose={() => {
          setCase(false);
          setCaseReason('')
          setError([])
        }}
      >
        <Box sx={ModelStyle}>
          <div style={{display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}} >
            <div>
            <FormControl>
              <RadioGroup value={caseReason} 
              onChange={(e)=>{
                  setCaseReason(e.target.value)
                //   console.log(e.target.value)
              }}
                name="controlled-radio-buttons-group">
                <FormControlLabel
                  value="DEMO CASE LIMIT REACHED"
                  control={<Radio style={{ margin: "10px 0px" }} />}
                  label="DEMO CASE LIMIT REACHED"
                />
                <FormControlLabel
                  value="PAYMENT OVERDUE"
                  control={<Radio style={{ margin: "10px 0px" }} />}
                  label="PAYMENT OVERDUE"
                />
                <FormControlLabel
                  value="DRAFT PAGE UNDER MAINTENANCE"
                  control={<Radio style={{ margin: "10px 0px" }} />}
                  label="DRAFT PAGE UNDER MAINTENANCE"
                />
                <FormControlLabel
                  value="OTHER REASON"
                  control={<Radio style={{ margin: "10px 0px" }} />}
                  label="OTHER REASON"
                />
              </RadioGroup>
            </FormControl>
            </div>

           {caseReason==='OTHER REASON' &&   <div>
              <div style={{width:'350px'}}>
               <TextField value={emailReason} onChange={(e)=>{setEmailReason(e.target.value)}}  style={{width:'100%'}} id="standard-basic" label="Please write detailed reason along with note of the discussion" variant="standard" />
              
              </div>
            </div>
           }

           {(caseReason==='DRAFT PAGE UNDER MAINTENANCE' ||caseReason ==='DEMO CASE LIMIT REACHED') &&   <div>
              <div style={{width:'350px'}}>
               <TextField value={emailReason} onChange={(e)=>{setEmailReason(e.target.value)}}  style={{width:'100%'}} id="standard-basic" label="Please enter the reason" variant="standard" />
              
              </div>
            </div>
           }  

          {caseReason==='PAYMENT OVERDUE' &&   <div>
              <div style={{width:'350px',marginTop:'10px'}}>
               <TextField value={amount} onChange={(e)=>{setAmount(e.target.value)}} type="number"  style={{width:'100%'}} id="standard-basic" label="Please Enter OverDue Amount" variant="standard" />
               <div style={{width:'350px',marginTop:'30px'}}>
               <TextField value={emailReason} onChange={(e)=>{setEmailReason(e.target.value)}}  style={{width:'100%'}} id="standard-basic" label="Please enter the reason" variant="standard" />
              
              </div>
              <div style={{marginTop:'30px'}}>
               <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker sx={{width:'350px'}}
                views={["month", "year"]}
                label="From"
                value={monthDate}
                onChange={setMonthDate}
                inputFormat="MM/yyyy"
                renderInput={(params) => (
                  <TextField className="monthPicker" value={monthDate} />
                )}
              />
            </LocalizationProvider>
            
              </div>
             
              </div>
            </div>

           }

            { error?.length>0  &&<div  style={{color:'red',marginTop:'10px'}}>
                 {error[0]}
              </div>
             }
            <div>
            <Button
                 onClick={() => {
                    if(caseReason==='PAYMENT OVERDUE'){
                      if(amount<=0){
                        setError(['Please Enter the OverDue Amount'])
                          return 
                      }
                      else if (monthDate===null){
                        setError(['Please Choose the month from'])
                        return 
                      }else if(emailReason?.toString()?.length<10 || !emailReason){
                        setError(['Reason should be more than 10 characters '])
                        return 
                      }
                    }else if (caseReason==='OTHER REASON'){
                      if(emailReason?.toString()?.length<100 || !emailReason){
                        setError(['Reason should be more than 100 characters '])
                        return 
                      }
                    }else{
                      if(emailReason?.toString()?.length<10 || !emailReason){
                        setError(['Reason should be more than 10 characters '])
                        return 
                      }
                    }
                    setError([])
                    updateStatus(caseReason)
                    sendAlertEmail('Case Deactivation',caseReason,emailReason,amount,monthDate)
                 }}
                  style={{ marginBottom: "20px", marginTop: "20px" }}
                 variant="contained"
                >
                 Update
            </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default CaseActivatioPop;
