import React,{useEffect, useState} from 'react'
import {
    getGridDateOperators,
    GridToolbarContainer,
    GridToolbarQuickFilter,
  } from "@mui/x-data-grid";
import SlidingPane from "react-sliding-pane";
import { DateRangePicker } from "react-date-range";
import Chip from '@mui/material/Chip';
import "../Radiologists/Radiologistics.css";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles, styled } from "@mui/styles";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { RiEditBoxLine } from "react-icons/ri";
import axios from "axios";
import {
    Box,
  } from "@mui/material/";
import { Link } from "react-router-dom";
import EditTicket from './EditTicket';



  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
  
      "& .css-204u17-MuiDataGrid-main": {
        borderRadius: "0px 0px 0px 0px",
      },
      "& .MuiDataGrid-cell": {
        textAlign: "center",
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      },
      "& .MuiDataGrid-filterForm": {},
      "& .MuiDataGrid-menuList": {
        fontSize: "10px",
      },
  
      "& .MuiList-root": {
        "& ..MuiDataGrid-menuList": {
          fontSize: "10px",
        },
      },
      "& .MuiDataGrid-footerContainer": {
        backgroundColor: "#FFFFFF",
      },
  
      "& .MuiDataGrid-row": {
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "lightblue !important",
          // transform: 'scale(1.01)',
          //'#d6d7f8', //'#d61e11',
          // borderRadius:'11px',
          // border:'solid 1px grey',
          transform: "scale(1.004)",
          fontWeight: "bold",
        },
        height: 60,
        border: "none",
        fontSize: "12px",
  
        "&:nth-child(2n)": {},
      },
      "& .MuiDataGrid-columnHeader": {
        textAlign: "center",
        backgroundColor: "#3C48AD",
        color: "#FFFFFF",
        fontSize: "0.875rem",
        borderColor: "black",
  
        // opacity:'0.7'
      },
      "& .MuiDataGrid-toolbarFilterList": {
        //  backgroundColor:'red'
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bolder",
        textAlign: "center",
      },
  
      "& .MuiDataGrid-sortIcon": {
        color: "white",
        "&:hover": {
          transform: "scale(1.6)",
        },
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
        },
      },
    },
  }));



function Ticket({email,accessLevel}) {
    const classes = useStyles();
    const [mapData,setMapData]= useState([])
    const [ticketPop,setTicketPop]=useState(null)
    const [ticketId,setTicketId]=useState(null)


    const getUrlParams = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const params = {};
        for (const [key, value] of urlParams.entries()) {
          params[key] = value;
        }
        return params;
      };

      useEffect(()=>{
        const params= getUrlParams()
        setTicketId(params?.id)
      },[])

      const updateUrlParams = (params) => {
        const url = new URL(window.location.href);
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            url.searchParams.set(key, params[key]);
          }
        }
        window.history.replaceState({}, '', url);

    }

    // console.log(accessLevel)

    const columns = [
        {
          field: "ticket_id",
          headerName: "Ticket Id",
          flex: 1,
          minWidth:70,
          // align:'center',
        },
        {
          field: "client_name",
          headerName: "Client Name",
          flex: 1,
          minWidth: 150,
          // align:'center',
        },

        {
            field: "dispute_reason",
            headerName: "Dispute Reason",
            flex: 1,
            minWidth: 150,
            // align:'center',
          },
      
        {
          field: "reference_no",
          headerName: "Ref No",
          flex: 1,
          minWidth: 150,
          // align:'center',
        },
        {
          field: "email_address",
          headerName: "Email",
          flex: 1,
          minWidth: 150,
          // align:'center',
        },
        {
          field: "contact_number",
          headerName: "Contact Number",
          flex: 1,
          minWidth: 150,
          // align:'center',
        },
        {
            field: "comment",
            headerName: "Comments",
            flex: 1,
            minWidth: 150,
            // align:'center',
          },
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          minWidth: 150,
          renderCell: (params) => {
            const status = params.value;
            return <span>{status?.toString()?.toLowerCase()==='closed'?
            <Chip color='error' label="Closed" variant='outlined'  /> : status?.toString()?.toLowerCase()==='created' ?
            <Chip color="success" label="Created"  variant='outlined'  /> :
            <Chip color="primary"  label="In Progress"  variant='outlined' /> 
        }</span>
            
          },
        },
      
        {
          field: "Action",
          headerName: "action",
          textAlign: "center",
          flex: 1,
          minWidth: 150,
          type: "actions",
          // cellClassName: 'actions',
          renderCell: (props) => (
            <strong>
          
              <RiEditBoxLine onClick={()=>{
                console.log(props)
                setTicketPop(props?.row)
              }}

                style={{
                  fontSize: "20px",
                  display: "block",
                  margin: "auto",
                  color: "blue",
                }}
              />
            </strong>
          ),
        },
      ];

    const columns1 = React.useMemo(
        () =>
          columns.map((col) =>
            col.field === "rad_onboarding_date"
              ? {
                  ...col,
                  filterOperators: getGridDateOperators(),
                }
              : col
          ),
        [columns]
      );

      const getTicketAll=async()=>{
        try{
            if(ticketId){
                const { REACT_APP_GET_TICKETS_BY_ID } = process.env
                const res = await axios.get(REACT_APP_GET_TICKETS_BY_ID+`?ticket_id=${ticketId}`)
                if (res?.data?.data?.length > 0) {
                    setMapData(res?.data?.data)
                    setTicketPop(res?.data?.data?.[0])
                }else{
                    setMapData([])
                }
            }else{
            const res = await axios.get(process.env.REACT_APP_TICKET_GET_ALL_URL)
            if(res?.data?.data?.length>0){
                setMapData(res?.data?.data)
            } 
        } 
        }catch(err){

        }
      }

      useEffect(()=>{
        getTicketAll()
      },[ticketId])


  return (
    <div>
        <Box
         sx={{
           height: "calc(100vh - 98px)",
           marginLeft: "-10px",
           marginTop:'20px',
           padding: "10px 10px 10px 25px",
           borderRadius: "10px",
           overflow: "hidden",
         }}
       >
         <DataGrid
           //  {...data}
           slotProps={{
             toolbar: { printOptions: { disableToolbarButton: true } },
           }}
           getRowId={(data1) => data1.ticket_id}
           sx={{
             boxShadow: 1,
             borderRadius: "10px",
             // width:'calc(100vw - 250px)',
             // width:'100%',
             // border:1,
             borderColor: "#f5f5f5",
             backgroundColor: "#FFFFFF",
           }}
           components={{
             Toolbar: ({ printOptions }) => {
               return (
                 <GridToolbarContainer
                   sx={{ justifyContent: "space-between", marginBottom: "5px" }}
                 >
                   {" "}
                   <div
                     style={{
                       padding: "10px",
                       fontSize: "20px",
                       fontWeight: "bold",
                       display: "flex",
                       alignItems: "center",
                     }}
                   >
                   
                        <div>Tickets</div>
                    
 
               
                   </div>
                   <div style={{ display: "flex" }}>
                     <GridToolbarQuickFilter
                       className="seacrh-filter"
                       style={{ margin: "15px 0px" }}
                     />
                     <div style={{ padding: "15px" }}>
                       <div
                         className="header-section"
                         style={{ marginTop: "4px", marginRight: "5px" }}
                       >
                         {/* <Tooltip title="Upload in House Salary" arrow> */}
                           
                         {/* </Tooltip> */}
                       </div>
                     </div>
                     {/* <GridToolbarExport
                        
                       printOptions={{ disableToolbarButton: true }}
                       style={{
                         width: "100px",
                         color: "black",
                         marginRight: "20px",
                         margin: "10px 0px",
                       }}
                     /> */}
                     
                   </div>
                 </GridToolbarContainer>
               );
             },
           }}
           density="standard"
           className={classes.root}
           rowSpacingType="border"
           columnHeaderHeight={60}
           rows={mapData}
           columns={columns1}
           initialState={{
             pagination: {
               paginationModel: {
                 pageSize: 20,
               },
             },
           }}
           pageSizeOptions={[5, 10, 20]}
           disableRowSelectionOnClick
         />
       </Box>

       {/* <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={ticketPop}
        title="Hey, it is optional pane title.  I can be React component too."
        subtitle="Optional subtitle."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
        //   setState({ isPaneOpen: false });
        }}
      >
        <div>And I am pane content. BTW, what rocks?</div>
        <br />
        <img src="img.png" />
      </SlidingPane> */}


       <EditTicket accessList={accessLevel} isModalOpen={ticketPop} email={email} fetchData={getTicketAll} setIsModalOpen={setTicketPop} />
      
    </div>
  )
}

export default Ticket
