export const ModelStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    // height:'500px',
    bgcolor: "background.paper",
    border: "2px solid lightgray",
    boxShadow: 24,
    borderRadius: "30px",
    p: 4,
  };