import React,{useState} from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions,Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Modal from '@mui/material/Modal'
import * as XLSX from 'xlsx';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid lightblue',
    boxShadow: 24,
    p: 4,
  };


function UploadRadPrice({data,onClose,email,setValue,setApiRefresh,handleExcelSend}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [excelFileName, setExcelFileName] = useState(null);
    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
      };

      const handleReadExcel = () => {
        if (!selectedFile) {
          //console.log('No file selected.');
          return;
        }
        //console.log(selectedFile)
        handleExcelSend(selectedFile)
      };
     
     

    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        //console.log('Selected file:', selectedFile);
        setSelectedFile(selectedFile);
        if(selectedFile?.name)
          setExcelFileName(selectedFile?.name);
        else{
            setExcelFileName('No file selected')
        }
        setOpenConfirmationDialog(true); // Show the confirmation dialog after file selection
      };
   
      const handleCancelUpload = () => {
        setOpenConfirmationDialog(false);
        setSelectedFile(''); // Reset selectedFile state when canceling the upload
        setExcelFileName('No file selected')
      };
    
    
      const handleConfirmUpload = () => {
        setOpenConfirmationDialog(false);
        handleReadExcel(); // Call handleReadExcel to process the selected file after confirmation
        onClose(); // Close the UploadCost modal after confirming the upload
      };

  return (
    <div>
    <Modal
    open={!!data}
    onClose={onClose}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
   <Box sx={{...style,  width: 350 }}>
         <h3 style={{display:'flex',justifyContent:'center',color:'darkblue'}}>Upload Price</h3>
    <div style={{display:'flex',justifyContent:'center'}}>
        <input type="file" accept=".xlsx" onChange={handleFileSelect} style={{ display: 'none' }} id="file-input-price" />
      <DialogActions>
        <Button onClick={onClose} style={{fontSize:'12px'}} color="primary">
          Close
        </Button>
        <label htmlFor="file-input-price">
          <Button style={{fontSize:'11px',marginLeft:'10px'}}  variant="contained" size='small' color="primary" component="span">
            <CloudUploadIcon style={{ marginRight: '5px',width:'10px !important'}} />
            Upload Excel File
          </Button>
        </label>
      </DialogActions>
      <Dialog width={500} open={openConfirmationDialog} onClose={handleCloseConfirmationDialog}>
  <DialogTitle>Confirmation !!!</DialogTitle>
  <DialogContent>
    {excelFileName && <div style={{width:'500px',height:"40px"}}>Selected Excel File: {excelFileName}</div>}
    {/* {uploadStatus && <p style={{ color: 'red' }}>{uploadStatus}</p>} */}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCancelUpload} color="primary">
      Cancel
    </Button>
    <Button onClick={handleConfirmUpload} color="primary" autoFocus> {/* Add autoFocus attribute here */}
      Upload
    </Button>
  </DialogActions>
</Dialog>
    </div>
    </Box>
    </Modal>

    </div>
  )
}

export default UploadRadPrice