import React, { useEffect, useState } from 'react'
import { Dialog } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import './ViewNote.css'
import axios from 'axios';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function ViewNote({ note, setNote, setApiResponse, setErrorAlert, email,handleAccess,setAuthPop }) {

    const [noteData, setNoteData] = useState([])
    const [resendNote,setResendNote]=useState('')

    const handleSendNote = async (data1) => {
        try {

            const data = [
                {
                    "clientId": data1?.client_fk,
                    "startDate": note?.start_date?.split('T')[0],
                    "endDate": note?.end_date?.split('T')[0],
                    "invoiceDate": note?.invoice_sent_date?.split('T')[0],
                    "user_email": email,
                },
            ];

            //console.log(data)
            if (data1?.flag === 'credit') {
                const { REACT_APP_CLIENT_SEND_CREDIT_URL } = process.env
                const response = await axios.post(REACT_APP_CLIENT_SEND_CREDIT_URL, data)
                if (response.status === 200) {
                    if (response.data[0]?.toString().toLowerCase() === 'Email sent successfully'.toString().toLowerCase()) {
                        setApiResponse('Email sent successfully'); // Set the API response in the state
                        //console.log(response.data)
                    } else {
                        setErrorAlert('Email has not been Sent')
                    }
                }
                else {
                    setErrorAlert('Sent Note Failed')
                }
            } else if (data1?.flag === 'debit') {
                const { REACT_APP_CLIENT_SEND_DEBIT_URL } = process.env
                const response = await axios.post(REACT_APP_CLIENT_SEND_DEBIT_URL, data)
                if (response.status === 200) {
                    if (response.data[0]?.toString().toLowerCase() === 'Email sent successfully'.toString().toLowerCase()) {
                        setApiResponse('Email sent successfully'); // Set the API response in the state
                        //console.log(response.data)
                    } else {
                        setErrorAlert('Email has not been Sent')
                    }
                }
                else {
                    setErrorAlert('Sent Note Failed')
                }
            }
        } catch (err) {
            //console.log(err)
            setErrorAlert("Sent Note Failed")
        } finally {
            // setNote('')
        }
    }

    const handleGetNoteApi = async () => {
        try {
            const { REACT_APP_CLIENT_VIEW_NOTES } = process.env
            const res = await axios.get(REACT_APP_CLIENT_VIEW_NOTES + `invoice_number=${note?.invoice_num}`)
            //console.log(res)
            if (res.status === 200) {
                if (res?.data?.data) {
                    setNoteData(res?.data?.data)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (note?.invoice_num) {
            handleGetNoteApi()
        }
    }, [note?.invoice_num, note])


    return (
        <Dialog
            fullScreen
            open={note}
            onClose={() => {
                setNote('')
            }}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#0059B2' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setNote('')}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        View Notes
                    </Typography>

                </Toolbar>
            </AppBar>

            <div className='view-note-table'>
                <div className='view-note-table-head'>
                    <div>Note Number</div>
                    <div>Type</div>
                    <div >Invoice Number</div>
                    <div>Amount</div>
                    <div>Send By</div>
                    {/* <div>Resend</div> */}
                </div>
                {noteData?.map((data, id) => (
                    <div key={id} className='view-note-table-body'>
                        <div>{data?.note_num}</div>
                        <div style={{ justifySelf: 'center' }}>

                            {data?.flag === 'credit' ?
                                <div className='credit'>
                                    Credit
                                </div> :
                                <div className='debit'>
                                    Debit
                                </div>

                            }

                        </div>
                        <div>{data?.invoice_num}</div>
                        <div>{data?.amount}</div>
                        <div>{data?.credit_note_sent_by}</div>
                        {/* <div>
                            <Button onClick={() => {
                                if(handleAccess('Hospital','Send Note')){ 
                                  setResendNote(data)
                                }else{
                                    setAuthPop(true)
                                    console.log('hehe')
                                }
                            }} size='small' variant='contained' style={{ fontSize: '12px' }} >
                                Resend
                            </Button>
                        </div> */}

                    </div>))

                }

            </div>
            <Dialog
                open={resendNote}
                TransitionComponent={Transition}
                keepMounted
                onClose={()=>setResendNote('')}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
                fullWidth
            >

                <DialogTitle>Confirmation !!!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Do you want to Resend the Note
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setResendNote('')}>Disagree</Button>
              <Button onClick={()=>{
                handleSendNote(resendNote)
                setResendNote('')
              }}>Agree</Button>
                </DialogActions>
            </Dialog>
        </Dialog>

    )
}

export default ViewNote
