import React,{useState,useEffect,useContext} from 'react'
import {
    useLocation,
  } from "react-router-dom";
import Payer from './Payer';
import Payment from './Payment';
import Projection from './Projection';
import Pending from './Pending';
import Productivity from './Productivity';
import { UserContext } from '../../App';
import AuthErrorPop from '../Util/AuthErrorPop';

function CollectionMain({email}) {
    const location = useLocation();
    const [url, setUrl] = useState(location.pathname);
    const [value,SetValue]=useState('')
    const pathnameParts = location.pathname.split('/');
    const tabname = pathnameParts[pathnameParts.length - 1];
    const [authPop,setAuthPop]=useState('')
    const {user} = useContext(UserContext);
    const {handleAccess,apiState,setApiState}= useContext(UserContext);
 
    useEffect(()=>{
        SetValue(tabname)
     },)
  return (
    <div>
            {(value==='payer') &&
              <Payer handleAccess={handleAccess} authPop={authPop} setAuthPop={setAuthPop} />
            }

            {(value==='payment') &&
              <Payment />
            }

            {(value==='projection') &&
                  <Projection />
             }

            {(value==='pending') &&
                          <Pending />
                        }

            {(value==='productivity') &&
                          <Productivity />
                        }

            <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop}  />
    </div>

    
  )
}

export default CollectionMain
