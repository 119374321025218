import React,{useState,useEffect,useRef} from 'react'
import Empty_image from "../../Images/Empty_Page.png";
import './Collection.css'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { FaUser } from "react-icons/fa";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import { MdClear } from "react-icons/md";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {TextField,InputAdornment } from "@mui/material";
import dayjs from "dayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
// import {SingleDatePickerField} from '@mui/x-date-pickers-pro/';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

export const dateBodyTemplate = (rowData) => {
  const formattedDate = new Date(rowData).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const datePart = formattedDate.slice(0, -8);
  const timePart = formattedDate.slice(-8);
  return (
    <div>
      <div className="date-part-collection">
        {datePart}
        {timePart}{" "}
      </div>
      {/* <div className="time-part">{timePart}</div> */}
    </div>
  );
};




function Collection() {
  const [data,setData]=useState([])
  const [mapData,setMapData]=useState([])
  const [emalCount,setEmailCount]=useState([])
  const [loading,setLoading]=useState(false)
  const navigate= useNavigate()
  const [search,setSearch]=useState('')
  const [selectDate,setSelectedDate]=useState([
    dayjs().startOf("month"),
    dayjs().endOf("month"),
  ])

  const columns = [
    {
       field: 'name',
     headerName: 'Name',
      width: 90,
       flex:1,
        align:'center' ,
        renderCell:(data)=>{
          return <div> <FaUser size={15} color='gray' style={{marginRight:'10px'}} /> {data?.row?.name}</div>
        }
       
      },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      align:'center',
      flex:1,
    },
    {
      field: 'count',
      headerName: 'Count',
      width: 150,
      align:'center',
      flex:1,
    },
    {
      field: 'last_message',
      headerName: 'Last Message',
      width: 110,
      flex:1,
      align:'center',
      renderCell:(data)=>{
        return <div>{dateBodyTemplate(data?.row?.last_updated)}</div>
      }
     
    },
  ];

  const columnsMessage = [
    { field: 'client_name', 
    headerName: 'Client Name',
     width: 90,
      flex:1,
       align:'center',
      
      },
    {
      field: 'name',
      headerName: 'Contact Name',
      renderCell:(data)=>{
        return <span onClick={()=>{ 
          if(data?.row?.contact_id){
          navigate(`/Collections/payer/${data?.row?.contact_id}`)}
        }
        } style={{margin:'10px !important',cursor:'pointer',color:'#1677ff'}} >{ (data?.row?.contact_id) ? data?.row?.name:'Client Level'}</span>
      },
      align:'center',
      flex:1,
    },
    {
      field: 'original_message',
      headerName: 'Message',

      align:'center',
      flex:1,
      renderCell:(data)=>{

        return <span style={{fontWeight:'bold',margin:'10px !important'}} >{data?.row?.original_message}</span>
      }
    },
    {
      field: 'sende',
      headerName: 'Messaged by',
      // width: 110,
      flex:1,
      align:'center',
      renderCell:(data)=>{
        return <div><FaUser size={15} color='gray' style={{marginRight:'10px'}} /> {data?.row?.from_json?.name}</div>
      }
    },
    {
      field: 'date',
      headerName: 'Messaged At',
      // width: 110,
      flex:1,
      align:'center',
      renderCell:(data)=>{
        return <div>{dateBodyTemplate(data?.row?.updated_at)}</div>
      }
    },
    {
      field: 'tag',
      headerName: 'Tag',
      flex:1,
      align:'center',
    },
  ];
      
    const getSummaryMessage =async()=>{
      try{
          setLoading(true)
          setData([])
          const {REACT_APP_GET_SUMMARY_MESSAGE} =process.env
          const res = await axios.get(REACT_APP_GET_SUMMARY_MESSAGE+`?start_date=${funDate(selectDate[0])}&end_date=${funDate(selectDate[1])}`)
          console.log(res?.data?.data)
          if(res.status==200){
              if(res?.data?.data?.length>0){
                  let key=0;
                  const data=res?.data?.data?.map((data)=>{
                      return{...data,key: key++}
                  })
                  console.log(data)
                  setData(data)
              }
          }
      }catch(err){

      }finally{
        setLoading(false)
      }
  }



  const getCountForMail=()=>{
      const result = [];
      let key=0;
      data.forEach((item) => {
      const email = item.from_json.email;
      const name = item.from_json.name;

      // Finding an existing entry in the result array
      const existingEntry = result.find(entry => entry.email === email );
        
      // If an entry exists, increment the count; otherwise, create a new entry
      if (existingEntry) {
          existingEntry.count++;
          if(new Date(existingEntry?.last_updated)<new Date(item?.updated_at)){
            existingEntry.last_updated=item?.updated_at
          }
      } else {
          result.push({ name, email, count: 1,last_updated:item?.updated_at,key:key++});
      }
      });

      console.log(result)
      
      setEmailCount(result?.sort((a,b)=>b.count-a.count))
  }


  const funDate = (date1) => {
    const date = new Date(date1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getSortData =()=>{

        setMapData(data?.sort((a,b)=>{
          return new Date(b?.updated_at) - new Date(a?.updated_at)
        }))
        
  }


  useEffect(()=>{
      getCountForMail()
      getSortData()
  },[data])

  const dateFormat=(date,format)=>{
    const givenDate = new Date(date);
    const oneDayBefore = new Date(givenDate);
    oneDayBefore.setDate(givenDate.getDate() +format);
    
    const formattedOneDayBefore = oneDayBefore.toISOString().slice(0, 10);
    return formattedOneDayBefore; 
  }

  const handleClearAll=()=>{
    getSortData()
    setSearch('')
    setSelectedDate([
      dayjs().startOf("month"),
      dayjs().endOf("month"),
    ])

  }


  useEffect(()=>{
    // console.log(new Date(selectDate[0]).setDate(new Date(selectDate[0])-1).toISOString().slice(0,10))
   
      getSummaryMessage()
  },[selectDate])

const [searchText, setSearchText] = useState('');
const [searchedColumn, setSearchedColumn] = useState('');
const searchInput = useRef(null);
  return (
      <div>
        <div style={{display:'flex',justifyContent:'space-between',padding:'30px'}} >
          <div className='tilte-collection' >Collection</div>
          <div style={{display:'flex',alignItems:'center'}}>
            <div className='div-daterange'>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["SingleInputDateRangeField"]}>
              <DateRangePicker
                slots={{ field: SingleInputDateRangeField }}
                format="YYYY/MM/DD"
                style={{height:'20px'}}
                value={selectDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                calendars={1}
              />
            </DemoContainer>
          </LocalizationProvider>


            </div>

            <div className='search-div-colection'  ><TextField 
                 value={search} onChange={(event) => setSearch(event.target.value)} id="outlined-basic" label="Search" variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    /></div>

            <div>  <MdClear onClick={()=>handleClearAll()} style={{cursor:'pointer',marginLeft:'10px'}} size={25} /></div>
          </div>
        </div>

        <div style={{margin:'0px 20px'}}>
        <Box className='summary-table' sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={emalCount?.filter(data=>{
          if(search===''){
            return true
          }else if (data?.email?.toString().toLowerCase().includes(search?.toString().toLowerCase())||
          data?.count?.toString().toLowerCase().includes(search?.toString().toLowerCase())  ||
          data?.name?.toString().toLowerCase().includes(search?.toString().toLowerCase()) 
      
          ){
              return true
          }
        })}
        columns={columns}
        getRowId={(data1) => data1.key}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5,10,20]}
        onCellClick={(params) => {
          console.log(params)
          setMapData(data?.filter(data=>{
            if(params?.row?.email===data?.from_json?.email){
                return data
            }
         })?.sort((a,b)=>new Date(b)-new Date(a)))
        }}
      />
    </Box>
        </div>

        <div style={{margin:'20px 20px'}}>
        <Box className='summary-table' sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={mapData?.filter(data=>{
          if(search===''){
            return true
          }else if (data?.client_name?.toString().toLowerCase().includes(search?.toString().toLowerCase())||
          data?.name?.toString().toLowerCase().includes(search?.toString().toLowerCase())  ||
          data?.original_message?.toString().toLowerCase().includes(search?.toString().toLowerCase()) ||
          data?.from_json?.name?.toString().toLowerCase().includes(search?.toString().toLowerCase()) ||
          data?.flag?.toString().toLowerCase().includes(search?.toString().toLowerCase()) ||
          data?.tag?.toString().toLowerCase().includes(search?.toString().toLowerCase())
          ){
              return true
          }
        })}
        columns={columnsMessage}
        getRowId={(data1) => data1.key}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5,10,20]}
      />
    </Box>
        </div>

        

       {/* <img className='page_img' style={{width:'99%',height:'93vh'}} src={Empty_image}></img> */}
     </div>
  )
}

export default Collection
