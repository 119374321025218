import React, { useState,useContext, useEffect } from 'react';
import {   Input, Select, message,Tag,Popconfirm } from 'antd';
import { Modal } from '@material-ui/core';
import { toast } from "react-toastify";
import { Button } from '@mui/material';
import { UserContext } from '../../App';
import { type } from '@testing-library/user-event/dist/type';
import axios from 'axios';
import SlidingPane from "react-sliding-pane";
import { TbBrandReason } from "react-icons/tb";
import { FaUser } from "react-icons/fa";
import { TbEye } from "react-icons/tb";
import "./Ticket.css"
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Textarea } from '../HospitalNotices/HospitalNotices';
import CustomAlertDialog from '../../Utils/CustomAlertDialog';
import { MdOutlinePreview } from "react-icons/md";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";


function EditTicket({isModalOpen,setIsModalOpen,clientId,email,type,fetchData,accessList}) {
    const [ticketStatus,setTicketStatus]=useState(null)
    const [assigned, setAssigned] = useState(null);
    const [comment, setComment] = useState(null);
    const {handleGetNotification } = useContext(UserContext);
    const [closed,setClosed]=useState(false)
    const [access,setAccess]=useState([])
    const  [history,setHistoryData]=useState(null)
    const [watcher,setWatcher]=useState([])
    const [close,setClose]=useState(null)
    const [documentPop,setDocumentPop]=useState(false)



   useEffect(()=>{
  
    if(accessList){
    
        setAccess(accessList)
    }
   },[accessList])



  const showModal = () => {
    setIsModalOpen(true);
  };


  useEffect(()=>{
    console.log('hii')
    if(isModalOpen?.ticket_id){
    
        getHistory()
    }
   

  },[isModalOpen?.ticket_id])

  useEffect(()=>{
    if(history?.comment?.[history?.comment?.length-1]?.status?.toLowerCase()==='closed'){
        setClosed(true)
    }
  },[history])

  useEffect(() => {
    // Scroll to the bottom of the chat when messages change

      const chatContainer = document.getElementById("comment-history");
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    
  }, [history]);

  const getHistory=async()=>{
    try{
        const {REACT_APP_TICKETS_HISTORY_URL} = process.env
        const res = await axios.get(REACT_APP_TICKETS_HISTORY_URL+`?ticket_id=${isModalOpen?.ticket_id}`)
        if(res?.data?.data?.length>0){
            setHistoryData(res?.data)
            setWatcher(res?.data?.data?.[0]?.watcher_list?.split(','))
        }

    }catch(err){

    }
  }


  const generateName=(email)=>{
    const data = access?.filter(data=>{
     if(data?.email==email?.toString()?.toLowerCase()){
       return data
     }
    })

    return data?.[0]?.name
 }

  const handleCancel = () => {
    // setIsModalOpen(false);
    setAssigned(null)
    setComment(null)
    setTicketStatus(null)
    setHistoryData(null)
    setClosed(false)
  };

  const handleOk = (status) => {
    if (!comment){
        message.error("Please Enter Comments")
        return
    }
    handleAddTicket(status)
  };

  const handleUpdateWatcher=async(watch)=>{
    let watchList=watcher?.length>0 ? watcher :[];
    if(watch){
        watchList.push(watch) 
    }
    try{
        const {REACT_APP_TICKETS_EDIT_WATCHER_LIST} = process.env
        const body={
            "ticket_id":isModalOpen?.ticket_id,
            "watcher_list":Array.from(new Set(watchList)).join()
        }
        const res = await axios.post(REACT_APP_TICKETS_EDIT_WATCHER_LIST,body)
        if(res?.status===200){
            if(!watch){
            message.success('Updated Watcher List')
            }
            getHistory()
        }else{

        }

    }catch(err){

    }
  }

  const handleSendTicketEmail=async(body)=>{
    try{
        const {REACT_APP_TICKETS_SEND_TICKET_EMAIL} = process.env
        const res = await axios.post(REACT_APP_TICKETS_SEND_TICKET_EMAIL,body)

    }catch(err){
        console.log(err)
    }




  }

  const handleAddTicket=async(status)=>{
    try{
        const {REACT_APP_EDIT_TICKETS}=process.env
        const assignedName = access?.filter(data=>{
            if(data?.email===assigned){
                return data
            }
        })
        console.log(access)
        const userName = access?.filter(data=>{
            if(data?.email===email){
                return data
            }
        })
        const body={
            "ticket_id":isModalOpen?.ticket_id,
            "ticket_details":comment,
            "user_details":userName?.[0]?.email,
            "type":"external",
            "assigned_to":assigned? assigned :history?.comment?.[history?.comment?.length-1]?.assigned_to,
            "department":"finance",
            "comment":comment,
            "status":status
        }
        const res = await axios.post(REACT_APP_EDIT_TICKETS,body)
        if(res?.data=='Successfully updated ticket'){
            // message.success('Ticket Updated Successfully')
            // handleUpdateWatcher(assigned? assigned :history?.comment?.[history?.comment?.length-1]?.assigned_to)
            if (status !=='Closed'){
                const dataBody={
                    type:'follow_up',
                    issue_type:history?.data?.[0]?.dispute_reason,
                    client_type:history?.data?.[0]?.type_of_client,
                    "sender":userName?.[0]?.name,
                    "client_name":history?.data?.[0]?.client_name,
                    ticket_id:isModalOpen?.ticket_id,
                    comment:comment,
                    status:'In Progress',
                    flag:'external'
                }
                handleSendTicketEmail(dataBody)
            }else{
                const dataBody={
                    type:'close',
                    issue_type:history?.data?.[0]?.dispute_reason,
                    client_type:history?.data?.[0]?.type_of_client,
                    "sender":userName?.[0]?.name,
                    "client_name":history?.data?.[0]?.client_name,
                    ticket_id:isModalOpen?.ticket_id,
                    comment:comment,
                    ref_no:history?.data?.[0]?.reference_no,
                    status:'Closed',
                    flag:'external',
                    external_email:history?.data?.[0]?.email_address,
                }
                handleSendTicketEmail(dataBody)
            }
        }else{
            message.error("Ticket Updation Failed")
        }
    }catch(err){
        message.error("Ticket Updation Failed")
        console.log(err)
    }finally{
        handleCancel()
        setComment(null)
        fetchData()
        getHistory()
    }
  }


  const dateFormated=(date1)=>{
    const date = new Date(date1);

// Define options for the desired format
const options = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
  day: '2-digit',
  month: 'short',
  year: 'numeric'
};

// Format the date
const formattedDate = date.toLocaleString('en-US', options).replace(',', '');
    return formattedDate
  }


  return (
    <div>
        <SlidingPane 
           className="tikcet-edit-view"
            closeIcon={<div style={{marginTop:'40px',padding:'20px'}} >Close</div>}
            isOpen={isModalOpen }
            title="Close"
            from="right"
            width="40%"  
            onRequestClose={() => {
                setIsModalOpen(false)
                handleCancel()
                window.history.replaceState({}, document.title, window.location.pathname);
            }}
            >
        <div style={{ display:'flex',flexDirection:'column',justifyContent:'space-around' }}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={{display:'flex',justifyContent:'space-between'}}> <TbBrandReason style={{marginRight:'10px'}} size={20} />{history?.data?.[0]?.type_of_client} - {history?.data?.[0]?.dispute_reason} </div>
                <div> 
                    {
                     isModalOpen?.url && <a href={isModalOpen?.url} target='blank'><MdOutlinePreview size={20} /></a>
                }
  </div>
                {/* <Popconfirm
                                            title={
                                                <div style={{cursor:'pointer'}}>
                                                    <div > Edit Watcher List</div>
                                                    <Select
                                                    className='watch-list'
                                                    label='Watcher List'
                                                    placeholder="Select Watcher List"
                                                    value={watcher}
                                                    showSearch
                                                    style={{
                                                        width: 400,
                                                        margin:'20px 0px'
                                                    }}
                                                    mode='multiple'
                                                    onChange={(value)=>setWatcher(value)}
                                                    options={
                                                        access?.filter(data=>{
                                                            if(data?.name!=='TechSupport'&&data?.name!=='5C Cyber'&&data?.name!=='5CAccounts'){
                                                                return true
                                                            }
                                                        })?.map(data=>{
                                                            return {
                                                                value:data?.email,
                                                                label:data?.name,
                                                            }
                                                        })
                                                    }
                                                    />
                                                   
                                                </div>
                                            }

                                            okText="Update"
                                            cancelText="No"
                                            onConfirm={() => {
                                                handleUpdateWatcher()
                                            }}
                                        >
                <div style={{display:'flex',flexDirection:'row',cursor:'pointer'}}>  <TbEye style={{marginRight:'10px'}}  size={20} />{history?.data?.[0]?.watcher_list?.split(',').length} </div>
                </Popconfirm> */}
                {/* <div  style={{display:'flex',justifyContent:'space-between'}}> <FaUser style={{marginRight:'10px'}}  size={20} />{generateName(history?.comment?.[history?.comment?.length-1]?.assigned_to)} </div> */}
            </div>
           <div className='comment-history' id='comment-history'>

                {
                    history?.comment?.sort((a,b)=>{
                        return new Date(a.created_at)- new Date(b.created_at)
                    })?.map((data,key)=>(
                             <div key={key}>
                                <div> <span style={{color:'brown'}}>{generateName(data?.user_details)  ? generateName(data?.user_details) : 'Platform' } </span> -  <span style={{color:'gray',marginRight:'10px'}}> {dateFormated(data?.created_at)} </span>  <Tag color={data?.status?.toLowerCase()==='created'? 'green' :data?.status?.toLowerCase()==='closed'?'red':'blue'  } >{data?.status} </Tag> </div>
                                 <div className='chat-box-ticket' style={{padding:'15px'}}>{data?.comment?.split('\n')?.map((data,id)=>(
                                     <div>
                                        {data}
                                        </div>
                                 ))} </div>
                            </div>
                    ))
                }
           </div>
        
           <div className='chat-comment'>

          

            <div>
            <Textarea
            value={comment? comment:''}
            sx={{width:'95%'}}
            onChange={(e)=>setComment(e.target.value)}
            disabled={closed}
            placeholder="Enter comments"
            style={{  height: 100 ,margin:'20px 0px'}}
             />
            </div>

            <div style={{display:'flex',justifyContent:'space-between',marginBottom:'30px'}}>
                <div>
                    {history?.comment?.[history?.comment?.length-1]?.status?.toLowerCase()==='closed' ?  <Button type="primary" variant='contained'  onClick={()=>{setClosed(false)}}>Mark As Unresolved</Button>:
               
                    <Button onClick={()=>{
                        setClose(true)
                    }} variant='contained' type="primary" >Sumbit & close </Button>
                
           
                    
        }
                </div>
                <div>
                    <Button style={{marginRight:'10px'}}  onClick={()=>{
                        handleCancel()
                        setIsModalOpen(false)
                    }} >Cancel </Button>
                    <Button type="primary" variant='contained'  onClick={()=>{handleOk('In Progress')}} >Update </Button>
                </div>


            </div>
              
           </div>
        
        </div>
      </SlidingPane>


      <CustomAlertDialog open={close} UpdateFunction={()=>{
        handleOk('Closed')
        setClose(false)
    }} handleClose={() => {
                // setAttachment(false)
                setClose(false)
            }}  >
                <p>
                    Are you sure you want to close this ticket
                </p>
                
            </CustomAlertDialog>



            <Modal width={1000} style={{height:'600px'}}  title="Document" open={documentPop} onClose={()=>setDocumentPop(false)}>

                <DocViewer style={{height:'60vh'}}
                documents={[{uri:documentPop}]}
                pluginRenderers={DocViewerRenderers}
                />
                
                </Modal>
      
    </div>
  )
}

export default EditTicket
