import React, { useEffect, useState,useContext,useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from 'axios';
import {Typography,FormControlLabel,Switch,Modal,TextField,Button,FormControl,MenuItem,InputLabel,Select} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { CSSTransition } from 'react-transition-group';
import EditIcon from '@mui/icons-material/Edit';
import AuthErrorPop from '../Util/AuthErrorPop';
import { UserContext } from '../../App';
import Alerts from '../Radiologists/Alerts';
import VendorContact from './VendorContact';
import VendorInvoice from './VendorInvoice';
import VendorDocument from './VendorDocument';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from 'dayjs'

const EditHandle = ({ open, onClose, editvalue, updateDetails, value }) => {
    const [error, setError] = useState('')
    const erroRef = useRef()
    const [inputValue, setValue] = useState(value)

    // console.log(value)
    const handleSubmit = () => {
        updateDetails(inputValue,editvalue)
        setValue('')
    }

    useEffect(() => {
        if(value){
            setValue(value)
            console.log(value)
        }
            //console.log(inputValue)
    }, [value, open])


    const style = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '1px solid lightblue',
          boxShadow: 24,
          p: 4,
    };

return (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
          <Box sx={{ ...style }}>

              <div className='pop-edit'>
                     <div className='text-red-500' style={{ textAlign: 'center', fontSize: '23px', marginBottom: '30px', color: 'darkblue' }}>Edit {editvalue}</div>
                       <div style={{ textAlign: 'center', marginBottom: '30px' }}>

                       {editvalue === 'Registration Type' ?
                                <FormControl style={{ marginLeft: '50px', width: '300px', }} >
                                  <InputLabel id="demo-simple-select-label">Registration Type</InputLabel>
                                  <Select sx={{ paddingLeft: '10px', }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={inputValue}
                                    label='Registration Type'
                                    onChange={(e) => { setValue(e.target.value) }}
                                  >
                                    <MenuItem value="Private Limited">Private Limited</MenuItem>
                                    <MenuItem value="Govt">Govt</MenuItem>
                                    <MenuItem value="Partnership Firm">Partnership Firm</MenuItem>
                                    <MenuItem value="Sole Proptiership">Sole Proptiership</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>

                                  </Select>
                                </FormControl> : editvalue === 'Vendor Type' ? 

                                    <FormControl style={{ marginLeft: '50px', width: '300px', }} >
                                    <InputLabel id="demo-simple-select-label">Vendor Type</InputLabel>
                                    <Select sx={{ paddingLeft: '10px', }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={inputValue}
                                    label='Vendor Type'
                                    onChange={(e) => { setValue(e.target.value) }}
                                    >
                                    <MenuItem value="Regular">Regular</MenuItem>
                                    <MenuItem value="One time">One time</MenuItem>

                                    </Select>
                                    </FormControl>  : editvalue === 'Expired Date'?
                                        <div className='edit-expire-date' style={{ marginLeft: '50px'}}  >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker  format="DD/MM/YYYY" label="select Expired Date" value={inputValue} onChange={(newValue) => setValue(newValue)} />
                                        </DemoContainer>
                                        </LocalizationProvider>
                                        </div>
                                        :

                                                            
                              <TextField className='edit-label' value={inputValue} onChange={(event) => {
                                setValue(event.target.value)
                                erroRef.current.innerHTML = ''
                              }} id="outlined-basic" label={editvalue} variant="outlined" />

                            }
                            
                       </div>
                    <div style={{ textAlign: 'center', color: 'red' }} ref={erroRef}></div>
                    <div style={{ textAlign: 'center', marginBottom: '5px', marginTop: '30px', }}>
                            <Button sx={{ marginRight: '30px', fontSize: '13px' }} onClick={() => {
                              setValue('')
                              onClose()
                            }} variant="contained">Close</Button>
                            <Button sx={{ fontSize: '13px' }} onClick={handleSubmit} variant="contained">Save</Button>
                    </div>
                  </div>

          </Box>
  </Modal>
)
}


function VendorDetail({isSidebarOpen,email}) {
    const navigate = useNavigate()
    const location = useLocation()
    const [value,SetValue] = useState('details')
    const pathnameParts = location.pathname.split('/');
    const uniqueId = pathnameParts[pathnameParts.length - 2];
    const tabname = pathnameParts[pathnameParts.length - 1];
    const [data,setData] = useState('');
    const[comment,setComment] = useState('');
    const [authPop,setAuthPop]=useState(false)
    const {user} = useContext(UserContext);
    const {handleAccess} = useContext(UserContext);
    const [vendorData,setVendorData]=useState(null)
    const [editTab, setEditTab] = useState(false)
    const [editvalue, setValue] = useState('')
    const [editableValue, setEditableValue] = useState('')
    const [alert,setAlert]=useState('')
    const [warning,setWarning]=useState('')
    const [selectedTab, setSelectedTab] = useState(tabname);

    const handleChange = (event, newPath) => {
        // console.log(newPath)
        SetValue(newPath)
        navigate(`/vendors/${uniqueId}/${newPath}`)
    };

    useEffect(() => {
        setSelectedTab(tabname)
   })
   

      const StyledTabs = styled(Tabs)(({ theme }) => ({
        '& .MuiTabs-indicator': {
          backgroundColor: theme.palette.primary.main,
        },
      }));
      
      const StyledTab = styled(Tab)(({ theme }) => ({
        '&.Mui-selected': {
          color: theme.palette.primary.main,
        },
      }));

      function TabPanel(props) {
        const { children, value, index } = props;
        return (
          <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box >{children}</Box>}
          </div>
        );
      }


      const getVendorDetails = async()=>{
        try{
            const  {REACT_APP_GET_VENDORS_BY_ID}= process.env
            const res = await axios.get(REACT_APP_GET_VENDORS_BY_ID+`?unique_id=${uniqueId}`)
            if(res?.data?.data?.length>0){
                setVendorData(res?.data?.data[0])
            }

        }catch(err){

        }
      }

      useEffect(()=>{
        getVendorDetails()
      },[])


      const updateDetails = async(value,edit)=>{
        console.log(edit,value)  
        let data ={
            unique_id:uniqueId
        }
        let varEdit;
        if(edit==='Email'){
            varEdit='email'
        }else if (edit==='Name'){
            varEdit='name'
        }
        else if (edit==='City'){
            varEdit='city'
        }else if (edit==='State'){
            varEdit='state'
        }else if (edit==='Country'){
            varEdit='country'
        }else if (edit==='Pincode'){
            varEdit='pincode'
        }else if (edit==='Address'){
            varEdit='address'
        }else if (edit==='Registration Type'){
            varEdit='registration_type'
        }else if (edit==='Vendor Type'){
            varEdit='vendor_type'
        }
        else if (edit==='PAN'){
            varEdit='pan'
        }else if (edit==='GST'){
            varEdit='gst'
        }else if (edit==='MSME'){
            varEdit='msme'
        }else if (edit==='TAN'){
            varEdit='tan'
        }
        else if (edit==='Bank Holder Name'){
            varEdit='bank_acc_holder_name'
        }
        else if (edit==='Bank Account Number'){
            varEdit='bank_acc_num'
        }
        else if (edit==='IFSC Code'){
            varEdit='ifsc_code'
        }
        else if (edit==='Expired Date'){
            varEdit='expired_date'
        }
        console.log(varEdit)
        if(edit==='Expired Date'){
            data[varEdit]=funDate(value)
        }else{
        data[varEdit]=value
        }
        try{
            const {REACT_APP_UPDATE_VENDORS_BY_ID}=process.env
            const res = await axios.put(REACT_APP_UPDATE_VENDORS_BY_ID,data)
            if(res?.data?.detail?.length>0){
                setAlert('Updated Successfully')
            }else{
                setWarning('Updated Failed')
            }

        }catch(err){
            setWarning('Updated Failed')
        }finally{
            getVendorDetails()
            setEditTab(false)
            setEditableValue('')

        }
      }

      const funDate = (date1) => {
        const date = new Date(date1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      };

      const handleTabChange = (event, newValue) => {
        //console.log(newValue)
        navigate(`/vendors/${uniqueId}/${newValue}`)
        setSelectedTab(newValue);
  };

     
      
      
  return (
    <CSSTransition
    in={true}
    timeout={500}
    classNames="hospital-details-container"
    appear // Add the 'appear' prop to trigger the transition on initial mount
    unmountOnExit
  >
   <div>
   
<div className='hospital-header-main'>
        <div className={`${isSidebarOpen ? 'hospital-header' : 'hospital-header-min'}`} style={{marginTop:'0px',marginLeft:'0px'}}>
          <KeyboardBackspaceIcon className="back-arrow-icon"  onClick={() =>{navigate(`/vendors`)}} />
          <Typography sx={{fontSize:'20px'}} variant="h4">{vendorData?.name}</Typography>
        </div>
        </div>
            <StyledTabs className='tabs' value={selectedTab} onChange={handleTabChange} centered>
              <StyledTab className='tabs-content' sx={{fontSize:'12px'}}  label="Details" value='details' />
              <StyledTab sx={{fontSize:'12px'}}  label="Contact" value='contact' />
              <StyledTab sx={{fontSize:'12px'}}  label="Invoices" value='invoice' />
              <StyledTab sx={{fontSize:'12px'}}  label="Documents" value='document' />
            </StyledTabs>
            <TabPanel   value={selectedTab} index={'details'}>
            <div className="hospital-details-container">
                                                  <div className="hospital-section">
                                
                                                      <div className="hospital-grid">
                                                        <ul>
                                                        <li className='details-li'>
                                                            <div className="hospital-label"><div>Name</div>:</div>
                                                            <div style={{ marginRight: '5px' }} className="hospital-value">


                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {vendorData?.name}
                                                              </div>

                                                              <EditIcon style={{ float: 'right', marginRight: '0px', marginTop: '-5px' }} onClick={() => {
                                                                if(handleAccess('Vendor','Edit Details')===true){
                                                                  setValue('Name')
                                                                  setEditableValue(vendorData?.name)
                                                                  setEditTab(true)
                                                                }
                                                                else {
                                                                  setAuthPop(true)
                                                                }

                                                              }}></EditIcon>
                                                            </div>


                                                          </li>
                                                        
                                                          <li className='details-li'>
                                                              <div className="hospital-label"><div>Unique ID</div>:</div>
                                                              <div className="hospital-value">{vendorData?.unique_id}</div>
                                                          </li>
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Email</div>:</div>
                                                            <div style={{ marginRight: '5px' }} className="hospital-value">


                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {vendorData?.email}
                                                              </div>

                                                              <EditIcon style={{ float: 'right', marginRight: '0px', marginTop: '-5px' }} onClick={() => {
                                                                if(handleAccess('Vendor','Edit Details')===true){
                                                                  setValue('Email')
                                                                  setEditableValue(vendorData?.email)
                                                                  setEditTab(true)
                                                                }
                                                                else {
                                                                  setAuthPop(true)
                                                                }

                                                              }}></EditIcon>
                                                            </div>


                                                          </li>
                                            
                                                          <li className='details-li'>
                                                              <div className="hospital-label ">
                                                                <div>Address</div>
                                                                <div style={{ paddingLeft: '43px' }}>:</div>
                                                              </div>
                                                              <div className="hospital-value hospital-address">

                                                                    <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                      {vendorData?.address}</div>
                                                                    <EditIcon
                                                                     onClick={() => {
                                                                      if(handleAccess('Vendor','Edit Details')===true){
                                                                        setValue('Address')
                                                                        setEditableValue(vendorData?.address)
                                                                        setEditTab(true)
                                                                      }
                                                                      else {
                                                                        setAuthPop(true)
                                                                      }
                                                                    }}
                                                                      sx={{ cursor: 'pointer' }}
                                                                    >
                                                                    </EditIcon>
                                                              </div>
                                                          </li>
                                                          <li className='details-li'>
                                                              <div className="hospital-label"><div>City</div>:</div>
                                                              <div className="hospital-value">
                                                                <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                  {vendorData?.city}</div>
                                                                <EditIcon style={{ marginTop: '-5px', }} 
                                                                onClick={() => {
                                                                  if(handleAccess('Vendor','Edit Details')===true){
                                                                    setValue('City')
                                                                    setEditableValue(vendorData?.city)
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer' }}
                                                                ></EditIcon>
                                                              </div>
                                                          </li>
                                                          <li className='details-li'>
                                                              <div className="hospital-label"><div>State</div>:</div>
                                                              <div className="hospital-value">
                                                                  <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                    {vendorData?.state}</div>
                                                                  <EditIcon style={{ marginTop: '-5px', }}
                                                                   onClick={() => {
                                                                
                                                                    if(handleAccess('Vendor','Edit Details')===true){
                                                                      setValue('State')
                                                                      setEditableValue(vendorData?.state)
                                                                      setEditTab(true)
                                                                    }
                                                                    else {
                                                                      setAuthPop(true)
                                                                    }

                                                                  }}
                                                                    sx={{ cursor: 'pointer' }}
                                                                  ></EditIcon>
                                                              </div>
                                                          </li>
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Country</div>:</div>
                                                            <div className="hospital-value">
                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {vendorData?.country}</div>
                                                              <EditIcon style={{ marginTop: '-5px', }}
                                                               onClick={() => {
                                                      
                                                                if(handleAccess('Vendor','Edit Details')===true){
                                                                  setValue('Country')
                                                                  setEditableValue(vendorData?.country)
                                                                  setEditTab(true)
                                                                }
                                                                else {
                                                                  setAuthPop(true)
                                                                }

                                                              }}
                                                                sx={{ cursor: 'pointer' }}
                                                              ></EditIcon>
                                                            </div>
                                                          </li>

                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Pincode</div>:</div>
                                                            <div className="hospital-value">
                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {vendorData?.pincode}</div>
                                                              <EditIcon style={{ marginTop: '-5px', }}
                                                               onClick={() => {
                                                      
                                                                if(handleAccess('Vendor','Edit Details')===true){
                                                                  setValue('Pincode')
                                                                  setEditableValue(vendorData?.pincode)
                                                                  setEditTab(true)
                                                                }
                                                                else {
                                                                  setAuthPop(true)
                                                                }

                                                              }}
                                                                sx={{ cursor: 'pointer' }}
                                                              ></EditIcon>
                                                            </div>
                                                          </li>
                                                         
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Onboarded Date</div>:</div>
                                                            <div className="hospital-value">{vendorData?.onboarded_at?.split(' ')[0]}</div></li>


                                                            <li className='details-li'>
                                                              <div className="hospital-label"><div>Expired Date</div>:</div>
                                                              <div className="hospital-value">
                                                                <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                  {vendorData?.expired_date?.split('T')[0]}</div>
                                                                <EditIcon style={{ marginTop: '-5px', }} 
                                                                onClick={() => {
                                                                  if(handleAccess('Vendor','Edit Details')===true){
                                                                    setValue('Expired Date')
                                                                    setEditableValue(vendorData?.expired_date? dayjs(vendorData?.expired_date): dayjs())
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer' }}
                                                                ></EditIcon>
                                                              </div>
                                                          </li>
                                                          {/* <li className='details-li'>
                                                            <div className="hospital-label"><div>Phone</div>:</div>
                                                            <div className="hospital-value">{hospitalData?.phone}</div></li>
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>SaaS Only</div>:</div>
                                                            <div className="hospital-value">{hospitalData?.saas_only ? 'Yes' : 'No'}</div>
                                                          </li> */}
                                                        </ul>
                                                      </div>
                                                  </div>

                                                  <div className="hospital-section">
                                
                                                      <div className="hospital-grid">
                                                        <ul>
                                                        <li className='details-li'>
                                                              <div className="hospital-label"><div>Registration Type</div>:</div>
                                                              <div className="hospital-value">
                                                                <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                  {vendorData?.registration_type}</div>
                                                                <EditIcon style={{ marginTop: '-5px', }} 
                                                                onClick={() => {
                                                                  if(handleAccess('Vendor','Edit Details')===true){
                                                                    setValue('Registration Type')
                                                                    setEditableValue(vendorData?.registration_type)
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer' }}
                                                                ></EditIcon>
                                                              </div>
                                                          </li>
                                                        
                                                          <li className='details-li'>
                                                              <div className="hospital-label"><div>Vendor Type</div>:</div>
                                                              <div className="hospital-value">
                                                                <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                  {vendorData?.vendor_type}</div>
                                                                <EditIcon style={{ marginTop: '-5px', }} 
                                                                onClick={() => {
                                                                  if(handleAccess('Vendor','Edit Details')===true){
                                                                    setValue('Vendor Type')
                                                                    setEditableValue(vendorData?.vendor_type)
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer' }}
                                                                ></EditIcon>
                                                              </div>
                                                          </li>
                                
                                            
                                                         
                                                          <li className='details-li'>
                                                              <div className="hospital-label"><div>PAN</div>:</div>
                                                              <div className="hospital-value">
                                                                <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                  {vendorData?.pan}</div>
                                                                <EditIcon style={{ marginTop: '-5px', }} 
                                                                onClick={() => {
                                                                  if(handleAccess('Vendor','Edit Details')===true){
                                                                    setValue('PAN')
                                                                    setEditableValue(vendorData?.pan)
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer' }}
                                                                ></EditIcon>
                                                              </div>
                                                          </li>
                                                          <li className='details-li'>
                                                              <div className="hospital-label"><div>GST</div>:</div>
                                                              <div className="hospital-value">
                                                                  <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                    {vendorData?.gst}</div>
                                                                  <EditIcon style={{ marginTop: '-5px', }}
                                                                   onClick={() => {
                                                                
                                                                    if(handleAccess('Vendor','Edit Details')===true){
                                                                      setValue('GST')
                                                                      setEditableValue(vendorData?.gst)
                                                                      setEditTab(true)
                                                                    }
                                                                    else {
                                                                      setAuthPop(true)
                                                                    }

                                                                  }}
                                                                    sx={{ cursor: 'pointer' }}
                                                                  ></EditIcon>
                                                              </div>
                                                          </li>
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>TAN</div>:</div>
                                                            <div className="hospital-value">
                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {vendorData?.tan}</div>
                                                              <EditIcon style={{ marginTop: '-5px', }}
                                                               onClick={() => {
                                                      
                                                                if(handleAccess('Vendor','Edit Details')===true){
                                                                  setValue('TAN')
                                                                  setEditableValue(vendorData?.tan)
                                                                  setEditTab(true)
                                                                }
                                                                else {
                                                                  setAuthPop(true)
                                                                }

                                                              }}
                                                                sx={{ cursor: 'pointer' }}
                                                              ></EditIcon>
                                                            </div>
                                                          </li>

                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>MSME</div>:</div>
                                                            <div className="hospital-value">
                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {vendorData?.msme}</div>
                                                              <EditIcon style={{ marginTop: '-5px', }}
                                                               onClick={() => {
                                                      
                                                                if(handleAccess('Vendor','Edit Details')===true){
                                                                  setValue('MSME')
                                                                  setEditableValue(vendorData?.msme)
                                                                  setEditTab(true)
                                                                }
                                                                else {
                                                                  setAuthPop(true)
                                                                }

                                                              }}
                                                                sx={{ cursor: 'pointer' }}
                                                              ></EditIcon>
                                                            </div>
                                                          </li>


                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Bank Holder Name</div>:</div>
                                                            <div className="hospital-value">
                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {vendorData?.bank_acc_holder_name}</div>
                                                              <EditIcon style={{ marginTop: '-5px', }}
                                                               onClick={() => {
                                                      
                                                                if(handleAccess('Vendor','Edit Details')===true){
                                                                  setValue('Bank Holder Name')
                                                                  setEditableValue(vendorData?.bank_acc_holder_name)
                                                                  setEditTab(true)
                                                                }
                                                                else {
                                                                  setAuthPop(true)
                                                                }

                                                              }}
                                                                sx={{ cursor: 'pointer' }}
                                                              ></EditIcon>
                                                            </div>
                                                          </li>

                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Bank Acc Number</div>:</div>
                                                            <div className="hospital-value">
                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {vendorData?.bank_acc_num}</div>
                                                              <EditIcon style={{ marginTop: '-5px', }}
                                                               onClick={() => {
                                                      
                                                                if(handleAccess('Vendor','Edit Details')===true){
                                                                  setValue('Bank Account Number')
                                                                  setEditableValue(vendorData?.bank_acc_num)
                                                                  setEditTab(true)
                                                                }
                                                                else {
                                                                  setAuthPop(true)
                                                                }

                                                              }}
                                                                sx={{ cursor: 'pointer' }}
                                                              ></EditIcon>
                                                            </div>
                                                          </li>

                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>IFSC Code</div>:</div>
                                                            <div className="hospital-value">
                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {vendorData?.ifsc_code}</div>
                                                              <EditIcon style={{ marginTop: '-5px', }}
                                                               onClick={() => {
                                                      
                                                                if(handleAccess('Vendor','Edit Details')===true){
                                                                  setValue('IFSC Code')
                                                                  setEditableValue(vendorData?.ifsc_code)
                                                                  setEditTab(true)
                                                                }
                                                                else {
                                                                  setAuthPop(true)
                                                                }
                                                              }}
                                                                sx={{ cursor: 'pointer' }}
                                                              ></EditIcon>
                                                            </div>
                                                          </li>
                                                         
                                                        </ul>
                                                      </div>
                                                  </div>
                                                  
                                            </div>


                   
                    
    
    
            </TabPanel>
            <TabPanel   value={selectedTab} index={'contact'}>
               <VendorContact vendorid={vendorData?.id} email={email} vendorname={vendorData?.name} userAccess={user} handleAccess={handleAccess} authPop={authPop} setAuthPop={setAuthPop} />
            </TabPanel>
            <TabPanel   value={selectedTab} index={'invoice'}>   
                <VendorInvoice vendorid={vendorData?.id}  email={email} vendorname={vendorData?.name} userAccess={user} handleAccess={handleAccess} setAlert={setAlert} setWarning={setWarning} authPop={authPop} setAuthPop={setAuthPop} />
            </TabPanel>
            <TabPanel   value={selectedTab} index={'document'} userAccess={user} setAuthPop={setAuthPop}>
                <VendorDocument vendorid={vendorData?.id} email={email} vendorname={vendorData?.name} userAccess={user} handleAccess={handleAccess} setAlert={setAlert} setWarning={setWarning} authPop={authPop} setAuthPop={setAuthPop} />
            </TabPanel>
            <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop}  />

            <EditHandle open={editTab} editvalue={editvalue} onClose={() => setEditTab(false)} value={editableValue} updateDetails={updateDetails}  />

            <Alerts alert={alert} setAlert={()=>setAlert('')} warning={warning} setWarning={()=>setWarning('')} />

            <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop} />
   </div>
   </CSSTransition>
  )
}

export default VendorDetail