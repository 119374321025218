import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import LineChart from "../charts/LineChart.jsx";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "../charts/variables/charts.js";
import "../HomeComponents/HomeMain.css";
import { IoCheckmarkCircle } from "react-icons/io5";
import { RiArrowUpSFill } from "react-icons/ri";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Box as Box1 } from "@mui/material";
function TotalSpent() {
  const [revenueData, setRevenueData] = useState([]);
  const [revenueOption, setRevenueOption] = useState([]);
  const [lastRevenue, setLastRevenue] = useState({});
  const monthList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleOverallRevenue = async () => {
    try {
      const { REACT_APP_CLIENT_HOME_OVERALL_REVENUE } = process.env;
      const res = await axios.get(REACT_APP_CLIENT_HOME_OVERALL_REVENUE);
      //console.log(res);
      const revenue = [];
      const payable = [];
      const gm = [];
      const month = [];
      res?.data?.data?.map((data) => {
        payable.push(parseInt(data?.payable));
        revenue.push(parseInt(data?.revenue));
        gm.push(parseInt(data?.gm));
        month.push(monthList[data?.study_month - 1]);
      });
      //  //console.log(option,data1)
      lineChartOptionsTotalSpent.xaxis.categories = month;
      setLastRevenue({
        revenue: revenue[revenue.length - 1],
        gm: gm[gm.length - 1],
      });
      const data = [
        {
          name: "Revenue",
          data: revenue,
        },
        {
          name: "Payable",
          data: payable,
        },
        {
          name: "GM",
          data: gm,
        },
      ];
      setRevenueData(data);
    } catch (err) {}
  };
  useEffect(() => {
    handleOverallRevenue();
  }, []);
  return (
    <div style={{ backgroundColor: "white", borderRadius: "20px" }}>
      {revenueData.length > 0 ? (
        <div style={{ padding: "20px" }}>
          <div style={{ color: "#1B2559", fontSize: "25px" }}>
            Revenue vs GM
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "50%" }}>
              <div className="pie-revenue-dollar">
                {new Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(lastRevenue.revenue)}
              </div>
              <div style={{ display: "flex" }}>
                <div className="pie-revenue-dollar-name">Gross Margin</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon as={RiArrowUpSFill} color="#01b574" />
                  <Text color="#01b574" fontSize="sm" fontWeight="700">
                    {`+${parseInt(lastRevenue.gm)}%`}
                  </Text>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Icon as={IoCheckmarkCircle} color="#01b574" me="4px" />
                <Text color="#01b574" fontSize="md" fontWeight="700">
                  On track
                </Text>
              </div>
            </div>
            {revenueData.length > 0 && (
              <LineChart
                chartData={revenueData}
                chartOptions={lineChartOptionsTotalSpent}
              />
            )}
          </div>
        </div>
      ) : (
        <Box1
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignSelf: "center",
            marginTop: "20px",
          }}
        >
          <CircularProgress />
        </Box1>
      )}
    </div>
  );
}

export default TotalSpent;
