import React, { useState, useEffect, useContext } from "react";
import Empty_image from "../../Images/Empty_Page.png";

import PropTypes from "prop-types";
import HomeNav from "../HomeComponents/HomeNav";
import HomeMain from "../HomeComponents/HomeMain";
import { UserContext } from "../../App";

function Home() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modalityName, setmodalityName] = React.useState([
    "CT",
    "MRI",
    "NM",
    "XRAY",
  ]);
  const [tag, setTag] = useState("submitted");
  const [apiState, setApiState] = useState({
    gmApi: null,
    billedApi: null,
  });
  // const {handleAccess,apiState,setApiState} =useContext(UserContext)

  return (
    <div
      style={{
        backgroundColor: "#F4F7FE",
        height: "100%",
        fontFamily: "DM Sans",
      }}
    >
      <HomeNav
        setStartDate={setStartDate}
        apiState={apiState}
        setEndDate={setEndDate}
        modalityName={modalityName}
        setmodalityName={setmodalityName}
        tag={tag}
        setTag={setTag}
      />
      <HomeMain
        startDate={startDate}
        endDate={endDate}
        modalityName={modalityName}
        setmodalityName={setmodalityName}
        setApiState={setApiState}
        apiState={apiState}
        tag={tag}
        setTag={setTag}
      />
    </div>
    //    <div>

    //    <img className='page_img' style={{width:'99%',height:'93vh'}} src={Empty_image}></img>
    //  </div>
  );
}

export default Home;
