import React, { useState, useEffect,useContext } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { IoIosContact } from "react-icons/io";
import { MdSend } from "react-icons/md";
import axios from "axios";
import { MentionsInput, Mention } from "react-mentions";
import { checkAcessLevel,getDocumentYaakeUsers } from "../../permission";
import Alerts from "../Radiologists/Alerts";
import Select from 'react-select'
import { RiTeamFill } from "react-icons/ri";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, Button } from "@mui/material";
import MyDocument from "./DownloadChat";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { UserContext } from '../../App';
import AddDocument from "./AddDocument";
import { Response } from "../Radiologists/Radiologists";
import ReplayIcon from '@mui/icons-material/Replay';
import { IoArrowBack } from "react-icons/io5";





function PayerContent({email}) {
                const location = useLocation();
                const navigate = useNavigate();
                const {handleAccess,apiState,setApiState,setAuthPop}= useContext(UserContext);
                const [url, setUrl] = useState(location.pathname);
                const [messageData, setMessageData] = useState([]);
                const pathnameParts = location.pathname.split("/");
                const payerId = pathnameParts[pathnameParts.length - 1];
                const [payerInfo, setPayerInfo] = useState([]);
                const [showInvoice, setShowInvoice] = useState([]);
                const [contactDetails, setContactDetails] = useState([]);
                const [yaakeUser,setYaakeUser]=useState([])
                const [userInput, setUserInput] = useState("");
                const [sendPayload,setSendPayload]=useState('')
                const [sendUser,setSendUser]=useState('')
                const [alert,setAlert]=useState('')
                const [warning,setWarning]=useState('')
                const [refresh,setRefresh]=useState(false)
                const [selectedValue, setSelectedValue] = useState({ value: 'General (Default)', label: 'General (Default)' },);
                const [load,setLoad]=useState(50)
                const [groupedMessages,setGroupedMessage]=useState('')
                const [enterpress,setEnterPress]=useState(false)
                const [payerCheck,handleChangePayer] =useState(false)
                const [loader,setLoader]=useState(true)
                const [downloadData,setDowloadData]=useState()
                const [download,setDownload]=useState('Load Chat')
                const [downloadGrouped,setDownloadGrouped]=useState([])
                const [response,setResponse]=useState('')
                const [inputBlock,setInputBlock]=useState(false)
                const [documents,SetDocument]=useState([])
             
                const handleChange = (selectedOption) => {
                  setSelectedValue(selectedOption);

                };

                const [suggestions, setSuggestions] = useState([
                    { id: "ghbn", display: "@Ghbn User" },
                    { id: "gokul", display: "@Gokul" },
                    { id: "sunil", display: "@Sunil" },
                   
                ]);

                const customStyles = {
                    menu: (provided, state) => ({
                      ...provided,
                      height: '100px' // Adjust the height as needed
                    }),
                    menuList: (provided, state) => ({
                      ...provided,
                      maxHeight: '100px', // Adjust the max height as needed
                      overflowY: 'auto'
                    })
                  };

                const options = [
                    { value: 'General (Default)', label: 'General (Default)' },
                    { value: 'Introduction Call', label: 'Introduction Call' },
                    { value: 'Billing', label: 'Billing' },
                    { value: 'Overdue', label: 'Overdue' },
                    { value: 'Account Block', label: 'Account Block' },
                    { value: 'Collection', label: 'Collection' }
                  ]

                const handleMentionInput = (value, id, display) => {
                            const filteredSuggestions = suggestions.filter((suggestion) =>
                            suggestion.display.toLowerCase().includes(value.toLowerCase()));
                            setSuggestions(filteredSuggestions);
                };

               
                useEffect(() => {
                    getPayerContact();
                }, [payerId]);


                useEffect(()=>{
                   setDowloadData([])
                   setDownloadGrouped([])
                },[groupedMessages])


                useEffect(() => {
                    const data = checkAcessLevel().then(data => {
                        // console.log(data)
                        setSuggestions(data?.user?.map(data=>{
                            return{
                                id:data?.email,
                                display:`@${data?.name}`
                            }
                        }))
                        let send=data?.user?.filter(data=>{
                            if(email===data?.email){
                                return {
                                    name:data?.name,
                                    email:data?.email
                                }
                            }
                        })
                        // console.log(send,email)
                        if(send.length>0){
                            setSendUser({
                                name:send[0].name,
                                email:send[0].email
                            })
                        }
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });


                         getDocumentYaakeUsers().then(data => {
                            //console.log(data)
                            let yaakeUser=[]
                            data['yaake-users']?.filter(data=>{
                                if(data?.name!=='5CAccounts'){
                                  yaakeUser.push(data?.name)
                                }
                            })
                            setYaakeUser(yaakeUser)
                            const user=data['yaake-users']?.map(data=>{
                                return{
                                    id:`${data?.email}`,
                                    display:`@${data?.name}`
                                }
                            })



                            // console.log(user)
                            setSuggestions(prev=>[...prev,...user])
                            
                            })
                            .catch(error => {
                                console.error('Error fetching data:', error);
                            });

                }, [payerId,email])

                const getPayerContact = async () => {
                    try {
                                const { REACT_APP_GET_COLLECTION_PAYER_CONTACT_BY_ID } = process.env;
                                const res = await axios.get(
                                    REACT_APP_GET_COLLECTION_PAYER_CONTACT_BY_ID + `id=${payerId}`);
                                        if (res?.data?.data?.length > 0) {
                                            setPayerInfo(res?.data?.data[0]);
                                        }
                    }
                     catch (err) {}
                };

                useEffect(() => {
                    if (payerInfo?.client_fk) {
                            getPayerInvoiceDetails();
                            getPayerContactDetails();
                            getPayerDocument()
                    }
                }, [payerInfo?.client_fk,payerId]);

                const getPayerDocument = async ()=>{
                    try{
                        const {REACT_APP_COLLECTION_GET_DOCUMENTS} = process.env
                        const res = await axios.get(REACT_APP_COLLECTION_GET_DOCUMENTS+`client_fk=${payerInfo?.client_fk}&contact_id=${payerId}`)
                        if(res?.status==200){
                            if(res?.data?.data?.length>0){
                                SetDocument(res?.data?.data)
                            }
                        }
                    }catch(err){

                    }
                }

                const getPayerInvoiceDetails = async () => {
                    try {
                            const { REACT_APP_CLIENT_SHOW_INVOICE_URL } = process.env;
                            const response = await axios.get(
                                REACT_APP_CLIENT_SHOW_INVOICE_URL + `?client_fk=${payerInfo?.client_fk}`
                            );

                            if (!response.data.show_invoice_data) {
                            } else if (response.data.show_invoice_data.length > 0) {
                                const data = response?.data?.show_invoice_data?.sort(function (a, b) {
                                return (
                                    new Date(b?.email_invoice_sent_date) -
                                    new Date(a?.email_invoice_sent_date)
                                );
                                });
                                setShowInvoice(data);
                            } else {
                            }
                            } catch (Err) {
                            //console.log(Err);
                            }
                        };

                const getPayerContactDetails = async () => {
                    try {
                            const { REACT_APP_GET_COLLECTION_PAYER_CONTACT_BY_CLIENT } = process.env;
                            const res = await axios.get(
                                REACT_APP_GET_COLLECTION_PAYER_CONTACT_BY_CLIENT +`client_id=${payerInfo?.client_fk}`
                            );
                            if (res?.data?.data?.length > 0) {
                                setContactDetails(res?.data?.data);
                            }
                    } catch (err) {

                    }
                };

                const messages = [ ];

                const handleShowMention=(value)=>{
                    const mentionRegex = /@([\w\s]+)/g;
                    let matchedMentions = value.match(mentionRegex);
                    let replacedComment = value;
                    const mentionRegex1 = /@(\[@[^\]]+\]\([^)]+\))/g;
                    const matches = replacedComment.match(mentionRegex1);

                    if (matches && matches.length > 0) {
                        matches.forEach((match) => {
                            const originalValueMatch = match.match(/@\[([^\]]+)\]\([^)]+\)/);
                            // //console.log(originalValueMatch)
                            if (originalValueMatch) {
                            const originalValue = originalValueMatch[1];
                            replacedComment = replacedComment.replace(match, `<strong style=color:#3F83F8;padding-right:5px;padding-left:5px>${originalValue}</strong>`);      
                            }
                        });
                    
                }  
                return replacedComment
            }


                const handleMentioned=(value)=>{
                    const mentionRegex = /@([\w\s]+)/g;
                    let matchedMentions = value.match(mentionRegex);
                    matchedMentions = matchedMentions?.filter(item => item !== '@5cnetwork').map(item => item.startsWith('@') ? item.substring(1) : item);;

                    
                    let replacedComment = value;
                    const mentionRegex1 = /@(\[@[^\]]+\]\([^)]+\))/g;
                    const matches = replacedComment.match(mentionRegex1);

                    let emailAddresses = matches?.map(item => {
                        let match = item.match(/\(([^)]+)\)/);
                        return match ? match[1] : null;
                      });
                      


                    if (matches && matches.length > 0) {
                    matches.forEach((match) => {
                        const originalValueMatch = match.match(/@\[([^\]]+)\]\([^)]+\)/);
        
                        if (originalValueMatch) {
                        const originalValue = originalValueMatch[1];
                        replacedComment = replacedComment.replace(match, `${originalValue}`);
                        
                        }
                    });
                    }


                    let result = emailAddresses?.map((email, index) => ({
                        email: email,
                        name: matchedMentions[index],
                        app:yaakeUser.includes(matchedMentions[index])?'yaake':'finance'
                      }));

                    //   console.log(yaakeUser)
                    //   console.log(result)

                    
                    
                    
                     return {
                        contact_id:payerId,
                        client_fk:payerInfo?.client_fk,
                        from_json:sendUser,
                        to_json:result?result:'',
                        message:{
                            mentioned_message:value,
                            original_message:replacedComment
                        }
                    }
                }


                const formatDate = (dateString) => {
                    const date = new Date(dateString);
                    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
                };

                useEffect(()=>{

                const groupedMessages1 = messageData.reduce((grouped, message) => {
                    // //console.log(message)
                    const date = formatDate(message.updated_at);
                    // //console.log(date)
                    if (!grouped[date]) {
                    grouped[date] = [];
                    }
                    grouped[date].push(message);
                    // //console.log(grouped)
                    return grouped;
                }, {});

                // //console.log(groupedMessages1)

                setGroupedMessage(groupedMessages1)

            },[messageData,payerId])


            useEffect(()=>{
              if(downloadData?.length>0){
                const groupedMessages1 = downloadData?.reduce((grouped, message) => {
                    // //console.log(message)
                    const date = formatDate(message.updated_at);
                    // //console.log(date)
                    if (!grouped[date]) {
                    grouped[date] = [];
                    }
                    grouped[date].push(message);
                    // //console.log(grouped)
                    return grouped;
                }, {});
            

                // //console.log(groupedMessages1)

                setDownloadGrouped(groupedMessages1)
                setDownload('Load Chat')
            }

            },[downloadData])

                const handleMessageSent =async(value)=>{
                    //console.log(value)
                    setInputBlock(true)
                    const data =handleMentioned(value)
                    if(payerCheck){
                        setWarning('Not allowed to send messages in merge conversation')
                        return  
                    }
                    if(!value){
                        setWarning('Please Enter a Message')
                        return  
                    }
                    if(!selectedValue?.value){
                        setWarning('Please Select a tag message')
                        return
                    }
                    try{
                        
                        const {REACT_APP_GET_COLLECTION_PAYER_INSERT_MESSAGE}=process.env
                        const res = await axios.post(REACT_APP_GET_COLLECTION_PAYER_INSERT_MESSAGE,[{...data,tag:selectedValue?.value,client_name:payerInfo?.client_name}])
                        if(res.status===200){
                            if(res?.data==='Inserted sucessfully'){
                                setAlert('Message Sent')
                                
                                setRefresh(true)
                            }else{
                                setWarning('Message not sent')
                            }
                        }else{
                            setWarning('Message not sent')
                        }
                         
                    }catch(err){
                        setWarning('Message not sent')
                    }finally{
                        setUserInput('')
                        setInputBlock(false)
                    }
                }

                useEffect(() => {
                    // Scroll to the bottom of the chat when messages change
                    if(load===50){
                    const chatContainer = document.getElementById('chat-main');
                    if (chatContainer) {
                      chatContainer.scrollTop = chatContainer.scrollHeight;
                    }
                }
                  }, [messages]);


                useEffect(()=>{
                    if(payerInfo?.client_fk ){
                     handleGetMessage()   
                    }
                },[payerInfo?.client_fk,load,payerId,payerCheck])

                useEffect(()=>{
                    if(refresh){
                        handleGetMessage() 
                        setRefresh(false)
                    }
                },[refresh])

                useEffect(()=>{
                  if(enterpress){
                    handleMessageSent(userInput)
                    setEnterPress(false)
                  }
                },[enterpress])

                const handleGetMessage=async()=>{
                    try{
                        const {REACT_APP_GET_COLLECTION_PAYER_GET_MESSAGE} = process.env
                        const res = await axios.get(REACT_APP_GET_COLLECTION_PAYER_GET_MESSAGE+`client_fk=${payerInfo?.client_fk}&contact_id=${payerId}&load=${load}&merge=${payerCheck}`)
                        if(res.status===200){
                            if(res?.data?.data?.length>0){
                                if(res?.data?.data?.length===messageData?.length){
                                   if(load!==50){
                                    setWarning('No more Data')
                                    return
                                   }
                                }
                                setMessageData(res?.data?.data)
                            }else{
                                setMessageData([])
                            }
                        }
                    }catch(err){

                    }finally{
                        setLoader(true)
                    }
                }

                const handleDownloadMessage=async()=>{
                    try{
                        const {REACT_APP_GET_COLLECTION_PAYER_GET_MESSAGE} = process.env
                        const res = await axios.get(REACT_APP_GET_COLLECTION_PAYER_GET_MESSAGE+`client_fk=${payerInfo?.client_fk}&contact_id=${payerId}&merge=${payerCheck}`)
                        if(res.status===200){
                            if(res?.data?.data?.length>0){
                                setDowloadData(res?.data?.data)
                            }else{
                                setMessageData([])
                            }
                        }
                    }catch(err){

                    }finally{
                        setLoader(true)
                    }
                }


                const handleOverallDue=()=>{
                    const total =showInvoice?.reduce((total,data)=>{return total+data?.balance_amount},0)
                    return new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(
                        total
                        // handleTotal(mapData, "sent_notes")
                      )
                }

                const handleToUser=(to)=>{
                    let user=''
                    let flag=1
                     to?.map(data=>{
                        if(flag!==1){
                        user+=','+data?.name
                       
                        }else{
                            user+=data?.name  
                            flag=2
                        }
                     })

                    return <span>{user}</span>
                }

  return (
                <div className="payer-tab">
              
                        <div className="payer-main-header">
                                <div style={{marginLeft:'30px',marginTop:'20px'}} ><IoArrowBack style={{cursor:'pointer'}} onClick={()=>{
                                    navigate('/Collections/payer')
                                }} size={30} /></div>
                                <div
                                    style={{
                                        fontSize: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "20px",
                                    }}
                                >
                                        {payerInfo?.client_name}
                                        <IoIosContact style={{ marginLeft: "10px", color: "black" }} />
                                </div>
                        </div>

                        <div className="payer-content-div">
                                <div className="chat-body">
                                <div className="contact-name">
                                    {payerInfo?.name}
                                    <IoIosContact style={{ marginLeft: "10px", color: "black" }} />
                                </div>

                                <div className="contact-info">
                                    
                                        <div>{payerInfo?.name}</div>
                                        <div>{payerInfo?.type}</div>
                                        <div>{payerInfo?.phone}</div>
                                        <div>{payerInfo?.email}</div>
                                </div>
                                <div>
                                    <div className="payer-chat">
                                    <div className="chat-info">
                                        <div className="payer-checkbox1" >
                                        <FormControlLabel 
            control={<Checkbox checked={payerCheck} onChange={()=>handleChangePayer(prev=>!prev)}   />}
            label="Merge Conversation"
          />
                                        </div>
                                        {/* <button>Download Chat</button> */}
                                        { downloadGrouped?.length===0 ? 
                                            <button onClick={()=>{
                                                if(handleAccess('Collection','Download Chat')){
                                                    if(messageData?.length<=0){
                                                        return
                                                    }
                                                setDownload('Loading...')
                                                handleDownloadMessage()
                                                }else{
                                                    setAuthPop(true)
                                                }
                                            }} > {download} </button>
                                        : 
                                        <button>
                                        <PDFDownloadLink style={
                                                {
                                                    color: 'white',
                                                    textDecoration: 'none'
                                                }
                                                }
                                                document={ <MyDocument user={JSON.stringify(downloadGrouped)} email={email} /> }
                                                fileName={`${payerInfo?.name}_${payerInfo?.client_name}_chat.pdf`}
                                                >
                                                    {({ loading, error }) => (
                        
                                                            loading ? 'Loading chat....' : 'Download Chat'

                                                            )}

                                         </PDFDownloadLink>

                                         </button>
                                         }
                                    </div>

                                    <div className="chat-main" id='chat-main'>
                                      
                                            <div className="load-more">
                                            { loader? 
                                        //  <button  onClick={()=>{
                                        //     setLoad(prev=>prev+50)
                                        //     setLoader(false)
                                        //  }
                                        // } >Load more</button>

                                        <div>
                                        { messageData.length>=50 &&
                                    <ReplayIcon onClick={()=>{
                                            setLoad(prev=>prev+50)
                                            setLoader(false)
                                         }}
                                         style={{color:'#3498db'}} />
                                        }</div>

                                        
                                        :
                                       
                                            <div className='loader-container'  >
                                                <div className="loader"></div>
                                                </div>
}
                                            </div>

                                            {Object.keys(groupedMessages).sort((a, b) => new Date(a) - new Date(b)).map((date) => (
                                                    <div key={date}>
                                                            <div  className="date-header">
                                                                <div style={{ display: "flex", width: "100%" }}>
                                                                        <hr></hr>
                                                                        <div style={{ padding: "0px 10px",width:'300px' }}>
                                                                            {new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
                                                                        </div>
                                                                        <hr></hr>
                                                                </div>
                                                            </div>
                                                            <React.Fragment key={date}>
                                                                {groupedMessages[date]?.sort((a,b)=>new Date(a?.updated_at)-new Date(b?.updated_at))?.map((message) => (
                                                                    
                                                                    <div
                                                                        key={message.id}
                                                                        className={`message ${
                                                                            message?.from?.email !== email ? "left" : "right"
                                                                        }`}
                                                                    >
                                                                       
                                                                    <div>
                                                                    
                                                                        <IoIosContact
                                                                        style={{ marginLeft: "10px", color: "black" }}
                                                                        />
                                                                        <div className="send-users" style={{display:'flex'}}>
                                                                         
                                                                            <div>{message?.from?.name}</div>
                                                                            { message?.to!=='' &&
                                                                            <div>
                                                                                to 
                                                                                {handleToUser(message?.to)}
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                        
                                                                        <div className="time-zone">
                                                                                {new Intl.DateTimeFormat('en-US', {
                                                                                        hour: '2-digit',
                                                                                        minute: '2-digit',
                                                                                        hour12: true
                                                                                        }).format(new Date(message?.updated_at))}
                                                                        </div>
                                                                        {/* <div  style={{fontSize:'11px'}}  > {message?.tag}</div> */}

                                                                        {payerCheck &&<div style={{fontSize:'11px'}} >{message?.contact_name}</div> }
                                                                        

                                                                       
                                                                        
                                                                    </div>

                                                                    <div>
                                                                        <div>{message?.tag}</div>
                                                                        
                                                                        </div>
                                                                    
                                                                    
                                                                        <div 
                                                                    style={{display:'flex',flexDirection:'column'}}
                                                                    // dangerouslySetInnerHTML={{ __html: handleShowMention(message?.mentioned_message) }}
                                                                    >  
                                                                        {/* {message?.original_message} */}
                                                                      { message?.mentioned_message?.split('\n')?.map((data,id)=>(
                                                                         <div  key={id} dangerouslySetInnerHTML={{ __html: handleShowMention(data) }} >
                                                                        {/* // <div> */}
                                                                           {/* { handleShowMention(data)} */}
                                                                        </div>
                                                                      ))
}
                                                                        </div>
                                                                    
                                                                        <div>{message?.flag}</div>
                                                                     
                                                                       
                                                                    </div>
                                                                ))}
                                                            </React.Fragment>
                                                    </div>
                                            ))}
                                    </div>

                                    <div className="chat-bottom">
                                    
                                        <MentionsInput className="menu_input"
                                                style={{...MentionStyle,width:'90%'}}
                                                value={userInput}
                                                placeholder="Type your message here..."
                                                readOnly={payerCheck || inputBlock}
                                                onChange={(e) =>{
                                                //console.log(e.target.value)
                                                setUserInput(e.target.value)   
                                                   }}

                                                onKeyDown={(e)=>{
                                                    if (e.key === "Enter" && !e.shiftKey) {
                                                        e.preventDefault();
                                                        if(!e.target.value){
                                                            setWarning('Please Enter a Message')
                                                            return
                                                        }
                                                        // //console.log(e.target.value)
                                                        setEnterPress(true)
                                                        // handleMessageSent(e.target.value);
                                                      }
                                                }}

                                            
                                           >
                                                        <Mention
                                                        style={{
                                                            backgroundColor: "#ccc",
                                                            // width:'0%'
                                                        }}
                                                        data={suggestions}
                                                        />
                                              </MentionsInput>

                                             <AddDocument clientId={payerInfo?.client_fk} payerId={payerId} email={email} data={response} setData={setResponse} setWarning={setWarning} />

                                <div className="chat-drop-down" >
                                        <Select options={options} styles={customStyles}  value={selectedValue} onChange={handleChange} menuPlacement="top" />
                                </div>
                                        <MdSend onClick={()=>{
                                            handleMessageSent(userInput)
                                        }} size={20} color="darkblue" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div style={{overflowY:'scroll',height:'calc(100vh - 120px)'}} className="other-info">
                                         <div className="head">Other Info</div>
                                        <div className="overall-due" style={{alignItems:'center'}} >
                                            <div>Overall Due : </div>
                                            <div style={{backgroundColor:"lightslategray",color:'#FFFFFF',marginLeft:'10px',padding:'5px 10px',borderRadius:'5px'}} >{handleOverallDue()}</div>
                                        </div>
                                        <div className="payer-invoice">
                                            <div> Last 5 Invoices </div>
                                            <div className="payer-table">
                                            <div className="payer-invoice-head">
                                                <div>Invoice </div>
                                                <div> Paid</div>
                                                <div> Balance</div>
                                            </div>
                                            {showInvoice?.slice(0, 5).map((data, id) => (
                                                <div className="payer-invoice-table">
                                                <div>{data?.invoice_num} </div>
                                                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
                                                       { data?.flag==='PAID' &&
                                                        <div style={{backgroundColor:'rgb(187 247 208)', padding:'2px',borderRadius:'3px' }} >{data?.flag}</div>
                                                        }
                                                        {data?.flag==='UNPAID' &&
                                                        <div style={{backgroundColor:'rgb(253 164 175)', padding:'2px',borderRadius:'3px' }} >{data?.flag}</div>
                                                         }
                                                        {data?.flag==='PARTIALLY PAID' &&
                                                        <div style={{backgroundColor:'rgb(226 232 240)', padding:'2px',borderRadius:'3px' }} >{data?.flag}</div>
                                                        }
                                                     </div>
                                                <div> {data?.balance_amount}</div>
                                                </div>
                                            ))}
                                            </div>
                                        </div>

                                        <div className="payer-invoice">
                                            <div>Other Contacts</div>
                                            <div className="payer-table">
                                            <div className="payer-contact-head">
                                                <div>Name</div>
                                                <div>Designation</div>
                                            </div>
                                            {contactDetails?.map((data, id) => (
                                                <div className="payer-contact-table">
                                                <div style={{cursor:'pointer'}} onClick={()=>{
                                                    navigate(`/Collections/payer/${data?.id}`)
                                                }} >{data?.name}</div>
                                                <div>{data?.type}</div>
                                                </div>
                                            ))}
                                    
                                            </div>
                                        </div>

                                        <div className="payer-invoice">
                                            <div> Document</div>
                                            <div className="payer-table">
                                            <div className="payer-contact-head">
                                                <div>Name</div>
                                                <div>Link</div>
                                            </div>
                                            {documents?.map((data, id) => (
                                                <div className="payer-contact-table">
                                                <div style={{cursor:'pointer'}} >{data?.file_name}</div>
                                                <div><a href={data?.url}>View</a></div>
                                                </div>
                                            ))}
                                    
                                            </div>
                                        </div>
                                </div>
                        </div>

                        <Alerts alert={alert} setAlert={setAlert} warning={warning} setWarning={setWarning} />
                        <Response data={response} setData={setResponse} />
                </div>
  );
}

export default PayerContent;



 const MentionStyle= {
    control: {
      fontSize: 12,
    },
    "&multiLine": {
      control: {
        fontFamily: "monospace",
        minHeight: 0,
        maxHeight:100,
        overflowY:'scroll',
      },
      highlighter: {
        padding: 10,
        border: "2px solid transparent",
      },
      input: {
        padding: 10,
        border: "2px solid silver",
      },
    },
    "&singleLine": {
      display: "inline-block",
      width: 180,
      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
      },
    },
    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid #333",
        fontSize: 12,
        marginTop:'-100px',
        height:'100px',
        overflowY:'scroll'
      },
      item: {
        padding: "10px 20px",
        // borderBottom: "1px solid #333",
        backgroundColor: "white",
        "&focused": {
          backgroundColor: "lightblue",
          color:'black',
          border:'none !important'
        },
      },
    },
  };
