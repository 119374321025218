import React,{useRef, useState} from 'react';
import Typography from '@mui/material/Typography';
import { AiOutlineCloseCircle } from "react-icons/ai";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {
    Switch,
    Modal,
    TextField,
    Box,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@mui/material";
import axios from 'axios';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid lightblue',
  boxShadow: 24,
  p: 4,
};

function DocumentViewer({open,setOpen}) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <div>

<Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid gray",
                                paddingBottom: "10px",
                                fontSize: "20px",
                            }}
                        >
                            <div>View Document</div>
                            <AiOutlineCloseCircle
                                onClick={() => {
                                    handleClose()
                                    // handleClearAll();
                                    // setAddContactPop("");
                                }}
                                size={25}
                                style={{ color: "red", cursor: "pointer" }}
                            />
                        </div>


                        <DocViewer style={{height:'60vh'}}
        documents={[{uri:open}]}
        pluginRenderers={DocViewerRenderers}
        />

                        
          </Box>
        </Modal>

      
    </div>
  )
}

export default DocumentViewer
