// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-note-table{
    margin: 20px;
    max-height: calc(100vh - 140px); /* Set a max height to enable scrolling */
    overflow-y: scroll; 
    /* padding:0px 20px; */
 }
 
 .view-note-table::-webkit-scrollbar {
    width: 0; /* Set the width of the scrollbar to a minimal value */
  }
  
  .view-note-table::-webkit-scrollbar-thumb {
    background: none; /* Set the scrollbar thumb to be transparent */
  }

 .view-note-table-head{
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    text-align: center;
    background-color: #0059B2;
    color: #FFFFFF;
    padding: 20px 10px;
    border-radius: 10px;
    position: sticky;
  top: 0;
  z-index: 1;
 }

 .view-note-table-body{
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    text-align: center;
    padding: 20px 10px;
    border-radius: 25px;
    margin: 20px 10px;
    border: 1px solid lightgray;
    font-size:13px;
    align-items: center;
    justify-content: center;
 }


 .credit{
    background-color:lightblue;
    width: 100px;
    padding: 10px 10px;
    border-radius: 20px;
 }

 .debit{
    background-color:navajowhite ;
    width: 100px;
    border-radius: 20px;
    padding: 10px 10px;
 }
`, "",{"version":3,"sources":["webpack://./src/components/Hospital/ViewNote.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,+BAA+B,EAAE,yCAAyC;IAC1E,kBAAkB;IAClB,sBAAsB;CACzB;;CAEA;IACG,QAAQ,EAAE,sDAAsD;EAClE;;EAEA;IACE,gBAAgB,EAAE,8CAA8C;EAClE;;CAED;IACG,aAAa;IACb,gDAAgD;IAChD,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;EAClB,MAAM;EACN,UAAU;CACX;;CAEA;IACG,aAAa;IACb,gDAAgD;IAChD,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,2BAA2B;IAC3B,cAAc;IAId,mBAAmB;IACnB,uBAAuB;CAJ1B;;;CAQA;IACG,0BAA0B;IAC1B,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;CACtB;;CAEA;IACG,6BAA6B;IAC7B,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;CACrB","sourcesContent":[".view-note-table{\n    margin: 20px;\n    max-height: calc(100vh - 140px); /* Set a max height to enable scrolling */\n    overflow-y: scroll; \n    /* padding:0px 20px; */\n }\n \n .view-note-table::-webkit-scrollbar {\n    width: 0; /* Set the width of the scrollbar to a minimal value */\n  }\n  \n  .view-note-table::-webkit-scrollbar-thumb {\n    background: none; /* Set the scrollbar thumb to be transparent */\n  }\n\n .view-note-table-head{\n    display: grid;\n    grid-template-columns: repeat(6, minmax(0, 1fr));\n    text-align: center;\n    background-color: #0059B2;\n    color: #FFFFFF;\n    padding: 20px 10px;\n    border-radius: 10px;\n    position: sticky;\n  top: 0;\n  z-index: 1;\n }\n\n .view-note-table-body{\n    display: grid;\n    grid-template-columns: repeat(6, minmax(0, 1fr));\n    text-align: center;\n    padding: 20px 10px;\n    border-radius: 25px;\n    margin: 20px 10px;\n    border: 1px solid lightgray;\n    font-size:13px;\n }\n\n .view-note-table-body{\n    align-items: center;\n    justify-content: center;\n }\n\n\n .credit{\n    background-color:lightblue;\n    width: 100px;\n    padding: 10px 10px;\n    border-radius: 20px;\n }\n\n .debit{\n    background-color:navajowhite ;\n    width: 100px;\n    border-radius: 20px;\n    padding: 10px 10px;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
