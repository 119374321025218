// Client_Invoice.js

import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import ApiResponsePopup from './ApiResponsePopup';
import axios from 'axios'
import MyDocument from './Invoice_Document';
import { PDFDownloadLink } from '@react-pdf/renderer';
import exportFromJSON from 'export-from-json';
import * as XLSX from 'xlsx';
import InvoiceTable from './InvoiceTable';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid lightblue',
  boxShadow: 24,
  p: 4,
};

export const ErrorModel = ({ data, error, onClose }) => {



  return (
      <Modal
        open={data}
        onClose={onClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
            <Box sx={{ ...style, width: 450 }}>
                <div className='pop-edit'>

                      <div style={{ display: 'flex', fontSize: '20px', color: 'red' }}>
                  
                            <div style={{ paddingLeft: '10px' }}>
                              {error}
                            </div>
                      </div>

                      <Button sx={{ marginTop: '10px', float: 'right', fontSize: '13px' }} onClick={onClose} variant="contained">Close</Button>

                </div>
            </Box>
      </Modal>
  )
}

const Client_Invoice = ({ clientid, clientname, email, userAccess, setAuthPop, handleAccess }) => {
              const [selectedMonth, setSelectedMonth] = useState(null);
              const [startDate, setStartDate] = useState(null);
              const [endDate, setEndDate] = useState(null);
              const [invoiceDate, setInvoiceDate] = useState(null);
              const [apiResponse, setApiResponse] = useState(null);
              const [pdf, setPdf] = useState('Download Pdf')
              const [errorpop, setError] = useState(true);
              const [excel, setExcel] = useState('Download Excel')
              const [customError, setCustomError] = useState('');
              const [customErrorPop, setCustomErrorPop] = useState(false);
              const [showInvoice, setShowInvoice] = useState([])
              const [address, setAddress] = useState('')
              const [showNotePop, setNotePop] = useState(false)
              const [Note, setNote] = useState('')
              const [errorAlert, setErrorAlert] = useState(null)
              const [invoiceName, setInvoiceName] = useState('')
              const [downlaodButton, setDownloadButton] = useState(true)
              const [downloadPop, setDownloadPop] = useState(false)
              const handleNoteChange = (event) => {
                setNote(event.target.value)
              }

              const fetchDataForMonth = async (month) => {
                        const timeZone = 'Asia/Kolkata';
                        const startDateKolkata = zonedTimeToUtc(new Date(month.getFullYear(), month.getMonth(), 1), timeZone);
                        const endDateKolkata = zonedTimeToUtc(new Date(month.getFullYear(), month.getMonth() + 1, 0), timeZone);

                        setStartDate(startDateKolkata);
                        setEndDate(endDateKolkata);
                        setInvoiceDate(endDateKolkata);
              };

              useEffect(() => {
                        if (showInvoice.length === 0)
                            handleShowInvoice()
              }, [])

              const handleSendNote = async () => {
                  try {
                        const data = [
                            {
                              "clientId": clientid,
                              "startDate": formattedStartDate,
                              "endDate": formattedEndDate,
                              "invoiceDate": formattedInvoiceDate,
                              "user_email": email,
                            },
                        ];
                        if (Note === 'Credit_Note') {
                            const { REACT_APP_CLIENT_SEND_CREDIT_URL } = process.env
                            const response = await axios.post(REACT_APP_CLIENT_SEND_CREDIT_URL, data)
                            if (response.status === 200) {
                                if (response.data[0]?.toString().toLowerCase() === 'Email sent successfully'.toString().toLowerCase()) {
                                    setApiResponse('Email sent successfully'); // Set the API response in the state
                                    //console.log(response.data)
                                } else {
                                     setErrorAlert('Email has not been Sent')
                                }
                            }
                            else {
                                 setErrorAlert('Sent Note Failed')
                            }
                        } else if (Note === 'Debit_Note') {
                            const { REACT_APP_CLIENT_SEND_DEBIT_URL } = process.env
                            const response = await axios.post(REACT_APP_CLIENT_SEND_DEBIT_URL, data)
                            if (response.status === 200) {
                                if (response.data[0]?.toString().toLowerCase() === 'Email sent successfully'.toString().toLowerCase()) {
                                  setApiResponse('Email sent successfully'); // Set the API response in the state
                                  //console.log(response.data)
                                } else {
                                  setErrorAlert('Email has not been Sent')
                                }
                            }
                            else {
                                 setErrorAlert('Sent Note Failed')
                            }
                        }
                  } catch (err) {
                        console.log(err)
                        setErrorAlert('Sent Note Failed')
                  } finally {
                        setNote('')
                  }
              }

              const handleSelfSendInvoice = async (start, end, invoice) => {
                try {
                      setDownloadPop(false)
                      const { REACT_APP_CLIENT_RESEND_INVOICE_URL } = process.env
                      const data = [
                        {
                          "clientId": clientid,
                          "startDate": start,
                          "endDate": end,
                          "invoiceDate": invoice,
                          "user_email": email,
                          "send_self": "yes"
                        },
                      ];
                      const res = await axios.post(REACT_APP_CLIENT_RESEND_INVOICE_URL, data)
             
                      if (res.status === 200) {
                          if (res.data[0]?.toString().toLowerCase() === 'Email sent successfully'.toString().toLowerCase()) {
                             setApiResponse(["Invoice sent successfully"])
                          } else {
                             setErrorAlert('Invoice Not Send')
                          }
                      } else {
                          setErrorAlert("Invoice Not Send")
                      }

                }
                catch (err) {
                  console.log(err)

                }


              }

              const handleShowInvoice = async () => {
                    try {
                          setShowInvoice([])
                          const { REACT_APP_CLIENT_SHOW_INVOICE_URL } = process.env
                          const response = await axios.get(REACT_APP_CLIENT_SHOW_INVOICE_URL + `?client_fk=${clientid}`)
                    
                          if (!response.data.show_invoice_data) {

                          }
                          else if (response.data.show_invoice_data.length > 0) {
                              const data = response?.data?.show_invoice_data?.sort(function (a, b) {
                                return new Date(b?.email_invoice_sent_date) - new Date(a?.email_invoice_sent_date);
                              })
                              setShowInvoice(data)
                          }
                          else {
                          }
                    } catch (Err) {
                          console.log(Err)
                    }

              }

              // Function to handle the "Download Invoice" button click
              const handleDownloadInvoice = async (start_date, end_date, invoice_date, type) => {
                try {
                      const { REACT_APP_CLIENT_DOWNLOAD_INVOICE_URL } = process.env
                      const response = await axios.get(REACT_APP_CLIENT_DOWNLOAD_INVOICE_URL + `?client_fk=${clientid}&start_date=${start_date}&end_date=${end_date}&invoice_date=${invoice_date}`)
                      if (response.data.status_code == 205) {
                          setCustomError('No Invoice Data')
                      }
                      else if (response.status === 200) {
                        if (type === 'pdf')
                         {
                            const res = await fetch(response.data.pdf_link);
                            const blob = await res.blob();
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = `${clientname}_${start_date}_to_${end_date}.pdf`;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        } else if (type === 'excel') {
                            //console.log(response.data.transaction_data)
                            const fileName = `${clientname}_${start_date}_to_${end_date}`;
                            // const type = 'xls';
                            // exportFromJSON({ data: response.data.transaction_data, fileName, type, exportType: type })
                            const wb = XLSX.utils.book_new();
                            const ws = XLSX.utils.json_to_sheet(response.data.transaction_data);
                            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                            XLSX.writeFile(wb, `${fileName}.xlsx`);
                        }
                      }
                } catch (err) {
                      console.log(err)
                } finally {
                      setPdf('Download Pdf')
                      setExcel('Download Excel')
                }

              }

            
              const handleMonthChange = (date) => {
                    setSelectedMonth(date);
                    // Fetch data for the selected month
                    fetchDataForMonth(date);
              };

              useEffect(() => {
                setError(true)

                if (selectedMonth) {
                    setDownloadButton(true)
                    const lastDay = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0);
                    setInvoiceDate(lastDay);

                }
              }, [selectedMonth, startDate, endDate]);

              // Function to handle the "Send Invoice" button click
              const [confirmationOpen, setConfirmationOpen] = useState('');
              const handleSendInvoice = (data) => {
                  setConfirmationOpen(data);
              };

              const timeZone = 'Asia/Kolkata';
              const formattedStartDate = startDate ? format(utcToZonedTime(startDate, timeZone), 'yyyy-MM-dd') : null;
              const formattedEndDate = endDate ? format(utcToZonedTime(endDate, timeZone), 'yyyy-MM-dd') : null;
              const formattedInvoiceDate = invoiceDate ? format(utcToZonedTime(invoiceDate, timeZone), 'yyyy-MM-dd') : null;


              const handleConfirmInvoice = (invoice) => {
                    const data = [
                      {
                        "clientId": clientid,
                        "startDate": formattedStartDate,
                        "endDate": formattedEndDate,
                        "invoiceDate": formattedInvoiceDate,
                        "user_email": email
                      },
                    ];
                    setConfirmationOpen(false);
                    if (invoice === 'Email') {
                          const { REACT_APP_CLIENT_SEND_INVOICE_URL } = process.env
                          fetch(REACT_APP_CLIENT_SEND_INVOICE_URL, {
                            method: 'POST', // or 'GET' if appropriate
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(data),

                          }).then(response => response.json())
                            .then((response) => {
                                if (response === null) {
                                  setErrorAlert('Invoice Note Failed')
                                }
                                else if (response[0]?.toString().toLowerCase() === 'Email sent successfully'.toString().toLowerCase()) {
                                  //console.log(response);
                                  //console.log('Invoice sent successfully!');
                                  setApiResponse("Invoice sent successfully")
                                }
                                else {
                                  setErrorAlert('Sent Invoice Failed')
                                  throw new Error('Network response was not ok');
                                }
                            })
                            .catch((error) => {
                                setErrorAlert('Sent Invoice Failed')
                                console.error('Error sending the invoice:', error);
                            })
                            .finally(() => {
                            });

                    } else if (invoice === 'Whatsapp') {
                      const { REACT_APP_CLIENT_SEND_WATSAPP_INVOICE_URL } = process.env
                      fetch(REACT_APP_CLIENT_SEND_WATSAPP_INVOICE_URL, {
                        method: 'POST', // or 'GET' if appropriate
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),

                      }).then(response => response.json())
                        .then((response) => {
                              if (response === null) {
                                setErrorAlert('Invoice Note Failed')
                              }
                              else if (response[0]?.toString().toLowerCase() === 'watsapp message sent successfully'.toString().toLowerCase()) {
                                //console.log(response);
                                //console.log('Invoice sent successfully!');
                                setApiResponse("Invoice sent successfully")
                              }
                              else {
                                setErrorAlert('Sent Invoice Failed')
                                throw new Error('Network response was not ok');
                              }

                        })
                        .catch((error) => {
                  
                              setErrorAlert('Sent Invoice Failed')
                              console.error('Error sending the invoice:', error);
                        })
                        .finally(() => {
                        });
                    }
                    else if (invoice === 'Historical'){




                      

                      const { REACT_APP_CLIENT_SEND_HISTORICAL_INVOICE_URL } = process.env
                      fetch(REACT_APP_CLIENT_SEND_HISTORICAL_INVOICE_URL, {
                        method: 'POST', // or 'GET' if appropriate
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),

                      }).then(response => response.json())
                        .then((response) => {
                              if (response === null) {
                                setErrorAlert('Invoice Note Failed')
                              }
                              else if (response[0]?.toString().toLowerCase() === 'Email sent successfully'.toString().toLowerCase()) {
                                //console.log(response);
                                //console.log('Invoice sent successfully!');
                                setApiResponse("Invoice sent successfully")
                              }
                              else {
                                setErrorAlert('Sent Invoice Failed')
                                throw new Error('Network response was not ok');
                              }

                        })
                        .catch((error) => {
                  
                              setErrorAlert('Sent Invoice Failed')
                              console.error('Error sending the invoice:', error);
                        })
                        .finally(() => {
                        });

                    }
              };



              const funDate = (date1) => {
                const date = new Date(date1);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const day = String(date.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                return formattedDate
              }





  return (
      <div >
        <div className='invoice-main' style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '20px', justifyContent: 'center' }}>
            <Box display="flex" alignItems="center" >

                <div style={{ marginRight: '10px', zIndex: '5' }}>
                    <div>Select Month</div>
                    <DatePicker
                      selected={selectedMonth}
                      onChange={handleMonthChange}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      customInput={<Button variant="outlined" color="primary" style={{ fontSize: '10px' }}>{selectedMonth ? selectedMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }) : 'SELECT MONTH'}</Button>}
                    />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                    <div style={{ marginRight: '10px', zIndex: '5' }}>
                        <div>Start Date</div>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="yyyy-MM-dd"
                          customInput={<Button variant="outlined" color="primary" style={{ fontSize: '10px' }}>{startDate ? startDate.toLocaleDateString('en-US') : 'Start Date'}</Button>}
                        />
                    </div>

                    <div style={{ marginRight: '10px', zIndex: '5' }}>
                        <div>End Date</div>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="yyyy-MM-dd"
                          customInput={<Button variant="outlined" color="primary" style={{ fontSize: '10px' }}>{endDate ? endDate.toLocaleDateString('en-US') : 'End Date'}</Button>}
                        />
                    </div>

                    <div style={{ zIndex: '5' }}>
                        <div>Invoice Date</div>
                        <DatePicker
                          selected={invoiceDate}
                          onChange={(date) => setInvoiceDate(date)}
                          dateFormat="yyyy-MM-dd"
                          customInput={<Button variant="outlined" color="primary" style={{ fontSize: '10px' }}>{invoiceDate ? invoiceDate.toLocaleDateString('en-US') : 'Invoice Date'}</Button>}
                        />
                      </div>
                </div>
            </Box>

            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '50px', marginTop: '10px' }}>

                  <Button variant="contained" color="primary" onClick={() => {
                        if (selectedMonth === null) {
                            setCustomError('Please Select the Month');
                            setCustomErrorPop(true)
                        }
                        else if (handleAccess('Hospital', 'Send Invoice') === true)
                          {
                            handleSendInvoice('Email')
                          }
                        else {
                          setAuthPop(true)
                        }
                      }
                      } 
                  style={{ fontSize: '10px',padding:'6px' }}>
                    Send Invoice
                  </Button>



                  <Button variant="contained" color="primary" onClick={() => {
                    if (selectedMonth === null) {
                      setCustomError('Please Select the Month');
                      setCustomErrorPop(true)
                    }
                    else if (handleAccess('Hospital', 'Send Invoice') === true) 
                    {
                      handleSendInvoice('Whatsapp') 
                    }
                    else {
                      setAuthPop(true)
                    }
                  }
                  } 
                  style={{ fontSize: '10px', marginLeft: '10px',padding:'6px' }}>
                    Send whatsapp Invoice
                  </Button>

                  <Button variant="contained" color="primary" onClick={() => {
                    if (selectedMonth === null) {
                      setCustomError('Please Select the Month');
                      setCustomErrorPop(true)
                    }
                    else if (handleAccess('Hospital', 'Send Invoice') === true) 
                    {
                      handleSendInvoice('Historical') 
                    }
                    else {
                      setAuthPop(true)
                    }
                  }
                  } 
                  style={{ fontSize: '10px', marginLeft: '10px',padding:'6px' }}>
                    Send Historical Invoice
                  </Button>

                  <Button style={{ fontSize: '10px', marginLeft: '10px',padding:'6px' }} onClick={() => {
                      if (selectedMonth === null) {
                        setCustomError('Please Select the Month');
                        setCustomErrorPop(true)
                      }
                      else if (handleAccess('Hospital', 'Download Invoice') === true) 
                      { 
                        setDownloadPop(true)
                      }
                      else {
                        setAuthPop(true)
                      }
                  }} variant="contained" color="primary">
                     Download Invoice
                  </Button>
      
                  <Button variant="contained" color="primary" onClick={() => {
                      if (selectedMonth === null) {
                        setCustomError('Please Select the Month')
                        setCustomErrorPop(true)
                      }
                      else if (handleAccess('Hospital', 'Download Invoice') === true) {
                        handleDownloadInvoice(formattedStartDate, formattedEndDate, formattedInvoiceDate, 'excel')
                        setExcel('Downloading...')
                      }
                      else {
                        setAuthPop(true)
                      }
                  }} style={{ marginLeft: '10px', fontSize: '10px',padding:'6px' }}>
                    {excel}
                  </Button>
            </div>
            {/* Confirmation Dialog */}

            <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                        {selectedMonth && startDate && endDate && (
                          <p>
                            You are trying to send {confirmationOpen} invoices for {clientname} for the duration of{' '}
                            {startDate.toLocaleDateString('en-US')} - {endDate.toLocaleDateString('en-US')}. Click 'YES' to confirm
                            and send the invoice.
                          </p>
                        )}
                  </DialogContent>
                  <DialogActions>
                        <Button onClick={() => setConfirmationOpen('')} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={() => handleConfirmInvoice(confirmationOpen)} color="primary" autoFocus>
                          Yes
                        </Button>
                  </DialogActions>
            </Dialog>

            <Dialog 
                onClose={() => {
                  setNotePop(false)
                  setNote('')
                }} open={showNotePop}
            >

                <div style={{ width: '400px', textAlign: 'center', padding: '30px' }}>
                      <div style={{ padding: '10px', marginBottom: '30px', fontSize: '20px', color: 'rgb(71 85 105)' }}> 
                        Choose Note
                      </div>
                      <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Note</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={Note}
                              label="Select Note"
                              onChange={handleNoteChange}
                            >
                              <MenuItem value='Credit_Note'>Credit Note</MenuItem>
                              <MenuItem value='Debit_Note'>Debit Note</MenuItem>
                            </Select>
                      </FormControl>
                      <div style={{ marginTop: '30px', marginBottom: '20px' }}>

                          <Button variant="contained" color="primary" 
                             onClick={() => {
                                setNotePop(false)
                                setNote('')
                             }} 
                            style={{ fontSize: '10px', marginLeft: '10px' }}
                          >
                            Cancel
                          </Button>

                          <Button variant="contained" color="primary" onClick={() => {
                            setNotePop(false);
                            handleSendNote()
                          }
                          } style={{ fontSize: '10px', marginLeft: '15px' }}>
                            submit
                          </Button>


                      </div>
                </div>
            </Dialog>


            <ApiResponsePopup data={true} apiResponse={apiResponse} onClose={() => setApiResponse(null)} />
        </div>

        {showInvoice.length > 0 &&
          <InvoiceTable data={showInvoice} email={email} clientname={clientname} handleSelfSend={handleSelfSendInvoice} userAccess={userAccess} handleAccess={handleAccess} setAuthPop={setAuthPop} setApiResponse={setApiResponse} setErrorAlert={setErrorAlert} />
        }

        <ErrorModel data={customErrorPop} error={customError} onClose={() => {setCustomErrorPop(false) }} />

        <Snackbar open={errorAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ zIndex: '6' }} onClose={() => { setErrorAlert(false) }}>
            <Alert onClose={() => { setErrorAlert(false) }} severity="error" sx={{ width: '100%' }}>
              {errorAlert}
            </Alert>
        </Snackbar>

        <Modal
          open={downloadPop}
          onClose={() => {
            setDownloadButton(true)
            setDownloadPop(false)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
              <Box sx={style}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ color: 'darkblue', marginBottom: '40px', fontSize: '20px' }}>Invoices </div>
                      

                        <Button variant="contained" color="primary" style={{ marginLeft: '10px', fontSize: '10px' }} onClick={() => {
                          handleDownloadInvoice(formattedStartDate, formattedEndDate, formattedInvoiceDate, 'pdf')
                          setPdf('Downloading...')
                        }}>
                          {pdf}
                        </Button>

                        <Button variant="contained" color="primary" style={{ marginLeft: '10px', fontSize: '10px' }} onClick={() => handleSelfSendInvoice(formattedStartDate, formattedEndDate, formattedInvoiceDate)}>
                          Send Invoice To Self
                        </Button>
                    </div>

              </Box>

        </Modal>


      </div>
  );
};

export default Client_Invoice;
