import React, { useEffect,useState } from 'react'
import { BorderLinearProgress, RedLinearProgress } from './GmStudyOverall';
import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";
import { FaFileDownload } from "react-icons/fa";

function RadWiseGm({gmData,handleDownload}) {
    const [gmActualData,setGmActualData]=useState([])

    useEffect(()=>{
        const result = gmData?.reduce((acc, current) => {
            const rad_fk = current.rad_fk 
          
            // Initialize values for the group if not present
            if (!acc[rad_fk]) {
              acc[rad_fk] = {
                revenue:0,
                payable: 0,
                No_of_report: 0,
                rad_name:current.rad_name
              };
            }
          
            // Update values for the group
            acc[rad_fk].revenue += current.Revenue;
            acc[rad_fk].payable += current['Rad Payable'];
            acc[rad_fk].No_of_report+=current['No_of_report']
          
            return acc;
          }, {});
          
        //   Convert the result into an array of objects
        if(result){
          const resultArray = Object?.entries(result)?.map(([grouped_fields, { revenue, payable,No_of_report,rad_name }]) => {
            const [rad_fk, date] = grouped_fields.split('to');
            return {
              rad_fk,
              rad_name,
              date,
              revenue,
              payable,
              No_of_report
            };
          });

         //console.log(resultArray,'hein')
          setGmActualData(resultArray)
        }
    },[gmData])
    const handleGm=(reve,paya)=>{
       const data= ((reve-paya)/reve)*100
       return parseFloat(data).toFixed(2)
    }

    const averageReport=(count,revenue)=>{
        const data= revenue/count
        return parseInt(data)
    }

    const averageCost=(count,payable)=>{
        const data= payable/count
        return parseInt(data)
    }

    const handleSendDownload=(data,filename)=>{
      const data1= data?.map(data=>{
        return{
          'Radiologist Name':data?.rad_name,
          'No of Reports':data?.No_of_report,
          'Revenue':data?.revenue,
          'Payable':data?.payable,
          'Gm':`${handleGm(data?.revenue, data.payable)}%` ,
          'Average Revenue per Report':averageReport(data?.No_of_report, data?.revenue),
          'Average Cost per Report':averageCost(data?.No_of_report, data?.payable)
        }
      })
  
      handleDownload(data1,filename)
  }

  return (
    <> {gmActualData.length===0?
        <div style={{margin:'auto'}}>
        <ReactLoading 
        type={"bars"}
        color={"#0059B2"}
        height={100}
        width={100}
      /> 
      </div>:
    <div style={{ backgroundColor: 'transparent', borderRadius: '20px', padding: '20px' }}>
            <div style={{ fontSize: '25px', color: '#1B2559',display:'flex',justifyContent:'space-between' }}>
             <div>RAD</div> 
            <div><FaFileDownload onClick={()=>handleSendDownload(gmActualData,'Rad_Wise_Gm')} color="blue" style={{cursor:'pointer'}} /></div>
              
              </div>
            <div >
                <div className='gm-revenue-head'>
                    <div>Rad Name</div>
                    <div>No of Reports</div>
                    <div>Revenue</div>
                    <div>Payable</div>
                    <div>GM</div>
                    <div>Average Revenue per Report</div>
                    <div>Average Cost per Report</div>
                </div>
                <div className='table-revenue-table' style={{ height: '700px', overflowY: 'scroll' }}>
                    {gmActualData?.sort((a,b)=>b.revenue-a.revenue).map((data, id) => (
                        <div key={id} className='gm-revenue-body'>
                            <div>{data.rad_name}</div>
                            <div>{data?.No_of_report}</div>
                            <div>{new Intl.NumberFormat('en-IN', {
                                style: 'currency',
                                currency: 'INR',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }).format(data?.revenue)}</div>
                            <div  >
                            {new Intl.NumberFormat('en-IN', {
                                style: 'currency',
                                currency: 'INR',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }).format(data.payable)}
                            
                            </div>
                            <div style={{textAlign:'end'}}>
                                 { handleGm(data?.revenue,data.payable)>0 ?
                                <div className='table-revenue-body-gm-postive'>
                                {`${handleGm(data?.revenue,data.payable)}%`}
                                <BorderLinearProgress variant="determinate" value={handleGm(data?.revenue,data.payable)>100?100:handleGm(data?.revenue,data.payable)} />
                                </div>
                                 :
                                 <div className='table-revenue-body-gm-negative'>
                                {`${handleGm(data?.revenue,data.payable)}%`}
                                <RedLinearProgress variant="determinate" value={Math.abs(handleGm(data?.revenue,data.payable))>100?100:Math.abs(handleGm(data?.revenue,data.payable))} />
                                </div>
                                }
                                </div>
                                <div>
                                {averageReport(data?.No_of_report,data?.revenue)}
                                </div>
                                <div>
                                {averageCost(data?.No_of_report,data?.payable)}
                                </div>
                               
                        </div>
                    ))
                    }
                </div>
                </div>
    </div>}</>
  )
}

export default RadWiseGm
