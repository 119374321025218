import React, { useEffect, useState, useContext } from 'react';
import './HospitalNotice.css'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import axios from 'axios'
import {Select,  } from "antd";
import { Input,  } from 'antd';

import UploadNotice from './UploadNotice';
import { DatePicker } from 'antd';
import { Refresh } from '@material-ui/icons';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const { Search } = Input;
const { Option } = Select;

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

export const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);



function HospitalNotices({ user, email,setWarning,setAlert,setAuthPop,handleAccess }) {
  const [hospitalData, setHospitalData] = useState([])
  const [noticeList, setNoticeList] = useState([])
  const [SearchHospital, setSearchHospital] = useState(null)
  const [month, setMonth] = useState(null)
  const [notice, setNotice] = useState(null)
  const [noticeType, setNoticeType] = useState(null)
  const [amount, setAmount] = useState(null)
  const [content, setContent] = useState(null)
  const [textEditable, setTextEditable] = useState(true)
  const [uploadPop, setUploadPop] = useState(false)
  const [dateActiveTill, setActiveTill] = useState(null)
  const [date, setDate] = useState('')
  const [refresh, setRefresh] = useState(true)
  const [confirmPopup, setConfirmPopup] = useState('')
  const [popUpdelete, setPopupDelete] = useState('')
  const [edit, setEdit] = useState(false)
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];
  useEffect(() => {
    fetchHospital()
  }, [])

  const fetchHospital = async () => {
    try {
      const { REACT_APP_CLIENT_LISTING_FOR_SEND_INVOICE_URL } = process.env
      const response = await axios.get(REACT_APP_CLIENT_LISTING_FOR_SEND_INVOICE_URL);
      const data = response.data[0];
      setHospitalData(data)
    } catch {
    }
  }

  const clearAll = () => {
    setTextEditable(false)
    setSearchHospital(null)
    setMonth(null)
    setNotice(null)
    setNoticeType(null)
    setDate(null)
    setAmount(null)
    setContent('')
  }

  const onChangeDate = (date, dateString) => {
    ////console.log(date, dateString);
    setActiveTill(dateString)
    setDate(date)
  };

  const handleEdit = (data) => {

    setEdit(data)
    setAmount(data?.amount)
    setSearchHospital(data.unique_id)
    setContent(data.message)
    setNotice(data.notice)
    setNoticeType(data.notice_type)
    setDate(dayjs((data.active_till?.split(['T'])[0]), 'YYYY-MM-DD'))
    // console.log(dayjs((data.active_till?.split(['T'])[0]), 'YYYY-MM-DD'))
    setActiveTill(data.active_till?.split(['T'])[0])
    // console.log(data.active_till?.split(['T'])[0])
  }

  const dateBodyTemplate = (rowData) => {
    const formattedDate = new Date(rowData).toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    const datePart = formattedDate.slice(0, -8);
    const timePart = formattedDate.slice(-8);
    return (
      <div>
        <div className="date-part">{datePart} </div>
        <div className="time-part">{timePart}</div>
      </div>
    );
  };


  const handleDeleteDeactivate = async (data) => {
    try {
      if (data?.type === 'InActivate') {
        const { REACT_APP_NOTICE_EDIT_URL } = process.env
        const body = {
          edit_type: 'deactivate',
          row_id: data.row_id,
          status: 'InActive'
        }
        const res = await axios.post(REACT_APP_NOTICE_EDIT_URL, body)
        if (res?.status === 200) {
          setAlert('InActivated Successfully')
          setRefresh(true)
        } else {
          setWarning('InActivated Failed')
        }
      } else if (data?.type === 'Delete') {
        //console.log('Delete')
        const { REACT_APP_NOTICE_EDIT_URL } = process.env
        const body = {
          edit_type: 'delete',
          row_id: data.row_id,

        }
        const res = await axios.post(REACT_APP_NOTICE_EDIT_URL, body)
        if (res?.status === 200) {
          setAlert('Deleted Successfully')
          setRefresh(true)
        } else {
          setWarning('Delete Failed')
        }
      }
    } catch (err) {
      setWarning('Delete Failed')
    }

  }

  const apiHandleEdit = async () => {
    try {
      const body = {
        "client_name": edit?.client_name,
        "unique_id": edit?.unique_id,
        "notice": notice,
        "type": noticeType,
        "amount":amount,
        "message": content,
        "active_till": dateActiveTill,
        "status": "Active",
        'updated_by': email,
        "row_id": edit?.row_id,
        "edit_type": 'edit'
      }
      //console.log(body)
      const { REACT_APP_NOTICE_EDIT_URL } = process.env
      const res = await axios.post(REACT_APP_NOTICE_EDIT_URL, body)
      //console.log(res)
      if (res?.status === 200) {
        setAlert('Updated Successfully')
        setRefresh(true)
      } else {
        setWarning('Update Failed')
      }

    } catch (err) {
      setWarning('Update Failed')
    } finally {
      clearAll()
      setEdit('')
      setTextEditable(true)
    }
  }

  const handleInsertNotice = () => {
    if (content === '' || content === null) {
      setWarning('Please generate the message')
      return
    }
    if (SearchHospital === null || SearchHospital === '') {
      setWarning('Please Select the Hospital')
      return
    }
    if (month === null || month === '') {
      setWarning('Please Select the month')
      return
    }
    if (notice === null || notice === '') {
      setWarning('Please Select the notice')
      return
    }
    if (amount === null || amount === '') {
      setWarning('Please Enter the Amount')
      return
    }
    if (noticeType === null || noticeType === '') {
      setWarning('Please Select the Notice Type')
      return
    }
    if (dateActiveTill === null || dateActiveTill === '') {
      setWarning('Please choose the active Till Date')
      return
    }
    if (new Date(dateActiveTill) <= new Date()) {
      setWarning('Active date should should be greater than now')
      return

    }
    if (!content?.includes(month) || !content?.includes(amount)) {
      setWarning('Selected month and money should not match with the message')
      return
    }
    const client_name = hospitalData?.filter(data => {
      if (SearchHospital === data.unique_id) {
        return data.client_name
      }
    })
    const body = {
      "client_name": client_name[0].client_name,
      "unique_id": SearchHospital,
      "notice": notice,
      "type": noticeType,
      "message": content,
      "active_till": dateActiveTill,
      "status": "Active",
      "amount":amount,
      'created_by': email
    }

    //console.log(body)
    //console.log(noticeList)
    const checkData = noticeList.filter((data) => {
      if (body?.client_name === data?.client_name && data?.status === 'Active') {
        //console.log('hello')
        return true
      }
    })

    //console.log(checkData)
    if (checkData?.length > 0) {
      let list = [];
      checkData.map(data => list.push(data.client_name))
      //console.log(list)
      setConfirmPopup({ body: [body], list: list })
    } else {
      apiInsertNotice([body])
    }
  }

  const apiInsertNotice = async (body) => {
    try {
      const { REACT_APP_NOTICE_INSERT_URL } = process.env
      const res = await axios.post(REACT_APP_NOTICE_INSERT_URL, body)
      //console.log(res)
      if (res.status === 200) {
        setAlert('Notice Inserted Successfully')
        setRefresh(true)
      } else {
        setWarning('Insert Failed')
      }
    } catch (err) {
      setWarning('Insert Failed')
    } finally {
      clearAll()
      setTextEditable(true)
    }
  }

  const getHosspitalNotice = async () => {
    try {
      const { REACT_APP_NOTICE_GET_URL } = process.env
      const res = await axios.get(REACT_APP_NOTICE_GET_URL)
      //  //console.log(res.data.data)
      if (res?.data?.data) {
            const data = res?.data.data.sort(function (a, b) {
              return new Date(b.created_on) - new Date(a.created_on);
            });
        setNoticeList(data)
      }
    } catch (err) {

    }
  }
  useEffect(() => {
    if (Refresh) {
      getHosspitalNotice()
      setRefresh(false)
    }

  }, [refresh])

  const handleGenerate = () => {
    if (SearchHospital === null || SearchHospital === '') {
      setWarning('Please Select the Hospital')
      return
    }
    if (month === null || month === '') {
      setWarning('Please Select the month')
      return
    }
    if (notice === null || notice === '') {
      setWarning('Please Select the notice')
      return
    }
    if (amount === null || amount === '') {
      setWarning('Please Enter the Amount')
      return
    }
    if (noticeType === null || noticeType === '') {
      setWarning('Please Select the Notice Type')
      return
    }
    if (dateActiveTill === null || dateActiveTill === '') {
      setWarning('Please choose the active Till Date')
      return
    } if (new Date(dateActiveTill) <= new Date()) {
      setWarning('Active Till date should should be greater than now')
      return

    }
    const formatContent = `Dear Valued Customer, you have an outstanding amount of Rs ${amount} as on ${month}. Kindly pay as early as possible to avoid service interruption.`
    setContent(formatContent)
  }

  const hanldefileUpload = (data) => {
    const matchingValues = [];
    for (const item1 of data) {
      // Find the corresponding item in the second list by id
      const item2 = noticeList.find((item) => item.unique_id === item1.unique_id);
      if (item2) {
        matchingValues.push(item1);
      }
    }

    const body = data.map(data => {
      return {
        ...data,
        'created_by': email
      }
    })

    if (matchingValues?.length > 0) {
      let list = []
      matchingValues?.map(data => list.push(data.client_name))
      setConfirmPopup({ body: body, list: list })
    } else {
      apiInsertNotice(body)
    }
  }


  const handleJoin = (list) => {
    return list?.join(', ')
  }

  return <div >
    <div className='notice-filters'>
      <div>

        <Select
          showSearch
          style={{ width: "300px" }}
          size="large"
          placeholder="Search for Client"
          optionFilterProp="children"
          value={SearchHospital}
          onChange={(value) => setSearchHospital(value)}
        >
          {
            hospitalData?.map((data, id) => (
              <Option
                key={id}
                value={data.unique_id}
              >
                {data.client_name}
              </Option>
            ))

          }

        </Select>
      </div>

      <div>
        <Select

          style={{ width: "150px" }}
          size="large"
          placeholder="Select Month"
          optionFilterProp="children"
          value={month}
          onChange={(value) => setMonth(value)}
        >
          {months.map((month, id) => (
            <Option key={id} value={month}>
              {month}
            </Option>
          ))}
        </Select>
      </div>

      <div>
        <Select

          style={{ width: "150px" }}
          size="large"
          placeholder="Select Notice"
          optionFilterProp="children"
          value={notice}
          onChange={(value) => setNotice(value)}
        >

          <Option value={'Payment Due'}>
            Payment Due
          </Option>
          <Option value={'Overdue'}>
            Overdue
          </Option>
          <Option value={'Suspension'}>
            Suspension
          </Option>
        </Select>
      </div>

      <div>
        <Input style={{height:'40px'}} value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Enter Outstanding Amount" />
      </div>

      <div>
        <Select

          style={{ width: "150px" }}
          size="large"
          placeholder="Select Notice Type"
          optionFilterProp="children"
          value={noticeType}
          onChange={(value) => setNoticeType(value)}
        >

          <Option value={'General'}>
            General
          </Option>
          <Option value={'Warning'}>
            Warning
          </Option>

        </Select>
      </div>

      <div>
        <DatePicker placeholder='Active Till' value={date} format="YYYY-MM-DD" onChange={onChangeDate} />

      </div>



    </div>
    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>

      <Textarea style={{ width: '65%', height: '80px' }}
        value={content}
        aria-label="maximum height"
        onChange={(e) => setContent(e.target.value)}
        defaultValue=""
        readOnly={textEditable}
      />
      {edit ?
        <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <Button style={{ marginTop: '10px', fontSize: '11px', width: '150px' }} variant='contained' onClick={() => setTextEditable(false)}  > Edit </Button>
          <Button style={{ marginTop: '10px', fontSize: '11px', width: '150px' }} variant='contained' onClick={() => {
            clearAll()
            setEdit(false)
            setTextEditable(true)
          }}> Cancel Edit </Button>
          <Button style={{ marginTop: '10px', fontSize: '11px', width: '150px' }} variant='contained' onClick={() => apiHandleEdit()} > Update </Button>

        </div>
        :
        <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <Button style={{ marginTop: '10px', fontSize: '11px', width: '100px' }} variant='contained' onClick={() => {
            // if (user?.access === 'admin') {
            if(handleAccess('HospitalNotice','Edit Notice Text Box')){
              setTextEditable(false)
            }
            else {
              setAuthPop(true)
            }
          }} > Edit </Button>
          <Button style={{ marginTop: '10px', fontSize: '11px', width: '100px' }} variant='contained' onClick={() => {
            // if (user?.access === 'admin') {
            if(handleAccess('HospitalNotice','Send Notice')){
              handleInsertNotice()
            }
            else {
              setAuthPop(true)
            }
          }} > Submit </Button>

        </div>
      }
      {edit ? '' :
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
          <Button style={{ fontSize: '11px', width: '150px', marginBottom: '10px' }} onClick={() => {
            // if (user?.access === 'admin') {
              handleGenerate()
            // }
            // else {
            //   setAuthPop(true)
            // }
          }} variant="contained">Generate</Button>
          <Button variant='contained' style={{ fontSize: '11px', width: '150px' }} onClick={() => {
            // if (user?.access === 'admin') {
            if(handleAccess('HospitalNotice','Bulk Upload Notice')){
              setUploadPop(true)
            }
            else {
              setAuthPop(true)
            }
          }}  > Bulk Upload</Button>
        </div>
      }
    </div>

    <div className='notice-table'>
      <div className='notice-table-head'>
        <div>Client Name</div>
        <div>Notice</div>
        <div>Notice Type</div>
        <div className='notice-message'>Message</div>
        <div>Status</div>
        <div>Created On</div>
        <div>Active Till</div>
        <div>Action</div>
      </div>
      {noticeList.map((data, id) => (
        <div key={id} className='notice-table-body'>
          <div>{data?.client_name}</div>
          <div>{data?.notice}</div>
          <div>{data?.notice_type}</div>
          <div className='notice-message'>{data?.message}</div>
          <div style={{ color: data?.status === 'Active' ? 'green' : 'red' }}>{data?.status}</div>
          <div>{dateBodyTemplate(data?.created_on)}</div>
          <div>{dateBodyTemplate(data?.active_till)}</div>
          <div className='notice-table-action'>
            <div><button onClick={() => {
              // if (user?.access === 'admin') {
              if(handleAccess('HospitalNotice','Notice Action')){
                handleEdit(data)
              }
              else {
                setAuthPop(true)
              }
            }}  >Edit</button></div>
            <div><button onClick={() => {
              // if (user?.access === 'admin') {
              if(handleAccess('HospitalNotice','Notice Action')){
                setPopupDelete({ ...data, type: 'InActivate' })
              }
              else {
                setAuthPop(true)
              }
            }} >InActivate</button></div>
            <div><button onClick={() => {
              // if (user?.access === 'admin') {
              if(handleAccess('HospitalNotice','Notice Action')){
                setPopupDelete({ ...data, type: 'Delete' })
              }
              else {
                setAuthPop(true)
              }
            }} >Delete</button></div>
          </div>

        </div>
      ))}

    </div>



    
    <UploadNotice open={uploadPop} handleClose={() => setUploadPop('')} SetError={setWarning} onFileUpload={hanldefileUpload} />
    {/* <img className='page_img' style={{width:'99%',height:'93vh'}} src={Empty_image}></img> */}
    

    <Dialog
      open={confirmPopup}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setConfirmPopup('')}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Confirmation !!!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Previous message will be replaced by the current message for {handleJoin(confirmPopup?.list)}
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => setConfirmPopup('')}>Disagree</Button>
        <Button onClick={() => {
          apiInsertNotice(confirmPopup.body)
          setConfirmPopup('')
        }}>Agree</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={popUpdelete}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setPopupDelete('')}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Confirmation !!!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Do you want to {popUpdelete?.type} the notice of {popUpdelete?.client_name}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPopupDelete('')}>Disagree</Button>
        <Button onClick={() => {
          handleDeleteDeactivate(popUpdelete)
          setPopupDelete('')
        }}>Agree</Button>
      </DialogActions>
    </Dialog>


  </div>;
}

export default HospitalNotices;
