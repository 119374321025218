import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputAdornment,
  TextField,
  DialogContentText,
} from "@mui/material";
function RadEditCost({ data, onClose, handleEdit }) {
  const [newCost, setNewCost] = useState(null);
  const [reason, setReason] = useState("");
  const errorRef = useRef(null);
  const handleSave = () => {
    //console.log(typeof newCost);
    if (newCost === null || newCost === "") {
      errorRef.current.innerHTML = "Please Enter the New Cost";
      return;
    }
    if (reason === null || reason === "") {
      errorRef.current.innerHTML = "Please Enter a Valid Reason";
      return;
    }

    handleEdit(data, newCost, reason);
    setNewCost(null);
    setReason("");
    onClose();
  };

  // useEffect(()=>{
  //    setNewCost(data?.rad_payable)
  // },[])

  return (
    <div>
      <Dialog
        open={data}
        onClose={() => {
          onClose();
          setNewCost(null);
          setReason("");
        }}
        width={50}
        fullWidth
      >
        <DialogTitle sx={{ textAlign: "center", color: "darkblue" }}>
          Edit Cost
        </DialogTitle>
        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <div style={{ padding: "5px" }}>
              <strong>Date: </strong>{" "}
              <span style={{ color: "gray" }}>{data?.date}</span>
            </div>
            <div style={{ padding: "5px" }}>
              <strong>Order ID: </strong>{" "}
              <span style={{ color: "gray" }}> {data?.order_id}</span>
            </div>
            <div style={{ padding: "5px" }}>
              <strong>Patient Name: </strong>{" "}
              <span style={{ color: "gray" }}> {data?.patient_name}</span>
            </div>
            <div style={{ padding: "5px" }}>
              <strong>Study Name: </strong>{" "}
              <span style={{ color: "gray" }}> {data?.study_name}</span>
            </div>
          </div>
          <div style={{ marginTop: 16 }}>
            <TextField
              sx={{ width: "100%" }}
              fullWidth
              label="New Cost"
              variant="outlined"
              type="number"
              value={newCost}
              onChange={(e) => {
                setNewCost(e.target.value);
                errorRef.current.innerHTML = "";
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">&#8377;</InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ marginTop: 16 }}>
            <TextField
              fullWidth
              sx={{ width: "100%" }}
              label="Enter Valid Reason"
              variant="outlined"
              type="text"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                errorRef.current.innerHTML = "";
              }}
            />
          </div>
        </DialogContent>
        <div
          style={{ color: "red", textAlign: "center", margin: "10px 0px" }}
          ref={errorRef}
        >
          {" "}
        </div>
        <DialogActions
          sx={{ justifyContent: "center !important", paddingBottom: "20px" }}
        >
          <Button
            onClick={() => {
              onClose();
              setNewCost(null);
              setReason("");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RadEditCost;
