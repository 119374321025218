import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router, } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

ReactDOM.render(
  <Auth0Provider
  domain={process.env.REACT_APP_OAUTH_DOMAIN}
  clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
  authorizationParams={{
    redirect_uri: window.location.origin
  }}
  >
  <React.StrictMode>
  <Router>
    <App />
    </Router>
  </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
);
