import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Link,
} from "@react-pdf/renderer";
import { IoIosContact } from "react-icons/io";

import { useEffect } from "react";

const styles = StyleSheet.create({
  tax: {
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "13px",
    marginTop: "2px",
    fontWeight: "bold",
  },
  page: {
    padding: 20,
    fontSize: "7px",
    fontWeight: 700,
    width: "400mm",
  },
  chatRight: {
    display: "flex",
    justifyContent: "end",
    marginLeft: "auto",
    marginTop: "2px",
    marginBottom: "2px",
    maxWidth: "70%",
  },

  chatLeft: {
    display: "flex",
    justifyContent: "end",
    marginRight: "auto",
    maxWidth: "70%",
    marginTop: "2px",
    marginBottom: "2px",
  },
});

const MyDocument = ({ email, user }) => {
  const [user1, setUser] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [startdate, setstartDate] = React.useState("");
  const [enddate, setendDate] = React.useState("");
  useEffect(() => {
    setUser(JSON.parse(user));
    //console.log(user1);
  }, [user]);

  const handleToUser = (to) => {
    let user = "";
    let flag = 1;
    to?.map((data) => {
      if (flag !== 1) {
        user += "," + data?.name;
       
      } else {
        user += data?.name;
        flag = 2;
      }
    });

    return <Text>{user}</Text>;
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <Image style={styles.logo} src={logo}></Image> */}
        <View style={styles.tax}>
          {/* {" "} */}
          <Text style={{fontWeight:'bold',marginBottom:'5px'}}>Chat History</Text>
        </View>
        {Object.keys(user1)
          .sort((a, b) => new Date(a) - new Date(b))
          .map((date) => (
            <View style={{ marginTop: "5px" }}>
              <View>
                <Text
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    fontSize: "7px",
                    marginBottom: "5px",
                  }}
                >
                  {" "}
                  {new Date(date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })}{" "}
                </Text>
                <Text style={{ borderBottom: "1px solid lightgray" }}></Text>
              </View>
              {user1[date]
                ?.sort(
                  (a, b) => new Date(a?.updated_at) - new Date(b?.updated_at)
                )
                ?.map((message) => (
                  <View style={{ marginTop: "0px" }}>
                    {message?.from?.email === email ? (
                      <View style={styles.chatRight}>
                        <Text style={{ fontSize: "4px", marginBottom: "5px",marginLeft:'auto' }}>
                        <Text>{message?.contact_name}</Text>
                          <Text>
                            {" "}
                            {new Intl.DateTimeFormat("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }).format(new Date(message?.updated_at))}
                          </Text>
                          <Text style={{ color: "lightslategray" }}>
                            {" "}
                            {message?.from?.name}
                          </Text>
                          {message?.to !== "" && (
                          <Text>
                          <Text> to</Text>
                          <Text style={{ color: "#FBD5D5" }}>
                            {" "}
                            {handleToUser(message?.to)}
                          </Text>
                          </Text>)}
                        </Text>
                        <Text style={{marginLeft:'auto',fontSize:'3px',marginBottom:'5px'}}>{message?.tag} </Text>
                        <Text
                          style={{
                            backgroundColor: "turquoise",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "#FFFFFF",
                          }}
                        >
                          {message?.original_message}
                        </Text>
                        <Text style={{marginRight:'auto',fontSize:'3px'}}>{message?.flag}</Text>
                      </View>
                    ) : (
                      <View style={styles.chatLeft}>
                        <Text style={{ fontSize: "4px", marginBottom: "5px",float:'left' }}>
                          <Text style={{ color: "lightslategray" }}>
                            {" "}
                            {message?.from?.name}
                          </Text>
                          {message?.to !== "" && (
                            <Text>
                              <Text> to</Text>
                              <Text style={{ color: "#FBD5D5" }}>
                                {" "}
                                {handleToUser(message?.to)}
                              </Text>
                            </Text>
                          )}
                          <Text>
                            {" "}
                            {new Intl.DateTimeFormat("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }).format(new Date(message?.updated_at))}{" "}
                          </Text>
                          <Text>{message?.contact_name}</Text>
                        </Text>
                        <Text style={{marginRight:'auto',fontSize:'3px',marginBottom:'2px'}}>{message?.tag} </Text>
                        <Text
                          style={{
                            backgroundColor: "#e6e6e6",
                            padding: "5px 10px",
                            borderRadius: "5px",
                          }}
                        >
                          {message?.original_message}
                        </Text>
                        <Text style={{marginLeft:'auto',fontSize:'3px'}}>{message?.flag}</Text>
                      </View>
                    )}
                  </View>
                ))}
            </View>
          ))}
      </Page>
    </Document>
  );
};

export default MyDocument;
