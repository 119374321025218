// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-dash{
    transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    -webkit-text-decoration: none;
    text-decoration: none;
    outline: 2px solid transparent;
    outline-offset: 2px;
    color: #1B254B;
    box-shadow: none;
    background: inherit;
    border-radius: inherit;
    font-weight: 700;
    font-size: 34px;
    margin-left: 40px;
    font-family: DM Sans;
    z-index: 9;
    margin-top: 20px;
}

.scrolling-component {
    position: fixed;
    /* top: 5; */
    /* left: 0; */
    /* width: 100%; */
    /* background-color: #333; */
    /* color: #fff; */
    /* padding: 10px; */
    /* transition: top 0.3s; */
    z-index: 9999;
  }
  
  .scrolling-component.hidden {
    top: -180px; /* Adjust this value based on the height of your component */
  }

`, "",{"version":3,"sources":["webpack://./src/components/HomeComponents/HomeNav.css"],"names":[],"mappings":"AAAA;IACI,iGAAiG;IACjG,0BAA0B;IAC1B,sDAAsD;IACtD,6BAA6B;IAC7B,qBAAqB;IACrB,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,oBAAoB;IACpB,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,4BAA4B;IAC5B,iBAAiB;IACjB,mBAAmB;IACnB,0BAA0B;IAC1B,aAAa;EACf;;EAEA;IACE,WAAW,EAAE,4DAA4D;EAC3E","sourcesContent":[".main-dash{\n    transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;\n    transition-duration: 150ms;\n    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);\n    -webkit-text-decoration: none;\n    text-decoration: none;\n    outline: 2px solid transparent;\n    outline-offset: 2px;\n    color: #1B254B;\n    box-shadow: none;\n    background: inherit;\n    border-radius: inherit;\n    font-weight: 700;\n    font-size: 34px;\n    margin-left: 40px;\n    font-family: DM Sans;\n    z-index: 9;\n    margin-top: 20px;\n}\n\n.scrolling-component {\n    position: fixed;\n    /* top: 5; */\n    /* left: 0; */\n    /* width: 100%; */\n    /* background-color: #333; */\n    /* color: #fff; */\n    /* padding: 10px; */\n    /* transition: top 0.3s; */\n    z-index: 9999;\n  }\n  \n  .scrolling-component.hidden {\n    top: -180px; /* Adjust this value based on the height of your component */\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
