// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-container{
    display: flex;
}
.details-container>div:first-child{
    width:50%;
    margin-left:30px;
}

.status-label{
   color: grey;
   font-weight: normal;
}

.status-label1{
    color: grey;
    font-weight: normal;
    letter-spacing: 0.5px;   
}

/* .status-label1-title{
    width: 100px
} */

.status-container{
    display: flex;
}


@media  screen and (max-width:870px) {
    .details-container{
        flex-direction: column;
    }
    .details-container>div:first-child{
        width: 90%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Radiologists/RadiologistsDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,SAAS;IACT,gBAAgB;AACpB;;AAEA;GACG,WAAW;GACX,mBAAmB;AACtB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;;GAEG;;AAEH;IACI,aAAa;AACjB;;;AAGA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".details-container{\n    display: flex;\n}\n.details-container>div:first-child{\n    width:50%;\n    margin-left:30px;\n}\n\n.status-label{\n   color: grey;\n   font-weight: normal;\n}\n\n.status-label1{\n    color: grey;\n    font-weight: normal;\n    letter-spacing: 0.5px;   \n}\n\n/* .status-label1-title{\n    width: 100px\n} */\n\n.status-container{\n    display: flex;\n}\n\n\n@media  screen and (max-width:870px) {\n    .details-container{\n        flex-direction: column;\n    }\n    .details-container>div:first-child{\n        width: 90%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
