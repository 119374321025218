import React from 'react'
import Empty_image from "../../Images/Empty_Page.png";

function Pending() {
  return (
      <div>

       <img className='page_img' style={{width:'99%',height:'93vh'}} src={Empty_image}></img>
     </div>
  )
}

export default Pending