import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, InputAdornment, TextField } from '@mui/material';
import axios from 'axios';


const EditCostModal = ({ open, onClose, rowData, onUpdateCost, email,historicalData }) => {
      const [newCost, setNewCost] = useState(rowData?.cost || '');
      const [reason, setReason] = useState('');
      const [showReasonDialog, setShowReasonDialog] = useState(false);
      const [showConfirmation, setShowConfirmation] = useState(false);
      const [apiResponseMessage, setApiResponseMessage] = useState('');

      const handleSave = async () => {
            try {
                if (reason.trim() === '') {
                  setShowReasonDialog(true);
                  return;
                }
                const { REACT_APP_CLIENT_EDIT_COST_URL,REACT_APP_CLIENT_HISTORICAL_EDIT_URL } = process.env
                const dataToUpdate = {
                    report_fk: rowData.report_fk,
                    revised_cost: newCost, // Convert the newCost to a floating-point number
                    by_user_id: email, // 'Edit from the UI' Replace this with an appropriate user ID/reference
                    edit_type: 'Manual Edit',
                    reason: reason,
                };
                // Make a POST request to the API to update the data
                let response;
                if(historicalData==='Historical Data'){
                  response = await axios.post(REACT_APP_CLIENT_HISTORICAL_EDIT_URL, [dataToUpdate]);
                }else{
                 response = await axios.post(REACT_APP_CLIENT_EDIT_COST_URL, dataToUpdate);
                }

                // // Check if the data was updated successfully
                if (response.status === 200) {
                    setShowConfirmation(true); // Show the confirmation message
                    onUpdateCost(parseFloat(newCost)); // Update the cost in the table
                    setApiResponseMessage('Cost Updated Successfully');
                } 
                else {
                  //console.log('Failed to update data:', response.data);
                  setApiResponseMessage('Failed to update data. Please try again.'); // Set the API response message
                }
            } catch (error) {
                console.error('Error updating data:', error);
                setApiResponseMessage('An error occurred while updating data. Please try again.'); // Set the API response message
            }
      };

      const handleCancel = () => {
          onClose();
          setApiResponseMessage('');
      };


      const handleReasonDialogClose = (userReason) => {
          setShowReasonDialog(false);
          if (userReason) {
              setReason(userReason);
              handleSave();
          }
      };

      const handleConfirmationClose = () => {
            setShowConfirmation(false);
            setApiResponseMessage('');
            onClose()
      };

      useEffect(() => {
            // Check if apiResponseMessage is not empty and show the dialog
            if (apiResponseMessage) {
                setShowConfirmation(true);
            }
      }, [apiResponseMessage]);



      return (
            <>
                <Dialog open={open} onClose={onClose} width={50} fullWidth>
                    <DialogTitle sx={{ textAlign: 'center', color: 'blue' }}>Edit Cost</DialogTitle>
                    <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                          <div >
                                <div style={{ padding: '5px' }}>
                                  <strong>Date: </strong> 
                                  {rowData?.date}
                                </div>
                                <div style={{ padding: '5px' }} >
                                  <strong>Order ID: </strong>
                                   {rowData?.order_id}
                                </div>
                                <div style={{ padding: '5px' }}>
                                  <strong>Patient Name: </strong>
                                   {rowData?.patient_name}
                                </div>
                                <div style={{ padding: '5px' }}>
                                  <strong>Study Name: </strong> {rowData?.study_name}
                                </div>
                          </div>
                          <div style={{ marginTop: 16 }}>
                                <TextField
                                  sx={{ width: '100%' }}
                                  fullWidth
                                  label="New Cost"
                                  variant="outlined"
                                  type="number"
                                  value={newCost}
                                  onChange={(e) => setNewCost(e.target.value)}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">&#8377;</InputAdornment>,
                                  }}
                                />
                          </div>
                          <div style={{ marginTop: 16 }}>
                                <TextField
                                  fullWidth
                                  sx={{ width: '100%' }}
                                  label="Enter Valid Reason"
                                  variant="outlined"
                                  type="text"
                                  value={reason}
                                  onChange={(e) => setReason(e.target.value)}
                                />
                          </div>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center !important', paddingBottom: '20px' }}>
                          <Button onClick={handleCancel} color="primary">
                            Cancel
                          </Button>
                          <Button onClick={handleSave} color="primary" variant="contained">
                            Save
                          </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={showReasonDialog} onClose={() => setShowReasonDialog('')} maxWidth="sm" fullWidth>
                   <DialogTitle>Please Enter a Valid Reason</DialogTitle>
                </Dialog>

                <Dialog open={showConfirmation} onClose={handleConfirmationClose} maxWidth="sm" fullWidth>
                      <DialogTitle>Cost Updated Successfully</DialogTitle>
                      <DialogContent>
                         <div>Reason: {reason}</div>
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={handleConfirmationClose} color="primary">
                            OK
                          </Button>
                      </DialogActions>
                </Dialog>
                <Dialog open={Boolean(apiResponseMessage)} onClose={() => setApiResponseMessage('')} maxWidth="sm" fullWidth>
                      <DialogTitle>API Response</DialogTitle>
                      <DialogContent>
                          <div>{apiResponseMessage}</div>
                      </DialogContent>
                      <DialogActions>
                          <Button onClick={() => setApiResponseMessage('')} color="primary">
                            OK
                          </Button>
                      </DialogActions>
                </Dialog>
            </>
      );
};

export default EditCostModal;