import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import "./HomeNav.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTheme } from "@mui/material/styles";
// import Box from '@mui/material/Box';
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
// import {SingleDatePickerField} from '@mui/x-date-pickers-pro/';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Switch from "@mui/material/Switch";

const label = { inputProps: { "aria-label": "Switch demo" } };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["XRAY", "CT", "NM", "MRI"];

function getStyles(name, modalityName, theme) {
  return {
    fontWeight:
      modalityName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function HomeNav({
  setStartDate,
  setEndDate,
  modalityName,
  setmodalityName,
  apiState,
  tag,
  setTag,
}) {
  const [scrolled, setScrolled] = useState(false);
  // const [selectedDate,setSelectedDate]=useState(dayjs().subtract(1,'month'))
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const [value, setValue] = React.useState([
    dayjs().startOf("month"),
    dayjs().endOf("month"),
  ]);

  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );
  let navbarBorder = "transparent";
  let secondaryMargin = "10px";
  let paddingX = "15px";
  let gap = "0px";

  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const theme = useTheme();

  const handleChangeTag = (event) => {
    //console.log(event.target.checked);
    if (event.target.checked) {
      setTag("submitted");
    } else {
      setTag("created");
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setmodalityName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    //console.log(modalityName);
  };

  const handleDateChange = (dateString) => {
    setSelectedDate(dateString);
    const inputDate = new Date(dateString.$d);
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const startDate = new Date(year, month, 2);
    const endDate = new Date(year, month + 1, 1);
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];
    setValue([dayjs(formattedStartDate), dayjs(formattedEndDate)]);
    //console.log(formattedStartDate, formattedEndDate);
  };

  useEffect(() => {
    const inputDate = new Date(selectedDate.$d);
    const year = inputDate.getFullYear();
    const month = inputDate.getMonth();
    const startDate = new Date(year, month, 2);
    const endDate = new Date(year, month + 1, 1);
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];
    // setStartDate(formattedStartDate);
    // setEndDate(formattedEndDate);
    setValue([dayjs(formattedStartDate), dayjs(formattedEndDate)]);
    //console.log(formattedStartDate, formattedEndDate);
  }, []);

  const funDate = (date1) => {
    const date = new Date(date1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  useEffect(() => {
    // const inputDate = new Date(value[0].$d);
    // const year = inputDate.getFullYear();
    // const month = inputDate.getMonth();
    // const startDate = new Date(year, month, 2);
    // const endDate = new Date(year, month + 1, 1);
    if (value.length === 2) {
      const formattedStartDate = funDate(value[0]);
      const formattedEndDate = funDate(value[1]);
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
      //console.log(value);
    }
  }, [selectedDate, value]);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });


  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos === 0);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <div className={`scrolling-component ${visible ? 'visible' : 'hidden'}`}>
      <Box
        position={navbarPosition}
        boxShadow={navbarShadow}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        backgroundPosition="center"
        backgroundSize="cover"
        borderRadius="16px"
        borderWidth="1.5px"
        borderStyle="solid"
        transitionDelay="0s, 0s, 0s, 0s"
        transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
        transition-property="box-shadow, background-color, filter, border"
        transitionTimingFunction="linear, linear, linear, linear"
        alignItems={{ xl: "center" }}
        // display={secondary ? 'block' : 'flex'}
        minH="118px"
        justifyContent={{ xl: "center" }}
        lineHeight="25.6px"
        mx="auto"
        mt={secondaryMargin}
        ml="10px"
        pb="8px"
        right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
        px={{
          sm: paddingX,
          md: "10px",
        }}
        ps={{
          xl: "12px",
        }}
        pt="8px"
        top={{ base: "12px", md: "16px", lg: "20px", xl: "20px" }}
        w="calc(100vw - 220px)"
        zIndex={9}
      >
        <Flex
          w="100%"
          flexDirection={{
            sm: "column",
            md: "row",
          }}
          alignItems={{ xl: "center" }}
          justifyContent={"space-between"}
          mb={gap}
        >
          <Box mb={{ sm: "8px", md: "0px" }}>
            <Breadcrumb>
              <BreadcrumbItem color={secondaryText} fontSize="sm">
                <div>Hospital</div>
              </BreadcrumbItem>

              <BreadcrumbItem color={secondaryText} fontSize="sm">
                <div>Dashboards</div>
              </BreadcrumbItem>
            </Breadcrumb>
            {/* Here we create navbar brand, based on route name */}
            <div
              className="main-dash"
              color={mainText}
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              fontSize="34px"
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              Main Dashboards
            </div>
          </Box>
          <Box
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "30px",
            }}
          >
            <FormControl sx={{ m: 1, width: 300, mt: "0px" }}>
              <InputLabel id="demo-multiple-chip-label">Modality</InputLabel>
              <Select
                disabled={
                  apiState.gmApi === "fetch" ||
                  apiState.billedApi === "fetch" ||
                  apiState?.clientgm === "fecth" ||
                  apiState?.radgm === "fetch"
                }
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={modalityName}
                onChange={handleChange}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Modality" />
                }
                style={{ padding: "0px 10px", height: "55px" }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, modalityName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["month", "year"]}
                label="Select Month and Year"
                value={selectedDate}
                onChange={handleDateChange}
                inputFormat="MM/yyyy"
                disabled={
                  apiState.gmApi === "fetch" ||
                  apiState.billedApi === "fetch" ||
                  apiState?.clientgm === "fecth" ||
                  apiState?.radgm === "fetch"
                }
                renderInput={(params) => (
                  <TextField className="monthPicker" value={selectedDate} />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Flex>
        {/* {secondary ? <Text color='white'>{message}</Text> : null} */}
        <Flex
          style={{ float: "right", marginRight: "30px", alignItems: "center",marginTop:'-15px' }}
        >
          <div style={{ alignItems: "center", marginRight: "20px" }}>
            {" "}
            Created Time
            <Switch
              checked={tag === "submitted"}
              onChange={handleChangeTag}
              inputProps={{ "aria-label": "controlled" }}
            />{" "}
            Submitted Time
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["SingleInputDateRangeField"]}>
              <DateRangePicker
                slots={{ field: SingleInputDateRangeField }}
                format="YYYY/MM/DD"
                value={value}
                disabled={
                  apiState.gmApi === "fetch" ||
                  apiState.billedApi === "fetch" ||
                  apiState?.clientgm === "fecth" ||
                  apiState?.radgm === "fetch"
                }
                onChange={(newValue) => setValue(newValue)}
                calendars={1}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Flex>
      </Box>
    </div>
  );
}

export default HomeNav;

HomeNav.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
