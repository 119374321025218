// import firebase from 'firebase/app';
import 'firebase/firestore';
import  {initializeApp}  from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
// import firebase from './firebase';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    // Your Firebase configuration
    apiKey: process.env.REACT_APP_FIREBAESE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBAESE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    databaseURL: process.env.REACT_APP_FIREBAESE_DB_URL,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBAESE_APP_ID,
    measurementId:process.env.REACT_APP_MEASUREMENT_ID
  };

export const firebase=initializeApp(firebaseConfig)
export const db = getFirestore(firebase);
export const analytics = getAnalytics(firebase)


export const getDocumentFirebase=async()=>{
    const docRef = doc(db, "Finance_Access", "Y5jz0XkKMbf4YtKg4ojW");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log(docSnap.data())
        return await docSnap.data();
      } else {
        // docSnap.data() will be undefined in this case
        //console.log("No such document!");
      }
}

export const getDocumentYaakeUsers=async()=>{
    const docRef = doc(db, "Finance_Access", "SGr4c772IcJkloAiKbek");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        //console.log(docSnap.data())
        return await docSnap.data();
      } else {
        // docSnap.data() will be undefined in this case
        //console.log("No such document!");
      }
}

// export const getDocument=()=>{
//     getDocumentFirebase()
// }

export const checkSuperAdmin=(email)=>{
    let data;
   data=getDocumentFirebase().then(data => {
    // d=data.users
    //console.log(data)
    //console.log(email)
    const user=data?.user?.filter(data=>{
        //console.log(data)
        if(data?.email===email){ 
            return true
        }
    })
    return user
  })

  .catch(error => {
    // Handle errors if needed
    console.error('Error fetching data:', error);
  });
   
//   //console.log(data)
  return data
}

export const checkAcessLevel=()=>{
    const data = getDocumentFirebase()
    // //console.log(data)
    return data
}








export const permissionList = [
    {
        name: 'Gokul',
        email: 'gokul@5cnetwork.com',
        access: 'admin'
    },
    {
        name: 'Analytics',
        email: 'analytics@5cnetwork.com',
        access: 'admin'
    },
    {
        name: 'Adithya',
        email: 'aditya@5cnetwork.com',
        access: 'view'
    },
    {
        name: 'Avanish',
        email: 'avanish@5cnetwork.com',
        access: 'view'
    },
    {
        name: 'Vishnu',
        email: 'vishnu.s@5cnetwork.com',
        access: 'view'
    },
    {
        name: 'Applications',
        email: 'applications@5cnetwork.com',
        access: 'view'
    },
    {
        name: 'Bargava',
        email: 'bargava@5cnetwork.com',
        access: 'view'
    },
    {
        name: 'SSA',
        email: 'ssa@5cnetwork.com',
        access: 'view'
    },
    {
        name: 'Kalyan',
        email: 'kalyan@5cnetwork.com',
        access: 'view'
    },
    {
        name: 'Kirthi',
        email: 'kirthi@5cnetwork.com',
        access: 'view'
    },
    {
        name: 'Bhavya',
        email: 'bhavya@5cnetwork.com',
        access: 'admin'
    },
    {
        name: 'Chethan',
        email: 'chethan@5cnetwork.com',
        access: 'admin'
    },
    {
        name: 'Nawaz',
        email: 'nawaz@5cnetwork.com',
        access: 'admin'
    },
    {
        name: 'santhosh',
        email: 'santhosh@5cnetwork.com',
        access: 'admin'
    },
    {
        name: 'sunil',
        email: 'sunil.kumar@5cnetwork.com',
        access: 'admin'
    },


]