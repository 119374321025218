import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Paper,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import Alerts from "./Alerts";
import { format } from 'date-fns';
import { Response } from "./Radiologists";

function RadInvoiceDetails({ radId,handleAccess,email,setAuthPop,radName }) {
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [warning,setWarning]=useState('')
  const [sendPop,setSendPop]=useState('')
  const [sendResponse,setSendResponse]=useState('')

  const handleMonthChange = (date) => {
    setSelectedMonth(date);
    // Fetch data for the selected month
    fetchDataForMonth(date);
  };

  const fetchDataForMonth = async (month) => {
    const timeZone = "Asia/Kolkata";
    const startDateKolkata = zonedTimeToUtc(
      new Date(month.getFullYear(), month.getMonth(), 1),
      timeZone
    );
    const endDateKolkata = zonedTimeToUtc(
      new Date(month.getFullYear(), month.getMonth() + 1, 0),
      timeZone
    );

    setStartDate(startDateKolkata);
    setEndDate(endDateKolkata);
    setInvoiceDate(endDateKolkata);
  };

  const useStyles = makeStyles({
    root: {
      "&.MuiTableCell-head ": {
        backgroundColor: "blue !important",
      },
    },
  });
  const classes = useStyles();
  useEffect(() => {
    if (radId) {
      radGetInvoice();
    }
  }, [radId]);

  const monthNames = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];
  const handleMonth = (month) => {
    const [monthNumber, monthYear] = month.split(" ");
    // //console.log(monthNames[monthNumber - 1])
    return monthNames[monthNumber - 1] + " " + monthYear;
  };

  const dateBodyTemplate = (rowData) => {
    const formattedDate = new Date(rowData).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const datePart = formattedDate.slice(0, -8);
    const timePart = formattedDate.slice(-8);
    return (
      <div>
        <div className="date-part">
          {datePart}
          {timePart}{" "}
        </div>
        {/* <div className="time-part">{timePart}</div> */}
      </div>
    );
  };

  const radGetInvoice = async () => {
    try {
      const { REACT_APP_RAD_GET_INVOICE_URL } = process.env;
      const res = await axios.get(
        REACT_APP_RAD_GET_INVOICE_URL + `?rad_fk=${radId}`
      );
      if (res.status === 200) {
        setData(res?.data?.show_invoice_data);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const handleGenerateInvoice=(data)=>{
    setSendPop(data)
  }

  const handleConfirmInvoice =async()=>{
    try {
        setSendPop('')
        const timeZone = 'Asia/Kolkata';
        const formattedStartDate = startDate ? format(utcToZonedTime(startDate, timeZone), 'yyyy-MM-dd') : null;
        const formattedEndDate = endDate ? format(utcToZonedTime(endDate, timeZone), 'yyyy-MM-dd') : null;
        const formattedInvoiceDate = invoiceDate ? format(utcToZonedTime(invoiceDate, timeZone), 'yyyy-MM-dd') : null;
        const data = [
                {
                  "radfk": radId,
                  "startDate": formattedStartDate,
                  "endDate": formattedEndDate,
                  "invoiceDate": formattedInvoiceDate,
                  "user_email": email
                },
              ];
        const { REACT_APP_RAD_INVOICE_URL } = process.env;
        const res = await axios.post(
            REACT_APP_RAD_INVOICE_URL,data 
        );
        if(res.status==200){
            if(res?.data.length>=0 && res?.data[0]==='Email sent successfully'){
                setSendResponse('Invoice Generated Successfully')
            }else{
              setWarning('Invoice Not Generated')
            }
          }else{
            setWarning('Invoice Not Generated')
          }
      } catch (err) {
        //console.log(err);
        setWarning('Invoice Not Generated')
      }
  }

  return (
    <div style={{marginRight:'15px'}}>
      <div
        className="invoice-main"
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "20px",
          justifyContent: "center",
        }}
      >
        <Box display="flex" alignItems="center">
          <div style={{ marginRight: "10px", zIndex: "5" }}>
            <div>Select Month</div>
            <DatePicker
              selected={selectedMonth}
              onChange={handleMonthChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              customInput={
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ fontSize: "10px" }}
                >
                  {selectedMonth
                    ? selectedMonth.toLocaleDateString("en-US", {
                        month: "long",
                        year: "numeric",
                      })
                    : "SELECT MONTH"}
                </Button>
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <div style={{ marginRight: "10px", zIndex: "5" }}>
              <div>Start Date</div>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                customInput={
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ fontSize: "10px" }}
                  >
                    {startDate
                      ? startDate.toLocaleDateString("en-US")
                      : "Start Date"}
                  </Button>
                }
              />
            </div>

            <div style={{ marginRight: "10px", zIndex: "5" }}>
              <div>End Date</div>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                customInput={
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ fontSize: "10px" }}
                  >
                    {endDate ? endDate.toLocaleDateString("en-US") : "End Date"}
                  </Button>
                }
              />
            </div>

            <div style={{ zIndex: "5" }}>
              <div>Invoice Date</div>
              <DatePicker
                selected={invoiceDate}
                onChange={(date) => setInvoiceDate(date)}
                dateFormat="yyyy-MM-dd"
                customInput={
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ fontSize: "10px" }}
                  >
                    {invoiceDate
                      ? invoiceDate.toLocaleDateString("en-US")
                      : "Invoice Date"}
                  </Button>
                }
              />
            </div>
          </div>
          <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (selectedMonth === null) {
                setWarning("Please Select the Month");

              } else if (handleAccess("Radiologist", "Rad Invoice") === true) {
                handleGenerateInvoice("Email");
              } else {
                setAuthPop(true);
              }
            }}
            style={{ fontSize: "10px",marginTop:'15px' }}
          >
            Generate Invoice
          </Button>
          </div>
        </Box>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "50px",
            marginTop: "10px",
          }}
        >
        

        </div>
      </div>
      <TableContainer
        component={Paper}
        style={{ height: "calc(100vh - 300px)", marginTop: "30px",marginRight:'0px' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow className={classes.root}>
              <TableCell className="table-size">Invoice Number</TableCell>
              <TableCell className="table-size">Month</TableCell>
              <TableCell className="table-size">Start Date</TableCell>
              <TableCell className="table-size">End Date</TableCell>
              <TableCell className="table-size">Invoice Sent Date</TableCell>
              <TableCell className="table-size">Invoice</TableCell>
              <TableCell className="table-size">Last Updated At</TableCell>
              <TableCell className="table-size">Sent By</TableCell>
              {/* <TableCell className="table-size">Download Invoice</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((data, id) => (
              <TableRow key={id}>
                <TableCell className="table-size">{data.invoice_num}</TableCell>
                <TableCell className="table-size">
                  {handleMonth(data.invoice_month)}
                </TableCell>
                <TableCell className="table-size">
                  {data.start_date.split(" ")[0]}
                </TableCell>
                <TableCell className="table-size">
                  {data.end_date.split(" ")[0]}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }} className="table-size">
                  {data.invoice_sent_date.split(" ")[0]}
                </TableCell>

                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      width: "100px",
                      textAlign: "center",
                    }}
                  >
                    <div> </div>
                    <div>F - Rs . {data?.first_invoice_amount}</div>
                  </div>
                  <div style={{ display: "flex", width: "100px" }}>
                    <div> </div>
                    <div>R - Rs . {data?.revision}</div>
                  </div>
                  <div style={{ display: "flex", width: "100px" }}>
                    <div> </div>
                    <div>L - Rs . {data?.last_invoice_amount}</div>
                  </div>
                </TableCell>

                <TableCell className="table-size">
                  {dateBodyTemplate(data?.last_updated_at)}
                </TableCell>

                <TableCell className="table-size">
                  {data.invoice_sent_by}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
       <Alerts warning={warning} setWarning={setWarning} />

       <Dialog open={sendPop} onClose={() => setSendPop(false)}>
                  <DialogTitle>Confirmation</DialogTitle>
                  <DialogContent>
                        {selectedMonth && startDate && endDate && (
                          <p>
                            You are trying to Generation invoices for {radName} for the duration of{' '}
                            {startDate.toLocaleDateString('en-US')} - {endDate.toLocaleDateString('en-US')}. Click 'YES' to confirm
                            and send the invoice.
                          </p>
                        )}
                  </DialogContent>
                  <DialogActions>
                        <Button onClick={() => setSendPop('')} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={() => handleConfirmInvoice()} color="primary" autoFocus>
                          Yes
                        </Button>
                  </DialogActions>
            </Dialog>


            <Response data={sendResponse} setData={setSendResponse} />
       
    </div>
  );
}

export default RadInvoiceDetails;
