import React from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
  } from "@mui/material";

function ConfirmationDialog({openConfirmationDialog,handleCloseConfirmationDialog,excelFileName,handleCancelUpload,handleConfirmUpload}) {
  return (
    <div>
       <Dialog
              open={openConfirmationDialog}
              onClose={()=>{handleCloseConfirmationDialog()}}
            >
              <DialogTitle>Confirmation</DialogTitle>
              <DialogContent>
                {excelFileName && <p>Selected Excel File: {excelFileName}</p>}
                {/* {uploadStatus && <p style={{ color: 'red' }}>{uploadStatus}</p>} */}
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>{handleCancelUpload()}} color="primary">
                  Cancel
                </Button>
                <Button onClick={()=>{handleConfirmUpload()}} color="primary" autoFocus>
                  {" "}
                  {/* Add autoFocus attribute here */}
                  Upload
                </Button>
              </DialogActions>
            </Dialog>
    </div>
  )
}

export default ConfirmationDialog
