import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function PieChart(props) {
      const [chartData,setChartData] = useState(props.chartData)
      const [chartOptions,setChartOption] = useState(props.chartOptions)
      // console.log(props)
        return (
          <ReactApexChart
            options={chartOptions}
            // options={{}}
            series={chartData}
            type='pie'
            width='120%'
            height={props.width}
          />
        );
      }
    
    

    


export default PieChart
