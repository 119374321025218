// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vendor-contact-table{
    margin: 20px;
    max-height: calc(100vh - 270px); /* Set a max height to enable scrolling */
    overflow-y: scroll; 
}
.vendor-contact-table>div{
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    text-align: center;
}

.vendor-contact-table>div:first-child{
    background-color: #0059B2;
    padding: 20px 0px;
    border-radius: 20px;
    color: #FFFFFF;
    position: sticky;
  top: 0;
  z-index: 1;
}

.vendor-contact-table-body{
    padding: 20px 0px;
    border-radius: 20px;
    margin: 10px 0px;
    border: 1px solid lightgrey;
    align-items: center;
}

.vendor-date-filter>div>div{
  min-width: 300px !important;
}


.vendor-contact-table::-webkit-scrollbar {
    width: 0; /* Set the width of the scrollbar to a minimal value */
  }
  
  .vendor-contact-table::-webkit-scrollbar-thumb {
    background: none; /* Set the scrollbar thumb to be transparent */
  }



  .edit-expire-date>div>div{
   min-width: 300px !important ;
  }`, "",{"version":3,"sources":["webpack://./src/components/Vendors/VendorContact.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,+BAA+B,EAAE,yCAAyC;IAC1E,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,gDAAgD;IAChD,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;EAClB,MAAM;EACN,UAAU;AACZ;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;EACE,2BAA2B;AAC7B;;;AAGA;IACI,QAAQ,EAAE,sDAAsD;EAClE;;EAEA;IACE,gBAAgB,EAAE,8CAA8C;EAClE;;;;EAIA;GACC,4BAA4B;EAC7B","sourcesContent":[".vendor-contact-table{\n    margin: 20px;\n    max-height: calc(100vh - 270px); /* Set a max height to enable scrolling */\n    overflow-y: scroll; \n}\n.vendor-contact-table>div{\n    display: grid;\n    grid-template-columns: repeat(6, minmax(0, 1fr));\n    text-align: center;\n}\n\n.vendor-contact-table>div:first-child{\n    background-color: #0059B2;\n    padding: 20px 0px;\n    border-radius: 20px;\n    color: #FFFFFF;\n    position: sticky;\n  top: 0;\n  z-index: 1;\n}\n\n.vendor-contact-table-body{\n    padding: 20px 0px;\n    border-radius: 20px;\n    margin: 10px 0px;\n    border: 1px solid lightgrey;\n    align-items: center;\n}\n\n.vendor-date-filter>div>div{\n  min-width: 300px !important;\n}\n\n\n.vendor-contact-table::-webkit-scrollbar {\n    width: 0; /* Set the width of the scrollbar to a minimal value */\n  }\n  \n  .vendor-contact-table::-webkit-scrollbar-thumb {\n    background: none; /* Set the scrollbar thumb to be transparent */\n  }\n\n\n\n  .edit-expire-date>div>div{\n   min-width: 300px !important ;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
