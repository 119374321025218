import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TextField } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialog({ open, handleClose, UpdateFunction ,sendAlertEmail}) {
  const [comment, setComment] = useState('')
  const [error,setError]=useState([])
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
            {open === 'comment' ?

              <div>
                  <DialogTitle>Update Account Status</DialogTitle>
                  <DialogContent>
                      <TextField value={comment} onChange={(e) => { setComment(e.target.value) }} label='Enter Reason' variant='standard' style={{ width: '100%' }} />
                  </DialogContent>

                 {error?.length>0 && <DialogContent style={{color:'red',textAlign:'center'}}>
                    {error[0]}
                 

                  </DialogContent>
} 
                  <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button onClick={() => {
                        console.log(comment?.toString()?.length )
                        // if(comment?.toString()?.length<100 || !comment){
                        //   setError(['Reason Should be more than 100 characters'])
                        //   return
                        // }
                  
                        UpdateFunction(comment);
                        setError([])
                        setComment('')
                      }}>Update</Button>
                  </DialogActions>
              </div>

              : open === 'case_activation_comment' ?

              <div>
                  <DialogTitle>Update Case Activation</DialogTitle>
                  <DialogContent>
                      <TextField value={comment} onChange={(e) => { setComment(e.target.value) }} label='Enter Reason' variant='standard' style={{ width: '100%' }} />
                  </DialogContent>

                 {error?.length>0 && <DialogContent style={{color:'red',textAlign:'center'}}>
                    {error[0]}
                 

                  </DialogContent>
} 
                  <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button onClick={() => {
                        console.log(comment?.toString()?.length)
                        if(comment?.toString()?.length<10 || !comment){
                          setError(['Reason Should be more than 10 characters'])
                          return
                        }
                        sendAlertEmail('Case Activation',comment)
                        UpdateFunction(comment);
                        setComment('')
                        setError([])
                      }}>Update</Button>
                  </DialogActions>
              </div>:
              <div>
                <DialogTitle>Confirmation !!!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      Do you want to update
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={UpdateFunction}>Agree</Button>
                </DialogActions>
              </div>
            }
      </Dialog>

    </div>
  )
}

export default AlertDialog