// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tikcet-edit-view{
    width: 40% !important;
    z-index: 9999;
  }

.tikcet-edit-view .slide-pane__header{
    margin-top: -25px;
}

.comment-history{
    height: calc(100vh - 350px);
    overflow-y: scroll;
    padding: 10px 0px;
    margin: 10px 0px;
}

.chat-box-ticket{
    background: #e6e7ec;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 0px 10px 10px 10px;
    width: -moz-fit-content;
    width: fit-content;
}

.client-name{
  font-weight: 700;
  font-size: 20px;
  padding-right:30px;

}

.ticket-table{
  box-shadow: 0px 0px 8px #e6e7ec;
}

.client-name>img{
  margin-left: 10px;
}


.timeline .vertical-timeline-element-content{
  box-shadow: 0px 0px 10px #e6e7ec;
}

.timeline .vertical-timeline::before {
  background: #4a9afb !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Ticket/Ticket.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,aAAa;EACf;;AAEF;IACI,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,iCAAiC;IACjC,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;;AAEpB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,iBAAiB;AACnB;;;AAGA;EACE,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".tikcet-edit-view{\n    width: 40% !important;\n    z-index: 9999;\n  }\n\n.tikcet-edit-view .slide-pane__header{\n    margin-top: -25px;\n}\n\n.comment-history{\n    height: calc(100vh - 350px);\n    overflow-y: scroll;\n    padding: 10px 0px;\n    margin: 10px 0px;\n}\n\n.chat-box-ticket{\n    background: #e6e7ec;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    border-radius: 0px 10px 10px 10px;\n    width: fit-content;\n}\n\n.client-name{\n  font-weight: 700;\n  font-size: 20px;\n  padding-right:30px;\n\n}\n\n.ticket-table{\n  box-shadow: 0px 0px 8px #e6e7ec;\n}\n\n.client-name>img{\n  margin-left: 10px;\n}\n\n\n.timeline .vertical-timeline-element-content{\n  box-shadow: 0px 0px 10px #e6e7ec;\n}\n\n.timeline .vertical-timeline::before {\n  background: #4a9afb !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
