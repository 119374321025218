import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BorderLinearProgress, RedLinearProgress } from "./GmStudyOverall";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import { FaFileDownload } from "react-icons/fa";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Select All",
  "Clear All",
  "CT Regular",
  "CT Abdomen",
  "CT Brain",
  "CT Thorax",
  "CT KUB",
  "CT Neck",
  "CT Temporal",
  "CT PNS",
  "CT Complex",
  "MRI Spine",
  "MRI MSK",
  "MRI Brain",
  "MRI Regular",
  "MRI Complex",
  "XRAY",
  "XRAY Chest",
  "NM",
];

function getStyles(name, study, theme) {
  return {
    fontWeight:
      study.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function GmStudyWise({ gmData,handleDownload }) {
  const [gmActualData, setGmActualData] = useState([]);
  const theme = useTheme();
  const [study, setStudy] = React.useState([
    "CT Regular",
    "CT Abdomen",
    "CT Brain",
    "CT Thorax",
    "CT KUB",
    "CT Neck",
    "CT Temporal",
    "CT PNS",
    "CT Complex",
    "MRI Spine",
    "MRI MSK",
    "MRI Brain",
    "MRI Regular",
    "MRI Complex",
    "XRAY",
    "XRAY Chest",
    "NM",
  ]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes("Select All")) {
      // //console.log('there')
      setStudy([
        "CT Regular",
        "CT Abdomen",
        "CT Brain",
        "CT Thorax",
        "CT KUB",
        "CT Neck",
        "CT Temporal",
        "CT PNS",
        "CT Complex",
        "MRI Spine",
        "MRI MSK",
        "MRI Brain",
        "MRI Regular",
        "MRI Complex",
        "XRAY",
        "XRAY Chest",
        "NM",
      ]);
    } else if (value.includes("Clear All")) {
      setStudy([]);
    } else {
      setStudy(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
    //console.log(study);
  };

  useEffect(() => {}, [gmData]);
  const handleGm = (reve, paya) => {
    const data = ((reve - paya) / reve) * 100;
    return parseFloat(data).toFixed(2);
  };

  const averageReport = (count, revenue) => {
    const data = revenue / count;
    return parseInt(data);
  };

  const averageCost = (count, payable) => {
    const data = payable / count;
    return parseInt(data);
  };

  const handleDelete = (value) => {
    //console.log("hii");
  };

  useEffect(() => {
    // const data= gmData?.filter(data=>data?.grouped_study_name===study)
    const filteredData = gmData.filter((item) =>
      study.some((name) => item.grouped_study_name.includes(name))
    );

    const result = filteredData?.reduce((acc, current) => {
      const groupName = current.Date;

      // Initialize values for the group if not present
      if (!acc[groupName]) {
        acc[groupName] = {
          revenue: 0,
          payable: 0,
          No_of_report: 0,
        };
      }

      // Update values for the group
      acc[groupName].revenue += current.Revenue;
      acc[groupName].payable += current["Rad Payable"];
      acc[groupName].No_of_report += current["No_of_report"];

      return acc;
    }, {});

    //console.log(result, "result");
    //   Convert the result into an array of objects
    if (result) {
      const resultArray = Object?.entries(result)?.map(
        ([grouped_fields, { revenue, payable, No_of_report }]) => {
          const [date] = grouped_fields.split("to");
          return {
            // grouped_study_name,
            date,
            revenue,
            payable,
            No_of_report,
          };
        }
      );
      setGmActualData(resultArray);
    }
  }, [study.length, gmData]);

  const handleSendDownload=(data,filename)=>{
    const data1= data?.map(data=>{
      return{
        'Date':data?.date,
        'No of Reports':data?.No_of_report,
        'Revenue':data?.revenue,
        'Payable':data?.payable,
        'Gm':`${handleGm(data?.revenue, data.payable)}%` ,
        'Average Revenue per Report':averageReport(data?.No_of_report, data?.revenue),
        'Average Cost per Report':averageCost(data?.No_of_report, data?.payable)
      }
    })

    handleDownload(data1,filename)
}

  return (
    <div
      style={{
        // backgroundColor: "white",
        borderRadius: "20px",
        padding: "20px",
      }}
    >
      <div
        style={{
          fontSize: "25px",
          color: "#1B2559",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>GM Study Wise</div>
        <div style={{ marginTop: "-10px",display:'flex',alignItems:'center' }}>
          <FormControl sx={{ m: 1, width: 820 }}>
            <InputLabel id="demo-multiple-chip-label">Group Study</InputLabel>
            <Select
              style={{}}
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={study}
              onChange={handleChange}
              input={
                <OutlinedInput id="select-multiple-chip" label="Group Study" />
              }
              renderValue={(selected) => (
                <Box
                  style={{ padding: "5px 15px 5px 5px" }}
                  sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                >
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {names.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, study, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div><FaFileDownload onClick={()=>handleSendDownload(gmActualData,'Gm_by_date')} color="blue" style={{cursor:'pointer'}} /></div>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div className="gm-revenue-head">
          <div>Date</div>
          <div>No of Reports</div>
          <div>Revenue</div>
          <div>Payable</div>
          <div>GM</div>
          <div>Average Revenue per Report</div>
          <div>Average Cost per Report</div>
        </div>
        <div
          className="gm-revenue-table"
          style={{ maxHeight: "630px", overflowY: "scroll" }}
        >
          {gmActualData
            ?.sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((data, id) => (
              <div key={id} className="gm-revenue-body">
                <div>{data.date}</div>
                <div>{data?.No_of_report}</div>
                <div>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(data?.revenue)}
                </div>
                <div>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(data?.payable)}
                </div>
                <div style={{ textAlign: "end" }}>
                  {handleGm(data?.revenue, data?.payable) > 0 ? (
                    <div className="table-revenue-body-gm-postive">
                      {`${handleGm(data?.revenue, data?.payable)}%`}
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          handleGm(data?.revenue, data?.payabale) > 100
                            ? 100
                            : handleGm(data?.revenue, data?.payable)
                        }
                      />
                    </div>
                  ) : (
                    <div className="table-revenue-body-gm-negative">
                      {`${handleGm(data?.revenue, data?.payable)}%`}
                      <RedLinearProgress
                        variant="determinate"
                        value={
                          Math.abs(handleGm(data?.revenue, data?.payable)) > 100
                            ? 100
                            : Math.abs(handleGm(data?.revenue, data?.payable))
                        }
                      />
                    </div>
                  )}
                </div>
                <div>{averageReport(data?.No_of_report, data?.revenue)}</div>
                <div>{averageCost(data?.No_of_report, data?.payable)}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default GmStudyWise;
