import React, { useEffect, useState } from 'react'
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, FormControl, Select, MenuItem, InputLabel, Snackbar
  , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import axios from 'axios';
import './HospitalDocument.css'
import { TbEdit } from 'react-icons/tb';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { HiDocumentDownload } from 'react-icons/hi'
import { MdPreview } from 'react-icons/md'
import { HiDocumentAdd } from 'react-icons/hi'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MuiAlert from '@mui/material/Alert'
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 1000,

      bgcolor: 'background.paper',
      border: '0.5px solid lightblue',
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
};



function HospitalDocument({ clientid, userAccess, setAuthPop,handleAccess }) {
        const [docData, setDocData] = useState([])
        const [open, setOpen] = useState(null)
        const [edit, setEdit] = useState(null)
        const [addDocument, setAddDocument] = useState(false)
        const [editName, setEditName] = useState(null)
        const [editType, setEditType] = useState(null)
        const [id, setId] = useState(null)
        const [alert, setAlert] = useState('')
        const [errorAlert, setErrorAlert] = useState('')
        const [deleteId, setDeleteId] = useState('')
        const [fileType,setFileType] = useState('')
        useEffect(() => {
          apiCall()
        }, [])

        const apiCall = async () => {
              try {
                  const { REACT_APP_CLIENT_DOCUMENT_URL } = process.env
                  const res = await axios.get(REACT_APP_CLIENT_DOCUMENT_URL + `${clientid}`)
                  setDocData(res.data)
              }
              catch (err) {
                   console.log(err)
              }
        }

        const handleDelete = async (id) => {
              try {
                    let body = {
                      by_user: "CLIENT",
                      by_user_id: clientid,
                      client_id: clientid,
                      document: {
                        id: id,
                        status: 'REMOVED'
                      }
                    }
                    //console.log(body)
                    const {REACT_APP_CLIENT_DOCUMENT_UPLOAD_URL}= process.env
                    const response = await axios.post(REACT_APP_CLIENT_DOCUMENT_UPLOAD_URL, body);
                    //console.log(response)
                    if (response.status === 200) {
                          apiCall()
                          setAlert('Deleted Successfully')
                    } else {
                          setErrorAlert('Deletion Failed')
                    }
              } catch (err) {

              } finally {
                    setDeleteId('')
              }
        }

        const handleEdit = async () => {
              try {
                    let body = {
                      by_user: "CLIENT",
                      by_user_id: clientid,
                      client_id: clientid,
                      document: {
                        id: id.id,
                        name: editName,
                        type: editType,
                        s3_name: id.s3_name
                      }
                    }
                    const {REACT_APP_CLIENT_DOCUMENT_UPLOAD_URL}= process.env
                    const response = await axios.post(REACT_APP_CLIENT_DOCUMENT_UPLOAD_URL, body);
                    //console.log(response)
                    if (response.status === 200) {
                        apiCall()
                        setAlert('Updated Successfully')
                    } else {
                        setErrorAlert('Update Failed')
                    }
              } catch (Err) {
                  setErrorAlert('Internal Server Error')
              } finally {
                  setEdit('')
              }
        }

        const handleAdd = async () => {
            const formData = new FormData();
            formData.append('textValue', editName);
            formData.append('selectedOption', editType);
        }

        const detectFileType = async (s3FileUrl) => {
              try {
                  const response = await fetch(s3FileUrl);
                  const contentType = response.headers.get('content-type');

                  if (contentType) {
                        if (contentType.includes('application/pdf')) {
                            setFileType('pdf')
                            return 'pdf';
                        } 
                        else if (contentType.includes('image')) {
                            setFileType('image')
                            return 'image';
                        } 
                        else {
                          // Handle other file types as needed
                          return 'unknown';
                        }
                  } 
                  else {
                    return 'unknown';
                  }
              } 
              catch (error) {
                console.error('Error detecting file type:', error);
              }
        };



        const handleDownload = async (imageUrl, name) => {
            try {
                  const format=await detectFileType(imageUrl)
                  const response = await fetch(imageUrl);
                  const blob = await response.blob();
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.href = url;
                  if(format==='pdf')
                  {
                      a.download = `${name}.pdf`;
                  }
                  else if(format==='image'){
                      a.download = `${name}.jpg`;
                  }
                  setFileType('')
                  a.click();
                  window.URL.revokeObjectURL(url);
            } 
            catch (error) {
                  console.error('Error downloading the image', error);
            }
        };


        return (
            <div style={{ marginRight: '10px', padding: '10px', borderRadius: '5px' }}>
                <div className='docu-add-doc'>
                  <Button onClick={() => {
                    if(handleAccess('Hospital','Document Tab')===true){
                      // setAddDocument(true)
                    }
                    else {
                      setAuthPop(true)
                    }
                  }} size='small' variant='contained' >
                    <HiDocumentAdd size={20} style={{ marginRight: '4px' }} />   Add Document
                  </Button>
                </div>

                {docData.length > 0 &&
                    <Table className='table-docu' sx={{ width: '100%' }} aria-label="simple table">
                   
                      <TableRow className='docu-head-row ' >
                          <TableCell className='docu-head' sx={{ width: '20%' }} align='center'>Type</TableCell>
                          <TableCell className='docu-head' sx={{ width: '20%' }} align='center'>Document Name</TableCell>
                          <TableCell className='docu-head' sx={{ width: '20%' }} align='center'>Upload On</TableCell>
                          <TableCell className='docu-head' sx={{ width: '20%' }} align='center'>Preview</TableCell>
                          <TableCell className='docu-head' sx={{ width: '20%' }} align='center'>Action</TableCell>
                      </TableRow>
                  
                      {docData.map((row) =>
                       (
                          <TableRow className='docu-body-row'
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ width: '20%' }} align='center' scope="row">
                                  {row.type}
                                </TableCell>
                                <TableCell sx={{ width: '20%', }} align='center'>{row.name}</TableCell>
                                <TableCell sx={{ width: '20%' }} align='center'>{row.updated_at}</TableCell>
                                <TableCell sx={{ width: '20%' }} align='center'>
                                  <a href={row.url} target='blank'>
                                    <MdPreview onClick={() => {
                                      // detectFileType(row.url)
                                      // setOpen(row.url)
                                    }}
                                      size={25}
                                      style={{ color: 'white', backgroundColor: 'rgb(59 130 246)', padding: '3px', borderRadius: '50%', cursor: 'pointer' }} 
                                    />
                                    </a>
                                </TableCell>
                                <TableCell className='action' sx={{ width: '20%' }} align='center'>
                                  <HiDocumentDownload onClick={() => {
                                    if(handleAccess('Hospital','Document Tab')===true){
                                      handleDownload(row.url, row.name)
                                    } else {
                                      setAuthPop(true)
                                    }
                                  }} 
                                  size={20} 
                                  style={{ color: 'white', backgroundColor: 'lightgreen', padding: '3px', borderRadius: '50%' }} />
                                  <TbEdit onClick={() => {
                                  
                                    if(handleAccess('Hospital','Document Tab')===true){ 
                                      setEditName(row.name);
                                      setEditType(row.type)
                                      setEdit(true)
                                      setId({ id: row.id, s3_name: row.s3_name })
                                    } else {
                                      setAuthPop(true)
                                    }

                                  }} size={20} style={{ color: 'white', backgroundColor: 'blue', padding: '3px', borderRadius: '50%' }} />
                                  <RiDeleteBin5Fill onClick={() => {
                                    if(handleAccess('Hospital','Document Tab')===true){
                                      setDeleteId(row.id)
                                    } else {
                                      setAuthPop(true)
                                    }
                                  }} size={20} style={{ color: 'white', backgroundColor: 'red', padding: '3px', borderRadius: '50%' }} />
                                </TableCell>
                          </TableRow>
                      )
                      )}
                      {/* </TableBody> */}
                    </Table>
                }

                <Modal
                  open={open}
                  onClose={() => setOpen('')}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box sx={{ ...style }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ fontSize: '20px', marginBottom: '20px', color: 'darkblue' }}>Preview Document</div>
                      <AiOutlineCloseCircle onClick={() => setOpen('')} size={25} style={{ color: 'red', cursor: 'pointer' }} />
                    </div>
                  { fileType ==='image' ?
                  
                    <img src={open} style={{ width: '100%' }} alt=' not an image' /> :
                    <div
                      style={{
                        height: '550px',
                      }}
                    >

{/* <DocViewer style={{height:'60vh'}}
                          documents={[{uri:open}]}
                          pluginRenderers={DocViewerRenderers}
                          /> */}
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer fileUrl={open} />
                      </Worker> 
                    </div>}


                
                  </Box>
                </Modal>

                <Modal
                  open={edit}
                  onClose={() => setEdit('')}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box sx={{ ...style, width: 400 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
                      <div style={{ fontSize: '20px', marginBottom: '20px', textAlign: 'center', color: 'darkblue' }}>Edit Document</div>
                      <AiOutlineCloseCircle onClick={() => setEdit('')} size={25} style={{ color: 'red', cursor: 'pointer' }} />
                    </div>
                    <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                      <TextField className='edit-label' value={editName} onChange={(event) => {
                        setEditName(event.target.value)
                      }} id="outlined-basic" label='Document Name' variant="outlined" />
                    </div>
                    <div>
                      <FormControl style={{ marginLeft: '50px', width: '300px', }} >
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select sx={{ paddingLeft: '10px', }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={editType}
                          label='Type'
                          onChange={(e) => { setEditType(e.target.value) }}
                        >
                          <MenuItem value='MSME_CERTIFICATE'>MSME_CERTIFICATE</MenuItem>
                          <MenuItem value='PAN_CARD'>PAN_CARD</MenuItem>
                          <MenuItem value='TAN'>TAN</MenuItem>
                          <MenuItem value='CLIENT_ACTIVATION_LETTER'>CLIENT_ACTIVATION_LETTER</MenuItem>
                          <MenuItem value='MOU'>MOU</MenuItem>

                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ textAlign: 'end', marginTop: '30px' }}>
                      <Button size='small' variant='contained' onClick={() => handleEdit()}>
                        Update
                      </Button>
                    </div>


                  </Box>
                </Modal>

                <Dialog
                  open={deleteId}
                  onClose={() => setDeleteId('')}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Confirmation !!!
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you Sure You want to delete this Document
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setDeleteId(null)}>Disagree</Button>
                    <Button onClick={() => handleDelete(deleteId)} autoFocus>
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>


                <Modal
                  open={addDocument}
                  onClose={() => setAddDocument(false)}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box sx={{ ...style, width: 400 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
                      <div style={{ fontSize: '20px', marginBottom: '20px', textAlign: 'center', color: 'darkblue' }}>Add Document</div>
                      <AiOutlineCloseCircle onClick={() => setAddDocument(false)} size={25} style={{ color: 'red', cursor: 'pointer' }} />
                    </div>
                    <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                      <TextField className='edit-label' value={editName} onChange={(event) => {
                        setEditName(event.target.value)
                      }} id="outlined-basic" label='Document Name' variant="outlined" required />
                    </div>
                    <div>
                      <FormControl style={{ marginLeft: '50px', width: '300px', }} >
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select sx={{ paddingLeft: '10px', }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={editType}
                          label='Type'
                          onChange={(e) => { setEditType(e.target.value) }}
                        >
                          <MenuItem value='MSME_CERTIFICATE'>MSME_CERTIFICATE</MenuItem>
                          <MenuItem value='PAN_CARD'>PAN_CARD</MenuItem>
                          <MenuItem value='TAN'>TAN</MenuItem>
                          <MenuItem value='CLIENT_ACTIVATION_LETTER'>CLIENT_ACTIVATION_LETTER</MenuItem>
                          <MenuItem value='MOU'>MOU</MenuItem>

                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <input
                        type="file"
                        accept=".pdf"
                        // onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="pdf-file-input"
                        required
                      />
                      <label htmlFor="pdf-file-input">
                        <Button variant="contained" component="span">
                          Select PDF File
                        </Button>
                      </label>
                    </div>
                    <div style={{ textAlign: 'end', marginTop: '30px' }}>
                      <Button size='small' variant='contained' onClick={() => handleEdit()}>
                        Add
                      </Button>
                    </div>


                  </Box>
                </Modal>

                <Snackbar open={alert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ zIndex: '6' }} autoHideDuration={6000} onClose={() => { setAlert(false) }}>
                  <Alert onClose={() => { setAlert(false) }} severity="success" sx={{ width: '100%' }}>
                    {alert}
                  </Alert>
                </Snackbar>

                <Snackbar open={errorAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={6000} onClose={() => { setErrorAlert(false) }}>
                  <Alert onClose={() => { setErrorAlert(false) }} severity="error" sx={{ width: '100%' }}>
                    {errorAlert}
                  </Alert>
                </Snackbar>

            </div>
        )
}

export default HospitalDocument
