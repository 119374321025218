import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Typography,
  IconButton,
  InputAdornment,
  Input,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./HospitalDetails.css"; // Import the CSS file
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploadPrice from "./UploadPrice";

import Modal from "@mui/material/Modal";

const SuccessResponse = ({ value, onClose, clientid }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid lightblue",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={!!value}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 350 }}>
        {value === "Price Updated Successfully" ||
        value === "Price Updating......" ? (
          <div style={{ color: "green" }}>{value}</div>
        ) : (
          <div style={{ color: "red" }}>{value}</div>
        )}
        <Button
          sx={{ marginTop: "20px", float: "right" }}
          onClick={onClose}
          size="small"
          variant="contained"
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

const PriceTab = ({
  email,
  clientname,
  userAccess,
  setAuthPop,
  handleAccess,
}) => {
  const [slabsData, setslabsData] = useState([]);
  const [agreedPriceData, setAgreedPriceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uploadpop, setUploadPop] = useState(false);
  const [value, setValue] = useState("");
  const [apiRefresh, setApiRefresh] = useState(false);
  const [apiState, setApiState] = useState({
    download: null,
    upload: null,
  });
  useEffect(() => {
    // Fetch slabs data for email
    const { REACT_APP_CLIENT_PAYMENT_SLAB_URL } = process.env;
    const slabsApiUrl = REACT_APP_CLIENT_PAYMENT_SLAB_URL + `?email=${email}`;

    const fetchslabsData = async () => {
      try {
        const response = await axios.get(slabsApiUrl);
        setslabsData(response.data);
      } catch (error) {
        console.error("Error fetching slabs data:", error);
      }
    };
    //console.log(slabsData);
    fetchslabsData();

    // Fetch agreed price data for email
    const { REACT_APP_CLIENT_PRICE_URL, REACT_APP_CLIENT_PRICE_TOKEN } =
      process.env;
    const agreedPriceApiUrl = REACT_APP_CLIENT_PRICE_URL + `?email=${email}`;
    const authorization = REACT_APP_CLIENT_PRICE_TOKEN;

    const fetchAgreedPriceData = async () => {
      try {
        const response = await axios.get(agreedPriceApiUrl, {
          headers: { Authorization: authorization },
        });

        // const response = await axios.get(REACT_FINANCE_CLIENT_DOWNLOAD_PRICE+`client_fk=${}`)
        setAgreedPriceData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching agreed price data:", error);
        setIsLoading(false);
      }
    };
    //console.log(agreedPriceData);
    fetchAgreedPriceData();
  }, [email, apiRefresh]);

  const renderAgreedPriceTable = () => {
    // Your code for rendering the agreed price data in a table format using agreedPriceData
    return (
      <TableContainer sx={{ height: "calc(100vh - 310px)" }} component={Paper}>
        <Table className="price-table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Study Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Night Price</TableCell>
              <TableCell>Default Rate?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agreedPriceData.map((row, id) => (
              <TableRow key={id}>
                <TableCell>{row.list ? row.list.join(", ") : ""}</TableCell>
                <TableCell>{row.price}</TableCell>
                <TableCell>{row.night}</TableCell>
                <TableCell>{row.default ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // const handleApiSuccessResponse=(data)=>{
  //   console.log(data)
  //   setValue(data)
  // }

  const handleDownload = async () => {
    try {
      setApiState((prevData) => ({
        ...prevData,
        download: "fetch",
      }));
      const { REACT_APP_CLIENT_DOWNLOAD_PRICE_URL } = process.env;
      const response = await axios.get(
        REACT_APP_CLIENT_DOWNLOAD_PRICE_URL + `?email=${email}`,
        {
          responseType: "blob", // Set the response type to blob to handle file download
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${clientname}_agreed_price.xlsx`); // Set the file name with the client name
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiState((prevData) => ({
        ...prevData,
        download: null,
      }));
    }
  };

  const handleUpload = () => {
    setUploadPop(true);
  };

  return (
    <div style={{ display: "flex" }}>
      {/* {isLoading ? (
        <Typography>Loading price details...</Typography>
      ) : ( */}
      {/* /* Display the price data fetched from the API */}
      <Box sx={{ flex: 1, paddingRight: "20px" }}>
        <div className="slab-section">
          <Typography className="section-heading">SLABS</Typography>
          {/* CT & MRI */}
          <Typography variant="h6" className="modality-heading">
            CT & MRI
          </Typography>
          <div className="slab-data slab-data-head">
            <div>Slab Name</div>
            <div>Start Volume</div>
            <div>End Volume</div>
            <div>Update</div>
          </div>
          {/* SLAB data for CT & MRI */}
          {slabsData
            .filter(
              (data) =>
                data.modality.includes("CT") || data.modality.includes("MRI")
            )
            .map((slab) => (
              <div key={slab.id} className="slab-data">
                <div>{slab.name}</div>
                <div>
                  <Input
                    value={slab.start_volume}
                    startAdornment={
                      <InputAdornment position="start">From</InputAdornment>
                    }
                  />
                </div>
                <div>
                  <Input
                    value={slab.end_volume}
                    startAdornment={
                      <InputAdornment position="start">To</InputAdornment>
                    }
                  />
                </div>
                <div className="update-icons">
                  {/* Update button with edit and delete icons */}
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small">
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          {/* ADD SLABS button for CT & MRI */}
          <Button variant="contained" size="small" className="add-slabs-button">
            ADD SLABS
          </Button>

          {/* XRAY */}
          <Typography variant="h6" className="modality-heading">
            XRAY
          </Typography>
          <div className="slab-data slab-data-head">
            <div>Slab Name</div>
            <div>Start Volume</div>
            <div>End Volume</div>
            <div>Update</div>
          </div>
          {/* SLAB data for XRAY */}
          {slabsData
            .filter((data) => data.modality.includes("XRAY"))
            .map((slab) => (
              <div key={slab.id} className="slab-data">
                <div>{slab.name}</div>
                <div>
                  <Input
                    value={slab.start_volume}
                    startAdornment={
                      <InputAdornment position="start">From</InputAdornment>
                    }
                  />
                </div>
                <div>
                  <Input
                    value={slab.end_volume}
                    startAdornment={
                      <InputAdornment position="start">To</InputAdornment>
                    }
                  />
                </div>
                <div className="update-icons">
                  {/* Update button with edit and delete icons */}
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small">
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          {/* ADD SLABS button for XRAY */}
          <Button variant="contained" size="small" className="add-slabs-button">
            ADD SLABS
          </Button>

          {/* NM */}
          <Typography variant="h6" className="modality-heading">
            NM
          </Typography>
          <div className="slab-data slab-data-head">
            <div>Slab Name</div>
            <div>Start Volume</div>
            <div>End Volume</div>
            <div>Update</div>
          </div>
          {/* SLAB data for NM */}
          {slabsData
            .filter((data) => data.modality.includes("NM"))
            .map((slab) => (
              <div key={slab.id} className="slab-data">
                <div>{slab.name}</div>
                <div>
                  <Input
                    value={slab.start_volume}
                    startAdornment={
                      <InputAdornment position="start">From</InputAdornment>
                    }
                  />
                </div>
                <div>
                  <Input
                    value={slab.end_volume}
                    startAdornment={
                      <InputAdornment position="start">To</InputAdornment>
                    }
                  />
                </div>
                <div className="update-icons">
                  {/* Update button with edit and delete icons */}
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small">
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          {/* ADD SLABS button for NM */}
          <Button variant="contained" size="small" className="add-slabs-button">
            ADD SLABS
          </Button>
        </div>
      </Box>
      {/* )} */}

      {/* Agreed Price Section */}
      <Box sx={{ flex: 1 }}>
        <div className="agreed-price-section">
          <Typography variant="h6" className="agreed-heading section-heading">
            Agreed Price
          </Typography>
          <div className="buttons-container">
            <Button
              variant="contained"
              size="small"
              className="price-btn"
              startIcon={<CloudDownloadIcon />}
              disabled={apiState?.download === "fetch"}
              onClick={() => {
                // if(userAccess?.access==='admin'){
                //   handleDownload()
                //   }

                if (handleAccess("Hospital", "Price Download") === true) {
                  handleDownload();
                } else {
                  setAuthPop(true);
                }
              }}
            >
              Download
            </Button>
            <Button
              variant="contained"
              size="small"
              className="price-btn"
              disabled={apiState?.upload === "fetch"}
              onClick={() => {
                // if(userAccess?.access==='admin'){
                //   handleUpload()
                //   }
                if (handleAccess("Hospital", "Price Upload") === true) {
                  handleUpload();
                } else {
                  setAuthPop(true);
                }
              }}
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </div>
          {renderAgreedPriceTable()} {/* Render the agreed price table */}
        </div>
      </Box>
      {uploadpop && (
        <UploadPrice
          setApiRefresh={setApiRefresh}
          email={email}
          data={uploadpop}
          setValue={setValue}
          onClose={() => setUploadPop(false)}
          setApiState={setApiState}
        />
      )}

      <SuccessResponse value={value} onClose={() => setValue("")} />
    </div>
  );
};

export default PriceTab;
