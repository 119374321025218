import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Tooltip,
  Dialog,
  DialogContent,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material/";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles, styled } from "@mui/styles";
import {
  getGridDateOperators,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { DateRangePicker } from "react-date-range";
import "./Radiologistics.css";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import axios from "axios";
import { Link } from "react-router-dom";
import UploadSalary from "./UploadSalary";
import { ErrorModel } from "../Hospital/TransactionsTab";
import HouseIcon from "@mui/icons-material/House";
import tick from "../../Images/tick.gif";
import AuthErrorPop from "../Util/AuthErrorPop";
import { UserContext } from "../../App";
import Alerts from "./Alerts";
import RadInvoice from "./RadInvoice";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import RadOverallInvoice from "./RadOverallInvoice";
import * as XLSX from 'xlsx'
const columns = [
  {
    field: "rad_uniqueid",
    headerName: "Unique ID",
    flex: 1,
    minWidth: 100,
    // align:'center',
  },
  {
    field: "rad_name",
    headerName: "Name",
    flex: 1,
    minWidth: 150,
    // align:'center',
  },

  {
    field: "rad_onboarding_date",
    headerName: "Joining Date",
    flex: 1,
    minWidth: 150,
    // align:'center',
  },
  {
    field: "rad_status",
    headerName: "Status",
    flex: 1,
    minWidth: 150,
    // align:'center',
  },
  {
    field: "rad_type",
    headerName: "Type",
    flex: 1,
    minWidth: 150,
    // align:'center',
  },
  {
    field: "rad_is_irad",
    headerName: "SaaS_Only",
    flex: 1,
    minWidth: 150,
    renderCell: (params) => {
      const saas = params.value;
      if (saas) {
        return <span>IRAD</span>;
      } else {
        return <span>Regular</span>;
      }
    },
  },
  {
    field: "grade",
    headerName: "Grade",
    flex: 1,
    minWidth: 150,
    // align:'center',
  },
  {
    field: "readmore",
    headerName: "ReadMore",
    textAlign: "center",
    flex: 1,
    minWidth: 150,
    type: "actions",
    // cellClassName: 'actions',
    renderCell: (props) => (
      <strong>
        <Link
          to={`/radiologists/${props.id}/details`}
          className="firstreadmoreIcon"
        >
          <ArrowCircleRightIcon
       
            sx={{ color: "blue", width: "80px", height: "30px" }}
          />{" "}
        </Link>
        <ReadMoreIcon
          className="readmoreIcon"
          style={{
            fontSize: "36px",
            display: "block",
            margin: "auto",
            color: "blue",
          }}
        />
      </strong>
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    "& .css-204u17-MuiDataGrid-main": {
      borderRadius: "0px 0px 0px 0px",
    },
    "& .MuiDataGrid-cell": {
      textAlign: "center",
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    },
    "& .MuiDataGrid-filterForm": {},
    "& .MuiDataGrid-menuList": {
      fontSize: "10px",
    },

    "& .MuiList-root": {
      "& ..MuiDataGrid-menuList": {
        fontSize: "10px",
      },
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: "#FFFFFF",
    },

    "& .MuiDataGrid-row": {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "lightblue !important",
        // transform: 'scale(1.01)',
        //'#d6d7f8', //'#d61e11',
        // borderRadius:'11px',
        // border:'solid 1px grey',
        transform: "scale(1.004)",
        fontWeight: "bold",
      },
      height: 60,
      border: "none",
      fontSize: "12px",

      "&:nth-child(2n)": {},
    },
    "& .MuiDataGrid-columnHeader": {
      textAlign: "center",
      backgroundColor: "#3C48AD",
      color: "#FFFFFF",
      fontSize: "0.875rem",
      borderColor: "black",

      // opacity:'0.7'
    },
    "& .MuiDataGrid-toolbarFilterList": {
      //  backgroundColor:'red'
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bolder",
      textAlign: "center",
    },

    "& .MuiDataGrid-sortIcon": {
      color: "white",
      "&:hover": {
        transform: "scale(1.6)",
      },
      "& .MuiFormControlLabel-label": {
        fontSize: "14px",
      },
    },
  },
}));

function Radiologists({email}) {
  const [uploadModal, setUploadModal] = useState(null);
  const [data1, SetData] = React.useState([]);
  const [mapData,setMapData]= useState([])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [error, SetError] = useState("");
  const [uploadSalaryPop, setUploadSalaryPop] = useState("");
  const [authPop, setAuthPop] = useState(false);
  const { user } = useContext(UserContext);
  const { handleAccess, apiState, setApiState } = useContext(UserContext);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef(null);
  const [currentFromDate, setCurrentFromDate] = useState(null);
  const [currentToDate, setCurrentToDate] = useState(null);
  const grade = ["ALL","WITHOUT GRADE","SILVER", "BRONZE", "GOLD", "BR_APR2022", "BR_SEPT2023_1"];
  const [selectedGrade, setSelectedGrade] = useState("");
  const [alert,setAlert]=useState('')
  const [warning,setWarning]=useState('')
  const [generate,setGenerate]=useState('')
  const [value, setValue] = React.useState('rad_details');
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleDateFieldClick = () => {
    setIsCalendarOpen((prevIsCalendarOpen) => !prevIsCalendarOpen);
  };

  useEffect(() => {
    //console.log(isCalendarOpen);
  }, [isCalendarOpen]);

  const handleDateChange = (ranges) => {
    setSelectedRange([ranges.selection]);
    setCurrentFromDate(ranges.selection.startDate);
    setCurrentToDate(ranges.selection.endDate);
  };


  const handleGenerateInvoice=()=>{
    if (!selectedRange[0].startDate || !selectedRange[0].endDate) {
      // alert('Please select both start date and end date.');
      setWarning("Please select both start date and end date.");
      return;
    }

    // Check if startdate is month beginning date or enddate is month end date
    const startDate = selectedRange[0].startDate;
    const endDate = selectedRange[0].endDate;
    const isStartDateMonthBeginning = startDate.getDate() === 1;
    const isEndDateMonthEnd =
      endDate.getDate() ===
      new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();

    if (!isStartDateMonthBeginning || !isEndDateMonthEnd) {
      const confirmation = window.confirm(
        "Selected date range is not a month start and end date. Do you want to proceed?"
      );
      if (!confirmation) {
        return;
      }
    }
    
    if (!selectedGrade) {
      // alert('Please select a billing category.');
      setWarning("Please select a Grade Type.");
      return;
    }
     
    setGenerate({
      selectedRange:selectedRange[0],
      selectedGrade:selectedGrade,
      mapData:mapData
    })

    

  }


  const handleSelectClick = () => {
    setIsCalendarOpen(false); // Close the calendar
  };

  const formatDate = (date) => {
    return date ? date.toLocaleDateString() : "";
  };

  const handleClearSelection = () => {
    setSelectedRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };
  const [isOptionDropdownOpen, setIsOptionDropdownOpen] = useState(false);
  const handleOutsideClick = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {

      setIsCalendarOpen((prevIsCalendarOpen) => !prevIsCalendarOpen);
    }

  };

  const handleGradeChange =(e)=>{
      setSelectedGrade(e.target.value)
      // //console.log
      // //console.log(data1)
      let data;
      if(e.target.value==='ALL'){
         data=data1
      }else if(e.target.value==='WITHOUT GRADE'){
        data = data1?.filter(data=>(null===data.grade))
      }
      else{
       data = data1?.filter(data=>(e.target.value===data.grade))
      }
      // //console.log(data)
      setMapData(data)

  }

  const handleGenerateApi=async(data)=>{
    try{
    const {REACT_APP_RAD_INVOICE_URL}= process.env
    const res=await axios.post(REACT_APP_RAD_INVOICE_URL,data)
    //console.log(res)
    if(res.status==200){
      if(res?.data.length>=0 && res?.data[0]==='Email sent successfully'){
        setUploadSalaryPop('Invoice Generated Successfully')
      }else{
        setWarning('Invoice Not Generated')
      }
    }else{
      setWarning('Invoice Not Generated')
    }
    }catch(err){
      //console.log(err)
      setWarning('Invoice Not Generated')
    }

  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const radAPI = async () => {
      setApiState((prevData) => ({
        ...prevData,
        radlist: "fetch",
      }));
      try {
        const { REACT_APP_RAD_LIST_URL } = process.env;
        const res = await axios.get(REACT_APP_RAD_LIST_URL);
        //console.log(res.data);
        const sortedData = [...res.data].sort(
          (a, b) =>
            new Date(b.rad_onboarding_date) - new Date(a.rad_onboarding_date)
        );
        SetData(sortedData);
        setMapData(sortedData)
      } catch (Err) {
        //console.log(Err);
      } finally {
        setApiState((prevData) => ({
          ...prevData,
          radlist: null,
        }));
      }
    };

    radAPI();
  }, []);

  const columns1 = React.useMemo(
    () =>
      columns.map((col) =>
        col.field === "rad_onboarding_date"
          ? {
              ...col,
              filterOperators: getGridDateOperators(),
            }
          : col
      ),
    [columns]
  );

  const onFileUpload = async (data) => {
    try {
      const { REACT_APP_RAD_UPLOAD_SALARY_URL } = process.env;
      const res = await axios.post(REACT_APP_RAD_UPLOAD_SALARY_URL, data);
      //console.log(res);
      if (res.status === 200) {
        if (res?.data?.message === "Data inserted successfully") {
          setUploadSalaryPop("Salary Uploaded Successfully");
        } else {
          SetError("Upload Salary Failed");
        }
      } else {
        SetError("Upload Salary Failed");
      }
    } catch (err) {
      SetError("Upload Salary Failed");
    }
  };

  const handleDownload=()=>{
    const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data1);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `RadList.xlsx`);
  }

  const getRowStyles = (params) => {
    const { grade } = params.row;
    //console.log(params.row);
    //  if(grade=='SILVER'){
    //   return { backgroundColor: 'black' };
    //  }
    return { backgroundColor: "lightgreen !important" };
  };

  const classes = useStyles();

  return (
    <div className="container-main"> 
      
      <TabContext  value={value}>
        
          <TabList  style={{marginLeft:'30px',padding:'10px 0px',width:'300px !important'}} className='notice-tab' onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Rad Details" value="rad_details" />
            <Tab label="Invoices" value="invoices" />
          </TabList>
      </TabContext>

    {
       value==='rad_details' &&
       <div>

       <Box
         sx={{
           height: "calc(100vh - 138px)",
           marginLeft: "-10px",
           padding: "10px 10px 10px 25px",
           borderRadius: "10px",
           overflow: "hidden",
         }}
       >
         <DataGrid
           //  {...data}
           slotProps={{
             toolbar: { printOptions: { disableToolbarButton: true } },
           }}
           getRowId={(data1) => data1.rad_id}
           sx={{
             boxShadow: 1,
             borderRadius: "10px",
             // width:'calc(100vw - 250px)',
             // width:'100%',
             // border:1,
             borderColor: "#f5f5f5",
             backgroundColor: "#FFFFFF",
           }}
           components={{
             Toolbar: ({ printOptions }) => {
               return (
                 <GridToolbarContainer
                   sx={{ justifyContent: "space-between", marginBottom: "5px" }}
                 >
                   {" "}
                   <div
                     style={{
                       padding: "10px",
                       fontSize: "20px",
                       fontWeight: "bold",
                       display: "flex",
                       alignItems: "center",
                     }}
                   >
                     <div>
                       <Button
                         size="small"
                         variant="contained"
                         className="btn-effect"
                         onClick={handleDateFieldClick}
                       >
                         {selectedRange[0].startDate ? (
                           <div>
                             {formatDate(currentFromDate)} -{" "}
                             {formatDate(currentToDate)}
                           </div>
                         ) : (
                           " Date Range"
                         )}
                       </Button>
                       {isCalendarOpen && (
                         <div
                           style={{ marginLeft: "-15px" }}
                           className="date-range-wrapper1"
                         >
                           <div
                             className="date-range-container1"
                             ref={calendarRef}
                           >
                             <DateRangePicker
                               ranges={selectedRange}
                               onChange={handleDateChange}
                               editableDateInputs={false}
                             />
                             <div>
                               <Button
                                 className="clear-btn1"
                                 size="small"
                                 variant="contained"
                                 onClick={handleClearSelection}
                               >
                                 Clear Date Selection
                               </Button>
                               <Button
                                 className="select-btn1"
                                 size="small"
                                 variant="contained"
                                 onClick={handleSelectClick}
                               >
                                 Select
                               </Button>
                             </div>
                           </div>
                         </div>
                       )}
                     </div>
 
                     <div style={{ marginLeft: "20px" }}>
                       <FormControl
                         style={{
                           marginTop: "5px",
                           marginBottom: "5px",
                           width: "200px",
                         }}
                         fullWidth
                       >
                         <InputLabel id="demo-simple-select-label">
                           Select Billing Category
                         </InputLabel>
                         <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={selectedGrade}
                           label="Select Billing Category"
                           onChange={handleGradeChange}
                           style={{ paddingLeft: "10px" }}
                         >
                           {grade.map((billingType, id) => (
                             <MenuItem key={id} value={billingType}>
                               {billingType}
                             </MenuItem>
                           ))}
                         </Select>
                       </FormControl>
                     </div>
 
                     <div style={{ marginLeft: "20px" }}>
                       <Button
                         size="small"
                         variant="contained"
                         className="btn-effect"
                         // onClick={handleDateFieldClick}
                         onClick={() => {
                           if (
                             handleAccess("Radiologist", "Rad Invoice") ===
                             true
                           ) {
                             handleGenerateInvoice()
                           } else {
                             setAuthPop(true);
                           }
                         }}
                        
                       >
                          Generate Invoice
                       </Button>
                     </div>
                   </div>
                   <div style={{ display: "flex" }}>
                     <GridToolbarQuickFilter
                       className="seacrh-filter"
                       style={{ margin: "15px 0px" }}
                     />
                     <div style={{ padding: "15px" }}>
                       <div
                         className="header-section"
                         style={{ marginTop: "4px", marginRight: "5px" }}
                       >
                         {/* <Tooltip title="Upload in House Salary" arrow> */}
                           <Button    size="small"
                         variant="contained"
                         className="btn-effect"
                             // className="clear-all-icon-container"
                             onClick={() => {
                               if (
                                 handleAccess("Radiologist", "Upload Salary") ===
                                 true
                               ) {
                                 setUploadModal(true);
                               } else {
                                 setAuthPop(true);
                               }
                             }}
                           > Upload Salary
                             {/* <HouseIcon
                               style={{ fontSize: 30, color: "blue" }}
                             /> */}
                           </Button>
                         {/* </Tooltip> */}
                       </div>
                     </div>
                     {/* <GridToolbarExport
                        
                       printOptions={{ disableToolbarButton: true }}
                       style={{
                         width: "100px",
                         color: "black",
                         marginRight: "20px",
                         margin: "10px 0px",
                       }}
                     /> */}
                     <div style={{display:'flex',alignItems:'center'}}>
                     <Button variant="standard" onClick={()=>{handleDownload()}}> Export</Button>
                     </div>
                   </div>
                 </GridToolbarContainer>
               );
             },
           }}
           density="standard"
           className={classes.root}
           rowSpacingType="border"
           columnHeaderHeight={60}
           rows={mapData}
           columns={columns1}
           getRowStyle={getRowStyles}
           initialState={{
             pagination: {
               paginationModel: {
                 pageSize: 20,
               },
             },
           }}
           pageSizeOptions={[5, 10, 20]}
           disableRowSelectionOnClick
         />
       </Box>
       <UploadSalary
         modalOpen={uploadModal}
         onClose={() => setUploadModal(false)}
         SetError={SetError}
         onFileUpload={onFileUpload}
       />
     
     </div>
    }
     
     {
       value==='invoices' &&
       <RadOverallInvoice handleAccess={handleAccess} setAuthPop={setAuthPop} />
     }

       <ErrorModel data={error} onClose={() => SetError("")} />
       <Response data={uploadSalaryPop} setData={setUploadSalaryPop} />
       <Alerts alert={alert} setAlert={()=>setAlert('')} warning={warning} setWarning={()=>setWarning('')} />
       <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop} />
       <RadInvoice data={generate} onClose={()=>setGenerate('')} email={email} handleGenerateApi={handleGenerateApi} />

    </div>
  );
}

export const Response=({data,setData})=>{
  return( 
    <Dialog open={data} onClose={() => setData("")}>
    <DialogContent style={{ width: "500px" }}>
      <div style={{ textAlign: "center" }}>
        <div style={{ marginTop: "20px" }}>
          <img style={{ width: "150px" }} src={tick} />
        </div>
        <div style={{ marginTop: "20px", fontSize: "18px", color: "gray" }}>
          {data}
        </div>
        <Button
          style={{ marginTop: "20px" }}
          variant="contained"
          onClick={() => setData("")}
          color="primary"
        >
          Close
        </Button>
      </div>
    </DialogContent>
  </Dialog>
  )
}

export default Radiologists;
