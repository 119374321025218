import React,{useState} from 'react'
import { HiOutlineUpload } from "react-icons/hi";
import ConfirmationDialog from './ConfirmationDialog';
import axios from 'axios'
import AddReminder from './AddReminder';
import Reminder from '../../Images/Reminder.png'
import { HiDocumentAdd } from "react-icons/hi";

function AddDocument({clientId,payerId,data,setData,setWarning,email}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState('');
    const [excelFileName, setExcelFileName] = useState(null);
    const [ reminder,setAddReminder]=useState('')
  
    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        //console.log("Selected file:", selectedFile);
        setSelectedFile(selectedFile);
        if (!!selectedFile) {
          setExcelFileName(selectedFile.name);
        }
        setOpenConfirmationDialog(true); // Show the confirmation dialog after file selection
      };

      const handleCancelUpload = () => {
        setOpenConfirmationDialog(false);
        setSelectedFile(null); // Reset selectedFile state when canceling the upload
        // setExcelFileName(null);
      };
    
      const handleConfirmUpload = () => {
        setOpenConfirmationDialog(false);
        handleUploadDocument()
      };


      const handleUploadDocument=async()=>{
         try{
            const formData = new FormData();
            formData.append("file", selectedFile);
            const {REACT_APP_COLLECTION_UPLOAD_DOCUMENT_URL}= process.env
            const response = await fetch(
                REACT_APP_COLLECTION_UPLOAD_DOCUMENT_URL + `client_fk=${clientId}&contact_fk=${payerId}&email=${email}`,
                {
                  method: "POST",
                  body: formData,
                }
              );
        
              if (response.ok) {
                //console.log("File Updated Successfully");
                setData('File Upload Successfully')
              } else {
                setWarning('File not Uploaded')
              }

         }catch(err){
                
            setWarning('File not Uploaded')
         }
      }


  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  //console.log(openConfirmationDialog)

  return (<div>
    <div style={{margin:'0px 10px 0px 20px',display:'flex'}}className="upload-main">
    {/* <HiOutlineUpload className="upload-icon" size={20} /> */}
    <img onClick={()=>setAddReminder(true)}  src={Reminder} style={{width:'30px',cursor:'pointer'}} ></img>
     
     {/* <ul className="upload-div" > */}
      { !openConfirmationDialog &&  <input
        type="file"
        accept=".xlsx"
          onChange={(e)=>{handleFileSelect(e)}}
        style={{ display: "none" }}
        id="file-input-price"
        />

      }
        <label htmlFor="file-input-price">
        <HiDocumentAdd size={32}  style={{cursor:'pointer',marginLeft:'10px'}} />
                

        {/* </li> */}
        </label>
        {/* <hr></hr> */}
        {/* <li onClick={()=>setAddReminder(true)} >Add reminder</li> */}
    {/* </ul> */}
  </div>
  
   <ConfirmationDialog openConfirmationDialog={openConfirmationDialog} handleCloseConfirmationDialog={()=>{handleCloseConfirmationDialog()}} excelFileName={excelFileName}
     handleCancelUpload={()=>{handleCancelUpload()}} handleConfirmUpload={()=>{handleConfirmUpload()}}  />
     
     <AddReminder email={email} reminder={reminder} setAddReminder={setAddReminder} clientId={clientId} />
  </div>
  )
}

export default AddDocument
