import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormControlLabel, Tab, Tabs, Typography, Box, Switch, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import './HospitalDetails.css';
import { CSSTransition } from 'react-transition-group';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PriceTab from './PriceTab';
import TransactionsTab from './TransactionsTab';
import Client_Invoice from './Client_Invoice';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import AlertDialog from './AlertDialog';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import HospitalDocument from './HospitalDocument';
import { UserContext } from '../../App';
import AuthErrorPop from '../Util/AuthErrorPop';
import HospitalContact, { GreenSwitch } from './HospitalContact';
import CaseActivatioPop from './CaseActivatioPop';
import AccountConfirmation from './AccountConfirmation';
import { dateBodyTemplate } from '../Invoice/Invoices';
import AgreementPricing from './AgreementPricing';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },

}));

const EditHandle = ({ open, onClose, editvalue, updateDetails, value }) => {
      const [error, setError] = useState('')
      const erroRef = useRef()
      const [inputValue, setValue] = useState(value)

      // console.log(value)
      const handleSubmit = () => {
              if (editvalue === 'Invoice Email') {
                      updateDetails(inputValue)
                      setValue('')
              } else {
                      updateDetails(inputValue)
                      setValue('')
              }
      }

      useEffect(() => {
          if(editvalue === 'Onboarded Date'){
              setValue(dayjs(value))
          }else{
            setValue(value)
          }

              //console.log(inputValue)
      }, [value, open])


      const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '1px solid lightblue',
            boxShadow: 24,
            p: 4,
      };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
            <Box sx={{ ...style }}>

                <div className='pop-edit'>
                       <div className='text-red-500' style={{ textAlign: 'center', fontSize: '23px', marginBottom: '30px', color: 'darkblue' }}>Edit {editvalue}</div>
                         <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                              {editvalue === 'MSME' ?
                                <FormControl style={{ marginLeft: '50px', width: '300px', }} >
                                  <InputLabel id="demo-simple-select-label">MSME</InputLabel>
                                  <Select sx={{ paddingLeft: '10px', }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={inputValue}
                                    label='MSME'
                                    onChange={(e) => { setValue(e.target.value) }}
                                  >
                                    <MenuItem value='YES'>YES</MenuItem>
                                    <MenuItem value='NO'>NO</MenuItem>

                                  </Select>
                                </FormControl> : editvalue==='Onboarded Date' ?
                                <div style={{display:'flex',justifyContent:'center'}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                
                                  <DatePicker format='DD/MM/YYYY'
                                    label={editvalue}
                                    value={dayjs(inputValue)}
                                    onChange={(newValue) => setValue(newValue)}
                                  />
                                </DemoContainer>
                              </LocalizationProvider> 
                              </div>
                                :
                                <TextField className='edit-label' value={inputValue} onChange={(event) => {
                                  setValue(event.target.value)
                                  erroRef.current.innerHTML = ''
                                }} id="outlined-basic" label={editvalue} variant="outlined" />
                              }
                         </div>
                      <div style={{ textAlign: 'center', color: 'red' }} ref={erroRef}></div>
                      <div style={{ textAlign: 'center', marginBottom: '5px', marginTop: '30px', }}>
                              <Button sx={{ marginRight: '30px', fontSize: '13px' }} onClick={() => {
                                setValue('')
                                onClose()
                              }} variant="contained">Close</Button>
                              <Button sx={{ fontSize: '13px' }} onClick={handleSubmit} variant="contained">Save</Button>
                      </div>
                    </div>
 
            </Box>
    </Modal>
  )
}

const HospitalDetails = ({ isSidebarOpen, email }) => {
            const [authPop, setAuthPop] = useState(false)
            const location = useLocation();
            const navigate = useNavigate();
            const pathnameParts = location.pathname.split('/');
            const uniqueId = pathnameParts[pathnameParts.length - 2];
            const tabname = pathnameParts[pathnameParts.length - 1];
            const [editTab, setEditTab] = useState(false)
            const [editvalue, setValue] = useState('')
            const [clientDetails,setClientDetails]=useState('')
            const [invoiceAPIValue, setInvoiceAPIValue] = useState('')
            const [hospitalData, setHospitalData] = useState({});
            const [isLoading, setIsLoading] = useState(true);
            const [selectedTab, setSelectedTab] = useState(tabname);
            const [checked, setChecked] = React.useState(true);
            const [pop, setPop] = useState(false)
            const [alert, setAlert] = useState(false);
            const [errorAlert, setErrorAlert] = useState(false)
            const [nightHawk, setNightHawk] = useState(true)
            const [popUp, setPopUp] = useState(false)
            const [changeValue, setChangeValue] = useState('')
            const [perView, setPerView] = useState(false)
            const [whatsApp, setWhatsApp] = useState(false)
            const [billingType, setBillType] = useState('')
            const [invoiceName, setInvoiceName] = useState('')
            const [invoiceEmail, setInvoiceEmail] = useState('')
            const [documentDetails, setDocumentDetails] = useState('')
            const [details,setDetails]=useState(null)
            const {user} = useContext(UserContext);
            const {handleAccess,accessLevel}=useContext(UserContext);
            const [contact, setContacts] = useState([])
            const [byUserId,setByUserId]=useState(312)
            const [caseActivation,setCaseActivation]=useState(false)
            const [Case,setCase]=useState('')
            const [accountConfirm,setAccountConfirm]=useState('')
            const [accountLock,setAccountLock]=useState('')

          const handleChange = (event) => {
              if(handleAccess('Hospital','Edit Details')!==true){
                    setAuthPop(true)
                    return
              }
              setChecked(event.target.checked);
              if (hospitalData.status === "ACTIVE") {
                
                setAccountConfirm(true)
              }
              else {
                setPop('comment')
                
              }
             
              
          };

          const handleNightHawkChange = (event) => {
                  if(handleAccess('Hospital','Edit Details')!==true){
                        setAuthPop(true)
                        return
                  }
                  setNightHawk(event.target.checked)
                  setPopUp(true)
                  setChangeValue('Night Hawk')
          }

          const handleBillingChange = (event) => {
 
                  if(handleAccess('Hospital','Edit Details')!==true){
                        setAuthPop(true)
                        return
                  }
                  setBillType(event.target.value)
                  //console.log(event.target.value)
                  setPopUp(true)
                  setChangeValue('Billing Type')
          }

          const handlePerviewChange = (event) => {
                  if(handleAccess('Hospital','Edit Details')!==true){
                        setAuthPop(true)
                        return
                  }
                  setPerView(event.target.checked)
                  setPopUp(true)
                  setChangeValue('Per View')
          }

          const handleCaseActivation =()=>{
            if(handleAccess('Hospital','Edit Details')!==true){
              setAuthPop(true)
              return
             }
            setChangeValue('Case Activation')
            if(!caseActivation){
              if(invoiceName && invoiceEmail){
                // updateStatus()
                setPopUp('case_activation_comment')
              }else{
                setErrorAlert('Please fill  invoice email and invoice name before enabling case activation')
              }
            }else{
             setCase(true)
            }
               
          }

          const handleWhatsAppChange = (event) => {
                  if(handleAccess('Hospital','Edit Details')!==true){
                        setAuthPop(true)
                        return
                  }
                  const contactLength = contact.filter((data) => (data?.type === 'PAYER'))
                  if (contactLength.length === 0) {
                        setErrorAlert("Please Add a payer contact under contact tab before enabling whatsapp invoice")
                        return
                  }
                  setWhatsApp(event.target.checked)
                  setPopUp(true)
                  setChangeValue('WhatsApp')
          }

          const updateStatus = async (value) => {
                  try {
                          const { REACT_APP_CLIENT_EDIT_DETAILS_SETTING_URL } = process.env
                          if (changeValue === 'Night Hawk') {

                                const res = await axios.put(REACT_APP_CLIENT_EDIT_DETAILS_SETTING_URL, {
                                  client_id: hospitalData.id,
                                  settings: {
                                    night_hawk: !hospitalData.night_hawk
                                  }
                                })

                                if (res.status === 200) setAlert(true)
                                else setErrorAlert(true)
                          }
                          else if (changeValue === 'Per View') {
                                const res = await axios.put(REACT_APP_CLIENT_EDIT_DETAILS_SETTING_URL, {
                                  client_id: hospitalData.id,
                                  settings: {
                                    per_view_price: !hospitalData.per_view_price
                                  }
                                })
                                if (res.status === 200) setAlert(true)
                                else setErrorAlert(true)
                          }
                          else if (changeValue === 'Billing Type') {
                                const res = await axios.put(REACT_APP_CLIENT_EDIT_DETAILS_SETTING_URL, {
                                  client_id: hospitalData.id,
                                  settings: {
                                    billing_type: billingType
                                  }
                                })
                                if (res.status === 200) setAlert(true)
                                else setErrorAlert(true)
                          }
                          else if (changeValue === 'WhatsApp') {
                                const res = await axios.put(REACT_APP_CLIENT_EDIT_DETAILS_SETTING_URL, {
                                  client_id: hospitalData.id,
                                  settings: {
                                    is_whatsapp_invoice_required: whatsApp
                                  }
                                })
                                if (res.status === 200) setAlert(true)
                                else setErrorAlert(true)
                          }else if (changeValue ==='Case Activation'){
                                let settings
                                if(clientDetails?.settings?.other_details?.drafts){
                                  settings= {
                                      other_details:{
                                        disabled_pages:{
                                        }
                                      }
                                    }
                                }else {
                                    if(value===null){
                                      setErrorAlert('Please Select any one of the type to disable')
                                      return
                                    }else{
                                        let titleValue;
                                        if(value==='DEMO CASE LIMIT REACHED'){
                                            titleValue={
                                              title:'Demo Case limit reached!',
                                              comment:'Please contact our Tech Support team at +91 74837 54078'
                                            }
                                        }else if(value==='PAYMENT OVERDUE'){
                                          titleValue={
                                            title:'Payment overdue!',
                                            comment:'Looks like your payment is overdue. Please contact Customer support at +91 95872 74858'
                                          }
                                        }else if(value==='DRAFT PAGE UNDER MAINTENANCE'){
                                          titleValue={
                                            title:'Under maintenance!',
                                            comment:'Looks like your payment is overdue. Please contact Customer support at +91 95872 74858'
                                          }
                                        }else if(value==='OTHER REASON'){
                                          titleValue={
                                            title:'Disabled!',
                                            comment:'Case activation has been disabled. Please contact Customer support at +91 95872 74858'
                                          }
                                        }

                                        settings= {
                                          other_details:{
                                            disabled_pages:{
                                              drafts:titleValue
                                            }
                                          }
                                        }

                                    }
                                }
                                //console.log(settings)
                                const res = await axios.put(REACT_APP_CLIENT_EDIT_DETAILS_SETTING_URL, {
                                  client_id: hospitalData.id,
                                  settings:settings
                                })
                                if (res.status === 200) {
                                  setAlert(true)
                                  setUpdateRefresh(true)
                                }
                                else setErrorAlert(true)
                          }
                  } catch (err) {
                          //console.log(err)
                          setErrorAlert(true)
                  } finally {
                          setPopUp(false)
                          setUpdateRefresh(false)
                          setCase(false)
                  }

          }

          const [updateRefresh, setUpdateRefresh] = useState(false)

          const updateDetails = async (value) => {
                  try {
                        setEditTab(false)
                        if (editvalue === 'Invoice Name' || editvalue === 'Invoice Email' || editvalue === 'PAN' || editvalue === 'GST' || editvalue === 'TAN' || editvalue === 'MSME') {
                                var body;
                                if (editvalue === 'Invoice Name') {

                                  body = {
                                    "client_id": hospitalData.id,
                                    "name": value
                                  }
                                }
                                else if (editvalue === 'Invoice Email') {

                                  value = value.split(',')
                                  body = {
                                    "client_id": hospitalData.id,
                                    "email": value
                                  }
                                }
                                else if (editvalue === 'PAN') {

                                  body = {
                                    "client_id": hospitalData.id,
                                    "pan": value
                                  }
                                }
                                else if (editvalue === 'GST') {

                                  body = {
                                    "client_id": hospitalData.id,
                                    "gstin_number": value
                                  }
                                }
                                else if (editvalue === 'TAN') {

                                  body = {
                                    "client_id": hospitalData.id,
                                    "tan_number": value
                                  }
                                }
                                else if (editvalue === 'MSME') {

                                  body = {
                                    "client_id": hospitalData.id,
                                    "msme_registered": value
                                  }
                                }

                                //console.log(body)
                                const { REACT_APP_CLIENT_EDIT_PAYMENT_URL } = process.env
                                const res = await axios.put(REACT_APP_CLIENT_EDIT_PAYMENT_URL,
                                  body
                                )
                                if (res.status === 200) {
                                  setAlert("Updated Successfully")
                                  setUpdateRefresh(true)
                                }
                                else {
                                  setErrorAlert(true)
                                }
                                //console.log(body)

                        }
                        else if (editvalue === 'Address' || editvalue === 'City' || editvalue === 'State' || editvalue === 'Country') {
                                var body;
                                if (editvalue === 'Address') {
                                  body = {
                                    "client_id": hospitalData.id,
                                    "address": value
                                  }
                                } else if (editvalue === 'City') {
                                  body = {
                                    "client_id": hospitalData.id,
                                    "city": value
                                  }
                                }
                                else if (editvalue === 'State') {
                                  body = {
                                    "client_id": hospitalData.id,
                                    "state": value
                                  }
                                }
                                else if (editvalue === 'Country') {
                                  body = {
                                    "client_id": hospitalData.id,
                                    "country": value
                                  }
                                }
                                const { REACT_APP_CLIENT_EDIT_ADDRESS_URL } = process.env
                                const res = await axios.post(REACT_APP_CLIENT_EDIT_ADDRESS_URL, body)
                                if (res.status === 200) {
                                    setAlert("Updated Successfully")
                                    setUpdateRefresh(true)
                                }
                                else {
                                    setErrorAlert(true)
                                }
                        }
                        else if(editvalue=== 'Additional View Price'|| editvalue==='Holiday Price'){
                                var body;
                                if (editvalue === 'Additional View Price') {
                                  body = {
                                    "client_id": hospitalData.id,
                                    "settings":{
                                      "additional_view_price":value
                                    } 
                                  }
                                }else if(editvalue === 'Holiday Price'){
                                  body = {
                                    "client_id": hospitalData.id,
                                    "settings":{
                                      "holiday_price_percentage":value
                                    } 
                                  }
                                }
                                const { REACT_APP_CLIENT_EDIT_DETAILS_SETTING_URL } = process.env
                                const res = await axios.put(REACT_APP_CLIENT_EDIT_DETAILS_SETTING_URL, body)
                                if (res.status === 200) {
                                  setAlert(true)
                                  setUpdateRefresh(true)
                                }
                                else setErrorAlert(true)
                        }
                        else if (editvalue==='Onboarded Date'){
                          body = {
                            "client_id": hospitalData.id,
                            "onboarded_at":covertUtcTime(value?.$d)
                          }
                          console.log(body)
                          const {REACT_APP_CLIENT_EDIT_DETAILS_URL} = process.env
                          const res = await axios.put(REACT_APP_CLIENT_EDIT_DETAILS_URL, body)
                          if (res.status === 200) {
                            setAlert(true)
                            setUpdateRefresh(true)
                          }
                          else setErrorAlert(true)
                        }
                  } catch (err) {
                          console.log(err)
                          setErrorAlert(true)
                  } finally {
                          setUpdateRefresh(false)
                    // setPopUp(false)
                  }

          }

          const covertUtcTime=(dateString)=>{


            var date = new Date(dateString);

            // Extract individual date components
            var year = date.getFullYear();
            var month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero based
            var day = ("0" + date.getDate()).slice(-2);
            var hours = ("0" + date.getHours()).slice(-2);
            var minutes = ("0" + date.getMinutes()).slice(-2);
            var seconds = ("0" + date.getSeconds()).slice(-2);
            var milliseconds = ("00" + date.getMilliseconds()).slice(-2);
            var timezoneOffset = date.getTimezoneOffset();
            
            // Calculate the timezone offset in hours and minutes
            var offsetHours = Math.abs(Math.floor(timezoneOffset / 75));
            var offsetMinutes = Math.abs(timezoneOffset % 75);
            var offsetSign = timezoneOffset < 0 ? "+" : "-";
            
            // Construct the formatted date string
            var formattedDateString = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds + "." + milliseconds + offsetSign +
                ("0" + offsetHours).slice(-2) + ":" + ("0" + offsetMinutes).slice(-2);
            
    return formattedDateString
          }

          const updateAccStatus = async (comment) => {
                  //console.log(comment)
                  if(comment===''){
                        setErrorAlert('Reason is mandatory for activating or blocking an account')
                        setPop(false)
                        return
                  }
                  if(byUserId===null){
                    setErrorAlert("You don't have a access for blocking and unblocking")
                    setPop(false)
                    return
                  }
                  let Status;
                  if (hospitalData.status === "ACTIVE") {
                         Status = "INACTIVE"
                         sendAlertEmail('Account Blocked',comment)
                  }
                  else {
                         Status = "ACTIVE"
                         sendAlertEmail('Account Unblocked',comment)
                  }

                  //console.log(Status)
                  try {
                          const { REACT_APP_CLIENT_EDIT_ACCOUNT_STATUS_URL } = process.env
                          const res = await axios.put(REACT_APP_CLIENT_EDIT_ACCOUNT_STATUS_URL, {
                            "client_id": hospitalData.id,
                            "status": Status,
                            "comment":comment,
                            "by_user_id":'312',
                            "account_type":'CLIENT'
                          })
                          //console.log(res.status)
                          if (res.status === 200) {
                              //  setAlert(true)
                              if (hospitalData.status === "ACTIVE") {
                                       setAccountLock('INACTIVE')
                                }
                                else {
                                  setAccountLock('ACTIVE')
                                }
       

                          } else {
                               setErrorAlert(true)
                          }
                          //console.log(res)
                  } catch (err) {
                          console.log(err)
                          setErrorAlert(true)
                  } finally {
                          setPop(false)
                  }
          }

          useEffect(() => {
            if (!pop && !popUp && !updateRefresh && !Case) {
              fetchHospitalData()
            }
          }, [pop, popUp, updateRefresh,Case])



          const sendAlertEmail=async(type,caseReason,emailReason,amount,month)=>{
            const dateBodyTemplateTime = () => {
              const formattedDate = new Date().toLocaleDateString("en-US", {
                day: "2-digit",
                month: "short",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              });
              const datePart = formattedDate.slice(0, -8);
              const timePart = formattedDate.slice(-8);
              return (
               
                   `${datePart}${timePart}`
          
              );
            };
          
            let data={
              'unique_id':uniqueId,
              'client_name':hospitalData.client_name,
              'by_user':user?.name,
              'time':dateBodyTemplateTime(),
              'type':type,
              'alert_type':(type==='Case Activation' || type=== 'Account Unblocked') ? 'activation_alert' :'deactivation_alert'
            }
  
              try{
              if(type==='Case Deactivation'){
                if(caseReason==='PAYMENT OVERDUE'){
                  data['reason']=caseReason
                  data['detail_reason']=emailReason?emailReason:''
                  month=month?.$d?.toString()?.split(' ')
                  data['overDue']=`${amount} from ${month?.[1]} - ${month?.[3]}`
                }else if (caseReason==='OTHER REASON'){
                  data['reason']=caseReason
                  data['detail_reason']=emailReason?emailReason:''
                  data['overDue']='NA'
                }
                else {
                  data['reason']=caseReason
                  data['detail_reason']=emailReason?emailReason:''
                  data['overDue']='NA'
                } 
              }else if(type==='Account Blocked'){
                data['reason']=caseReason
                data['detail_reason']=emailReason?emailReason:''
                data['overDue']='NA'
              }else if (type==='Case Activation' || type=== 'Account Unblocked'){
                data['reason']=caseReason
                data['detail_reason']=''
                data['overDue']='NA'
              }
              let message={}
              if(type==='Case Activation' || type=== 'Account Unblocked'){
                message={
                  'original_message': emailReason ? `This Account has been Unblocked due to the reason of ${caseReason} - ${emailReason}`:`This Account has been Unblocked due to the reason of ${caseReason} `,
                  'mentioned_message':emailReason? `This Account has been Unblocked due to the reason of ${caseReason} - ${emailReason}`:`This Account has been Unblocked due to the reason of ${caseReason} `,
                  }
              }else{
                message={
                  'original_message': emailReason ? `This Account has been blocked due to the reason of ${caseReason} - ${emailReason}`:`This Account has been blocked due to the reason of ${caseReason} `,
                  'mentioned_message':emailReason? `This Account has been blocked due to the reason of ${caseReason} - ${emailReason}`:`This Account has been blocked due to the reason of ${caseReason} `,
                  }
              }

                // console.log(data)
                const {REACT_APP_NOTIFICATION_ALERT}=process.env
                const res = await axios.post(REACT_APP_NOTIFICATION_ALERT,data)
                const body={
                    client_fk:hospitalData?.id,
                    contact_id:null,
                    from_json:{
                      name:user?.name,
                      email:user?.email
                    },
                    to_json:'',
                    message:message,
                }
                const {REACT_APP_GET_COLLECTION_PAYER_INSERT_MESSAGE}=process.env
                const response = await axios.post(REACT_APP_GET_COLLECTION_PAYER_INSERT_MESSAGE,[{...body,tag:type,client_name:hospitalData.client_name}])
              }catch(err){

              }
          }



          const dateFormatDisplay=(dateString)=>{
           

// Create a Date object from the given string
var date = new Date(dateString);

// Define an array of month names
var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// Get individual date components
var dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()];
var day = date.getDate();
var monthName = months[date.getMonth()];
var year = date.getFullYear();

// Construct the formatted date string
var formattedDateString = dayOfWeek + ", " + day + " " + monthName + " " + year;

return formattedDateString
          }

          const fetchHospitalData = async () => {
                try {
                      const { REACT_APP_CLIENT_DETAILS_URL } = process.env
                      const response = await axios.get(REACT_APP_CLIENT_DETAILS_URL + `?uniqueid=${uniqueId}`);
                      const data = response.data[0];
                      setHospitalData(data);
                      apiContact(data.id)
                      //console.log(response)


                      if (data.status === 'ACTIVE') {
                               setChecked(true)
                      } else {
                                setChecked(false)
                      }

                      if (data.night_hawk) {      
                               setNightHawk(true)
                      } else {
                               setNightHawk(false)
                      }
  
                      if (data.per_view_price) {
                               setPerView(true)
                      } else {
                               setPerView(false)
                      }
                      setBillType(data.billing_type)
                      setIsLoading(false);
                      const { REACT_APP_CLIENT_INVOICE_DETAILS_URL } = process.env
                      const res = await axios.get(REACT_APP_CLIENT_INVOICE_DETAILS_URL + `${data.id}`)

                      if(res?.data){
                              setClientDetails(res?.data)
                      }
                      if(!res?.data?.settings?.other_details?.disabled_pages?.drafts){
                        setCaseActivation(true)
                      }else{
                        setCaseActivation(false)
                      }

                      if (res?.data?.payments?.name) {
                              setInvoiceName(res?.data.payments.name)
                              setDocumentDetails(res?.data.payments)
                      }

                      if (res?.data?.payments?.email) {
                              setInvoiceEmail(res?.data.payments.email.join(','))
                              //console.log(res?.data.payments.email, 'hello')
                      }

                      if (res?.data?.settings?.is_whatsapp_invoice_required) {
                              setWhatsApp(res?.data?.settings?.is_whatsapp_invoice_required)
                      } 
                      else {
                              setWhatsApp(false)
                      }

                      const {REACT_APP_CLIENT_ALL_DETAILS_URL}=process.env
                      const client_res = await axios.get(REACT_APP_CLIENT_ALL_DETAILS_URL + `id=${data.id}`)
                      if(client_res?.data){
                              setDetails(client_res?.data)
                      }
                      //console.log(res, 'name,email')
                } catch (error) {
                      console.error('Error fetching hospital data:', error);
                      setIsLoading(false);
                }
          };

          const apiContact = async (clientid) => {
                    try {
                          const { REACT_APP_CLIENT_CONTACT_DETAIL_URL } = process.env
                          const res = await axios.get(REACT_APP_CLIENT_CONTACT_DETAIL_URL + clientid)
                          if (res.status === 200) {
                                const data = res.data.sort(function (a, b) {
                                  return new Date(b.updated_at) - new Date(a.updated_at);
                                });
                                setContacts(data)
                            //   //console.log(res?.data.contacts)
                          }
                    } catch (err) {
                          console.log(err)
                    }
          }


          useEffect(() => {
               fetchHospitalData();
               getByUser()
          }, [uniqueId, selectedTab]);

          useEffect(() => {
               setSelectedTab(tabname)
             
          })


          const getByUser=async()=>{
            try{
             const {REACT_APP_GET_BY_USER_ID_URL}=process.env
             const res = await axios.get(REACT_APP_GET_BY_USER_ID_URL+`email=${email}`)
             if(res.status===200){
              if(res?.data?.data?.length>0){
                setByUserId(res?.data?.data[0])
              }
             }
            }catch(err){

            }
          }


          const handleTabChange = (event, newValue) => {
                //console.log(newValue)
                navigate(`/hospitals/${uniqueId}/${newValue}`)
                setSelectedTab(newValue);
          };


  return (


          <CSSTransition
            in={true}
            timeout={500}
            classNames="hospital-details-container"
            appear // Add the 'appear' prop to trigger the transition on initial mount
            unmountOnExit
          >
                  <div>
                          <div className='hospital-header-main'>
                                <div className={`${isSidebarOpen ? 'hospital-header' : 'hospital-header-min'}`}>
                                      <KeyboardBackspaceIcon className="back-arrow-icon" onClick={() => { setSelectedTab(0); navigate(`/hospitals`) }} />
                                      <Typography sx={{ fontSize: '20px' }} variant="h4">{hospitalData?.client_name}</Typography>
                                </div>
                          </div>
                          {isLoading ? (
                              <Typography>Loading hospital details...</Typography>
                              ) : (
                                <div>
                                      <StyledTabs className='tabs' value={selectedTab} onChange={handleTabChange} centered>
                                          <StyledTab className='tabs-content' sx={{ fontSize: '12px' }} label="Hospital Details" value='details' />
                                          <StyledTab sx={{ fontSize: '12px' }} label="Contact" value='contact' />
                                          <StyledTab sx={{ fontSize: '12px' }} label="Price" value='pricing' />
                                          <StyledTab sx={{ fontSize: '12px' }} label="Agreement Price" value='agreement_price' />
                                          <StyledTab sx={{ fontSize: '12px' }} label="Transactions" value='transaction' />
                                          <StyledTab sx={{ fontSize: '12px' }} label="Invoices" value='invoices' />
                                          <StyledTab sx={{ fontSize: '12px' }} label="Documents" value='document' />
                                      </StyledTabs>
                                      <TabPanel value={selectedTab} index={'details'}>
                                            <div className="hospital-details-container">
                                                  <div className="hospital-section">
                                
                                                      <div className="hospital-grid">
                                                        <ul>
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Invoice Name</div>:</div>
                                                            <div style={{ marginRight: '5px' }} className="hospital-value">


                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {invoiceName}
                                                              </div>

                                                              <EditIcon style={{ float: 'right', marginRight: '0px', marginTop: '-5px' }} onClick={() => {
                                                                if(handleAccess('Hospital','Edit Details')===true){
                                                                  setValue('Invoice Name')
                                                                  setInvoiceAPIValue(invoiceName)
                                                                  setEditTab(true)
                                                                }
                                                                else {
                                                                  setAuthPop(true)
                                                                }

                                                              }}></EditIcon>
                                                            </div>


                                                          </li>
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Invoice Email</div>:</div>
                                                            <div style={{ marginRight: '5px' }} className="hospital-value">
                                                          
                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {invoiceEmail}
                                                              </div>

                                                              <EditIcon style={{ marginTop: '-5px', }} onClick={() => {
                                                                    if(handleAccess('Hospital','Edit Details')===true){
                                                                        setValue('Invoice Email')
                                                                        setInvoiceAPIValue(invoiceEmail)
                                                                        setEditTab(true)
                                                                    }
                                                                    else {
                                                                        setAuthPop(true)
                                                                    }
                                                              }}
                                                                sx={{ cursor: 'pointer', marginTop: '-5px' }}
                                                              ></EditIcon>
                                                            </div>
                                                          </li>
                                                          <li className='details-li'>
                                                              <div className="hospital-label"><div>Unique ID</div>:</div>
                                                              <div className="hospital-value">{hospitalData?.unique_id}</div>
                                                          </li>
                                                          <li className='details-li'>
                                                              <div className="hospital-label"><div>Email </div>:</div>
                                                              <div className="hospital-value">{hospitalData?.email}</div>
                                                          </li>
                                                          <li className='details-li'>
                                                              <div className="hospital-label"><div>Billing Address  </div>:</div>
                                                              <div className="hospital-value" >
                                                                  <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                  {clientDetails?.payments?.address}
                                                                      
                                                                  </div>
                                                              </div>
                                                          </li>
                                                          <li className='details-li'>
                                                              <div className="hospital-label ">
                                                                <div>Address</div>
                                                                <div style={{ paddingLeft: '43px' }}>:</div>
                                                              </div>
                                                              <div className="hospital-value hospital-address">

                                                                    <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                      {hospitalData?.address}</div>
                                                                    <EditIcon onClick={() => {
                                                                      if(handleAccess('Hospital','Edit Details')===true){
                                                                        setValue('Address')
                                                                        setInvoiceAPIValue(hospitalData?.address)
                                                                        setEditTab(true)
                                                                      }
                                                                      else {
                                                                        setAuthPop(true)
                                                                      }
                                                                    }}
                                                                      sx={{ cursor: 'pointer' }}
                                                                    >
                                                                    </EditIcon>
                                                              </div>
                                                          </li>
                                                          <li className='details-li'>
                                                              <div className="hospital-label"><div>City</div>:</div>
                                                              <div className="hospital-value">
                                                                <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                  {hospitalData?.city}</div>
                                                                <EditIcon style={{ marginTop: '-5px', }} onClick={() => {
                                                                  if(handleAccess('Hospital','Edit Details')===true){
                                                                    setValue('City')
                                                                    setInvoiceAPIValue(hospitalData?.city)
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer' }}
                                                                ></EditIcon>
                                                              </div>
                                                          </li>
                                                          <li className='details-li'>
                                                              <div className="hospital-label"><div>State</div>:</div>
                                                              <div className="hospital-value">
                                                                  <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                    {hospitalData?.state}</div>
                                                                  <EditIcon style={{ marginTop: '-5px', }} onClick={() => {
                                                                
                                                                    if(handleAccess('Hospital','Edit Details')===true){
                                                                      setValue('State')
                                                                      setInvoiceAPIValue(hospitalData?.state)
                                                                      setEditTab(true)
                                                                    }
                                                                    else {
                                                                      setAuthPop(true)
                                                                    }

                                                                  }}
                                                                    sx={{ cursor: 'pointer' }}
                                                                  ></EditIcon>
                                                              </div>
                                                          </li>
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Country</div>:</div>
                                                            <div className="hospital-value">
                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {hospitalData?.country}</div>
                                                              <EditIcon style={{ marginTop: '-5px', }} onClick={() => {
                                                      
                                                                if(handleAccess('Hospital','Edit Details')===true){
                                                                  setValue('Country')
                                                                  setInvoiceAPIValue(hospitalData?.country)
                                                                  setEditTab(true)
                                                                }
                                                                else {
                                                                  setAuthPop(true)
                                                                }

                                                              }}
                                                                sx={{ cursor: 'pointer' }}
                                                              ></EditIcon>
                                                            </div>
                                                          </li>
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Onboarded Date</div>:</div>
                                                            <div className="hospital-value">
                                                              <div className='edit-value' style={{ overflowX: 'scroll' }}>
                                                                {dateFormatDisplay(details?.onboarded_at)}</div>
                                                                  <EditIcon style={{ marginTop: '-5px', }} onClick={() => {
                                                          
                                                                    if(handleAccess('Hospital','Edit Details')===true){
                                                                      setValue('Onboarded Date')
                                                                      setInvoiceAPIValue(details?.onboarded_at)
                                                                      setEditTab(true)
                                                                    }
                                                                    else {
                                                                      setAuthPop(true)
                                                                    }
                                                                  }}
                                                                    sx={{ cursor: 'pointer' }}
                                                                  ></EditIcon>
                                                            </div>
                                                          </li>
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Date</div>:</div>
                                                            <div className="hospital-value">{hospitalData?.date}</div></li>
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>Phone</div>:</div>
                                                            <div className="hospital-value">{hospitalData?.phone}</div></li>
                                                          <li className='details-li'>
                                                            <div className="hospital-label"><div>SaaS Only</div>:</div>
                                                            <div className="hospital-value">{hospitalData?.saas_only ? 'Yes' : 'No'}</div>
                                                          </li>

                                                       
                                                        </ul>
                                                      </div>
                                                  </div>
                                                  <div className="hospital-section">
                                                  <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              Demo Enable
                                                              <FormControlLabel

                                                                control={
                                                                  <GreenSwitch
                                                                    size="small"
                                                                    color="secondary"
                                                                    checked={details?.settings?.enable_demo}
                                                                    disabled
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                  />
                                                                }
                                                                // label={hospitalData.status === 'ACTIVE' ? 'Active' : 'Account Blocked'}
                                                                labelPlacement="start"
                                                              />

                                                            </div>

                                                           { details?.settings?.enable_demo && <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              <div>Demo Case Count</div>
                                                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div style={{ color: 'black', marginRight: '10px', fontWeight: 'normal' }}>{details?.settings?.demo_case_count} </div>
                                                              
                                                              </div>

                                                            </div>
}

                                                  <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              Account Status
                                                              <FormControlLabel

                                                                control={
                                                                  <Switch
                                                                    size="small"
                                                                    color="primary"
                                                                    checked={checked}
                                                                    onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                  />
                                                                }
                                                                label={hospitalData.status === 'ACTIVE' ? 'Active' : 'Account Blocked'}
                                                                labelPlacement="start"
                                                              />

                                                            </div>

                                                        
                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              {checked ? 'Active ' :'InActive ' }Reason

                                                                <div style={{ color: 'black', marginRight: '10px', fontWeight: 'normal' }}>
                                                                  {clientDetails?.inactive_reason_details?.comment}
                                                                </div>
                                                            </div>

                                                        <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                                    Case Activation
                                                                    <FormControlLabel
                                                                      control={
                                                                        <Switch size="small" color="primary" onChange={handleCaseActivation} checked={caseActivation} />
                                                                      }
                                                                      label={caseActivation ? 'Enabled' : 'Disabled'}
                                                                      labelPlacement="start"
                                                                    />
                                                                  </div>
                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              Night Hawk
                                                              <FormControlLabel
                                                                control={
                                                                  <Switch size="small" color="primary" onChange={handleNightHawkChange} checked={nightHawk} />
                                                                }
                                                                label={hospitalData.night_hawk ? 'Enabled' : 'Disabled'}
                                                                labelPlacement="start"
                                                              />
                                                            </div>

                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              Per View Price
                                                              <FormControlLabel
                                                                control={
                                                                  <Switch
                                                                    size="small"
                                                                    color="primary"
                                                                    onChange={handlePerviewChange}
                                                                    checked={perView}
                                                                  />
                                                                }
                                                                label={hospitalData.per_view_price ? 'Enabled' : 'Disabled'}
                                                                labelPlacement="start"
                                                              />
                                                            </div>

                                                           


                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>Billing Type
                                                              {/* <div className={`billing-type-bar ${hospitalData.billing_type ? 'updated' : ''}`}> */}
                                                              {/* {hospitalData.billing_type || 'Not Updated'} */}
                                                              <FormControl >
                                                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                                                <Select sx={{ width: '200px', paddingLeft: '10px' }}
                                                                  labelId="demo-simple-select-label"
                                                                  id="demo-simple-select"
                                                                  value={billingType}
                                                                  label='Type'
                                                                  onChange={handleBillingChange}
                                                                >
                                                                  <MenuItem value='demo'>Demo</MenuItem>
                                                                  <MenuItem value='dollar'>Dollar</MenuItem>
                                                                  <MenuItem value='edap'>Edap</MenuItem>
                                                                  <MenuItem value='irad'>IRad</MenuItem>
                                                                  <MenuItem value='irad_regular'>IRad Regular</MenuItem>
                                                                  <MenuItem value='mvr'>MVR</MenuItem>
                                                                  <MenuItem value='prodigi_trix'>Prodigi Trix</MenuItem>
                                                                  <MenuItem value='twenty_nine_days'>Twenty Nine Days</MenuItem>
                                                                  <MenuItem value='weekkly'>Weekly</MenuItem>
                                                                  <MenuItem value='regular'>Regular</MenuItem>
                                                                  <MenuItem value='OMS'>OMS</MenuItem>
                                                                  <MenuItem value='Tender'>Tender</MenuItem>
                                                                  <MenuItem value='strategic_account'>Strategic Account</MenuItem>
                                                                </Select>
                                                              </FormControl>
                                                              {/* </div> */}
                                                            </div>

                                                            <EditHandle open={editTab} editvalue={editvalue} onClose={() => setEditTab(false)} value={invoiceAPIValue} updateDetails={updateDetails} />
                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              Proofreader
                                                              <FormControlLabel
                                                                control={
                                                                  <Switch
                                                                    size="small"
                                                                    color="primary"
                                                                    checked={hospitalData.is_pr}
                                                                    disabled
                                                                  />
                                                                }
                                                                label={hospitalData.is_pr ? 'Enabled' : 'Disabled'}
                                                                labelPlacement="start"
                                                              />
                                                            </div>

                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              Pre-Read
                                                              <FormControlLabel
                                                                control={
                                                                  <Switch
                                                                    size="small"
                                                                    color="primary"
                                                                    checked={hospitalData.is_pre_read}
                                                                    disabled
                                                                  />
                                                                }
                                                                label={hospitalData.is_pre_read ? 'Enabled' : 'Disabled'}
                                                                labelPlacement="start"
                                                              />
                                                            </div>

                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              OMS Partner
                                                              <FormControlLabel
                                                                control={<Switch size="small" color="primary" disabled />}
                                                                label={hospitalData.oms_partner ? 'Yes' : 'No'}
                                                                labelPlacement="start"
                                                              />
                                                            </div>
                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              What'sApp Invoice
                                                              <FormControlLabel
                                                                control={
                                                                  <Switch
                                                                    size="small"
                                                                    color="primary"
                                                                    onChange={handleWhatsAppChange}
                                                                    checked={whatsApp}
                                                                  />
                                                                }
                                                                label={whatsApp ? 'Enabled' : 'Disabled'}
                                                                labelPlacement="start"
                                                              />
                                                            </div>

                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              <div>Additional View Price</div>
                                                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div style={{ color: 'black', marginRight: '10px', fontWeight: 'normal' }}>{details?.settings?.additional_view_price} </div>
                                                                <EditIcon style={{ marginTop: '-5px', color: 'black' }} onClick={() => {
                                                                  if(handleAccess('Hospital','Edit Details')===true){
                                                                    setValue('Additional View Price')
                                                                    setInvoiceAPIValue(details?.settings?.additional_view_price)
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer', marginTop: '' }}
                                                                ></EditIcon>

                                                              </div>

                                                            </div>

                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              <div>Holiday Price</div>
                                                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div style={{ color: 'black', marginRight: '10px', fontWeight: 'normal' }}>{details?.settings?.holiday_price_percentage} </div>
                                                                <EditIcon style={{ marginTop: '-5px', color: 'black' }} onClick={() => {
                                                                  if(handleAccess('Hospital','Edit Details')===true){
                                                                    setValue('Holiday Price')
                                                                    setInvoiceAPIValue(details?.settings?.holiday_price_percentage)
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer', marginTop: '' }}
                                                                ></EditIcon>

                                                              </div>

                                                            </div>


                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              <div>GST</div>
                                                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div style={{ color: 'black', marginRight: '10px', fontWeight: 'normal' }}> {documentDetails?.gstin_number}</div>
                                                                <EditIcon style={{ marginTop: '-5px', color: 'black' }} onClick={() => {
                                                                  // if (user?.access === 'admin') {
                                                                    // setValue('GST')
                                                                    // setInvoiceAPIValue(documentDetails?.gstin_number)
                                                                  //   setEditTab(true)
                                                                  // }
                                                                  if(handleAccess('Hospital','Edit Details')===true){
                                                                    setValue('GST')
                                                                    setInvoiceAPIValue(documentDetails?.gstin_number)
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer', marginTop: '' }}
                                                                ></EditIcon>

                                                              </div>
                                                            </div>
                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              <div>PAN</div>
                                                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div style={{ color: 'black', marginRight: '10px', fontWeight: 'normal' }}>{documentDetails?.pan}</div>
                                                                <EditIcon style={{ marginTop: '-5px', color: 'black' }} onClick={() => {
                                                                  // if (user?.access === 'admin') {
                                                                    // setValue('PAN')
                                                                    // setInvoiceAPIValue(documentDetails?.pan)
                                                                  //   setEditTab(true)
                                                                  // }
                                                                  if(handleAccess('Hospital','Edit Details')===true){
                                                                    setValue('PAN')
                                                                    setInvoiceAPIValue(documentDetails?.pan)
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer', marginTop: '' }}
                                                                ></EditIcon>

                                                              </div>
                                                            </div>
                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              <div>TAN</div>
                                                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div style={{ color: 'black', marginRight: '10px', fontWeight: 'normal' }}>{documentDetails?.tan_number}</div>
                                                                <EditIcon style={{ marginTop: '-5px', color: 'black' }} onClick={() => {
                                                                  // if (user?.access === 'admin') {
                                                                    // setValue('TAN')
                                                                    // setInvoiceAPIValue(documentDetails?.tan_number)
                                                                  //   setEditTab(true)
                                                                  // }
                                                                  if(handleAccess('Hospital','Edit Details')===true){
                                                                    setValue('TAN')
                                                                    setInvoiceAPIValue(documentDetails?.tan_number)
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer', marginTop: '' }}
                                                                ></EditIcon>

                                                              </div>

                                                            </div>
                                                            <div className="hospital-toggle-label" style={{ padding: '13px' }}>
                                                              <div>MSME</div>
                                                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div style={{ color: 'black', marginRight: '10px', fontWeight: 'normal' }}>{documentDetails?.msme_registered} </div>
                                                                <EditIcon style={{ marginTop: '-5px', color: 'black' }} onClick={() => {

                                                                  // if (user?.access === 'admin') {
                                                                    // setValue('MSME')
                                                                    // setInvoiceAPIValue(documentDetails?.msme_registered)
                                                                  //   setEditTab(true)
                                                                  // }
                                                                  if(handleAccess('Hospital','Edit Details')===true){
                                                                    setValue('MSME')
                                                                    setInvoiceAPIValue(documentDetails?.msme_registered)
                                                                    setEditTab(true)
                                                                  }
                                                                  else {
                                                                    setAuthPop(true)
                                                                  }

                                                                }}
                                                                  sx={{ cursor: 'pointer', marginTop: '' }}
                                                                ></EditIcon>

                                                              </div>

                                                            </div>



                                                   </div>
                                            </div>
                                      </TabPanel>

                                      <TabPanel value={selectedTab} index={'contact'}>
                                          <HospitalContact clientid={hospitalData.id} email={hospitalData.email} clientname={hospitalData.client_name} userAccess={user} handleAccess={handleAccess} setAuthPop={setAuthPop} />
                                      </TabPanel>

                                      <TabPanel value={selectedTab} index={'pricing'}>
                                          <PriceTab clientid={hospitalData.id} email={hospitalData.email} clientname={hospitalData.client_name} userAccess={user} handleAccess={handleAccess} setAuthPop={setAuthPop} />
                                      </TabPanel>

                                      <TabPanel value={selectedTab} index={'agreement_price'}>
                                          <AgreementPricing hospitalData={clientDetails} uniqueId={uniqueId} user={user} clientid={hospitalData.id} clientEmail={hospitalData.email} email={email} clientname={hospitalData.client_name} userAccess={user} handleAccess={handleAccess} setAuthPop={setAuthPop} />
                                      </TabPanel>

                                      <TabPanel value={selectedTab} index={'transaction'}>
                                         <TransactionsTab clientid={hospitalData.id} clientname={hospitalData.client_name} uniqueId={uniqueId} email={email} userAccess={user} handleAccess={handleAccess} setAuthPop={setAuthPop} />
                                      </TabPanel>

                                      <TabPanel value={selectedTab} index={'invoices'}>
                                         <Client_Invoice clientid={hospitalData.id} clientname={hospitalData.client_name} email={email} userAccess={user} handleAccess={handleAccess} setAuthPop={setAuthPop} />
                                      </TabPanel>

                                      <TabPanel value={selectedTab} index={'document'}>
                                         <HospitalDocument clientid={hospitalData.id} userAccess={user} handleAccess={handleAccess} setAuthPop={setAuthPop} />
                                      </TabPanel>
                                </div>
                          )}
                          <AlertDialog open={pop} handleClose={() => setPop(false)} UpdateFunction={updateAccStatus} sendAlertEmail={sendAlertEmail} />
                          <AlertDialog open={popUp} handleClose={() => setPopUp(false)} UpdateFunction={updateStatus} sendAlertEmail={sendAlertEmail} />


                          <Snackbar open={alert} sx={{ top: '50px !important' }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={() => { setAlert(false) }}>
                            <Alert onClose={() => { setAlert(false) }} severity="success" sx={{ width: '100%' }}>
                              Updated Successfully
                            </Alert>
                          </Snackbar>

                          <Snackbar open={errorAlert} sx={{ top: '50px !important' }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={() => { setErrorAlert(false) }}>
                            <Alert onClose={() => { setErrorAlert(false) }} severity="error" sx={{ width: '100%' }}>
                              {errorAlert === true ? 'Updation Failed' : errorAlert}
                            </Alert>
                          </Snackbar>

                          <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop} />

                          <CaseActivatioPop Case={Case} setCase={setCase} updateStatus={updateStatus} sendAlertEmail={sendAlertEmail} />


                          <AccountConfirmation setChecked={setChecked} open={accountConfirm} setOpen={setAccountConfirm} setPop={setPop} confirmPop={accountLock} setConfirmPop={setAccountLock} />


                  </div>
          </CSSTransition>

  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box >{children}</Box>}
    </div>
  );
}

export default HospitalDetails;
