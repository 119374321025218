// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add styling for your sidebar and tab navigation here
.sidebar {
  width: 200px;
  background-color: #f1f1f1;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  padding: 10px;
  cursor: pointer;
}

.content {
  margin-left: 200px;
  padding: 20px;
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;GAmBG","sourcesContent":["/* Add styling for your sidebar and tab navigation here\n.sidebar {\n  width: 200px;\n  background-color: #f1f1f1;\n}\n\nul {\n  list-style-type: none;\n  padding: 0;\n}\n\nli {\n  padding: 10px;\n  cursor: pointer;\n}\n\n.content {\n  margin-left: 200px;\n  padding: 20px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
