import React, { useEffect, useState } from 'react'
import PieChart from '../charts/PieChart'
import { revenueModality, revenueModalityOptions } from '../charts/variables/charts.js'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
function PieChartRevenueBillling({startDate,endDate}) {
  const [modalityData, setModalityData] = useState([])
  const [modalityOption, setModalityOption] = useState('')
  const apiCall = async () => {
    try{
    setModalityData([])
    const {REACT_APP_CLIENT_HOME_MODALITY_WISE_REVENUE}=process.env
    const res = await axios.get(REACT_APP_CLIENT_HOME_MODALITY_WISE_REVENUE+`?start_date=${startDate}&end_date=${endDate}`)
    //console.log(res)
    const data1 = []
    const option = []
    res?.data?.data?.map((data) => {
      option.push(data?.modality)
      data1.push(parseInt(data?.revenue))
    })
    //console.log(data1, option)
    setModalityOption({ ...revenueModalityOptions, labels: option })
    setModalityData(data1)
    //console.log(modalityOption)
  }catch(err){
    //console.log(err)
  }
  }
  useEffect(() => {
    if(startDate!==null){
    apiCall()
    }
  }, [startDate,endDate])

  useEffect(() => {
    //console.log(modalityOption)
  }, [modalityOption])

  return (<div style={{ backgroundColor: 'white', borderRadius: '20px', padding: '20px',}}>
     {modalityData.length > 0 ?
    <div style={{  display: 'flex' }}>
      <div>
          <PieChart chartData={modalityData} chartOptions={modalityOption} />    
      </div>
      <div style={{ width: '50%' }}>
        <div style={{ color: '#1B2559', display: 'flex', justifyContent: 'center', fontSize: '25px' }}>Revenue By Modality</div>
        <div className='billing-revenue' style={{ marginTop: '50px' }}>
          {modalityOption.labels?.map((data, id) => (<div>
            <span key={id}>{data}</span>
            <span>{new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(modalityData[id])}</span></div>))}

        </div>
      </div>


    </div>:<div>
    <Box sx={{ display: 'flex',flexDirection:'row',justifyContent:'center',marginTop:'auto',marginBottom:'auto'}}>
    <CircularProgress />
    </Box>
    </div>

}
    </div>
  )
}

export default PieChartRevenueBillling
