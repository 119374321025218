import React,{useEffect, useRef, useState} from 'react'
import axios from 'axios';
import {
  Modal,
  Box,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
TextField,Button
} from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Alerts from '../Radiologists/Alerts';
import { useLocation, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  // height:'500px',
  bgcolor: "background.paper",
  border: "2px solid lightgray",
  boxShadow: 24,
  borderRadius: "30px",
  p: 4,
};

const AddReminder = ({reminder,setAddReminder,email,clientId}) => {
  const location = useLocation();
  const pathnameParts = location.pathname.split("/");
  const contactId = pathnameParts[pathnameParts.length - 1];
  const [subject,setSubject]=useState('')
  const [message,setMessage]=useState('')
  const [datetime,setDateTime]=useState(null)
  const [alert,setAlert]=useState('')
  const [warning,setWarning]=useState('')
  const [formattedDate,setFormattedDate]=useState(null)
  const errorRef = useRef(null)
  const [meetingType,setMeetingType]=useState(null)
  const MeetingOption=['OnCall','Field Visit']


  useEffect(()=>{
    if(datetime){
      const inputDate = new Date(datetime);
      const year = inputDate.getFullYear();
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
      const day = String(inputDate.getDate()).padStart(2, '0');
      const hours = String(inputDate.getHours()).padStart(2, '0');
      const minutes = String(inputDate.getMinutes()).padStart(2, '0');
      const seconds = String(inputDate.getSeconds()).padStart(2, '0');
      const timezoneOffsetMinutes = -inputDate.getTimezoneOffset(); // Note the negative sign
      const timezoneOffsetHours = Math.floor(timezoneOffsetMinutes / 60);
      const timezoneOffsetMinutesRemainder = Math.abs(timezoneOffsetMinutes % 60);
      const formattedDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffsetHours >= 0 ? '+' : '-'}${String(Math.abs(timezoneOffsetHours)).padStart(2, '0')}:${String(timezoneOffsetMinutesRemainder).padStart(2, '0')}`;
      setFormattedDate(formattedDateString)
    }

  },[datetime])

  const handleClearAll=()=>{
    setFormattedDate(null)
    setDateTime(null)
    setSubject('')
    setMessage('')
  }

  const handleSendReminder=async()=>{
    errorRef.current.innerHTML = "";
    if(subject===''){
      errorRef.current.innerHTML = "Please fill the subject";
      return
    }
    if(message===''){
      errorRef.current.innerHTML = "Please enter the message";
      return
    }
    if(datetime===null){
      errorRef.current.innerHTML = "Please select the time";
      return
    }
    setAddReminder(false)
    try{
      const data =[{
        subject:subject,
        message:message,
        time:formattedDate,
        email:[email],
        by_user:email,
        client_fk:clientId,
        type:'finance-client',
        meeting_type:meetingType,
        status:'Uncoming',
        'contact_fk':contactId,
      }]
      const {REACT_APP_COLLECTION_ADD_REMINDER}=process.env
      const res = await axios.post(REACT_APP_COLLECTION_ADD_REMINDER,data)
      if(res.status===200){
        if(res.data==='Event Created Successfully'){
          setAlert('Reminder Added Successfully')
        }else{
          setWarning('Add Reminder Failed')
        }
      }else{
        setWarning('Add Reminder Failed')

      }

    }catch{
      setWarning('Add Reminder Failed')

    }finally{
      handleClearAll()
    }
  }
  

  return (
    
    <div>

            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={reminder}
                onClose={() => {
                    handleClearAll()
                    setAddReminder(false);
                }}
            >
                <Box sx={style}>
                <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid gray",
                                paddingBottom: "10px",
                                fontSize: "20px",
                            }}
                        >
                            <div>Add Reminder</div>
                            <AiOutlineCloseCircle
                                onClick={() => {
                                    handleClearAll()
                                    setAddReminder("");
                                }}
                                size={25}
                                style={{ color: "red", cursor: "pointer" }}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "20px",
                    
                            }}
                        >
                            <TextField
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                style={{ marginBottom: "20px", marginTop: "20px",width:'90%' }}
                                id="standard-basic"
                                label="Subject"
                                variant="standard"
                            />
                            <TextField
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                style={{ marginBottom: "20px", marginTop: "20px",width:'90%' }}
                                id="standard-basic"
                                label="Message"
                                variant="standard"
                            />

                            <div>
                            <FormControl
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Meeting Type
                  </InputLabel>
                            <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={meetingType}
                    label="Select Meeting Type"
                    onChange={(e)=>setMeetingType(e.target.value)}
                    style={{ paddingLeft: "10px",width:'300px' }}
                  >
                    {MeetingOption.map((option, id) => (
                      <MenuItem key={id} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                            </div>
                            {/* <div  style={{display:'flex',justifyContent:'space-between',width:'90%',alignItems:'center',marginBottom: "20px", marginTop: "20px",}}>
                             
                              <div> */}
                               <div style={{marginTop:'20px', color:'darkblue'}} >Select Time</div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DemoContainer components={['DateTimePicker']}>
                                  <DateTimePicker
                                           
                                            value={datetime}
                                            onChange={(newValue) => setDateTime(newValue)}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                      {/* </div>

                                      </div> */}

                              <div style={{ color: "red",marginTop:'30px' }} ref={errorRef}>
                                                              {" "}
                                                          </div>

                                                        

                                <Button
                                    onClick={() => handleSendReminder()}

                                    style={{ marginBottom: "20px", marginTop: "40px" }}
                                    variant="contained"
                                >
                                    Save Reminder
                                </Button>
                            </div>

                        </div>
               
                </Box>
            </Modal>

            <Alerts alert={alert} setAlert={setAlert} warning={warning} setWarning={setWarning} />

    </div>
  );
};

export default AddReminder;
