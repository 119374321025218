import React, { useState, useEffect, useMemo } from 'react'
import PieChart from '../charts/PieChart'
import { revenueBilling, revenueBillingOptions } from '../charts/variables/charts.js'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
function PieChartRevenueBillling({startDate,endDate}) {
  const [billingData, setBillingData] = useState([])
  const [billingOption, setBillingOption] = useState('')
  const [billingBoth,setBillingBoth]=useState([])
  const apiCall = async () => {
    try{
    setBillingData([])
    const {REACT_APP_CLIENT_HOME_BILLING_WISE_REVENUE} = process.env
    const res = await axios.get(REACT_APP_CLIENT_HOME_BILLING_WISE_REVENUE+`?start_date=${startDate}&end_date=${endDate}`)
    //console.log(res)
    const data1 = []
    const option = []
    const both=[]
    res?.data?.data?.map((data) => {
      if(data?.billing_type){
      option.push(data?.billing_type)
      }
      // if(data?.revenue){
      data1.push(parseInt(data?.revenue))
      // }
       both.push({
        revenue:parseInt(data?.revenue),
        billing_type:data?.billing_type
       })
    })
    setBillingBoth(both)
    setBillingOption({ ...revenueBillingOptions, labels: option })
    setBillingData(data1)
  }catch(err){
    //console.log(err)
  }
  }
  useEffect(() => {
    if(startDate!==null){
    apiCall()
    }
  }, [startDate,endDate])
  

  return ( <div style={{ backgroundColor: 'white', borderRadius: '20px', padding: '20px',}}>
     {  billingData.length>0 ?
    <div style={{  display: 'flex' }}>
      <div style={{ width: '50%' }}>
        <div style={{ color: '#1B2559', fontSize: '25px' }}>Revenue By Billing</div>
        <div className='billing-revenue'>
          {billingBoth?.sort((a,b)=>b.revenue-a.revenue).map((data, id) => (<div key={id}>
            <span>{data.billing_type}</span>
            <span>{new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(data.revenue)}</span></div>))}
        </div>
      </div>
      <div style={{marginTop:'20px'}}>
      <PieChart chartData={billingData} chartOptions={billingOption} />
      </div>
    </div> :<div>
    <Box sx={{ display: 'flex',flexDirection:'row',justifyContent:'center',marginTop:'auto',marginBottom:'auto'}}>
    <CircularProgress />
    </Box>
    </div>

}
    </div>
  )
}

export default PieChartRevenueBillling
