
export const revenueBillingOptions = {
  // labels: ["Demo", "Dollar", "Edap","irad","irad_regular","mvr","prodigi_trix","regular","twenty_nine_days","weekly"],
  colors: ["#a78bfa", "#64748b","#312e81", "#881337", "#ffe4e6","#2563eb","#4318FF", "#6AD2FF", "#6ee7b7","#38bdf8", ],
  chart: {
    width: "50px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: [ "#a78bfa", "#64748b","#312e81", "#881337", "#ffe4e6","#2563eb","#4318FF", "#6AD2FF", "#6ee7b7","#38bdf8",],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};

export const  revenueBilling= [];

export const revenueModality = [];

export const revenueModalityOptions = {
  labels: ["CT","XRAY","MRI","NM"],
  colors: ["#4318FF", "#6AD2FF", "#6ee7b7","#38bdf8"],
  chart: {
    width: "50px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#4318FF", "#6AD2FF", "#6ee7b7","#38bdf8"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};


// Total Spent Default


export const lineChartDataTotalSpent = [
  {
    name: "Revenue",
    data: [50000, 23644,55548, 16161, 42349, 75668],
  },
  {
    name: "GM",
    data: [22330, 44550,67724, 24446, 34420, 45546],
  },
];

export const lineChartOptionsTotalSpent = {
  chart: {
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: "#4318FF",
    },
  },
  colors: ["#4318FF", "#39B8FF","#01b574"],
  markers: {
    size: 0,
    colors: "white",
    strokeColors: "#7551FF",
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    type: "line",
  },
  xaxis: {
    type: "number",
    categories: ["SEP", "OCT", "NOV", "DEC", "JAN", "FEB"],
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
    column: {
      color: ["#7551FF", "#39B8FF","#01b574"],
      opacity: 0.5,
    },
  },
  color: ["#7551FF", "#39B8FF","#01b574"],
};
