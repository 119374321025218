import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Card, Col, Row, Select, notification } from "antd";
import { Textarea } from './HospitalNotices';
import { Button } from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './HospitalRemainder.css'
import RemainderUpload from './RemainderUpload';
import SearchIcon from '@mui/icons-material/Search';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide,TextField,InputAdornment } from '@mui/material';
const { Option } = Select;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function HospitalRemainder({ setWarning, setAlert, email, alert,user,setAuthPop,handleAccess }) {
    const [hospitalData, setHospitalData] = useState([])
    const [SearchHospital, setSearchHospital] = useState({
        uniqueId: null,
        clientName: null
    })
    const [search,setSearch]=useState(null)
    const [remainderData, setRemaiderData] = useState([])
    const [notice, setNotice] = useState(null)
    const [content, setContent] = useState(null)
    const [textEditable, setTextEditable] = useState(false)
    const [sendType, setSendType] = useState(null)
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [selectedRange, setSelectedRange] = useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection',
        },
    ]);
    const [bulkOpen, setBulkOpen] = React.useState(false);
    const [confirmPopup, setConfirmPopup] = useState('')
    const calendarRef = useRef(null);

    useEffect(() => {
        fetchHospital()
    }, [])


    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the index range for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = remainderData?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil((remainderData?.filter((data, id) => {
        if(search===null){
            return data
        }   
        if (data.client_name?.toString().toLowerCase().includes(search?.toString().toLowerCase())||data?.client_id?.toString().toLowerCase().includes(search?.toString().toLowerCase())) {
          return data
        }
      }).length || 0) / itemsPerPage);


    const handleDateChange = (ranges) => {
        setSelectedRange([ranges.selection]);
    };

    const fetchHospital = async () => {
        try {
            const { REACT_APP_CLIENT_LISTING_FOR_SEND_INVOICE_URL } = process.env
            const response = await axios.get(REACT_APP_CLIENT_LISTING_FOR_SEND_INVOICE_URL);
            const data = response.data[0];
            setHospitalData(data)
        } catch {
        }
    }

    const dateBodyTemplate = (rowData) => {
        const formattedDate = new Date(rowData).toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
        const datePart = formattedDate.slice(0, -8);
        const timePart = formattedDate.slice(-8);
        return (
          <div>
            <div className="date-part">{datePart} </div>
            <div className="time-part">{timePart}</div>
          </div>
        );
      };

    const handleTypeChange = (value) => {
        setNotice(value)
        let data;
        if (value === 'OutStanding') {
            data = `Dear Customer,

            You have an outstanding amount of Rs {amount} as of {month}. Kindly pay as early as possible to avoid service interruption.

            Kindly contact us at +91 6366433919 / 6366433905 / 9900892536 / 8431688274 immediately for any queries            
            `
        }
        else if (value === 'Generated') {
            data = `Dear  Customer,

            Invoice for the {month} has been generated and shared via email/WhatsApp. Same copy is available to download on the 5C Network Platform. 

            Kindly reach out to +91 6366433919 / 6366433905 / 9900892536 / 8431688274 for any queries.

            ` }
        else if (value === 'Due') {
            data =`Dear Valued Customer, 

            The invoice generated on {Date}, is due for payment. Kindly make the payment by or before {Date} to avoid service interruption.

            Please feel free to reach out to us at +91 6366433919 / 6366433905 / 9900892536 / 8431688274 for any concern or queries.

            `
        }
        else if (value === 'OverDue') {
            data = `Dear Valued Customer, 

            The invoice generated on {Date}, is overdue for payment by {days_count} days. Kindly make the payment immediately to avoid service interruption. 

            You will experience interest of 1.5% per month on the amount payable as agreed. Please reach out to +91 6366433919 / 6366433905 / 9900892536 / 8431688274 immediately for any queries.


             `
        }
        else if (value === 'Pre Suspension') {
            data = `Dear Valued Customer, 

            After several reminders, the overdue amount is still unpaid. You may not be able to send any new cases if the amount is unpaid within {Hours} hours. 

            You will experience interest of 1.5% per month on the amount payable as agreed. 

            Please reach out to +91 6366433919 / 6366433905 / 9900892536 / 8431688274 immediately for any queries.

            ` 
         }
        else if (value === 'Suspension') {
            data = `Dear Customer, 

            Due to unpaid overdue amounts, your account has been automatically blocked for sending new cases. You will experience interest of 1.5% per month on the amount payable as mentioned in the agreement until it is cleared.

            Please reach out to +91 6366433919 / 6366433905 / 9900892536 / 8431688274 immediately for any queries.

            ` 
         }
        setContent(data)
    }

    const handleSendRemainder = () => {
        
        
        if (SearchHospital.uniqueId === null || SearchHospital.uniqueId === '') {
            setWarning('Please Choose the client')
            return
        } else if (notice === null || notice === '') {
            setWarning('Please Select the Type')
            return
        }
        else if (content === null || content === '') {
            setWarning('Please Enter the content')
            return
        }
        else if (sendType === null || sendType === '') {
            setWarning('Please select the send type')
            return
        }
        const placeholder=['{month}','{amount}','{Date}','{days_count}','{Hours}']
        const isPresent = placeholder.some(str=>content.includes(str))
        if(isPresent){
            setWarning('Please replace the placeholder with value')
            return
        }
        else {
            setConfirmPopup({
                client_id: SearchHospital.uniqueId,
                client_name: SearchHospital.clientName,
                message: content,
                type: notice,
                sent_from: sendType,
                sent_by: email
            })
        }

    }

    const handleInsertRemainder = async (body) => {
        try {
            const { REACT_APP_REMAINDER_INSERT_URL } = process.env
            //console.log(body)
            const res = await axios.post(REACT_APP_REMAINDER_INSERT_URL, body)
            if (res.status === 200) {
                setAlert('Email Send Successfully')
            } else {
                setWarning('Email Not Sent')
            }
        } catch (err) {
            console.log(err)
            setWarning('Email Not Sent')
        } finally {
            setSearchHospital({
                uniqueId: null,
                clientName: null
            })
            setNotice(null)
            setSendType(null)
            setContent('')
        }

    }

    const handleOutsideClick = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setIsCalendarOpen((prevIsCalendarOpen) => !prevIsCalendarOpen);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleGetRemainder = async () => {
        try {
            //console.log('data')
            setRemaiderData([])
            const { REACT_APP_REMAINDER_GET_URL } = process.env
            if (selectedRange[0]?.startDate === null) {
                const res = await axios.get(REACT_APP_REMAINDER_GET_URL)
                if (res.status === 200) {
                    const data = res?.data.data.sort(function (a, b) {
                        return new Date(b.sent_on) - new Date(a.sent_on);

                      });
                    setRemaiderData(data)
                }
            } else {
                const res = await axios.get(REACT_APP_REMAINDER_GET_URL + `start_date=${formatDate(selectedRange[0].startDate)}&end_date=${formatDate(selectedRange[0].endDate)}`)
                if (res.status === 200) {
                    const data = res?.data.data.sort(function (a, b) {
                        return new Date(b.sent_on) - new Date(a.sent_on);
                      });
                    setRemaiderData(data)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {

        if (selectedRange[0]?.startDate === null) {
            handleGetRemainder()
        }
    }, [selectedRange[0]?.startDate, alert])

    const formatDate = (date1) => {
        const date = new Date(date1);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    };

    const handleClearSelection = () => {
        setSelectedRange([
            {
                startDate: null,
                endDate: null,
                key: 'selection',
            },

        ]);
        handleGetRemainder()
    };

    const handleSelectClick = () => {
        setIsCalendarOpen(false);
    };

    const handleResend = (data) => {
        setContent(data?.message)
        setNotice(data?.type)
        setSendType(data?.sent_from)
        setSearchHospital({
            uniqueId: data?.client_id,
            clientName: data?.client_name

        })
    }


    const handleFileUpload = (dataToSend) => {
        const data = dataToSend.map(data => {
            return {
                client_id: data.unique_id,
                client_name: data.client_name,
                message: data.message,
                type: data.type,
                sent_from: data.sent_from,
                sent_by: email
            }
        })
        handleInsertRemainder(data)
        //    console.log(data)
    }


    return (
        <div>
            <div style={{ alignItems: 'flex-start' }} className='notice-filters'>
                <Select
                    showSearch
                    style={{ width: "250px" }}
                    size="large"
                    placeholder="Search for Client"
                    optionFilterProp="children"
                    value={SearchHospital.uniqueId}
                    onChange={(value, option) => setSearchHospital({
                        uniqueId: value,
                        clientName: option.label
                    })}
                >
                    {
                        hospitalData?.map((data, id) => (
                            <Option
                                key={id}
                                value={data.unique_id}
                                label={data?.client_name}
                            >
                                {data.client_name}
                            </Option>
                        ))
                    }
                </Select>
                <div>
                    <Select
                        style={{ width: "150px" }}
                        size="large"
                        placeholder="Select Type"
                        optionFilterProp="children"
                        value={notice}
                        onChange={(value) => handleTypeChange(value)}
                    >

                        <Option value={'OutStanding'}>
                            OutStanding
                        </Option>
                        <Option value={'Generated'}>
                            Generated
                        </Option>
                        <Option value={'Due'}>
                            Due
                        </Option>
                        <Option value={'OverDue'}>
                            Over Due
                        </Option>
                        <Option value={'Pre Suspension'}>
                            Pre Suspension
                        </Option>
                        <Option value={'Suspension'}>
                            Suspension
                        </Option>
                    </Select>
                </div>
                <div >
                    <Textarea style={{ width: '480px', height: '60px' }}
                        value={content}
                        aria-label="maximum height"
                        onChange={(e) => setContent(e.target.value)}
                        defaultValue=""
                        readOnly={textEditable}
                    />
                </div>
                <div>
                    <Select

                        style={{ width: "150px" }}
                        size="large"
                        placeholder="Select Type"
                        optionFilterProp="children"
                        value={sendType}
                        onChange={(value) => setSendType(value)}
                    >
                        <Option value={'Email'}>
                            Email
                        </Option>
                        {/* <Option value={'WhatsApp'}>
                            WhatsApp
                        </Option> */}
                    </Select>
                </div>
                <div>
                    <Button

                        onClick={()=>{
                            // if (user?.access === 'admin') {
                            if(handleAccess('HospitalNotice','Send Reminder')){
                                handleSendRemainder()
                              }else{
                                setAuthPop(true)
                              }
                        }
                    }
                        size='small'
                        style={{ fontSize: '11px', width: '80px',height:'40px' }}
                        variant="contained" >
                        Send
                    </Button>
                </div>
            </div>

            <div className='notice-filters'>
                <div style={{ display: 'flex' }}>
                    <div>
                        <Button size="small" variant="contained" className='btn-effect' style={{height:'40px'}}
                            // className={`date-field ${isCalendarOpen ? 'active' : ''}`}
                            onClick={() => setIsCalendarOpen((prev => !prev))}
                        >
                            {selectedRange[0].startDate ? (<div>

                                {formatDate(selectedRange[0].startDate)} -  {formatDate(selectedRange[0].endDate)}

                            </div>
                            ) : 'Date Range'}
                        </Button>

                        {isCalendarOpen && (
                            <div className="date-range-wrapper1">
                                <div className="date-range-container1" style={{ marginLeft: '300px', marginTop: '0px', zIndex: '2' }} ref={calendarRef}>
                                    <DateRangePicker
                                        ranges={selectedRange}
                                        onChange={handleDateChange}
                                    />

                                    <div className='select-btn-div'>
                                        <Button size="small" variant="contained" className='clear-btn' onClick={handleClearSelection}>
                                            Clear Date Selection
                                        </Button>
                                        <Button size="small" variant="contained" className='select-btn' onClick={handleSelectClick}>
                                            Select
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div style={{ marginLeft: '20px' }}>
                        <Button style={{height:'40px'}} onClick={() => handleGetRemainder()} size='small' className='btn-effect' variant="contained">Search</Button>
                    </div>

                   

                </div>
                <div style={{display:'flex',}}>
                <div><TextField className='search-div'
                 value={search} onChange={(event) => setSearch(event.target.value)} id="outlined-basic" label="Search" variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    /></div>
                    <Button size='small' 
                    style={{marginLeft:'10px'}}
                    onClick={()=>{
                        // if (user?.access === 'admin') {
                          if(handleAccess('HospitalNotice','Bulk Upload Reminder')){
                            setBulkOpen('true')
                          }else{
                            setAuthPop(true)
                          }
                    }
                }
                 className='btn-effect' variant="contained">Bulk Upload</Button>
                </div>
            </div>
            <div className='remainder-table'>
                <div className='remainder-table-head'>
                    <div>Client ID</div>
                    <div>Client Name</div>
                    <div className='remainder-message'>Message</div>
                    <div>Send On</div>
                    <div>Send By</div>
                    <div>Send From</div>
                    <div>Type</div>
                    <div>Action</div>
                </div>
                {remainderData?.filter((data, id) => {
                    if(search===null){
                        return data
                    }
                    if (data.client_name?.toString().toLowerCase().includes(search?.toString().toLowerCase())||data?.client_id?.toString().toLowerCase().includes(search?.toString().toLowerCase())) {
                      return data
                    }
                  })?.slice(indexOfFirstItem, indexOfLastItem)?.map((data, id) => (
                    <div key={id} className='remainder-table-body'>

                        <div>{data?.client_id}</div>
                        <div>{data?.client_name}</div>
                        <div className='remainder-message'>{data?.message}</div>
                        <div>{dateBodyTemplate(data?.sent_on)}</div>
                        <div>{data?.sent_by}</div>
                        <div>{data?.sent_from}</div>
                        <div>{data?.type}</div>
                        <div>
                            <Button onClick={() => handleResend(data)} style={{ fontSize: '9px' }} size='small' variant='contained' >Resend</Button>
                        </div>
                    </div>
                ))}


            </div>

            <div style={{ float: 'right',marginRight:'20px' }} >
                <Button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                >
                    Previous
                </Button>
                <span>{`${currentPage} of ${totalPages}`}</span>
                <Button
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    Next
                </Button>
            </div>


            <Dialog
                open={confirmPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setConfirmPopup('')}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle style={{ textAlign: 'center' }} >Confirmation !!!</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-slide-description">
                        {/* Do you want to {popUpdelete?.type} the notice of {popUpdelete?.client_name} */}
                        You are sending {confirmPopup.type} Email to {confirmPopup.client_name} with the below message
                    </DialogContentText>
                    <DialogContentText style={{ marginTop: '20px', fontSize: '12px', color: 'black' }}>
                        {confirmPopup.message?.split('\n').map((data, id) => (
                            <div>
                                {data}
                            </div>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmPopup('')}>Disagree</Button>
                    <Button onClick={() => {
                        handleInsertRemainder([{
                            client_id: SearchHospital.uniqueId,
                            client_name: SearchHospital.clientName,
                            message: content,
                            type: notice,
                            sent_from: sendType,
                            sent_by: email
                        }])
                        setConfirmPopup('')
                    }}>confirm</Button>
                </DialogActions>
            </Dialog>

            <RemainderUpload open={bulkOpen} handleClose={() => setBulkOpen(false)} SetError={setWarning} handleFileUpload={handleFileUpload} />
        </div>
    )
}

export default HospitalRemainder
