import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TextField, Tooltip
} from "@mui/material";
import { DateRangePicker } from "react-date-range";
import axios from "axios";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CalculateIcon from "@mui/icons-material/Calculate";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import EditCostModal from "./EditCostModal";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import UploadCost from "./UploadCost";
import Moon from '../../Images/Moon.svg'
import Urgent from '../../Images/urgent.svg'
import Premieum from '../../Images/star-gold.svg'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Slide,
    DialogContentText,
} from "@mui/material";
import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz";
import Modal from "@mui/material/Modal";
import "./TransactionsTab.css";
import tick from "../../Images/tick.gif";
import { VscDebugRerun } from "react-icons/vsc";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Typography from "@mui/material/Typography";
import PaidReport from "../../Images/paid_report.png"
import FreePremium from "../../Images/free-premium.png"


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ErrorModel = ({ data, onClose }) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "1px solid lightblue",
        boxShadow: 24,
        p: 4,
    };
    return (
        <Modal
            open={!!data}
            onClose={onClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: 350 }}>
                <div className="pop-edit">
                    <div style={{ display: "flex", fontSize: "20px", color: "red" }}>
                        {/* <div>Error :</div> */}
                        <div style={{ paddingLeft: "10px" }}>{data}</div>
                    </div>

                    <Button
                        sx={{ marginTop: "10px", float: "right", fontSize: "13px" }}
                        onClick={onClose}
                        variant="contained"
                    >
                        Close
                    </Button>
                </div>

                {/* <ChildModal /> */}
            </Box>
        </Modal>
    );
};

const TransactionsTab = ({
    clientid,
    clientname,
    email,
    userAccess,
    setAuthPop,
    uniqueId,
    handleAccess,
}) => {
    const [startDate, setStartDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    );
    const [showDatePicker, setShowDatePicker] = useState(false);
    const datePickerRef = useRef(null);
    const [tableData, setTableData] = useState([]); // Initialize as an empty array
    const [nightHawkCase, setNightHawkCase] = useState(false);
    const [perViewCase, setPerViewCase] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    // const [selectedFile, setSelectedFile] = useState(null);
    const [historicalData, setHistoricalData] = useState("");
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [apiState, setApiState] = useState({
        transaction: null,
        uploadcost: null,
        costRerun: null,
    });
    const [apiResponse, setApiResponse] = useState(null); // State to store the API response
    const [openApiResponseDialog, setOpenApiResponseDialog] = useState(false); // State to control the API response dialog
    const timeZone = "Asia/Kolkata";
    const [error, setError] = useState("");
    const [errorPop, setErrorPop] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [costRerunPop, setCostRerunPop] = useState("");
    const [costconfirmPop, setCostConfirmPop] = useState("");
    const [toggle, setToggle] = useState('Case Level')

    // Function to handle opening the edit modal
    const handleEditClick = (rowData) => {

        if (handleAccess("Hospital", "Manual Edit") !== true) {
            setAuthPop(true);
            return;
        }
        setSelectedRowData(rowData);
        setShowEditModal(true);
    };

    // const handleFileSelect = (event) => {
    //   setSelectedFile(event.target.files[0]);
    // };

    // Function to update the cost in the tableData state
    const handleUploadCostClick = () => {
        setShowUploadModal(true);
    };

    const handleCloseUploadModal = () => {
        setShowUploadModal(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                datePickerRef.current &&
                !datePickerRef.current.contains(event.target)
            ) {
                setShowDatePicker(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const getStartAndEndDatesForCurrentMonth = () => {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        return { startOfMonth, endOfMonth };
    };

    const fetchTableData = async () => {
        try {
            setApiState((prevData) => ({
                ...prevData,
                transaction: "fetch",
            }));
            const apiStartDate = format(
                zonedTimeToUtc(startDate, timeZone),
                "yyyy-MM-dd"
            );
            const apiEndtDate = format(
                zonedTimeToUtc(endDate, timeZone),
                "yyyy-MM-dd"
            );
            if (apiStartDate >= "2023-07-01") {
                setHistoricalData("");
                const {
                    REACT_APP_CLIENT_TRANSACTION_PAYABLE_URL,
                    REACT_APP_CLIENT_TRANSACTION_PAYABLE_TOKEN,
                    REACT_APP_CLIENT_TRANSACTION_CASE_LEVEL_PAYABLE_URL,
                    REACT_APP_CLIENT_TRANSACTION_CASE_LEVEL_PAYABLE_TOKEN
                } = process.env;

                let apiUrl;
                let config;
                if (toggle !== 'Report Level') {
                    config = {
                        headers: {
                            Authorization: `Bearer ${REACT_APP_CLIENT_TRANSACTION_PAYABLE_TOKEN}`,
                        },
                    };
                    apiUrl =
                        REACT_APP_CLIENT_TRANSACTION_PAYABLE_URL +
                        `?client_fk=${clientid}&start_date=${apiStartDate}&end_date=${apiEndtDate}`;
                    setHistoricalData('Report Level Data');
                } else if (toggle !== 'Case Level') {
                    config = {
                        headers: {
                            Authorization: `Bearer ${REACT_APP_CLIENT_TRANSACTION_CASE_LEVEL_PAYABLE_TOKEN}`,
                        },
                    };
                    apiUrl =
                        REACT_APP_CLIENT_TRANSACTION_CASE_LEVEL_PAYABLE_URL +
                        `?client_fk=${clientid}&start_date=${apiStartDate}&end_date=${apiEndtDate}`;
                    setHistoricalData('Case Level Data');
                }

                const response = await axios.get(apiUrl, config);
                //console.log(response);
                if (response.data.error) {
                    setTableData([]);
                    if (errorPop) setError("Response Error");
                } else if (response.data.client_price.length === 0) {
                    if (errorPop) setError("No Transaction Data");
                    setTableData([]);
                } else {
                    const data = response.data.client_price.sort(function (a, b) {
                        return (
                            new Date(a.study_created_time) - new Date(b.study_created_time)
                        );
                    });

                    setTableData(data);

                }
            } else {
                setHistoricalData("Historical Data");
                setTableData([]);
                const api = `https://clientapi.dhan.5cnetwork.com/api/get_historical_data/?unique_id=${uniqueId}&start_date=${apiStartDate}&end_date=${apiEndtDate}`;
                // console.log(apiStartDate, apiEndtDate, clientid)
                const response = await axios.get(api);
                //console.log(response.data.data);
                if (response.data.data) {
                    if (response.data.data.length === 0) {
                        setError("No Transaction Data");
                    } else {
                        const data = response.data.data.sort(function (a, b) {
                            return (
                                new Date(a.study_created_time) - new Date(b.study_created_time)
                            );
                        });
                        setTableData(data);
                    }
                } else {
                    setError("Response Error");
                }
                // if (response.data.error) {
                //   setTableData([])
                //   if (errorPop)
                //     setError('Response Error')
                // } else if (response.data.client_price.length === 0) {
                //   if (errorPop)
                //     setError('No Transaction Data')
                //   setTableData([])
                // } else {
                //   const data=response.data.client_price.sort(function(a, b) {
                //     return new Date(a.study_created_time) - new Date(b.study_created_time);
                // });

                //   setTableData(data);

                // }
            } // Extract the array from the object
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Response Error");
            setTableData([]);
        } finally {
            setApiState((prevData) => ({
                ...prevData,
                transaction: null,
            }));
        }
    };

    useEffect(() => {
        fetchTableData();
        setErrorPop(true);
    }, [toggle]);

    const handleFileUpload = (dataToSend) => {
        // console.log('File upload data:', dataToSend);

        setApiState((prevData) => ({
            ...prevData,
            uploadcost: "fetch",
        }));
        // Make an API call to send the curated data to the server

        const { REACT_APP_CLIENT_UPLOAD_COST_URL } = process.env;

        // Use fetch or axios to send a POST request with the curated data
        if (historicalData === 'Historical Data') {

            const { REACT_APP_CLIENT_HISTORICAL_EDIT_URL } = process.env;

            fetch(REACT_APP_CLIENT_HISTORICAL_EDIT_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataToSend),
            })
                .then((response) => response.json())
                .then((data) => {
                    //console.log("Cost Update in the Database:", data);
                    setApiResponse(data);
                    // Open the API response dialog
                    setOpenApiResponseDialog(true);
                })
                .catch((error) => {
                    console.error("API error:", error);
                    // Handle API error
                })
                .finally(() => {
                    setApiState((prevData) => ({
                        ...prevData,
                        uploadcost: null,
                    }));
                });

        }
        else {

            fetch(REACT_APP_CLIENT_UPLOAD_COST_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataToSend),
            })
                .then((response) => response.json())
                .then((data) => {
                    //console.log("Cost Update in the Database:", data);
                    setApiResponse(data);
                    // Open the API response dialog
                    setOpenApiResponseDialog(true);
                })
                .catch((error) => {
                    console.error("API error:", error);
                    // Handle API error
                })
                .finally(() => {
                    setApiState((prevData) => ({
                        ...prevData,
                        uploadcost: null,
                    }));
                });

        }
    };

    const formatDate = (dateStr) => {
        const date = zonedTimeToUtc(new Date(dateStr), timeZone);
        return format(date, "yyyy-MM-dd", { timeZone: "Asia/Kolkata" });
    };

    // useEffect(()=>{
    //   console.log(tableData)
    // })

    const handleSearch = () => {
        setErrorPop(true);
        fetchTableData();
    };


    // Function to handle Night Hawk Case tick mark button click
    const handleNightHawkCaseToggle = () => {
        setNightHawkCase((prevValue) => !prevValue);
    };

    // Function to handle Per View Case tick mark button click
    const handlePerViewCaseToggle = () => {
        setPerViewCase((prevValue) => !prevValue);
    };

    // Function to calculate the sum of cost from the table
    const calculateTotalCost = () => {
        if (!Array.isArray(tableData) || tableData.length === 0) {
            return 0;
        }
        return tableData.reduce((total, row) => total + row.cost, 0);
    };

    // Function to calculate the sum of Varience from the table
    const calculateVarianceCost = () => {
        if (!Array.isArray(tableData) || tableData.length === 0) {
            return 0;
        }
        const total = tableData.reduce(
            (total, row) => total + row.compromised_cost,
            0
        );
        return total;
    };

    // Function to calculate the sum of Revised Cost from the table
    const calculateRevisedCost = () => {
        if (!Array.isArray(tableData) || tableData.length === 0) {
            return 0;
        }
        const total = tableData.reduce((total, row) => total + row.revised_cost, 0);
        return total;
    };

    const [filteredTableData, setFilteredTableData] = useState([]);

    useEffect(() => {
        // Apply initial filter on mount
        filterData();
    }, [tableData, nightHawkCase, perViewCase]);

    const filterData = () => {
        if (nightHawkCase && perViewCase) {
            const filteredData = tableData.filter(
                (row) => row.is_night_hawk === "yes" || row.is_per_view === "yes"
            );
            //console.log(filteredData);
            setFilteredTableData(filteredData);
        } else if (nightHawkCase) {
            const filteredData = tableData.filter(
                (row) => row.is_night_hawk === "yes"
            );
            setFilteredTableData(filteredData);
        } else if (perViewCase) {
            const filteredData = tableData.filter((row) => row.is_per_view === "yes");
            setFilteredTableData(filteredData);
        } else {
            setFilteredTableData(tableData);
        }
    };

    // Function to create the Excel worksheet with the additional 'reason' column
    const convertDataToWorksheet = () => {
        if (Array.isArray(tableData) && tableData.length > 0) {
            // Map through tableData and add 'reason' field to each object
            const dataWithReason = tableData.map((row) => ({
                order_id: row.order_id,
                report_fk: row.report_fk,
                date: row.study_created_time,
                patient_name: row.patient_name,
                patient_id: row?.patient_id,
                modality: row?.modality,
                study_name: row.study_name,
                image_count: row.image_count,
                is_night_hawk: row.is_night_hawk,
                is_per_view_case: row.is_per_view_case,
                cost: row.cost,
                compromised_cost: row.compromised_cost,
                revised_cost: row.revised_cost,
                reason: "", // Add an empty 'reason' field to each row
                num_of_reports:row.num_of_reports,
            }));
            const worksheet = XLSX.utils.json_to_sheet(dataWithReason);
            return worksheet;
        }
        return null;
    };

    // Function to download data in Excel
    const handleDownload = () => {
        downloadDataAsExcel();
    };

    const handlecostRerun = async () => {
        setApiState((prevData) => ({
            ...prevData,
            costRerun: "fetch",
        }));
        setCostConfirmPop(false);
        try {
            const { REACT_APP_CLIENT_COST_RERUN_URL } = process.env;
            const apiStartDate = format(
                zonedTimeToUtc(startDate, timeZone),
                "yyyy-MM-dd"
            );
            const apiEndtDate = format(
                zonedTimeToUtc(endDate, timeZone),
                "yyyy-MM-dd"
            );
            const body = {
                client_fk: clientid,
                start_date: apiStartDate,
                end_date: apiEndtDate,
            };
            const res = await axios.get(
                REACT_APP_CLIENT_COST_RERUN_URL +
                `?client_fk=${clientid}&start_date=${apiStartDate}&end_date=${apiEndtDate}`
            );
            //console.log(res.data);
            if (res?.data === "cost rerun success") {
                setCostRerunPop("Cost Rerun Successfullly");
            } else {
                setError("Cost Rerun Failed");
            }
        } catch (err) {
            console.log(err);
            setError("Cost Rerun Failed");
        } finally {
            setApiState((prevData) => ({
                ...prevData,
                costRerun: null,
            }));
        }
    };

    const handleToggle = () => {
        if (toggle === 'Report Level') {
            setToggle('Case Level')
        } else {
            setToggle('Report Level')
        }
    }

    const handleTransactionDownload = () => {
        const jsonData = tableData?.map((row) => ({
            order_id: row.order_id,
            report_fk: row.report_fk,
            patient_name: row.patient_name,
            study_name: row.study_name,
            is_night_hawk: row.is_night_hawk,
            is_per_view_case: row.is_per_view_case,
            cost: row.cost,
            compromised_cost: row.compromised_cost,
            revised_cost: row.revised_cost,
            reason: row.reason,
            study_created_time: row.study_created_time,
            report_submitted_time: row.report_submitted_time,
            patient_id: row.patient_id,
            image_count: row.image_count,
            rad_name: row.rad_name,
        }));
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(jsonData);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(
            wb,
            `${clientname}_transaction_${format(
                zonedTimeToUtc(startDate, timeZone),
                "yyyy-MM-dd"
            )}_${format(zonedTimeToUtc(endDate, timeZone), "yyyy-MM-dd")}.xlsx`
        );
    };

    // Function to download data as Excel with 'reason' column added
    const downloadDataAsExcel = () => {
        const worksheet = convertDataToWorksheet();
        if (!worksheet) {
            console.error("No data to download.");
            return;
        }

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Cost_sheet");

        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });

        const filename = `${clientname}_${format(
            zonedTimeToUtc(startDate, timeZone),
            "yyyy-MM-dd"
        )}_${format(zonedTimeToUtc(endDate, timeZone), "yyyy-MM-dd")}.xlsx`;

        const blob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, filename);
    };

    // Function to handle closing the API response dialog
    const handleCloseApiResponseDialog = () => {
        setOpenApiResponseDialog(false);
    };

    const handleDateRangePickerToggle = () => {
        setShowDatePicker(!showDatePicker);

        const currentDate = zonedTimeToUtc(new Date(), timeZone);

        const startOfMonth = zonedTimeToUtc(
            new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            timeZone
        );
        setStartDate(startOfMonth);

        const endOfMonth = zonedTimeToUtc(
            new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
            timeZone
        );
        setEndDate(endOfMonth);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div>
            {/* DateRangePicker */}
            <div
                className="transaction-main"
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                    justifyContent: "center",
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDateRangePickerToggle}
                    style={{ marginRight: "10px", fontSize: "10px" }}
                >
                    {format(zonedTimeToUtc(startDate, timeZone), "yyyy-MM-dd")} -{" "}
                    {format(zonedTimeToUtc(endDate, timeZone), "yyyy-MM-dd")}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    disabled={apiState?.transaction === "fetch"}
                    onClick={() => {
                        handleSearch();
                    }}
                    style={{ marginRight: "10px", fontSize: "10px" }}
                >
                    Search
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        handleToggle()

                        //   if (handleAccess("Hospital", "Download") === true) {
                        //     handleTransactionDownload();
                        //   } else {
                        //     setAuthPop(true);
                        //   }
                    }}
                    style={{ marginRight: "40px", fontSize: "10px" }}
                >
                    Switch to {toggle}
                </Button>
                {/* Tick mark buttons */}
                <Button
                    variant={nightHawkCase ? "contained" : "outlined"}
                    color="primary"
                    onClick={handleNightHawkCaseToggle}
                    style={{ marginRight: "10px", fontSize: "10px" }}
                >
                    {nightHawkCase ? "✓ Only Night Hawk" : "Night Hawk Case"}
                </Button>
                <Button
                    variant={perViewCase ? "contained" : "outlined"}
                    color="primary"
                    onClick={handlePerViewCaseToggle}
                    style={{ fontSize: "10px" }}
                >
                    {perViewCase ? "✓ Only Per View" : "Per View Case"}
                </Button>
                {/* Additional buttons */}
                <div
                    style={{ display: "flex", alignItems: "center", marginLeft: "40px" }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={apiState?.costRerun === "fetch"}
                        onClick={() => {
                            // if(userAccess?.access==='admin'){
                            if (handleAccess("Hospital", "Cost Rerun") === true) {
                                setCostConfirmPop(true);
                            } else {
                                setAuthPop(true);
                            }
                        }}
                        style={{ marginRight: "10px", fontSize: "9px" }}
                    >
                        {/* <CalculateIcon style={{ marginRight: '5px' }} /> */}
                        <VscDebugRerun size={25} />
                        Cost Rerun
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            // if(userAccess?.access==='admin'){
                            if (handleAccess("Hospital", "Download Cost") === true) {
                                handleDownload();
                            } else {
                                setAuthPop(true);
                            }
                        }}
                        style={{ marginRight: "10px", fontSize: "9px" }}
                    >
                        <CloudDownloadIcon style={{ marginRight: "5px" }} />
                        Download Cost
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={apiState?.uploadcost === "fetch"}
                        onClick={() => {
                            // if(userAccess?.access==='admin'){
                            if (handleAccess("Hospital", "Bulk Upload Cost") === true) {
                                handleUploadCostClick();
                            } else {
                                setAuthPop(true);
                            }
                        }}
                        style={{ marginRight: "10px", fontSize: "9px" }}
                    >
                        <CloudUploadIcon style={{ marginRight: "5px" }} />
                        Upload Cost
                    </Button>

                    <div
                        style={{
                            fontWeight: "bold",
                            color: "darkblue",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "10px",
                            borderColor: "blue",
                            borderRadius: "10px",
                        }}
                    >
                        <div>
                            Total Billing Value: Rs.
                            {calculateRevisedCost() === 0 && calculateVarianceCost() === 0
                                ? calculateTotalCost()
                                : calculateRevisedCost()}
                        </div>
                    </div>
                </div>
            </div>
            {showDatePicker && (
                <div
                    ref={datePickerRef}
                    style={{
                        position: "absolute",
                        zIndex: 9999,
                        backgroundColor: "white",
                    }}
                >
                    <DateRangePicker
                        onChange={(item) => {
                            setStartDate(item.selection.startDate);
                            setEndDate(item.selection.endDate);
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1} // Set to 1 to show only one month
                        ranges={[{ startDate, endDate, key: "selection" }]} // Add the key 'selection' for the range
                        direction="horizontal" // Show the calendar in a horizontal layout
                    />
                </div>
            )}
            {/* Table to display data */}
            {filteredTableData.length > 0 ? ( // Conditional rendering based on perViewCase and filtered data
                <Paper>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                marginLeft: "10px",
                                color: "darkblue",
                                fontSize: "15px",
                            }}
                        >
                            {historicalData}
                        </div>
                        <TextField
                            className="search-field"
                            label="Search"
                            variant="outlined"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <TableContainer
                        className="transaction-table"
                        component={Paper}
                        style={{ marginTop: "20px", paddingRight: "5px" }}
                    >
                        <Table stickyHeader>
                            <TableHead
                                style={{
                                    backgroundcolor: "#f5f5f5",
                                    fontSize: "10px !important",
                                }}
                            >
                                <TableRow>
                                    <TableCell></TableCell>

                                    <TableCell className="tb-head">Date</TableCell>
                                    <TableCell className="tb-head">Order ID</TableCell>
                                    <TableCell className="tb-head">Patient Name</TableCell>
                                    <TableCell className="tb-head">Study Name</TableCell>
                                    <TableCell className="tb-head">Image Count</TableCell>
                                    <TableCell className="tb-head">
                                        Cost(Rs. {calculateTotalCost()} )
                                    </TableCell>
                                    <TableCell className="tb-head">
                                        Var(Rs. {calculateVarianceCost()} )
                                    </TableCell>
                                    <TableCell className="tb-head">
                                        Revised Cost(Rs. {calculateRevisedCost()} )
                                    </TableCell>
                                    <TableCell className="tb-head">Edit Comments</TableCell>
                                    <TableCell className="tb-head">Update</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTableData
                                    ?.filter((data, id) => {
                                        if (
                                            data.patient_name
                                                ?.toString()
                                                .toLowerCase()
                                                .includes(searchQuery?.toString().toLowerCase()) ||
                                            data.order_id
                                                ?.toString()
                                                .toLowerCase()
                                                .includes(searchQuery?.toString().toLowerCase()) ||
                                            data.study_name
                                                ?.toString()
                                                .toLowerCase()
                                                .includes(searchQuery?.toString().toLowerCase())
                                        ) {
                                            return data;
                                        }
                                    })
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, id) => (
                                        <TableRow key={id}>
                                            <TableCell style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                {(row?.is_night_hawk === 'true') &&
                                                    <Tooltip title="Night Hawk Case" arrow>
                                                        <img style={{ width: '20px' }} src={Moon} />
                                                    </Tooltip>
                                                }
                                                {(row?.is_per_view_case === 'yes') &&
                                                    <Tooltip title="Per View Case" arrow>
                                                        <VisibilityIcon style={{ color: 'blue' }} />
                                                    </Tooltip>
                                                }
                                                {(row?.is_urgent === 'true') &&
                                                    <Tooltip title="Urgent Case" arrow>
                                                        <img style={{ width: '20px' }} src={Urgent} />
                                                    </Tooltip>
                                                }
                                                {(row?.is_pro_rad === 'true') &&
                                                    <Tooltip title="Pro Rad Case" arrow>
                                                        <img style={{ width: '20px' }} src={Premieum} />
                                                    </Tooltip>
                                                } 
                                                {row?.is_pro_insight_free !== 'true' ?
                                                    <Tooltip title="Paid Report" arrow>
                                                        <img style={{ width: '20px' }} src={PaidReport} />
                                                    </Tooltip> :
                                                    <Tooltip title="Free Premium Report" arrow>
                                                        <img style={{ width: '20px' }} src={FreePremium} />
                                                    </Tooltip>
                                                }



                                            </TableCell>

                                            <TableCell className="tb-head">
                                                {formatDate(row.study_created_time)}
                                            </TableCell>
                                            <TableCell className="tb-head">{row.order_id}</TableCell>
                                            <TableCell className="tb-head">
                                                {row.patient_name}
                                            </TableCell>
                                            <TableCell className="tb-head">
                                                {row.study_name}
                                            </TableCell>
                                            <TableCell className="tb-head">
                                                {row.image_count}
                                            </TableCell>
                                            <TableCell className="tb-head">{row.cost}</TableCell>
                                            <TableCell className="tb-head">
                                                {row.compromised_cost}
                                            </TableCell>
                                            <TableCell className="tb-head">
                                                {row.revised_cost}
                                            </TableCell>
                                            <TableCell className="tb-head">
                                                {row?.edit_comments &&
                                                    <div style={{ padding: '3px', backgroundColor: 'lightslategrey', color: 'white', borderRadius: '5px' }}>
                                                        {row?.edit_comments}
                                                    </div>
                                                }
                                            </TableCell>
                                            <TableCell className="tb-head">
                                                <Button
                                                    size="small"
                                                    className="edit-btn"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleEditClick(row)}
                                                >
                                                    Edit
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[10, 20, 100]}
                        component="div"
                        count={filteredTableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            ) : null}
            {/* Render the EditCostModal */}
            {showEditModal && (
                <EditCostModal
                    open={showEditModal}
                    onClose={() => setShowEditModal(false)}
                    rowData={selectedRowData}
                    historicalData={historicalData}
                    onUpdateCost={(newCost) => {
                        // Implement the update cost logic here
                        //console.log("Updated cost:", newCost);
                    }}
                    fetchTableData={fetchTableData}
                    email={email}
                />
            )}

            {showUploadModal && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.5)",
                        zIndex: 999,
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            background: "#fff",
                            padding: "20px",
                            borderRadius: "5px",
                            width: "400px",
                            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                    >
                        <h3>Upload Cost</h3>
                        <UploadCost
                            onFileUpload={handleFileUpload}
                            onClose={handleCloseUploadModal}
                            setErrorPop={setError}
                            email={email}
                            setApiState={setApiState}
                        />
                    </div>
                </div>
            )}

            <Dialog
                open={openApiResponseDialog}
                onClose={handleCloseApiResponseDialog}
            >
                <DialogContent style={{ width: "500px" }}>
                    <div style={{ textAlign: "center" }}>
                        <div style={{ marginTop: "20px" }}>
                            <img style={{ width: "150px" }} src={tick} />
                        </div>
                        <div style={{ marginTop: "20px", fontSize: "18px", color: "gray" }}>
                            {" "}
                            Data Inserted Successfully
                        </div>
                        <Button
                            style={{ marginTop: "20px" }}
                            variant="contained"
                            onClick={handleCloseApiResponseDialog}
                            color="primary"
                        >
                            Close
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={costRerunPop} onClose={() => setCostRerunPop("")}>
                <DialogContent style={{ width: "500px" }}>
                    <div style={{ textAlign: "center" }}>
                        <div style={{ marginTop: "20px" }}>
                            <img style={{ width: "150px" }} src={tick} />
                        </div>
                        <div style={{ marginTop: "20px", fontSize: "18px", color: "gray" }}>
                            {costRerunPop}
                        </div>
                        <Button
                            style={{ marginTop: "20px" }}
                            variant="contained"
                            onClick={() => setCostRerunPop("")}
                            color="primary"
                        >
                            Close
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={costconfirmPop}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setCostConfirmPop(false)}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Confirmation !!!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Do you want to Rerun the cost
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCostConfirmPop(false)}>Disagree</Button>
                    <Button onClick={handlecostRerun}>Agree</Button>
                </DialogActions>
            </Dialog>

            {errorPop && (
                <ErrorModel
                    data={error}
                    onClose={() => {
                        setError("");
                    }}
                />
            )}
        </div>
    );
};

export default TransactionsTab;
