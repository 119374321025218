import React,{useRef, useState} from 'react';
import Typography from '@mui/material/Typography';
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
    Switch,
    Modal,
    TextField,
    Box,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@mui/material";
import axios from 'axios';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid lightblue',
  boxShadow: 24,
  p: 4,
};

function AddVendor({open,setOpen,setAlert,setWarning,getVendors}) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const errorRef=useRef(null)
    const [apiCall,setApiCall]=useState(true)

    const [vendorData,setVendorData]=useState({
        name :'',
        registration_type:'',
        vendor_type:'',
        city:'',
        email:'',
        state:'',
        country:'',
        address:''
    })

    const handleChange = (e)=>{
        const { name, value } = e.target;
    setVendorData(prevState => ({
        ...prevState,
        [name]: value
    }));

       console.log(vendorData)
    }

    const handleSubmit=async()=>{
        if(apiCall===false){
            return 
        }
        errorRef.current.innerHTML=''
        if(vendorData?.name===''){
            errorRef.current.innerHTML='Please fill the name'
        }else if(vendorData?.registration_type===''){
            errorRef.current.innerHTML='Please fill the Registration Type'
        }
        else if(vendorData?.vendor_type==''){
            errorRef.current.innerHTML='Please fill the Vendor Type'
        }
        else if(vendorData?.city==''){
            errorRef.current.innerHTML='Please fill the City'
        }
        else if(vendorData?.state==''){
            errorRef.current.innerHTML='Please fill the state'
        }
        else if(vendorData?.country==''){
            errorRef.current.innerHTML='Please fill the country'
        }
        else{
            handleClose()
            try{
                const {REACT_APP_ADD_VENDORS} = process.env
                const res = await axios.post(REACT_APP_ADD_VENDORS,vendorData)
                if(res?.data?.detail?.length>0){
                    setAlert('Vendor Added Successfully')
                }else{
                    setWarning('Vendor Add Failed')
                }
            }catch(err){
                setWarning('Vendor Add Failed')
            }finally{
                setVendorData({
                    name :'',
                    registration_type:'',
                    vendor_type:'',
                    city:'',
                    email:'',
                    state:'',
                    country:'',
                    address:''
                })
                setApiCall(true)
                
                getVendors()
            }
        }
    }


  return (
    <div>
     
  

       
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid gray",
                                paddingBottom: "10px",
                                fontSize: "20px",
                            }}
                        >
                            <div>Add Vendors</div>
                            <AiOutlineCloseCircle
                                onClick={() => {
                                    handleClose()
                                    // handleClearAll();
                                    // setAddContactPop("");
                                }}
                                size={25}
                                style={{ color: "red", cursor: "pointer" }}
                            />
                        </div>

                        <div style={{display:'flex', flexDirection:'row'}} >
                              <div style={{display:'flex',flexDirection:'column',width:'50%'}}>

                              <TextField
                                // value={contactName}
                                name='name'
                                value={vendorData?.name}
                                onChange={handleChange}
                                style={{ marginBottom: "20px", marginTop: "20px" }}
                                id="standard-basic"
                                label="Name"
                                variant="standard"
                            />

                            <FormControl
                                style={{ marginBottom: "20px", marginTop: "20px",width:'300px',marginLeft:'0px' }}
                                sx={{ m: 1, minWidth: 110 }}
                                size="small"
                            >
                                <InputLabel id="demo-select-small-label">
                                    Registeration Type
                                </InputLabel>
                                <Select 
                                    name='registration_type'
                                    labelId="demo-select-small-label"
                                    variant='standard'
                                    id="demo-select-small"
                                    onChange={handleChange}
                                    value={vendorData?.registration_type}
                                    label="Registeration Type"
                                    // onChange={(e) => setDesignation(e.target.value)}
                                >
                                    <MenuItem value="Private Limited">Private Limited</MenuItem>
                                    <MenuItem value="Govt">Govt</MenuItem>
                                    <MenuItem value="Partnership Firm">Partnership Firm</MenuItem>
                                    <MenuItem value="Sole Proptiership">Sole Proptiership</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                </Select>
                            </FormControl>


                            <FormControl
                                style={{ marginBottom: "20px", marginTop: "20px",width:'300px',marginLeft:'0px'  }}
                                sx={{ m: 1, minWidth: 310 }}
                                size="small"
                            >
                                <InputLabel id="demo-select-small-label">
                                    Vendor Type
                                </InputLabel>
                                <Select
                                  name='vendor_type'
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={vendorData?.vendor_type}
                                    onChange={handleChange}
                                    variant='standard'
                                    label="Vendor Type"
                                    // onChange={(e) => setDesignation(e.target.value)}
                                >
                                    <MenuItem value="Regular">Regular</MenuItem>
                                    <MenuItem value="One time">One time</MenuItem>
                                
                                </Select>
                            </FormControl>

                            <TextField
                                name='email'
                                value={vendorData?.email}
                                onChange={handleChange}
                                style={{ marginBottom: "20px", marginTop: "20px" }}
                                id="standard-basic"
                                label="Email"
                                variant="standard"
                            />


                              </div>

                              <div style={{display:'flex',flexDirection:'column',width:'50%'}}>

                                        <TextField
                                         value={vendorData?.address}
                                        onChange={handleChange}
                                        name='address'
                                        style={{ marginBottom: "20px", marginTop: "20px" }}
                                        id="standard-basic"
                                        label="Address"
                                        variant="standard"
                                        />

                                        <TextField
                                        name='city'
                                        value={vendorData?.city}
                                        onChange={handleChange}
                                        style={{ marginBottom: "20px", marginTop: "20px" }}
                                        id="standard-basic"
                                        label="City"
                                        variant="standard"
                                        />


                                        <TextField
                                        name='state'
                                        value={vendorData?.state}
                                        onChange={handleChange}
                                        style={{ marginBottom: "20px", marginTop: "20px" }}
                                        id="standard-basic"
                                        label="State"
                                        variant="standard"
                                        />


                                        <TextField
                                        name='country'
                                        value={vendorData?.country}
                                        onChange={handleChange}
                                        style={{ marginBottom: "20px", marginTop: "20px" }}
                                        id="standard-basic"
                                        label="Country"
                                        variant="standard"
                                        />

                                        </div>

                              <div>

                            </div>
                        </div>

                        <div>
                            <p style={{textAlign:'center',color:'red'}} ref={errorRef}></p>
                        </div>

                        <div style={{textAlign:'center',marginTop:'20px'}}>
                            <Button onClick={()=>handleSubmit()}  variant='contained' >Add</Button>
                        </div>
          </Box>
        </Modal>
      </div>


  )
}

export default AddVendor
