import React, { useEffect, useState,useContext } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from 'axios';
import {Typography,FormControlLabel,Switch} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './RadiologistsDetails.css'
import RadTransaction from './RadTransaction';
import { CSSTransition } from 'react-transition-group';
import RadPricing from './RadPricing';
import AuthErrorPop from '../Util/AuthErrorPop';
import { UserContext } from '../../App';
import RadInvoiceDetails from './RadInvoiceDetails';

function RadiologistsDetails({isSidebarOpen,email}) {
    const navigate = useNavigate()
    const location = useLocation()
    const [value,SetValue] = useState('details')
    const pathnameParts = location.pathname.split('/');
    const radId = pathnameParts[pathnameParts.length - 2];
    const tabname = pathnameParts[pathnameParts.length - 1];
    const [data,setData] = useState('');
    const[comment,setComment] = useState('');
    const [authPop,setAuthPop]=useState(false)
    const {user} = useContext(UserContext);
    const {handleAccess} = useContext(UserContext);

    const handleChange = (event, newPath) => {
          // console.log(newPath)
          SetValue(newPath)
          navigate(`/radiologists/${radId}/${newPath}`)
      };
      useEffect(()=>{
         SetValue(tabname)
      },)

      useEffect(()=>{
         const radAPI = async()=>{
            try{
              const {REACT_APP_RAD_DETAILS_URL} =process.env
            const res = await axios.get(REACT_APP_RAD_DETAILS_URL+`${radId}`)
            // console.log(res)
            setData(res.data)
            if(res.data.inactive_reason_details!==null) {setComment(res.data.inactive_reason_details.comment )};
            }catch(err){
              console.log(err)
            }
         }

         radAPI()
      },[radId])

      const StyledTabs = styled(Tabs)(({ theme }) => ({
        '& .MuiTabs-indicator': {
          backgroundColor: theme.palette.primary.main,
        },
      }));
      
      const StyledTab = styled(Tab)(({ theme }) => ({
        '&.Mui-selected': {
          color: theme.palette.primary.main,
        },
      }));

      function TabPanel(props) {
        const { children, value, index } = props;
        return (
          <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box >{children}</Box>}
          </div>
        );
      }

     
      
      
  return (
    <CSSTransition
    in={true}
    timeout={500}
    classNames="hospital-details-container"
    appear // Add the 'appear' prop to trigger the transition on initial mount
    unmountOnExit
  >
   <div>
   
<div className='hospital-header-main'>
        <div className={`${isSidebarOpen ? 'hospital-header' : 'hospital-header-min'}`} style={{marginTop:'0px',marginLeft:'0px'}}>
          <KeyboardBackspaceIcon className="back-arrow-icon"  onClick={() =>{navigate(`/radiologists`)}} />
          <Typography sx={{fontSize:'20px'}} variant="h4">{data.rad_name}</Typography>
        </div>
        </div>
<StyledTabs className='tabs' value={value} onChange={handleChange} centered>
              <StyledTab className='tabs-content' sx={{fontSize:'12px'}}  label="Details" value='details' />
              <StyledTab sx={{fontSize:'12px'}}  label="Price" value='pricing' />
              <StyledTab sx={{fontSize:'12px'}}  label="Transactions" value='transaction' />
              <StyledTab sx={{fontSize:'12px'}}  label="Invoices" value='invoices' />
              <StyledTab sx={{fontSize:'12px'}}  label="Documents" value='document' />
            </StyledTabs>
            <TabPanel   value={value} index={'details'}>
                    {/* {data.rad_id}  */}
                    <div className='details-container'>
                    <div >
                    <ul>
                        <li className='details-li' style={{justifyContent:'center'}}>
                        <div className="hospital-label"><div>Unique ID </div>:</div>
                          <div className="hospital-value">{data.rad_uniqueid}</div>
                        </li>
                        <li className='details-li' style={{justifyContent:'center'}}>
                        <div className="hospital-label"><div>Email </div>:</div>
                          <div className="hospital-value">{data.rad_email}</div>
                        </li>
                        <li className='details-li' style={{justifyContent:'center'}}>
                        <div className="hospital-label"><div>Joining Date </div>:</div>
                          <div className="hospital-value">{data.rad_onboarding_date}</div>
                        </li>
                        <li className='details-li' style={{justifyContent:'center'}}>
                        <div className="hospital-label"><div>Designation </div>:</div>
                          <div className="hospital-value">{data.rad_deisgnation}</div>
                        </li>
                        <li className='details-li' style={{justifyContent:'center'}}>
                        <div className="hospital-label"><div>Phone Number </div>:</div>
                          <div className="hospital-value">{data.rad_phone}</div>
                        </li>
                        <li className='details-li' style={{justifyContent:'center'}}>
                        <div className="hospital-label"><div>Register Number </div>:</div>
                          <div className="hospital-value">{data.rad_regno}</div>
                        </li>
                        <li className='details-li' style={{justifyContent:'center'}}>
                        <div className="hospital-label"><div>Type</div>:</div>
                          <div className="hospital-value">{data.rad_type}</div>
                        </li>
                        <li className='details-li' style={{justifyContent:'center'}}>
                        <div className="hospital-label"><div>Status</div>:</div>
                          <div className="hospital-value">{data.rad_status}</div>
                        </li>
                        <li className='details-li' style={{justifyContent:'center'}}>
                        <div className="hospital-label"><div>SaaS_Only</div>:</div>
                          <div className="hospital-value">{data.rad_is_irad ?'IRAD':'Regular'}</div>
                        </li>
                        <li className='details-li' style={{justifyContent:'center'}}>
                        <div className="hospital-label"><div>Grade</div>:</div>
                          <div className="hospital-value">{data?.grade}</div>
                        </li>
                    </ul>
                    </div>
                    <div style={{width:'calc(100vh - 100px)',marginLeft:'30px'}} >

                    
    <div className="hospital-toggle-label" style={{width:'calc(100% - 10%)',padding:'10px'}}   >
        Night Hawk
        <FormControlLabel 
          control={
            <Switch size="small" color="primary" checked={data.night_hawk} disabled />
          }
          label={data.night_hawk ? 'Enabled' : 'Disabled'}
          labelPlacement="start"
        />
      </div>
                         
    <div className="hospital-toggle-label" style={{width:'calc(100% - 10%)',padding:'10px'}}   >
        Pre Read
        <FormControlLabel 
          control={
            <Switch size="small" color="primary" checked={data.is_pr_enabled} disabled />
          }
          label={data.is_pr_enabled ? 'Enabled' : 'Disabled'}
          labelPlacement="start"
        />
      </div>
      <div className="hospital-toggle-label" style={{width:'calc(100% - 10%)',padding:'10px'}}   >
        OQC Edit
        <FormControlLabel 
          control={
            <Switch size="small" color="primary" checked={data.enable_oqc_edit} disabled />
          }
          label={data.enable_oqc_edit ? 'Enabled' : 'Disabled'}
          labelPlacement="start"
        />
      </div>
      <div className="hospital-toggle-label" style={{width:'calc(100% - 10%)',padding:'10px'}}   >
        Reporting
        <FormControlLabel 
          control={
            <Switch size="small" color="primary" checked={data.is_reporting_allowed} disabled />
          }
          label={data.is_reporting_allowed ? 'Enabled' : 'Disabled'}
          labelPlacement="start"
        />
      </div>
      <div className="hospital-toggle-label" style={{width:'calc(100% - 10%)',padding:'10px'}}   >
        Account Status  
        <div className='status-label'>{(data.inactive_reason!==null)?'InActive':'Active'}</div>
      </div>
      <div className="details-li" style={{width:'calc(100% - 10%)',padding:'10px',marginTop:'10px',color:'#02098d',fontWeight:'bold',display:'flex',justifyContent:'space-between'}}   >
       <div> Reason For Inactive </div>  
        <div className='status-label1'>{comment}</div>
      </div>
      </div>
      </div>
            </TabPanel>
            <TabPanel   value={value} index={'pricing'}>
                    <RadPricing radEmail={data.rad_email} radName={data.rad_name} status={data.rad_status} userAccess={user} handleAccess={handleAccess} setAuthPop={setAuthPop} />
            </TabPanel>
            <TabPanel   value={value} index={'transaction'} >
                  <RadTransaction radUniqueid={data.rad_uniqueid} radId={data.rad_id} userAccess={user}  handleAccess={handleAccess} email={email} radName={data?.rad_name} setAuthPop={setAuthPop} />
            </TabPanel>
            <TabPanel   value={value} index={'invoices'}>
                    <RadInvoiceDetails radId={data.rad_id} radName={data.rad_name}  handleAccess={handleAccess} email={email}  setAuthPop={setAuthPop}   />
                
            </TabPanel>
            <TabPanel   value={value} index={'document'} userAccess={user} setAuthPop={setAuthPop}>
                    Documents    
            </TabPanel>
            <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop}  />
   </div>
   </CSSTransition>
  )
}

export default RadiologistsDetails