// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-main{
    margin-top: 30px !important;
    margin-right: 10px;
}

/* 
.tablehead > TableCell{

  color: blue !important;
} */

.css-15wwp11-MuiTableHead-root {
    /* z-index: 2 !important; */
    position: sticky;
}

.css-y8ay40-MuiTableCell-root{
   background-color: #0059B2 !important;
}  

.btn1{
    font-size: 10px !important;
}

.table-size{
    font-size: 12px !important;
    padding: 10px 5px !important;
    width: 200px !important;
 
}

.table-main td{
    padding: 5px 5px;
    font-size: 11px !important;
    text-align: center;
}

.table-main th{
    padding: 5px 5px;
    font-size: 11px !important;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Hospital/InvoiceTable.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;;;;GAIG;;AAEH;IACI,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;GACG,oCAAoC;AACvC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;IAC5B,uBAAuB;;AAE3B;;AAEA;IACI,gBAAgB;IAChB,0BAA0B;IAC1B,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,0BAA0B;IAC1B,kBAAkB;AACtB","sourcesContent":[".table-main{\n    margin-top: 30px !important;\n    margin-right: 10px;\n}\n\n/* \n.tablehead > TableCell{\n\n  color: blue !important;\n} */\n\n.css-15wwp11-MuiTableHead-root {\n    /* z-index: 2 !important; */\n    position: sticky;\n}\n\n.css-y8ay40-MuiTableCell-root{\n   background-color: #0059B2 !important;\n}  \n\n.btn1{\n    font-size: 10px !important;\n}\n\n.table-size{\n    font-size: 12px !important;\n    padding: 10px 5px !important;\n    width: 200px !important;\n \n}\n\n.table-main td{\n    padding: 5px 5px;\n    font-size: 11px !important;\n    text-align: center;\n}\n\n.table-main th{\n    padding: 5px 5px;\n    font-size: 11px !important;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
