import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { FaFileDownload } from "react-icons/fa";


export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "lightgreen" : "#308fe8",
  },
}));

export const RedLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light" ? "rgb(239 68 68)" : "rgb(239 68 68)",
  },
}));

function GmStudyOverall({ gmData,handleDownload }) {
  const [gmActualData, setGmActualData] = useState([]);

  useEffect(() => {
    const result = gmData?.reduce((acc, current) => {
      const groupName = current.grouped_study_name;

      // Initialize values for the group if not present
      if (!acc[groupName]) {
        acc[groupName] = {
          revenue: 0,
          payable: 0,
          No_of_report: 0,
        };
      }

      // Update values for the group
      acc[groupName].revenue += current.Revenue;
      acc[groupName].payable += current["Rad Payable"];
      acc[groupName].No_of_report += current["No_of_report"];

      return acc;
    }, {});

    //console.log(result, "result");
    //   Convert the result into an array of objects
    if (result) {
      const resultArray = Object?.entries(result)?.map(
        ([grouped_fields, { revenue, payable, No_of_report }]) => {
          const [grouped_study_name, date] = grouped_fields.split("to");
          return {
            grouped_study_name,
            date,
            revenue,
            payable,
            No_of_report,
          };
        }
      );
      setGmActualData(resultArray);
    }
  }, [gmData]);
  const handleGm = (reve, paya) => {
    const data = ((reve - paya) / reve) * 100;
    return parseFloat(data).toFixed(2);
  };

  const averageReport = (count, revenue) => {
    const data = revenue / count;
    return parseInt(data);
  };

  const averageCost = (count, payable) => {
    const data = payable / count;
    return parseInt(data);
  };

  const handleSendDownload=(data,filename)=>{
      const data1= data?.map(data=>{
        return{
          'Grouped Study Name':data?.grouped_study_name,
          'No of Reports':data?.No_of_report,
          'Revenue':data?.revenue,
          'Payable':data?.payable,
          'Gm':`${handleGm(data?.revenue, data.payable)}%` ,
          'Average Revenue per Report':averageReport(data?.No_of_report, data?.revenue),
          'Average Cost per Report':averageCost(data?.No_of_report, data?.payable)
        }
      })

      handleDownload(data1,filename)
  }

  return (
    <div
      style={{
        backgroundColor: "transparent",
        borderRadius: "20px",
        padding: "20px",
      }}
    >
      <div style={{ fontSize: "25px", color: "#1B2559",display:'flex',justifyContent:"space-between" }}>
        <div>GM Overall</div>
        <div><FaFileDownload onClick={()=>handleSendDownload(gmActualData,'Gm_by_modstudy')} color="blue" style={{cursor:'pointer'}} /></div>
        </div>
      <div style={{ marginTop: "20px" }}>
        <div className="gm-revenue-head">
          <div>Grouped Study Name</div>
          <div>No of Reports</div>
          <div>Revenue</div>
          <div>Payable</div>
          <div>GM</div>
          <div>Average Revenue per Report</div>
          <div>Average Cost per Report</div>
        </div>
        <div
          className="table-revenue-table"
          style={{ height: "700px", overflowY: "scroll" }}
        >
          {gmActualData
            ?.sort((a, b) => b.revenue - a.revenue)
            .map((data, id) => (
              <div key={id} className="gm-revenue-body">
                <div>{data.grouped_study_name}</div>
                <div>{data?.No_of_report}</div>
                <div>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(data?.revenue)}
                </div>
                <div>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(data.payable)}
                </div>
                <div style={{ textAlign: "end" }}>
                  {handleGm(data?.revenue, data.payable) > 0 ? (
                    <div className="table-revenue-body-gm-postive">
                      {`${handleGm(data?.revenue, data.payable)}%`}
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          handleGm(data?.revenue, data.payable) > 100
                            ? 100
                            : handleGm(data?.revenue, data.payable)
                        }
                      />
                    </div>
                  ) : (
                    <div className="table-revenue-body-gm-negative">
                      {`${handleGm(data?.revenue, data.payable)}%`}
                      <RedLinearProgress
                        variant="determinate"
                        value={
                          Math.abs(handleGm(data?.revenue, data.payable)) > 100
                            ? 100
                            : Math.abs(handleGm(data?.revenue, data.payable))
                        }
                      />
                    </div>
                  )}
                </div>
                <div>{averageReport(data?.No_of_report, data?.revenue)}</div>
                <div>{averageCost(data?.No_of_report, data?.payable)}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default GmStudyOverall;
