import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
// import DatePicker from "react-datepicker";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  widdiv: 800,
  bgcolor: "background.paper",
  border: "1px solid lightblue",
  boxShadow: 24,
  borderRadius: "25px",
  p: 4,
  //   height:600
  minHeight: 600,
};

function RadInvoice({ data, onClose,email,handleGenerateApi }) {
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [mapData, setMapDate] = useState([]);
  const [SelectedRads, setSelectedRads] = useState([]);
  const [search, setSearch] = useState("");
  // //console.log(data)

  const handleInvoiceDateChange = (date) => {
    setInvoiceDate(date);
  };

  useEffect(() => {
    setMapDate(data?.mapData);
  }, [data?.mapData]);

  const handleSelectAllClick = (isChecked) => {
    if (isChecked) {
      // If dive checkbox is checked, select all visible clients in dive table
      const visibleClientIds = mapData?.map((item) => item.rad_id);
      setSelectedRads(visibleClientIds);
    } else {
      // If dive checkbox is unchecked, clear all selected clients
      setSelectedRads([]);
    }
  };

  const handleRowSelect = (clientId) => {
    setSelectedRads((prevSelectedRads) =>
      prevSelectedRads.includes(clientId)
        ? prevSelectedRads.filter((id) => id !== clientId)
        : [...prevSelectedRads, clientId]
    );
  };

  const handleSendButtonClick = (invoice) => {
    if (!invoiceDate) {
      alert("Please select an Invoice date.");
      return;
    }

    // Check if at least one client is selected
    if (SelectedRads.length === 0) {
      alert("Please select at least one client.");
      return;
    }
    



    const jsonData = generateJSONData();
    //console.log(jsonData)
    handleGenerateApi(jsonData)
    onClose()
    setInvoiceDate('')
    setSearch('')
    setSelectedRads([])

    // if (invoice === "Whatsapp") {
    //   sendWhatappInvoicesAPI(jsonData);
    // } else if (invoice === "Email") {
    //   sendInvoicesAPI(jsonData);
    // }
    // setIsModalOpen(false);
  };

  const funDate = (date1) => {
    const date = new Date(date1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const generateJSONData = () => {
    const timeZone = "Asia/Kolkata";

    //console.log("invoice", email);
    const selectedData = mapData.filter((item) =>
      SelectedRads.includes(item.rad_id)
    );

    const jsonData = selectedData.map((item) => ({
        radfk: item.rad_id,
        startDate: funDate(data?.selectedRange?.startDate),
        endDate: funDate(data?.selectedRange?.endDate),
        invoiceDate: invoiceDate ? funDate(invoiceDate) : null,
        user_email: email,
      }));
    
    //console.log(jsonData);
    return jsonData;
  };

  return (
    <div>
      <Modal
        open={data}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid gray",
              paddingBottom: "10px",
            }}
          >
            <Typography
              style={{}}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Radiologist Invoice
            </Typography>
            <AiOutlineCloseCircle
              onClick={() => onClose()}
              size={25}
              style={{ color: "red", cursor: "pointer" }}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <p style={{ display: "flex", justifyContent: "space-between" }}>
              <div> Selected Date Range </div>
              <div>
                <span
                  style={{
                    backgroundColor: "wheat",
                    padding: "10px",
                    marginRight: "10px",
                    borderRadius: "20PX",
                  }}
                >
                  {data?.selectedRange?.startDate?.toLocaleDateString()}{" "}
                </span>{" "}
                to{" "}
                <span
                  style={{
                    backgroundColor: "wheat",
                    padding: "10px",
                    marginLeft: "10px",
                    borderRadius: "20PX",
                  }}
                >
                  {data?.selectedRange?.endDate?.toLocaleDateString()}
                </span>
              </div>
            </p>

            <p
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <div>Selected Rad Grade</div>
              <div
                style={{
                  marginRight: "10px",
                  color: "darkgray",
                  padding: "10px",
                  borderRadius: "20PX",
                }}
              >
                {data?.selectedGrade}
              </div>
            </p>
          </div>
          <div>
            <p className="invoice-date">Invoice Date:</p>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
           
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker label="Invoice Date"  selected={invoiceDate}
                onChange={handleInvoiceDateChange}
                // sx={{height:30}}
                // style={{ zIndex: 9999 }}
                format="YYYY-MM-DD" />
                </DemoContainer>
              </LocalizationProvider>

              <div>
                <TextField
                  className="search-div"
                  style={{ marginTop: "7px" }}
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="table-container">
            <div className="rad-invoice-table">
              {/* <div  > */}
              <div
                className="rad-invoice-table-head"
                style={{ borderRadius: "10px" }}
              >
                <div>
                  {/* Select All Checkbox */}
                  <input
                    type="checkbox"
                    checked={SelectedRads?.length === mapData?.length}
                    onChange={(e) => handleSelectAllClick(e.target.checked)}
                  />
                </div>
                <div>Rad ID</div>
                <div>Rad Name</div>
                <div>Grade</div>
              </div>
              {/* </divead> */}

              {mapData
                ?.filter((data) => {
                  if (search === "") {
                    return true;
                  } else if (
                    data?.rad_name
                      .toString()
                      .toLowerCase()
                      .includes(search.toString().toLowerCase())
                  ) {
                    return true;
                  }
                })
                .map((item) => (
                  <div key={item.rad_id} className="rad-invoice-table-body">
                    <td>
                      <input
                        type="checkbox"
                        checked={SelectedRads.includes(item.rad_id)}
                        onChange={() => handleRowSelect(item.rad_id)}
                      />
                    </td>
                    <td>{item.rad_id}</td>
                    <td>{item.rad_name}</td>
                    <td>{item.grade}</td>
                  </div>
                ))}
            </div>
          </div>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <Button
                onClick={() => handleSendButtonClick()}
              style={{
                fontSize: "11px",
                marginLeft: "10px",
                padding: "20px",
                borderRadius: "30px",
                widdiv: "200px",
              }}
              variant="contained"
              size="small"
              color="primary"
              component="span"
            >
              Generate Invoice
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default RadInvoice;
