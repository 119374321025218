import React, { useState, useRef, useEffect, useMemo } from 'react'
import './RadTransaction.css'
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,TextField } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ErrorModel } from '../Hospital/TransactionsTab';
import * as XLSX from 'xlsx';
import AuthErrorPop from '../Util/AuthErrorPop';
import RadEditCost from './RadEditCost';
import AlertDialog from '../Hospital/AlertDialog';
import UploadRadCost from './UploadRadCost';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import axios from 'axios';
import tick from '../../Images/tick.gif'



const RadTransaction = ({ radId, userAccess, handleAccess, email, radName,radUniqueid }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [currentFromDate, setCurrentFromDate] = useState('');
  const [currentToDate, setCurrentToDate] = useState('');
  const [payableData, setPayableData] = useState([]);
  const [totalpayableData, setTotalPayableData] = useState('');
  const calendarRef = useRef(null);
  const [showTable, setShowTable] = useState(false)
  const [initialCall, setInitialCall] = useState(false)
  const [authPop, setAuthPop] = useState(false)
  const [edit, setEdit] = useState('')
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [alertOpen, setAlertOpen] = useState('')
  const [error, setError] = useState('')
  const [bulkUpload, setBulkUpload] = useState('')
  const [alert,setAlert]=useState('')
  const [historicalData,setHistoricalData]=useState('')
  const [searchQuery,setSearchQuery]=useState('')



  const formatDate = (date1) => {
    const date = new Date(date1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  };

  const handleDateChange = (ranges) => {
    setSelectedRange([ranges.selection]);
    setCurrentFromDate(ranges.selection.startDate);
    setCurrentToDate(ranges.selection.endDate);
  };

  useEffect(() => {
    setCurrentFromDate(selectedRange[0].startDate);
    setCurrentToDate(selectedRange[0].endDate);
    setInitialCall(true)
  }, [])

  useEffect(() => {

    if (currentFromDate && initialCall) {

      // console.log(id,)
      radPayable()
      setInitialCall(false)
      // console.log(id,initialCall)
    }
  }, [initialCall])

  const handleOutsideClick = (event) => {
    // Check if the click is outside the date range picker
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      // setIsCalendarOpen(false); // Close the date range picker
      setIsCalendarOpen((prevIsCalendarOpen) => !prevIsCalendarOpen);
    }
  };


  const handleClearSelection = () => {
    setSelectedRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },

    ]);
    setCurrentFromDate(new Date());
    setCurrentToDate(new Date());
    setShowTable(false)
    setPayableData([])
    setTotalPayableData('')
  };

  const handleSelectClick = () => {
    setIsCalendarOpen(false); // Close the calendar
  };


  const handleDownload = () => {
    const data = payableData.map(row => {
      return {
        order_id: row.order_id,
        report_fk: row.report_fk,
        date: row.date,
        patient_name: row.patient_name,
        study_name: row.study_name,
        revised_cost: row.rad_payable,
        reason: ''
      }
    })
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Cost_sheet');
    XLSX.writeFile(wb, `${radName}-${formatDate(currentFromDate)}to${formatDate(currentToDate)}.xlsx`);
  }

  // useEffect(()=>{
  const radPayable = async () => {
    // setShowTable(false)
    //console.log(currentFromDate)
    const start_date = formatDate(currentFromDate);
    const end_date = formatDate(currentToDate);
    const { REACT_APP_RAD_PAYABLE_URL, REACT_APP_RAD_PAYABLE_TOKEN,REACT_APP_GET_HISTORICAL_RAD_URL } = process.env
    let  apiUrl;
    if (start_date >= "2023-07-01") {
      setHistoricalData('')
     apiUrl = REACT_APP_RAD_PAYABLE_URL + `?rad_fk=${radId}&start_date=${start_date}&end_date=${end_date}`;
    }else{
      setHistoricalData('Historical Data')
       apiUrl = REACT_APP_GET_HISTORICAL_RAD_URL + `rad_fk=${radUniqueid}&start_date=${start_date}&end_date=${end_date}`;
    }
    const headers = {
      Authorization: `Bearer ${REACT_APP_RAD_PAYABLE_TOKEN}`,
      // Add other headers if needed
    };
    fetch(apiUrl, { headers })
      .then(response => response.json())
      .then(data => {
        //console.log(data.radpayment)
        if (data.radpayment) {
          setPayableData(data.radpayment)
          setTotalPayableData(data.total_rad_payable)
          if (data.radpayment.length === 0) {
            //console.log(initialCall)
            if (!initialCall)
              setError("No Transaction Data")
          }
        }
        //console.log(data);
        //console.log(data)
      })
      .catch(error => {
        // Handle errors
        // setError('Api Error')
        console.error('Error:', error);
      })

  }



  const handleEdit = (data, newCost, reason) => {
    //console.log(reason)
    setAlertOpen({
      data: data,
      cost: newCost,
      reason: reason
    })
  }

  const handleEditAPI = async (data) => {

    try {
      //console.log(data)
      const { REACT_APP_RAD_PAYABLE_EDIT_URL } = process.env
      const res = await axios.post(REACT_APP_RAD_PAYABLE_EDIT_URL, data)
      // console.log(res)
      if (res.status === 200) {
         setAlert(true)
      }else{
        setError('Data Insertion Failed')
      }

    } catch (err) {
      console.log(err)
    }finally{
      setAlertOpen('')
      radPayable()
    }

  }


  const downloadTransaction = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(payableData);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${radName}-transaction-${formatDate(currentFromDate)}to${formatDate(currentToDate)}.xlsx`);
  }


  const calculateCost = () => {
    if (!Array.isArray(payableData) || payableData.length === 0) {
      return 0;
    }
    const total = payableData.reduce((total, row) => total + row.rad_payable, 0);
    if (total > 0) return total;
    else return 0;
  };

  const calculateVariantCost = () => {
    if (!Array.isArray(payableData) || payableData.length === 0) {
      return 0;
    }
    const total = payableData.reduce((total, row) => total + row.compromised_rad_pay, 0);
    if (total > 0) return total;
    else return 0;
  };

  const calculateRevisedCost = () => {
    if (!Array.isArray(payableData) || payableData.length === 0) {
      return 0;
    }
    const total = payableData.reduce((total, row) => total + row.revised_rad_pay, 0);
    if (total > 0) return total;
    else return 0;
  };

  const dateBodyTemplate = (rowData) => {
    const formattedDate = new Date(rowData).toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
    const datePart = formattedDate.slice(0, -8);
    const timePart = formattedDate.slice(-8);
    return (
      <div>
        <div className="date-part">{datePart} </div>
        <div className="time-part">{timePart}</div>
      </div>
    );
  };



  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  return (
    <div className='rad-transaction-main'>
      <div className='transaction-content'>
        <Button size="small" variant="contained" className='btn-effect' sx={{ fontSize: '12px !important' }}
          // className={`date-field ${isCalendarOpen ? 'active' : ''}`}
          onClick={() => setIsCalendarOpen((prev => !prev))}
        >
          {selectedRange[0].startDate ? (<div>
            {formatDate(currentFromDate)} -  {formatDate(currentToDate)}
          </div>
          ) : 'Date Range'}
        </Button>

        {isCalendarOpen && (
          <div className="date-range-wrapper1">
            <div className="date-range-container1" style={{ marginTop: '40px', zIndex: '5', marginLeft: '-100px' }} ref={calendarRef}>
              <DateRangePicker
                ranges={selectedRange}
                onChange={handleDateChange}
              />

              <div className='select-btn-div'>
                <Button size="small" variant="contained" className='clear-btn' onClick={handleClearSelection}>
                  Clear Date Selection
                </Button>
                <Button size="small" variant="contained" className='select-btn' onClick={handleSelectClick}>
                  Select
                </Button>
              </div>
            </div>
          </div>
        )}

        <Button size="small" variant="contained" className='search-btn' onClick={() => {
          setShowTable(true)
          //console.log(!currentFromDate)
          if (!currentFromDate) {
            setError('Please Select the Date')
          }
          else {
            radPayable()
          }
        }
        }>
          Search
        </Button>
        <Button size="small" variant="contained" className='search-btn' onClick={() => {
          // if(userAccess?.access==='admin'){
          if (handleAccess('Radiologist', 'Download Transaction') === true) {
            if (payableData.length > 0) {
              downloadTransaction()
            } else {
              setError('No transaction Data')
            }
          } else {
            setAuthPop(true)
          }
        }}>
          Download
        </Button>

        <Button variant="contained" size='small' color="secondary" onClick={
          () => {
            // if(userAccess?.access==='admin'){
            if (handleAccess('Radiologist', 'Download Cost') === true) {
              handleDownload()
            } else {
              setAuthPop(true)
            }
          }
        } style={{ fontSize: '11px', margin: '0px 10px' }}>
          <CloudDownloadIcon style={{ marginRight: '5px' }} />
          Download Cost
        </Button>

        <Button style={{ fontSize: '11px', margin: '0px 10px' }} size="small" variant="contained" color='secondary' onClick={() => {
         if (handleAccess('Radiologist', 'Upload Cost')) {
            setBulkUpload(true)
          } else {
            setAuthPop(true)
          }

         }
          } >
          <CloudUploadIcon style={{ marginRight: '5px' }} />
          Upload Cost
        </Button>

        <div className='total-payable'>
          Total Payable : Rs. {totalpayableData ? Math.round(totalpayableData) : '0'}
        </div>

      </div>
      {(payableData.length > 0) ?
      <Paper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: "10px",
            color: "darkblue",
            fontSize: "15px",
          }}
        >
          {historicalData}
        </div>
        <TextField
          className="search-field"
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
        <div style={{ marginRight: '20px' }}>
          <TableContainer className='rad-transaction-table' component={Paper} style={{ height: 'calc(100vh - 280px)', marginTop: '20px', marginLeft: '0px' }}>
            <Table stickyHeader>
              <TableHead style={{ backgroundcolor: '#f5f5f5', fontSize: '10px !important' }}>
                <TableRow >
                  <TableCell className='tb-head'>Date</TableCell>
                  <TableCell className='tb-head'>Order ID</TableCell>
                  <TableCell className='tb-head'>Patient Name</TableCell>
                  <TableCell className='tb-head'>Study Name</TableCell>
                  <TableCell className='tb-head'>Cost(Rs. {Math.round(calculateCost())}{/* {calculateTotalCost()} */} )</TableCell>
                  { historicalData!=='Historical Data' && <>
                  <TableCell className='tb-head'>Variance Cost(Rs. {Math.round(calculateVariantCost())}{/* {calculateTotalCost()} */} )</TableCell>
                  <TableCell className='tb-head'>Revised Cost(Rs. {Math.round(calculateRevisedCost())} )</TableCell>
                  <TableCell className='tb-head'>Edit_Comments</TableCell>
                  <TableCell className='tb-head'>Edit</TableCell>
                  </>}

                </TableRow>
              </TableHead>
              <TableBody>
                {payableData?.filter((data, id) => {
                    if (
                      data.patient_name
                        ?.toString()
                        .toLowerCase()
                        .includes(searchQuery?.toString().toLowerCase()) ||
                      data.order_id
                        ?.toString()
                        .toLowerCase()
                        .includes(searchQuery?.toString().toLowerCase()) ||
                      data.study_name
                        ?.toString()
                        .toLowerCase()
                        .includes(searchQuery?.toString().toLowerCase())
                    ) {
                      return data;
                    }
                  })?.map((row, id) => (
                  <TableRow key={id}>
                    <TableCell className='tb-head'>{dateBodyTemplate(row.date)}</TableCell>
                    <TableCell className='tb-head'>{row.order_id}</TableCell>
                    <TableCell className='tb-head'>{row.patient_name}</TableCell>
                    <TableCell className='tb-head'>{row.study_name}</TableCell>
                    <TableCell className='tb-head'>{Math.round(row.rad_payable)}</TableCell>
                    { historicalData!=='Historical Data' && <>
                    <TableCell className='tb-head'>{Math.round(row.compromised_rad_pay)}</TableCell>
                    <TableCell className='tb-head'>{Math.round(row.revised_rad_pay)}</TableCell>
                    <TableCell className="tb-head">
                      {row?.edit_comments &&
                        <div style={{padding:'3px',backgroundColor:'lightslategrey',color:'white',borderRadius:'5px'}}>
                         {row?.edit_comments}
                        </div>
}
                      </TableCell>
                    <TableCell className='tb-head'>
                      <Button variant='contained' size='small' onClick={
                        () => {
                          if (handleAccess('Radiologist', 'Manual Edit') === true) {
                            setEdit(row)
                          } else {
                            setAuthPop(true)
                          }
                        }
                        } >Edit</Button>
                    </TableCell>

                    </>   }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        </Paper>
         : null}

      <ErrorModel data={error} onClose={() => setError('')} />
      <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop} />
      <RadEditCost data={edit} onClose={() => setEdit('')} handleEdit={handleEdit} />
      {bulkUpload && (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: '#fff', padding: '20px', borderRadius: '5px', width: '400px', boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)' }}>
            <h3>Upload Cost</h3>
            <UploadRadCost onFileUpload={handleEditAPI} onClose={() => setBulkUpload('')} email={email} setErrorPop={setError} />
          </div>
        </div>
      )}


      <Dialog open={alert} onClose={()=>setAlert('')}>

        <DialogContent style={{ width: '500px', }}>

          <div style={{ textAlign: 'center' }}>
            <div style={{ marginTop: '20px' }}><img style={{ width: '150px' }} src={tick} /></div>
            <div style={{ marginTop: '20px', fontSize: '18px', color: 'gray' }}> Data Inserted Successfully</div>
            <Button style={{ marginTop: '20px' }} variant='contained' onClick={()=>setAlert('')} color="primary">
              Close
            </Button>
          </div>
        </DialogContent>

      </Dialog>


      <Dialog
        open={alertOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setAlertOpen('')}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <div>
          <DialogTitle>Confirmation !!!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Do you want to update the <b>{alertOpen?.data?.order_id}</b> cost of <b>{alertOpen?.cost}</b> with the following reason <b>"{alertOpen?.reason}"</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAlertOpen('')}>Disagree</Button>
            <Button onClick={() => {
              handleEditAPI([{
                "report_fk": alertOpen?.data?.report_fk,
                "revised_cost": parseInt(alertOpen?.cost),
                "by_user_id": email,
                "edit_type": "Edit by Manual",
                "reason": alertOpen?.reason
              }])
              setAlertOpen('')
            }}>Agree</Button>
          </DialogActions>
        </div>
      </Dialog>

    </div>
  )
}

export default RadTransaction