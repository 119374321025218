import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Link,
} from "@react-pdf/renderer";
import logo from "../../Images/logo.png";
import { Add } from "@material-ui/icons";
import { width } from "@mui/system";

import { useEffect } from "react";
import customFont from "../../fonts/CALIBRIZ.TTF";
import numberToWords from "number-to-words";
Font.register({
  family: "arial",
  src: customFont,
});

const styles = StyleSheet.create({
  tax: {
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "13px",
    marginTop: "2px",
    fontWeight: "bold",
  },
  page: {
    padding: 20,
    fontSize: "11px",
    fontWeight: 700,
    width: "400mm",
  },
  logo: {
    width: 60,
    height: 60,
    marginLeft: "auto",
    marginRight: "auto",
  },
  table: {
    marginTop: 10,
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
  },
  total: {
    flexDirection: "row",
    padding: "10px",
    display: "flex",
  },
  head1: {
    margin: "-0.5px",
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "grey",
    fontSize: "10px",
  },
  head2: {
    margin: "-0.5px",
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "grey",
    fontSize: "10px",
  },
  head3: {
    margin: "-0.5px",
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "grey",
    fontSize: "10px",
  },
  head4: {
    margin: "-0.5px",
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "grey",
    fontSize: "10px",
  },
  head5: {
    margin: "-0.5px",
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "grey",
    fontSize: "10px",
  },
  head6: {
    margin: "-0.5px",
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "grey",
    fontSize: "10px",
  },
  head7: {
    margin: "-0.5px",
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "grey",
    fontSize: "10px",
  },
  head8: {
    margin: "-0.5px",
    width: "13%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "grey",
    fontSize: "10px",
  },
  head9: {
    margin: "-0.5px",
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "grey",
    fontSize: "10px",
  },
  head10: {
    margin: "-0.5px",
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "grey",
    fontSize: "10px",
  },
  head11: {
    margin: "-0.5px",
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    backgroundColor: "grey",
    fontSize: "10px",
  },
  cell1: {
    margin: "-0.5px",
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,

    fontSize: "8px",
    color: "grey",
  },
  cell2: {
    margin: "-0.5px",
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    fontSize: "8px",
    color: "grey",
  },
  cell3: {
    margin: "-0.5px",
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    fontSize: "8px",
    color: "grey",
  },
  cell4: {
    margin: "-0.5px",
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    fontSize: "8px",
    color: "grey",
  },
  cell5: {
    margin: "-0.5px",
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    fontSize: "8px",
    color: "grey",
  },
  cell6: {
    margin: "-0.5px",
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    fontSize: "8px",
    color: "grey",
  },
  cell7: {
    margin: "-0.5px",
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    wordBreak: "break-all",
    padding: 5,
    fontSize: "8px",
    color: "grey",
  },
  cell8: {
    margin: "-0.5px",
    width: "13%",

    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    fontSize: "8px",
    color: "grey",
  },
  cell9: {
    margin: "-0.5px",
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    fontSize: "8px",
    color: "grey",
  },
  cell10: {
    margin: "-0.5px",
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    fontSize: "8px",
    color: "grey",
  },
  cell11: {
    margin: "-0.5px",
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    fontSize: "8px",
    color: "grey",
  },

  mainAdd: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  date: {
    display: "flex",
    flexDirection: "row",
  },
  cont1: {
    marginTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  content: {
    textAlign: "center",
    fontFamily: "arial",
    marginTop: "30px",
  },
  contentBottom: {
    textAlign: "center",
    fontFamily: "arial",
    marginTop: "30px",
    color: "grey",
  },
  textCon: {
    fontWeight: 700,
  },
});

const MyDocument = ({ user, name, data, startDate, endDate }) => {
  const [user1, setUser] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [startdate, setstartDate] = React.useState("");
  const [enddate, setendDate] = React.useState("");
  useEffect(() => {
    const fun1 = (inputDate) => {
      const parsedDate = new Date(inputDate);
      const options = { year: "numeric", month: "long", day: "numeric" };
      const formattedDate = parsedDate.toLocaleDateString("en-US", options);
      return formattedDate;
    };

    setstartDate(fun1(startDate));
    setendDate(fun1(endDate));

    //console.log(startDate);
    //console.log(data);

    setUser(JSON.parse(user));
    //console.log(user1);
  }, [user]);

  return (
    <Document>
      <Page size="A3" style={styles.page}>
        <Image style={styles.logo} src={logo}></Image>
        <View style={styles.tax}>
          {" "}
          <Text>Tax Invoices</Text>
        </View>
        <View style={styles.mainAdd}>
          <View style={styles.add}>
            {" "}
            <Text>To,</Text>
            <Text>{name},</Text>
            <Text
              style={{
                width: "200px",
                paddingLeft: "2px",
                paddingBottom: "10px",
                marginTop: "1px",
              }}
            >
              {data.Address}
            </Text>
            <Text></Text>
          </View>
          <View style={styles.date}>
            <View>
              <Text> Date:</Text>
              <Text>Invoice No:</Text>
            </View>
            <View style={{ paddingLeft: "3px" }}>
              <Text>{data.invoice_date}</Text>
              <Text>{data.invoice_num}</Text>
            </View>
          </View>
        </View>
        <View>
          <Text style={{ padding: "2px" }}>
            Sub: Invoice of 5C Network Teleradiology | SAC/HSN Code - 999321
          </Text>
          <Text style={{ padding: "2px" }}>
            Transaction Details From {startdate} to {enddate}{" "}
          </Text>
        </View>
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={styles.head1}>
              <Text>Order Id</Text>
            </View>
            <View style={styles.head2}>
              <Text>Patient Id</Text>
            </View>
            <View style={styles.head3}>
              <Text>Patient Name</Text>
            </View>
            <View style={styles.head4}>
              <Text>Radiolgist Name</Text>
            </View>
            <View style={styles.head5}>
              <Text>Modality</Text>
            </View>
            <View style={styles.head6}>
              <Text>Study</Text>
            </View>
            <View style={styles.head7}>
              <Text>Date</Text>
            </View>
            <View style={styles.head8}>
              <Text>Number of Reports</Text>
            </View>
            <View style={styles.head9}>
              <Text>Cost (RS)</Text>
            </View>
            <View style={styles.head10}>
              <Text>R/A Cost (RS)</Text>
            </View>
            <View style={styles.head11}>
              <Text>Revised Cost (RS)</Text>
            </View>
          </View>
          {user1.map((item, key) => (
            <View key={key} style={styles.row}>
              <View style={styles.cell1}>
                <Text>{item["Order Id"]}</Text>
              </View>
              <View style={styles.cell2}>
                <Text>{item["Patient Id"]}</Text>
              </View>
              <View style={styles.cell3}>
                <Text>{item["Patient Name"]}</Text>
              </View>
              <View style={styles.cell4}>
                <Text>{item["Radiolgist Name"]}</Text>
              </View>
              <View style={styles.cell5}>
                <Text>{item["Modality"]}</Text>
              </View>
              <View style={styles.cell6}>
                <Text>{item["Study"]}</Text>
              </View>
              <View style={styles.cell7}>
                <Text>{item["Date"]}</Text>
              </View>
              <View style={styles.cell8}>
                <Text>{item["Num of Reports"]}</Text>
              </View>
              <View style={styles.cell9}>
                <Text>{item["Cost (Rs)"]}</Text>
              </View>
              <View style={styles.cell10}>
                <Text>{item["R/A Cost (Rs)"]}</Text>
              </View>
              <View style={styles.cell11}>
                <Text>{item["Revised Cost (Rs)"]}</Text>
              </View>
            </View>
          ))}

          <View style={styles.total}>
            <Text>Total Cost in Words - </Text>
            {user1
              .filter((item, key) => {
                if (item["Order Id"] === "Total") return item;
              })
              .map((item, key) => (
                <Text style={{ color: "grey" }}>
                  {" "}
                  {item["Revised Cost (Rs)"]
                    ? numberToWords
                        .toWords(item["Revised Cost (Rs)"])
                        .replace(/[-]/g, " ")
                        .toUpperCase()
                    : item["R/A Cost (Rs)"]
                    ? numberToWords
                        .toWords(item["R/A Cost (Rs)"])
                        .replace(/[-]/g, " ")
                        .toUpperCase()
                    : item["Cost (Rs)"]
                    ? numberToWords
                        .toWords(item["Cost (Rs)"])
                        .replace(/[-]/g, " ")
                        .toUpperCase()
                    : "Zero"}
                </Text>
              ))}
          </View>
        </View>
        <View style={styles.content}>
          <View style={styles.cont1}>
            <Text style={styles.textCon}>
              Payment Terms and Conditions: 5C does not accept or collect
              payments made in cash, via UPI (Individual Account) and any
              person's bank account. Please ensure that you make a payment
              towards the 5C Designated Bank Account / 5C UPI Scanner. The
              Payment details have been intimated to you via email. Payment
              through any other mode will be considered null and{" "}
            </Text>
          </View>
          <View style={styles.cont1}>
            <Text style={{ fontWeight: 700 }}>
              Kindly make the payment within 15 days of the{" "}
            </Text>
          </View>
          <View style={styles.cont1}>
            <Text>
              Any unpleasant encounter and discrepancy in billing and the amount
              to be paid, please highlight within 15 days of your bill{" "}
            </Text>
          </View>
          <View style={styles.cont1}>
            <Text>
              We try to make our clientâ€™s experience easy by making
              transactional experience smooth and transparent.
            </Text>
          </View>
          <View style={styles.cont1}>
            <Text>
              For any queries and clarification please do reach out to the below
              mentioned contact details
            </Text>
          </View>
          <View style={styles.cont1}>
            <Text>We are MSME certified, protected under the MSMED act</Text>
          </View>
          <View style={styles.cont1}>
            <Text>UAM No:KR03E0092207</Text>
          </View>
          <View style={styles.cont1}>
            <Text>
              For failure to pay within the specified duration kindly refer to
              the link below
            </Text>
          </View>
          <View>
            <Link src="https://www.indiafilings.com/learn/msme-samadhaan/">
              {" "}
              https://www.indiafilings.com/learn/msme-samadhaan/
            </Link>
          </View>
        </View>

        <View style={{ fontSize: "10px" }}>
          <Text>Yours Sincerely</Text>
          <Text>5C Network</Text>
          <Text style={{ fontSize: "4px" }}>
            This is computer generated invoice no signature required.
          </Text>
        </View>
        <View style={styles.contentBottom}>
          <Text>Make all checks payable to 5C Network (India) Pvt Ltd</Text>
          <Text>5CNetwork Account Details: </Text>
          <Text>A/c no :-914020032293847</Text>
          <Text>IFSC Code</Text>
          <Text>UTIB0000052</Text>
          <Text>Axis bank Jayanagar branch</Text>
          <Text>PAN : AAACZ7690L, GST: 29AAACZ7690L1ZG</Text>
          <Text>CIN : U72200KA2014PTC075191,TIN : 29551252222</Text>
          <Text>Thank You for Your Business!</Text>
          <Text>5C Network India Pvt Ltd,</Text>
          <Text>
            {" "}
            No. 313, Mico Employees Gruha Nirmana Sangha, 7th Main Road
          </Text>
          <Text> BTM 2nd Stage , Rashtrakavi Kuvempu Nagara Bangalore,</Text>
          <Text> Landmark Opp Ashirwad Supermarket</Text>
          <Text> Pin: 560076</Text>
        </View>
        <View style={{ fontSize: "7px", color: "grey" }}>
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            src="https://5cnetwork.com/mail/unsubscribe?email=%5B%5Bto%5D%5D"
          >
            unsubscribe
          </Link>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
