import React, { useState, useEffect } from 'react'
import './Users.css'
import { checkAcessLevel } from '../../permission';
import { Button } from '@mui/material';
import AddUser from './AddUser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import EditUser from './EditUser';
import { TbEdit } from 'react-icons/tb';
import axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Users({ isSidebarOpen }) {
    const [user, setUser] = useState([])
    const [addUser, setAddUser] = useState('')
    const [editUser, setEditUser] = useState('')
    const [alert,setAlert]=useState('')
    const [warning,setWarning]=useState('')
    useEffect(() => {
      
        const data = checkAcessLevel().then(data => {
            //console.log(data)
            setUser(data.user)
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [])




    
    return (
        <div className={`${isSidebarOpen ? 'report-main' : 'report-min'}`}>

            <div className={`${isSidebarOpen ? 'report-div' : 'report-div-min'}`} >
                <div className='title-report'>Manage Users</div>

                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                    <Button variant='contained' onClick={() => { setAddUser(user) }} >Add Users</Button>
                </div>

                <div className='user-table'>
                    <div className='user-table-head'>
                        <div>Name</div>
                        <div>Email</div>
                        <div>Access Level</div>
                        <div>Action</div>
                    </div>
                    {user?.map((data, id) => (
                        <div key={id} className='user-table-body'>
                            <div>{data?.name}</div>
                            <div>{data?.email}</div>
                            <div>{data?.Permission?.level}</div>
                            <div><TbEdit size={20} style={{cursor:'pointer',backgroundColor:'red',borderRadius:'50%',padding:'5px', color:'#FFF'}} onClick={()=>setEditUser(data)}>Edit</TbEdit></div>
                        </div>
                    ))}
                </div>

            </div>


            <AddUser addUser={addUser} setAlert={setAlert} setAddUser={() => { setAddUser(false) }} />

            <EditUser userList={user} editUser={editUser} setEditUser={()=>{setEditUser(false)}} setAlert={setAlert} />

            <Snackbar sx={{ top: '50px !important' }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={warning} autoHideDuration={6000} onClose={() => setWarning('')}>
                <Alert onClose={() => setWarning('')} severity="error" sx={{ width: '100%', }}>
                    {warning}
                </Alert>
            </Snackbar>

            <Snackbar sx={{ top: '50px !important', zIndex: 9999 }} anchorOrigin={{ vertical: 'top', horizontal: 'center', zIndex: 9999 }} open={alert} autoHideDuration={6000} onClose={() => setAlert('')}>
                <Alert onClose={() => setAlert('')} severity="success" sx={{ width: '100%', zIndex: 9999 }}>
                    {alert}
                </Alert>
            </Snackbar>


        </div>
    )
}

export default Users
