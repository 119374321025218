import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography, Button, styled } from '@mui/material';
import Modal from '@mui/material/Modal';
import { AiOutlineCloseCircle } from 'react-icons/ai'
import * as XLSX from 'xlsx';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { message } from 'antd';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  // height:'500px',
  bgcolor: 'background.paper',
  border: '2px solid lightgray',
  boxShadow: 24,
  borderRadius: '30px',
  p: 4,
};
function UploadNotice({ open, handleClose,SetError,onFileUpload }) {
  const [selectedFile, setSelectedFile] = useState('')
  const [excelFileName, setExcelFileName] = useState('')
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);

  };

  const handleCancelUpload = () => {
    setOpenConfirmationDialog(false);
    setSelectedFile(null); // Reset selectedFile state when canceling the upload
  };


  const handleConfirmUpload = () => {
    setOpenConfirmationDialog(false);
    handleReadExcel(); // Call handleReadExcel to process the selected file after confirmation
    handleClose(); // Close the UploadCost modal after confirming the upload
  };


  const handleNoticeDownload = () => {
    const jsonData = [{
      'Unique Id': '5C0001',
      'Client Name': 'Jeeevan Hosapital',
      'Notice': 'Overdue',
      'Notice Type': 'Warning',
      'Message': 'Dear Valued Customer, you have an outstanding amount of Rs 109 as on May. Kindly pay as early as possible to avoid service interruption.',
      'Status': 'Active',
      'Active Till': '2023-10-10',
      'Amount':'60000'
    }]
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(jsonData);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet');
    XLSX.writeFile(wb, `Sample_Notice.xlsx`);
  }

  

  const handleReadExcel = () => {
    if (selectedFile) {
      setExcelFileName(selectedFile.name);


      const reader = new FileReader();

      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });

        const sheetName = 'Sheet';
        const sheet = workbook.Sheets[sheetName];
      
        if (!sheet) {
          //console.log('Incorrect file or format".');
          SetError('Incorrect file or format')
          return;
        }

        const headers = [];
        for (const cell in sheet) {
          //console.log(sheet[cell].v)
          if (sheet[cell].v !== undefined) {
            headers.push(sheet[cell].v);
          }
        }

        //console.log(headers)
        if (headers[0] !== 'Unique Id' && headers[1] !== 'Client Name' && headers[2] !== 'Notice' && headers[3] !== 'Notice Type' & headers[4] !== 'Message'||headers[5]!=='Status'||headers[6]!=='Active Till'||headers[7]!=='Amount') {
          SetError('Incorrect file or format')
          return;
        }

        const jsonData = XLSX.utils.sheet_to_json(sheet,{
          raw: false, // Keep cell values as string
        });

        if (jsonData.length === 0) {
          // setErrorPop('No data found in the selected file.')
          //console.log('No data found in the selected file.');
          SetError('No data found in the selected file.')
          return;
        }
        if (jsonData.length === 1) {
          if (jsonData[0]['Unique Id'] === '' && jsonData[0]['Client Name'] === '' && jsonData[0]['Notice'] === '' && jsonData[0]['Notice Type'] === '' && jsonData[0]['Message']=== ''&& jsonData[0]['Status']=== ''&& jsonData[0]['Active Till']=== '') {
            //console.log('No data found in the selected file.');
            SetError('No data found in the selected file.')
            return;
          }
        }

        const dataToSend = jsonData.filter((row) => row['Unique Id'] !== undefined && row['Unique Id'] !== '' && row['Client Name'] !== undefined  && row['Amount'] !== undefined && row['Amount'] !== '' && row['Client Name'] !== '' && row['Notice'] !== undefined && row['Notice'] !== '' && row['Notice Type'] !== undefined && row['Notice Type'] !== '' &&  row['Message'] !== undefined &&  row['Message'] !== '' && row['Status'] !== undefined && row['Status'] !== '' && row['Active Till'] !== undefined && row['Active Till'] !== '').map((row) => {return {
          unique_id:row['Unique Id'],
          client_name:row['Client Name'],
          notice:row['Notice'],
          type: row['Notice Type'],
          message:row['Message'],
          status:row['Status'],
          amount:row['Amount'],
          active_till:row['Active Till']
        }});
        
        if(jsonData.length!==dataToSend.length){
          SetError("Data's Missing!!! Please fill all the value")
          return;
        }

        //console.log(jsonData)
        //console.log(dataToSend)
        onFileUpload(dataToSend)
      }
      reader.readAsBinaryString(selectedFile);
    }
  };


  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    //console.log('Selected file:', selectedFile);
    setSelectedFile(selectedFile);
    setExcelFileName(selectedFile.name);
    setOpenConfirmationDialog(true);
    handleClose()
  }

  return (
    <div>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}

      >
        <Box sx={style}>
          <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid gray', paddingBottom: '10px' }}>
            <Typography style={{}} id="modal-modal-title" variant="h6" component="h2">
              Upload Notice
            </Typography>
            <AiOutlineCloseCircle onClick={() => handleClose()} size={25} style={{ color: 'red', cursor: 'pointer' }} />
          </div>

          <div className='pop-btn-rad' style={{ display: 'flex', justifyContent: 'space-between' }}>

           

            <Button onClick={() => handleNoticeDownload()}
              style={{ fontSize: '11px', marginLeft: '10px', padding: '20px', borderRadius: '30px', width: '200px' }} variant="contained" size='small' color="primary" component="span">

              Download Sample
            </Button>

            {/* <label htmlFor="file-input-price4" >
            <input onChange={handleFileSelect} type="file" accept=".xlsx" style={{ display: 'none' }} id="file-input-price4" />
          <Button  style={{fontSize:'11px',marginLeft:'10px', padding:'20px',borderRadius:'30px',width:'200px'}}  variant="contained" size='small' color="primary" component="span">
            Select File
          </Button>
          </label> */}
            <Button style={{ fontSize: '11px', marginLeft: '10px', padding: '20px', borderRadius: '30px', width: '200px' }} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
              Upload file
              <VisuallyHiddenInput type="file"  onChange={handleFileSelect} />
            </Button>

          </div>

        </Box>

      </Modal>

      <Dialog width={500} open={openConfirmationDialog} onClose={handleCloseConfirmationDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          {excelFileName && <p>Selected Excel File: {excelFileName}</p>}
          {uploadStatus && <p style={{ color: 'red' }}>{uploadStatus}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpload} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmUpload} color="primary" autoFocus> {/* Add autoFocus attribute here */}
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UploadNotice
