// import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  // Your Firebase configuration
  apiKey: process.env.REACT_APP_FIREBAESE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBAESE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  databaseURL: process.env.REACT_APP_FIREBAESE_DB_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBAESE_APP_ID
};



// const firebase=initializeApp(firebaseConfig);



// export default firebase;