import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FiSearch } from "react-icons/fi";
import HospitalTable from "./HospitalTable";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import "./Hospitals.css";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import { SiMicrosoftexcel } from "react-icons/si";
import { convertToCSV, downloadCSV } from "./dataDownload";
import CalculateIcon from "@mui/icons-material/Calculate";
import Button from "@mui/material/Button";
import SendInvoices from "./SendInvoices";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import * as XLSX from "xlsx";
import { UserContext } from "../../App";
import AuthErrorPop from "../Util/AuthErrorPop";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Hospitals = ({ email }) => {
  const [uniqueBillingTypes, setUniqueBillingTypes] = useState([]);
  const [isDownloadHovered, setIsDownloadHovered] = useState(false);
  const [isMvrButtonScaled, setIsMvrButtonScaled] = useState(false);
  const [filterState, setFilterState] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth >= 1080);
  const [fetchAlldata, setAllData] = useState([]);
  const [errorAlert, setErrorAlert] = useState("");
  const [authPop, setAuthPop] = useState(false);
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [alert, setAlert] = useState("");
  const [warning, setWarning] = useState("");
  const headerRef = useRef(null);
  const filterRef = useRef(null);
  const dropRef = useRef(null);
  const { user } = useContext(UserContext);
  const { handleAccess, apiState, setApiState } = useContext(UserContext);
  const handleDownloadClick = async () => {
    try {
      // Fetch all data without any filters
      // if(apiState?.downloadhospital==='fetch'){
      //   return 0
      // }
      setApiState((prevData) => ({
        ...prevData,
        downloadhospital: "fetch",
      }));
      //console.log(user, "hiuser");
      setAlert("Downloading...");
      const { REACT_APP_CLIENT_HOSPITAL_DOWNLOAD_LIST_URL } = process.env;
      const response = await axios.get(
        REACT_APP_CLIENT_HOSPITAL_DOWNLOAD_LIST_URL
      );
      if (response.data[0] === null) {
        setAlert("");
        setErrorAlert("Download Failed - No Data Found");
      }
      const data = response.data[0];

      const data1 = data.map((item) => {
        const jsonData = {
          uniqueID: item?.unique_id,
          name: item?.client_name,
          type: item?.type,
          status: item?.client_status,
          phone: item?.phone,
          "Default email": item?.email,
          pincode: item?.pincode,
          city: item?.city,
          state: item?.state,
          country: item?.country,
          address: item?.address,
          "invoice Email address": item?.invoice_email,
          "Client Type": item?.client_type,
          "Onboarded By": item["Onboarded By"],
          "Installed By": item["Installed By"],
          "OB Date": item?.date,
          "Billing Type": item?.billing_type,
          "Last Active Date": item?.last_active_date,
          "Account Manager": item?.account_manager,
          is_pre_read: item?.is_pre_read,
          is_iqc: item?.is_iqc,
          is_pr: item?.is_pr,
          night_hawk: item?.night_hawk,
          reason:item?.reason?.comment,
          gstin:item?.gstin_number,
          msme_registered:item?.msme_registered,
          pan:item?.pan,
          tan:item?.tan_number
        };
        return jsonData;
      });

      setAllData(data1);

      //console.log(data1, "hello");

      // Convert the data to CSV format using the function from dataDownload.js

      // Generate and download the CSV file using the function from dataDownload.js
    } catch (error) {
      console.error("Error fetching data for download:", error);
      setErrorAlert("Download Failed ");
    } finally {
      setApiState((prevData) => ({
        ...prevData,
        downloadhospital: null,
      }));
    }
  };

  useEffect(() => {
    if (fetchAlldata.length > 0) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(fetchAlldata);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, `All_Hospitals_Data.xlsx`);
      setAlert("");
    }
  }, [fetchAlldata]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth >= 1080);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      headerRef.current.style.cssText = `
         display: flex !important;
       `;
    } else {
      //   headerRef.current.style.cssText=`
      //   display: none !important;
      // `;
    }
  }, [isSmallScreen]);

  const handleCalculateMvrClick = () => {
    // For now, do nothing
    setIsMvrButtonScaled(true);
    setTimeout(() => {
      setIsMvrButtonScaled(false);
    }, 300);
  };

  const handleDownloadIconHover = () => {
    setIsDownloadHovered(!isDownloadHovered);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef(null);

  const [currentFromDate, setCurrentFromDate] = useState(null);
  const [currentToDate, setCurrentToDate] = useState(null);

  const handleDateFieldClick = () => {
    setIsCalendarOpen((prevIsCalendarOpen) => !prevIsCalendarOpen);
  };

  const handleDateChange = (ranges) => {
    setSelectedRange([ranges.selection]);
    setCurrentFromDate(ranges.selection.startDate);
    setCurrentToDate(ranges.selection.endDate);
  };

  const handleSelectClick = () => {
    setIsCalendarOpen(false); // Close the calendar
  };

  const formatDate = (date) => {
    return date ? date.toLocaleDateString() : "";
  };

  const handleClearSelection = () => {
    setSelectedRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };
  const [isOptionDropdownOpen, setIsOptionDropdownOpen] = useState(false);
  const handleOutsideClick = (event) => {
    // Check if the click is outside the date range picker
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      // setIsCalendarOpen(false); // Close the date range picker
      setIsCalendarOpen((prevIsCalendarOpen) => !prevIsCalendarOpen);
    }
    // if(dropRef.current && dropRef.current.contains(event.target)){
    //   setIsOptionDropdownOpen(false);
    //   console.log("hii")
    // }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [selectedBillingCategory, setSelectedBillingCategory] = useState("");

  const handleOptionSelect = (event) => {
    setSelectedBillingCategory(event.target.value);
    setIsOptionDropdownOpen(false); // Close the menu after selection
    fetchHospitalData(); // Call the function to fetch data
  };

  const [isButtonScaled, setIsButtonScaled] = useState(false);
  // Function to handle the click event of the "Send Invoice" button
  const handleSendInvoiceClick = () => {
    setIsButtonScaled(true);
    setTimeout(() => {
      setIsButtonScaled(false);
    }, 300);
  };

  const [searchText, setSearchText] = useState("");
  const handleInputChange = (event) => {
    // setSearchText(event.target.value);
  };

  const handleSearch = (searchTerm) => {
    setSearchText(searchTerm);
  };

  const SearchBar = ({ searchText, apiState,setSearchText }) => {
    // const [searchText, setSearchText] = useState("");

    const handleKeyUp = (event) => {
      // if (event.key === "Enter") {
        // onSearch(searchText); // Call the callback function passed from parent
      // }
    };

    return (
      <div className="search-hospitals">
        <input
          type="text"
          className="search-bar"
          // disabled={apiState?.hospitallist === "fetch"}
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value)
            // onSearch(event.target.value)
          }
          }
          // onKeyUp={handleKeyUp} // Use onKeyUp instead of onKeyDown
          placeholder="Search Hospitals"
        />
        <div className="search-icon">
          <FiSearch size={15} />
        </div>
      </div>
    );
  };

  const handleFilter = () => {
    setFilterState((prevValue) => !prevValue);
    if (filterState) {
      headerRef.current.style.cssText = `
         display: flex !important;
       `;
    } else {
      headerRef.current.style.cssText = `
         display: none !important;
       `;
    }
  };

  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500); // Decreased debounce timer to 500 milliseconds

    return () => {
      clearTimeout(timerId);
    };
  }, [searchText]);

  useEffect(() => {
    const fetchUniqueBillingTypes = async () => {
      try {
        const { REACT_APP_CLIENT_HOSPITAL_BILLING_TYPE_URL } = process.env;
        const response = await axios.get(
          REACT_APP_CLIENT_HOSPITAL_BILLING_TYPE_URL
        );
        setUniqueBillingTypes(response.data);
      } catch (error) {
        console.error("Error fetching unique billing types:", error);
      }
    };
    if (apiState?.hospitallist === "fetch") {
      return;
    }
    fetchUniqueBillingTypes();
  }, []);

  const fetchHospitalData = async () => {
    try {
      setApiState((prevData) => ({
        ...prevData,
        hospitallist: "fetch",
      }));
      const { REACT_APP_CLIENT_HOSPITAL_LIST_URL } = process.env;
      const response = await axios.get(REACT_APP_CLIENT_HOSPITAL_LIST_URL, {
        params: {
          page: 1,
          pageSize: 20,
          searchTerm: searchText,
          billingType: selectedBillingCategory,
        },
      });

      // Process the response data as needed
      // For example, you can update the HospitalTable component with the new data here
    } catch (error) {
      console.error("Error fetching hospital data:", error);
    } finally {
      setApiState((prevData) => ({
        ...prevData,
        hospitallist: null,
      }));
    }
    //console.log(searchText);
  };

  useEffect(() => {
    if (apiState?.hospitallist !== "fetch") {
      // fetchHospitalData();
    }
    // Call the function to fetch initial data
  }, [searchText, selectedBillingCategory, currentFromDate, currentToDate]);

  const handleClearAll = () => {
    // Clear date range
    setSelectedRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
    setCurrentFromDate(null);
    setCurrentToDate(null);

    // Clear selected billing category
    setSelectedBillingCategory("");

    // Clear search text
    setSearchText("");

    // Refetch all data in the table
    fetchHospitalData();
  };

  const ClearAllWrapper = styled("div")(({ theme }) => ({
    display: "inline-block",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  }));

  const formatDated = (date1) => {
    const fun = () => {
      const date = new Date(date1);
      const addLeadingZero = (value) => (value < 10 ? `0${value}` : value);
      const day = addLeadingZero(date.getDate());
      const month = addLeadingZero(date.getMonth() + 1); // Months are zero-based
      const year = date.getFullYear();
      const hours = addLeadingZero(date.getHours());
      const minutes = addLeadingZero(date.getMinutes());
      const seconds = addLeadingZero(date.getSeconds());
      const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    };
    return date1 ? fun(date1) : "";
  };
  const ClearAllIconContainer = styled("div")({
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.3)",
    },
  });

  return (
    <div className="container-main">
      <div className="container">
        <div className="header-container">
          <div ref={headerRef} className="header">
            <div className="header-section">
              {/* Date Range Picker */}
              <div className="date-range-picker">
                <Button
                  size="small"
                  variant="contained"
                  className="btn-effect"

                  onClick={handleDateFieldClick}
                >

                  {selectedRange[0].startDate ? (
                    <div>
                   
                      {formatDate(currentFromDate)} -{" "}
                      {formatDate(currentToDate)}
                    
                    </div>
                  ) : (
                    "Date Range"
                  )}
                </Button>
              </div>
            </div>
            <div className="header-section">
              {/* Select Billing Category */}
              <div className="select-billing-category">
                <FormControl
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  fullWidth
                >
                  <InputLabel id="demo-simple-select-label">
                    Select Billing Category
                  </InputLabel>
                  <Select
                    disabled={apiState?.hospitallist === "fetch"}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedBillingCategory}
                    label="Select Billing Category"
                    onChange={handleOptionSelect}
                    style={{ paddingLeft: "10px" }}
                  >
                    {uniqueBillingTypes.map((billingType, id) => (
                      <MenuItem key={id} value={billingType}>
                        {billingType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="header-section">
              {/* Send Invoice Button */}
              <SendInvoices
                selectedRange={selectedRange}
                selectedBillingCategory={selectedBillingCategory}
                setIsButtonScaled={setIsButtonScaled}
                isButtonScaled={isButtonScaled}
                currentFromDate={formatDate(currentFromDate)}
                currentToDate={formatDate(currentToDate)}
                email={email}
                setWarning={setWarning}
                userAccess={user}
                handleAccess={handleAccess}
                apiState={apiState}
                setApiState={setApiState}
              />
            </div>

            {/* Step 3: Calculate MVR Button with Icon */}
            <div className="calculate-mvr-button">
              <Button
                className="btn-mvr"
                variant="contained"
                color="primary"
                style={{
                  marginRight: "10px",
                  height: "30px",
                  fontSize: "11px",
                }}
                onClick={handleCalculateMvrClick}
              >
                <CalculateIcon style={{ marginRight: "5px" }} />
                Calculate MVR
              </Button>
            </div>

            <div className="header-section">
              {/* Search Hospitals */}
              {/* <SearchBar apiState={apiState} 
              // onSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
               /> */}

            <div className="search-hospitals">
                    <input
                      type="text"
                      className="search-bar"
                      // disabled={apiState?.hospitallist === "fetch"}
                      value={searchText}
                      onChange={(event) => {
                        setSearchText(event.target.value)
                        // onSearch(event.target.value)
                      }
                      }
                      // onKeyUp={handleKeyUp} // Use onKeyUp instead of onKeyDown
                      placeholder="Search Hospitals"
                    />
                    <div className="search-icon">
                      <FiSearch size={15} />
                    </div>
                  </div>
            </div>

            <div className="header-section">
              {/* Clear All Button */}
              <Tooltip title="Clear All Filters" arrow>
                <div
                  className="clear-all-icon-container"
                  onClick={handleClearAll}
                >
                  <ClearAllIcon
                    className="clear-all-icon"
                    style={{ fontSize: 30 }}
                  />
                  {/* <span>Clear All Filters</span> */}
                </div>
              </Tooltip>
            </div>

            <div
              style={{ cursor: "pointer" }}
              className="download-excel-button"
            >
              <Tooltip title="Download Excel" arrow>
                <div
                  aria-disabled={true}
                  className={`button-effect ${
                    isDownloadHovered ? "download-hover" : ""
                  }`}
                  onMouseEnter={handleDownloadIconHover}
                  onMouseLeave={handleDownloadIconHover}
                  onClick={() => {
                    if (apiState?.downloadhospital === "fetch") {
                      return;
                    }
                    //   if(user?.access==='admin'){
                    //     handleDownloadClick()
                    //   }else{
                    //   setAuthPop(true)
                    // }
                    if (handleAccess("Hospital", "Download") === true) {
                      handleDownloadClick();
                    } else {
                      setAuthPop(true);
                    }
                  }}
                >
                  {isDownloadHovered ? (
                    <SiMicrosoftexcel fontSize="28px" />
                  ) : (
                    <DownloadIcon fontSize="28px" />
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
          {isCalendarOpen && (
            <div className="date-range-wrapper">
              <div className="date-range-container" ref={calendarRef}>
                <DateRangePicker
                  ranges={selectedRange}
                  onChange={handleDateChange}
                  editableDateInputs={false}
                />
                <div>
                  <Button
                    className="clear-btn1"
                    size="small"
                    variant="contained"
                    onClick={handleClearSelection}
                  >
                    Clear Date Selection
                  </Button>
                  <Button
                    className="select-btn1"
                    size="small"
                    variant="contained"
                    onClick={handleSelectClick}
                  >
                    Select
                  </Button>
                </div>
              </div>
            </div>
          )}

          {isOptionDropdownOpen && (
            <div className="select-dropdown1" ref={dropRef}>
              <ul>
                {uniqueBillingTypes.map((billingType) => (
                  <li
                    key={billingType}
                    onClick={() => handleOptionSelect(billingType)}
                  >
                    {billingType}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* <div className='filter' ref={filterRef} >
        
        <div onClick={handleFilter}>
        <FilterAltIcon className='filtericon'  />
         Filter
        </div>
        
        </div> */}
        </div>

        {/* Table */}
        <div className="table">
          <HospitalTable
            searchText={searchText}
            selectedBillingCategory={selectedBillingCategory}
            currentFromDate={formatDated(currentFromDate)}
            currentToDate={formatDated(currentToDate)}
            apiState={apiState}
          />
        </div>

        <Snackbar
          sx={{ top: "50px !important" }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={warning}
          autoHideDuration={6000}
          onClose={() => setWarning("")}
        >
          <Alert
            onClose={() => setWarning("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {warning}
          </Alert>
        </Snackbar>

        <Snackbar
          open={alert}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{ zIndex: "6" }}
          onClose={() => {
            setAlert(false);
          }}
        >
          <Alert
            onClose={() => {
              setAlert(false);
            }}
            severity="info"
            sx={{ width: "100%" }}
          >
            {alert}
          </Alert>
        </Snackbar>

        <Snackbar
          open={errorAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{ zIndex: "6" }}
          onClose={() => {
            setErrorAlert(false);
          }}
        >
          <Alert
            onClose={() => {
              setErrorAlert(false);
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorAlert}
          </Alert>
        </Snackbar>
        <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop} />
      </div>
    </div>
  );
};

export default Hospitals;
