import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Alerts({ setWarning, warning, alert, setAlert }) {
  return (
    <div>
      <Snackbar
        sx={{ top: "50px !important" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={warning}
        autoHideDuration={6000}
        onClose={() => setWarning("")}
      >
        <Alert
          onClose={() => setWarning("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {warning}
        </Alert>
      </Snackbar>

      <Snackbar
        sx={{ top: "50px !important", zIndex: 9999 }}
        anchorOrigin={{ vertical: "top", horizontal: "center", zIndex: 9999 }}
        open={alert}
        autoHideDuration={6000}
        onClose={() => setAlert("")}
      >
        <Alert
          onClose={() => setAlert("")}
          severity="success"
          sx={{ width: "100%", zIndex: 9999 }}
        >
          {alert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Alerts;
