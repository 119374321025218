// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width:1069px) {
  
    .transaction-main{
      justify-content: inherit !important;
    }
  }

  .tb-head{
    font-size: 12px !important;
    padding: 10px 10px !important;
  }

  .edit-btn{
    font-size: 11px !important;
  }

  .search-field{
    float: right;
    margin-right: 15px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }

  .search-field>div>input{
    padding: 10px !important;
  }

 @media screen and (max-height:480px) {
  .transaction-table{
   height:400px !important
  }
 }
  .transaction-table{
    height: calc(100vh - 390px) ;
  }`, "",{"version":3,"sources":["webpack://./src/components/Hospital/TransactionsTab.css"],"names":[],"mappings":"AAAA;;IAEI;MACE,mCAAmC;IACrC;EACF;;EAEA;IACE,0BAA0B;IAC1B,6BAA6B;EAC/B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,YAAY;IACZ,6BAA6B;IAC7B,6BAA6B;IAC7B,0BAA0B;EAC5B;;EAEA;IACE,wBAAwB;EAC1B;;CAED;EACC;GACC;EACD;CACD;EACC;IACE,4BAA4B;EAC9B","sourcesContent":["@media screen and (max-width:1069px) {\n  \n    .transaction-main{\n      justify-content: inherit !important;\n    }\n  }\n\n  .tb-head{\n    font-size: 12px !important;\n    padding: 10px 10px !important;\n  }\n\n  .edit-btn{\n    font-size: 11px !important;\n  }\n\n  .search-field{\n    float: right;\n    margin-right: 15px !important;\n    margin-bottom: 5px !important;\n    margin-top: 5px !important;\n  }\n\n  .search-field>div>input{\n    padding: 10px !important;\n  }\n\n @media screen and (max-height:480px) {\n  .transaction-table{\n   height:400px !important\n  }\n }\n  .transaction-table{\n    height: calc(100vh - 390px) ;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
