// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import Card from "./CardContent";
// Custom icons
import React from "react";

export default function CardComponent(props) {
  const { startContent, endContent, name, growth, value, loader } = props;
  const textColor = "#1B2559";
  const textColorSecondary = "#A3AED0";

  return (
    <div __css={{ bg: "white" }} py="15px">
      {loader ? (
        <Flex
          my="auto"
          h="100%"
          alignItems={"center"}
          justifyContent={"center"}
        >
          {startContent}

          <Stat my="auto" ms={startContent ? "18px" : "0px"}>
            <StatLabel
              fontWeight={"500"}
              lineHeight="100%"
              color={textColorSecondary}
              fontSize={"0.875rem"}
            >
              {name}
            </StatLabel>
            <StatNumber
              verticalAlign={"baseline"}
              ml={0}
              fontWeight={"600"}
              fontSize={"1.5rem"}
              color={textColor}
            >
              {value}
            </StatNumber>
            {growth ? (
              <Flex align="center">
                <Text
                  color="#01b574"
                  fontSize="0.75rem"
                  fontWeight="700"
                  me="5px"
                >
                  {growth}
                </Text>
                <Text color="#A3AED0" fontSize="0.75rem" fontWeight="400">
                  Gross Margin
                </Text>
              </Flex>
            ) : null}
          </Stat>

          <Flex ms="auto" w="max-content">
            {endContent}
          </Flex>
        </Flex>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
