import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import {
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./RadPricing.css";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UploadRadPrice from "./UploadRadPrice";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import MuiAlert from "@mui/material/Alert";
import BoyOutlinedIcon from "@mui/icons-material/BoyOutlined";
import DoctorImg from "../../Images/doctor.png";
import AuthErrorPop from "../Util/AuthErrorPop";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function RadPricing({ radEmail, radName, status, userAccess, handleAccess }) {
  const [priceData, setpriceData] = useState([]);
  const [ComboData, setCombaData] = useState([]);
  const [uploadpop, setUploadPop] = useState(false);
  const [alert, setAlert] = useState("");
  const [errorAlert, setErrorAlert] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [errorAlertdown, setErrorAlertDown] = useState("");
  const [authPop, setAuthPop] = useState(false);
  const RadPrice = async () => {
    try {
      const { REACT_APP_RAD_DOWNLOAD_PRICE_URL } = process.env;
      const res = await axios.get(
        REACT_APP_RAD_DOWNLOAD_PRICE_URL + `?email=${radEmail}`,
        {
          responseType: "arraybuffer", // Specify the response type as 'arraybuffer'
        }
      );
      const binaryData = res.data;

      // Convert the binary data to an ArrayBuffer
      const arrayBuffer = new Uint8Array(binaryData).buffer;

      // Parse the Excel file using XLSX
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      // Assuming the first sheet is the one you want to read
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const sheetName1 = workbook.SheetNames[1];
      const worksheet1 = workbook.Sheets[sheetName1];
      const data1 = XLSX.utils.sheet_to_json(worksheet1, { header: 1 });

      // Set the Excel data in state
      setpriceData(data);

      const filterdata1 = data.filter((row) => {
        for (let i = 0; i < row.length; i++) {
          if (typeof row[i] === "undefined") {
            row[i] = " ";
          }
        }
        return row;
      });

      //console.log(filterdata1);
      for (let i = 0; i < data1.length; i++) {
        if (data1[i] === "empty") {
          data1[i] = ""; // Replace missing value with an empty string
        }
      }

      const filterdata = data1.filter((row) => {
        for (let i = 0; i < row.length; i++) {
          if (typeof row[i] === "undefined") {
            row[i] = " ";
          }
        }
        return row;
      });
      //console.log(filterdata);
      setCombaData(filterdata);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    RadPrice();
  }, [radEmail]);

  useEffect(() => {
    if (refresh) {
      RadPrice();
      setRefresh(false);
    }
  }, [refresh]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDownload = async () => {
    try {
      const { REACT_APP_RAD_DOWNLOAD_PRICE_URL } = process.env;
      const response = await axios.get(
        REACT_APP_RAD_DOWNLOAD_PRICE_URL + `?email=${radEmail}`,
        {
          responseType: "blob", // Set the response type to blob to handle file download
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${radName}_price.xlsx`); // Set the file name with the client name
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
      setErrorAlertDown("Error downloading file");
    }
  };

  const handleUpload = () => {
    setUploadPop(true);
  };

  const handleExcelUpload = async (selectedFile) => {
    setAlert("Updating.....");
    //console.log(selectedFile);
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const { REACT_APP_RAD_UPLOAD_PRICE_URL } = process.env;
      const response = await fetch(
        REACT_APP_RAD_UPLOAD_PRICE_URL + `?email=${radEmail}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `NWNuZXR3b3JrOjVjbmV0d29yaw0986==`,
          },
        }
      );

      if (response.ok) {
        //console.log("Price Updated Successfully");
        setAlert("Price Update Successfully");
        setRefresh(true);
      } else {
        setErrorAlert("Updation Failed");
      }
    } catch {
      setErrorAlert("Updation Failed");
    }
  };

  return (
    <div>
      {status === "ACTIVE" ? (
        <div>
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tabs value={value} onChange={handleChange} className="table-tabs">
              <Tab label="Grade" />
              <Tab label="Combo Grade" />
            </Tabs>
            <div style={{ textAlign: "end" }}>
              <Button
                onClick={() => {
                  // if(userAccess?.access==='admin'){
                  if (handleAccess("Radiologist", "Download Price")) {
                    handleDownload();
                  } else {
                    setAuthPop(true);
                  }
                }}
                size="small"
                variant="contained"
                className="download-btn"
                startIcon={<CloudDownloadIcon />}
              >
                Download
              </Button>
              <Button
                onClick={() => {
                  //  if(userAccess?.access==='admin'){
                  if (handleAccess("Radiologist", "Upload Price")) {
                    handleUpload();
                  } else {
                    setAuthPop(true);
                  }
                }}
                size="small"
                className="upload-btn"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload
              </Button>
            </div>
          </Box>
          <div>
            {/* <div style={{textAlign:'center'}}>Grade Price</div> */}

            <TableContainer
              className="price-table"
              sx={{
                height: "calc(100vh - 260px)",
                width: "calc(100vw - 260px)",
                marginLeft: "30px",
              }}
              component={Paper}
            >
              <Table stickyHeader>
                <TableHead>
                  {value === 0 ? (
                    <TableRow>
                      <TableCell className="table-cell-pricing">
                        Model Study
                      </TableCell>
                      <TableCell className="table-cell-pricing">Gold</TableCell>
                      <TableCell className="table-cell-pricing">
                        Silver
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Bronze
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Night
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Br_April 2022
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Br_Sept 2023_1
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        status
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Default
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell className="table-cell-pricing">
                        Model Study1
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Model Study2
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Model Study3
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Model Study4
                      </TableCell>
                      <TableCell className="table-cell-pricing">Gold</TableCell>
                      <TableCell className="table-cell-pricing">
                        Silver
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Bronze
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Night
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Br_April 2022
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Br_Sept 2023_1
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        status
                      </TableCell>
                      <TableCell className="table-cell-pricing">
                        Default
                      </TableCell>
                    </TableRow>
                  )}
                </TableHead>
                {value === 0 ? (
                  <TableBody>
                    {priceData.slice(1).map((row, rowIndex) => (
                      <TableRow className="price-row">
                        {row.map((cell, cellIndex) => (
                          <TableCell
                            className="table-cell-pricing"
                            key={cellIndex}
                          >
                            {cell.toString()}
                          </TableCell>
                        ))}
                        {row.length === 7 && (
                          <TableCell className="table-cell-pricing"></TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    {ComboData.slice(1).map((row, rowIndex) => (
                      <TableRow className="price-row">
                        {row.map((cell, cellIndex) =>
                          cell || cell === 0 || cell === " " ? (
                            <TableCell
                              className="table-cell-pricing"
                              key={cellIndex}
                            >
                              {cell.toString()}
                            </TableCell>
                          ) : (
                            <TableCell></TableCell>
                          )
                        )}
                        {row.length === 10 && (
                          <TableCell className="table-cell-pricing"></TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <UploadRadPrice
              data={uploadpop}
              onClose={() => setUploadPop(false)}
              handleExcelSend={handleExcelUpload}
            />

            <Snackbar
              open={!!alert}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              sx={{ zIndex: "6" }}
              autoHideDuration={8000}
              onClose={() => {
                setAlert("");
              }}
            >
              <Alert
                open={alert}
                onClose={() => {
                  setAlert("");
                }}
                severity="success"
                sx={{ width: "100%" }}
              >
                {alert}
              </Alert>
            </Snackbar>

            <Snackbar
              open={errorAlert}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              autoHideDuration={6000}
              onClose={() => {
                setErrorAlert(false);
              }}
            >
              <Alert
                onClose={() => {
                  setErrorAlert(false);
                }}
                severity="error"
                sx={{ width: "100%" }}
              >
                Updation Failed
              </Alert>
            </Snackbar>
            <Snackbar
              open={errorAlertdown}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              autoHideDuration={6000}
              onClose={() => {
                setErrorAlertDown("");
              }}
            >
              <Alert
                onClose={() => {
                  setErrorAlertDown(false);
                }}
                severity="error"
                sx={{ width: "100%" }}
              >
                {errorAlertdown}
              </Alert>
            </Snackbar>
            <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop} />
          </div>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <img
            src={DoctorImg}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "40px",
              width: "230px",
              borderRadius: "50%",
              boxShadow: "0px 0px 10px grey",
            }}
            alt="error"
          ></img>
          <div
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginTop: "20px",
              marginLeft: "30px",
              fontWeight: "400",
              fontSize: "25px",
              color: "lightgrey",
            }}
          >
            Radiologist is InActive
          </div>
        </div>
      )}
    </div>
  );
}

export default RadPricing;
