import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TextField, FormControl, InputLabel, Select, MenuItem, Checkbox,FormControlLabel } from '@mui/material/';
// import firebase from '../../firebase';
import { db,firebase } from '../../permission'
import { doc, getDoc, setDoc } from "firebase/firestore";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddUser({ addUser, setAddUser,setAlert,setWarning }) {
    const [details, setDetails] = useState({
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        role: null
    })
    const permissionlist = {
        Hospital: ['Download',
            'Send Invoice',
            'Edit Details',
            'Price Download',
            'Price Upload',
            'Contact',
            'Cost Rerun',
            'Download Cost',
            'Bulk Upload Cost',
            'Manual Edit',
            'Send Note',
            'Download Invoice',
            'Document Tab','Agreement'
        ],
        Radiologist: ['Upload Salary','Download Price','Upload Price','Download Transaction','Download Cost','Upload Cost','Manual Edit','Rad Invoice'],
        Invoices: ['Download','Send Note'],
        Reports: ['Download'],
        HospitalNotice: ['Edit Notice Text Box','Bulk Upload Notice','Send Notice','Notice Action','Send Reminder','Bulk Upload Reminder'],
        Collection:['Download Clients','Download Chat'],
        Vendor:['Add vendor','Edit Details','Contact','Invoice','Document']
    }
    const [accesslist, setAccesslist] = useState({
        Hospital: [],
        Radiologist: [],
        Invoices: [],
        Reports: [],
        HospitalNotice: [],
        Collection:[],
        Vendor:[]
    })
    const errorRef = useRef(null)


    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        //console.log(name, value)
        setDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));

    }

    const isStrongPassword = (password) => {
        if (password.length < 8) {
            return false
        }

        if (!/[A-Z]/.test(password)) {
            return false;
        }

        if (!/[a-z]/.test(password)) {
            return false;
        }

        if (!/\d/.test(password)) {
            return false;
        }

        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            return false;
        }
        return true;

    }

    function isEmailValid(email) {
        // Regular expression pattern for a basic email validation
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    }

    const handleAdduser = () => {
        errorRef.current.innerHTML = ''
        if (details.name === null || details.name === '') {
            errorRef.current.innerHTML = 'Please Enter the Name'
            return;
        } else if (details.email === null || details.email === '') {
            errorRef.current.innerHTML = 'Please Enter the email'
            return;
        }
        else if (details.password === null || details.password === '') {
            errorRef.current.innerHTML = 'Please Enter the Password'
            return;
        }
        else if (!isStrongPassword(details.password)) {
            errorRef.current.innerHTML = 'Password should include lower,uppercase,special character and digits and minimum of 8 diigits'
            return;
        }
        else if (details.password !== details.confirm_password) {
            errorRef.current.innerHTML = 'Password and Confirm Password did not match'
            return;
        } else if (!isEmailValid(details.email)) {
            errorRef.current.innerHTML = 'Please Enter a Valid Email'
            return;
        }
        else if (details.role === null || details.role === '') {
            errorRef.current.innerHTML = 'Please Select the role'
            return;
        } else {
            handleRegisterUser()
        }
    }

    const handleCheckChange =(value,page)=>{
         //console.log(value.target.value,page)
         if(accesslist[page].includes(value.target.value)){
            //console.log('value1')
            const updatedList = accesslist[page].filter(item => item !== value.target.value);
            setAccesslist({ ...accesslist, [page]: updatedList });
         }else{
            //console.log('value')
            setAccesslist({
                ...accesslist,
                [page]: [...accesslist[page], value.target.value],
              });
         }
         //console.log(accesslist.Hospital)

    }

    const handleRegisterUser = () => {
        firebase.auth().fetchSignInMethodsForEmail(details.email)
        .then((signInMethods) => {
          // If signInMethods array is not empty, the email is already registered
          if (signInMethods && signInMethods.length > 0) {
            errorRef.current.innerHTML='Email already registered. Choose a different email or sign in.'
          } else {
            // Email is not registered, proceed with account creation
            firebase.auth().createUserWithEmailAndPassword(details.email, details.password)
              .then((cred) => {
                //console.log('Account created successfully:', cred);
                const newUser = {
                    email: details.email,
                    name: details.name,
                    Permission: {
                        level: details.role,
                        Pages: accesslist
                    }
        
                }
                const data = [ newUser,...addUser]
                //console.log(data)
                handleUpdate(data)
               
              })
              .catch((error) => {
                console.error('Error creating account:', error.message);
              });
          }
        })
        .catch((error) => {
          console.error('Error checking email existence:', error.message);
        });


      
       
       
    }


    const handleUpdate = async (newData) => {
        try {
            const documentRef = doc(db, "Finance_Access", "Y5jz0XkKMbf4YtKg4ojW");
            await setDoc(documentRef, { user: newData });
            //console.log(`New chat entry added for user successfully.`);
            window.location.reload()
            setAddUser()
            handleClear()
            setAlert('New User Added Successfully')

        } catch (error) {
            console.error('Error updating document:', error.message);
            setWarning('User Added,Permission access failed')
        }
    };


    const handleClear=()=>{
         setDetails({
            name: null,
            email: null,
            password: null,
            confirm_password: null,
            role: null
        })

        setAccesslist({
            Hospital: [],
            Radiologist: [],
            Invoices: [],
            Reports: [],
            HospitalNotice: []
        })
    }



    return (

        <Dialog
            fullScreen
            open={addUser}
            onClose={()=>{setAddUser()
                handleClear()
            }}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative',backgroundColor:'#0059B2' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={setAddUser}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Add User
                    </Typography>
                    {/* <Button autoFocus color="inherit" onClick={setAddUser}>
                        save
                    </Button> */}
                </Toolbar>
            </AppBar>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>

                <div className='add-user-form'>
                    <TextField value={details.name} name='name' onChange={handleChange} style={{ marginBottom: '20px', marginTop: '20px', width: '90%' }} id="standard-basic" label="Enter Your Name" variant="standard" />
                    <TextField value={details.email} name='email' onChange={handleChange} style={{ marginBottom: '20px', marginTop: '20px', width: '90%' }} id="standard-basic" label="Enter Email Address" variant="standard" />
                    <TextField value={details.password} name='password' onChange={handleChange} style={{ marginBottom: '20px', marginTop: '20px', width: '90%' }} id="standard-basic" label="Enter Password" variant="standard" />
                    <TextField value={details.confirm_password} name='confirm_password' onChange={handleChange} style={{ marginBottom: '20px', marginTop: '20px', width: '90%' }} id="standard-basic" label="Confirm Password" variant="standard" />
                    <FormControl style={{ marginBottom: '20px', marginTop: '20px' }} sx={{ m: 1, minWidth: 730 }} variant='standard'>
                        <InputLabel id="demo-select-small-label">Select Role</InputLabel>
                        <Select
                            value={details.role} name='role' onChange={handleChange}
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            // value={designation}
                            label="Select Role"
                        // onChange={(e) => setDesignation(e.target.value)}
                        >

                            <MenuItem value='view'>View</MenuItem>
                            <MenuItem value='admin'>Admin</MenuItem>
                            <MenuItem value='super_admin'>Super Admin</MenuItem>
                            <MenuItem value='selected_permission'>Selected Permission</MenuItem>
                            <MenuItem value='Dasboard'>Dashboard</MenuItem>
                        </Select>

                    </FormControl>
                 { details.role==='selected_permission' &&
                    <div>

                    <div>
                        <div style={{textAlign:'left',margin:'30px',fontWeight:'bold'}}> HOSPITAL PAGE</div>
                     { permissionlist?.Hospital.map((data,id)=>(
                        <FormControlLabel
                       control={ <Checkbox label='data' key={id}
                             value={data}
                            checked={accesslist?.Hospital.includes(data)}
                            onChange={(e)=>handleCheckChange(e,'Hospital')}
                            // inputProps={{ 'aria-label': 'controlled' }}
                        /> }
                         label={data}
                        />
                     ))

                     }
                    </div>

                    <div>
                        <div style={{textAlign:'left',margin:'30px',fontWeight:'bold'}}> RADIOLOGIST PAGE</div>
                     { permissionlist?.Radiologist.map((data,id)=>(
                        <FormControlLabel
                       control={ <Checkbox label='data' key={id}
                             value={data}
                            checked={accesslist?.Radiologist.includes(data)}
                            onChange={(e)=>handleCheckChange(e,'Radiologist')}
                            // inputProps={{ 'aria-label': 'controlled' }}
                        /> }
                         label={data}
                        />
                     ))

                     }
                    </div>

                    <div>
                        <div style={{textAlign:'left',margin:'30px',fontWeight:'bold'}}> REPORTS PAGE</div>
                     { permissionlist?.Reports.map((data,id)=>(
                        <FormControlLabel
                       control={ <Checkbox label='data' key={id}
                             value={data}
                            checked={accesslist?.Reports.includes(data)}
                            onChange={(e)=>handleCheckChange(e,'Reports')}
                            // inputProps={{ 'aria-label': 'controlled' }}
                        /> }
                         label={data}
                        />
                     ))

                     }
                    </div>

                    <div>
                        <div style={{textAlign:'left',margin:'30px',fontWeight:'bold'}}> INVOICE PAGE</div>
                     { permissionlist?.Invoices.map((data,id)=>(
                        <FormControlLabel
                       control={ <Checkbox label='data' key={id}
                             value={data}
                            checked={accesslist?.Invoices.includes(data)}
                            onChange={(e)=>handleCheckChange(e,'Invoices')}
                            // inputProps={{ 'aria-label': 'controlled' }}
                        /> }
                         label={data}
                        />
                     ))
                     }
                    </div>

                    <div>
                        <div style={{textAlign:'left',margin:'30px',fontWeight:'bold'}}> HOSPITAL NOTICE PAGE</div>
                     { permissionlist?.HospitalNotice.map((data,id)=>(
                        <FormControlLabel
                       control={ <Checkbox label='data' key={id}
                             value={data}
                            checked={accesslist?.HospitalNotice.includes(data)}
                            onChange={(e)=>handleCheckChange(e,'HospitalNotice')}
                            // inputProps={{ 'aria-label': 'controlled' }}
                        /> }
                         label={data}
                        />
                     ))

                     }
                    </div>

                    <div>
                                <div style={{ textAlign: 'left', margin: '30px',fontWeight:'bold' }}> COLECTION PAGE</div>
                                {permissionlist?.Collection.map((data, id) => (
                                    <FormControlLabel
                                        control={<Checkbox label='data' key={id}
                                            value={data}
                                            checked={accesslist?.Collection.includes(data)}
                                            onChange={(e) => handleCheckChange(e, 'Collection')}
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label={data}
                                    />
                                ))

                                }
                            </div>

                            <div>
                                <div style={{ textAlign: 'left', margin: '30px',fontWeight:'bold' }}> VENDOR PAGE</div>
                                {permissionlist?.Vendor.map((data, id) => (
                                    <FormControlLabel
                                        control={<Checkbox label='data' key={id}
                                            value={data}
                                            checked={accesslist?.Vendor.includes(data)}
                                            onChange={(e) => handleCheckChange(e, 'Vendor')}
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label={data}
                                    />
                                ))

                                }
                            </div>
                    </div>

                    

}

                    <p ref={errorRef} style={{ color: 'red' }}></p>

                    <Button className='submits1-button' variant='contained' onClick={handleAdduser}> Submit</Button>
                </div>


            </div>

        </Dialog>

    );
}