import React, { useState,useRef, useEffect } from 'react'
import axios from "axios";
import {
    Modal,
    Box,
    Button,
    TextField
} from "@mui/material";
import './VendorDocument.css'
import ConfirmationDialog from '../Collection/ConfirmationDialog';
import { MdPreview } from "react-icons/md";
import DocumentViewer from './DocumentViewer';

function VendorDocument({vendorid,email,handleAccess,setAlert,setWarning, setAuthPop}) {
    const [vendorDocument,setDocument]=useState([])
    const [selectedFile, setSelectedFile] = useState(null);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState('');
    const [excelFileName, setExcelFileName] = useState(null);
    const [ reminder,setAddReminder]=useState('')
    const [documentOpen,setDocumentOpen]=useState('')
  
    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        //console.log("Selected file:", selectedFile);
        setSelectedFile(selectedFile);
        if (!!selectedFile) {
          setExcelFileName(selectedFile.name);
        }
        setOpenConfirmationDialog(true); // Show the confirmation dialog after file selection
      };

      const handleCancelUpload = () => {
        setOpenConfirmationDialog(false);
        setSelectedFile(null); // Reset selectedFile state when canceling the upload
        // setExcelFileName(null);
      };
    
      const handleConfirmUpload = () => {
        setOpenConfirmationDialog(false);
        handleUploadDocument()
      };

      const getVendorDocument=async()=>{
        try{
            const {REACT_APP_GET_VENDORS_DOCUMENT}=process.env
            const res = await axios.get(REACT_APP_GET_VENDORS_DOCUMENT+`?vendor_id=${vendorid}`)
            if(res?.data?.data?.length>0){
                setDocument(res?.data?.data)
            }
        }catch(err){

        }
      }

      useEffect(()=>{
        if(vendorid){
            getVendorDocument()
        }
      },[vendorid])

      const handleUploadDocument=async()=>{
        try{
           const formData = new FormData();
           formData.append("file", selectedFile);
           const {REACT_APP_VENDORS_DOCUMENT}= process.env
           const response = await fetch(
               REACT_APP_VENDORS_DOCUMENT + `?vendor_fk=${vendorid}&email=${email}`,
               {
                 method: "POST",
                 body: formData,
               }
             );
       
             if (response.ok) {
               //console.log("File Updated Successfully");
               setAlert('File Upload Successfully')
             } else {
               setWarning('File not Uploaded')
             }

        }catch(err){ 
           setWarning('File not Uploaded')
        }
     }


 const handleCloseConfirmationDialog = () => {
   setOpenConfirmationDialog(false);
 };


 const dateBodyTemplate = (rowData) => {
    const formattedDate = new Date(rowData).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    });
    const datePart = formattedDate.slice(0, -8);
    const timePart = formattedDate.slice(-8);
    return (
        <div>
            <div className="date-part">{datePart} </div>
            <div className="time-part">{timePart}</div>
        </div>
    );
};

  return (
    <div>
             <div style={{ display: "flex", justifyContent: "end" }}>
                    <div style={{ float: "right", paddingRight: "20px" }}>
                    <input
                                type="file"
                                onChange={(e)=>{
                                    if(handleAccess('Vendor','Document')){
                                       handleFileSelect(e)
                                    }else{
                                        setAuthPop(true)
                                    }
                                } }
                                style={{ display: "none" }}
                                id="file-input"
                            />
                    <label htmlFor="file-input">
                        <div>
                        <span className='custom-btn btn-3' style={{textAlign:'center'}}
                            size="small"
                            variant="contained"
                        >                  
                            <span >Add Document</span>
                        </span>
                        </div>
                        </label>
                    </div>
                </div>


        <div className="vendor-document-table">
                    <div>
                        <div>Document Name</div>
                        <div>Upload by</div>
                        <div>Uploaded At</div>
                        <div>View</div>
                    </div>

                    {vendorDocument?.map((data, id) => (
                        <div key={id} className="vendor-document-table-body">
                            <div>{data?.file_name}</div>
                            <div>{data?.email}</div>
                            <div>{dateBodyTemplate(data?.created_at)}</div>
                            <div   >

                                <MdPreview onClick={()=>{
                                    setDocumentOpen(data?.url)
                                    }}  style={{cursor:'pointer'}} 
                                    color='blue' size={25} />
                            </div>
                        </div>
                    ))}
                </div>

                <ConfirmationDialog openConfirmationDialog={openConfirmationDialog} handleCloseConfirmationDialog={()=>{handleCloseConfirmationDialog()}} excelFileName={excelFileName}
     handleCancelUpload={()=>{handleCancelUpload()}} handleConfirmUpload={()=>{handleConfirmUpload()}}  />

                <DocumentViewer open={documentOpen} setOpen={setDocumentOpen} />

              
      
    </div>
  )
}

export default VendorDocument
