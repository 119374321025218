import React, { useEffect, useState } from "react";
import "./InvoiceTable.css";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Paper,Typography
} from "@mui/material";
import {
  Modal,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import MyDocument from "./Invoice_Document";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import tick from "../../Images/tick.gif";
import { ErrorModel } from "./Client_Invoice";
import axios from "axios";
import { IoLogoWhatsapp } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import Slide from "@mui/material/Slide";
import ViewNote from "./ViewNote";
import { DialogContentText } from "@mui/material";
import * as XLSX from "xlsx";
import Alerts from "../Radiologists/Alerts";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: "absolute",

  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid lightblue",
  boxShadow: 24,
  p: 4,
};

const DownloadInvoice = ({
  clientid,
  start,
  end,
  invoice_date,
  clientname,
  handleSelfSend,
  userAccess,
  setAuthPop,
  handleAccess,
}) => {
  const [customErrorPop, setCustomErrorPop] = useState(false);
  const [customError, setCustomError] = useState("");
  const [downlaodButton, setDownloadButton] = useState(true);
  const [downloadPop, setDownloadPop] = useState(false);
  const [pdf, setPdf] = useState("Download");

  const DownloadInvoices = async (start, end, invoice) => {
    // setCustomError('Still Fecthing Data,Try Again')
    try {
      const { REACT_APP_CLIENT_DOWNLOAD_INVOICE_URL } = process.env;
      const response = await axios.get(
        REACT_APP_CLIENT_DOWNLOAD_INVOICE_URL +
        `?client_fk=${clientid}&start_date=${start}&end_date=${end}&invoice_date=${invoice}`
      );
      if (response.data.status_code == 205) {
        setCustomError("No Invoice Data");
      } else if (response.status === 200) {
        const res = await fetch(response.data.pdf_link);
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${clientname}_${start}_to_${end}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log(err);
      setCustomError("Response Error");
    } finally {
      setDownloadPop(false);
      setPdf("Download");
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          if (handleAccess("Hospital", "Download Invoice") === true) {
            setDownloadPop(true);
          } else {
            setAuthPop(true);
          }
        }}
        style={{ marginLeft: "10px", fontSize: "10px",width:'105px' }}
      >
        Download
      </Button>
      <Modal
        open={downloadPop}
        onClose={() => {
          setDownloadButton(true);
          setDownloadPop(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                color: "darkblue",
                marginBottom: "40px",
                fontSize: "20px",
              }}
            >
              Invoices{" "}
            </div>

            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px", fontSize: "10px" }}
              onClick={() => {
                DownloadInvoices(start, end, invoice_date);
                setPdf("Downloading....");
              }}
            >
              {pdf}{" "}
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px", fontSize: "10px" }}
              onClick={() => {
                handleSelfSend(start, end, invoice_date);
                setDownloadPop(false);
              }}
            >
              Send Invoice To Self
            </Button>
          </div>
        </Box>
      </Modal>

      <ErrorModel
        data={customErrorPop}
        error={customError}
        onClose={() => {
          setCustomErrorPop(false);
        }}
      />
    </div>
  );
};

function InvoiceTable({
  data,
  clientname,
  handleSelfSend,
  userAccess,
  setAuthPop,
  handleAccess,
  setApiResponse,
  setErrorAlert,
  email,
}) {
  const [note, setNote] = useState(false);
  const [sendNote, setSendNote] = useState("");
  const [noteRes, setNoteRes] = useState("");
  const [error, setError] = useState("");
  const monthNames = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];
  

  const dateBodyTemplate = (rowData) => {
    const formattedDate = new Date(rowData).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const datePart = formattedDate.slice(0, -8);
    const timePart = formattedDate.slice(-8);
    return (
      <div>
        <div className="date-part">
          {datePart}
          {timePart}{" "}
        </div>
        {/* <div className="time-part">{timePart}</div> */}
      </div>
    );
  };

  const handleMonth = (month) => {
    const [monthNumber, monthYear] = month.split(" ");
    // console.log(monthNames[monthNumber - 1])
    return monthNames[monthNumber - 1] + " " + monthYear;
  };
  const useStyles = makeStyles({
    root: {
      "&.MuiTableCell-head ": {
        backgroundColor: "blue !important",
      },
    },
  });

  const handleDownload = (data) => {
    //console.log(data);
    const datadownload = data?.summary_data?.map((data) => {
      return {
        "Invoice Details": data?.Details,
        Amount: data?.Amount,
      };
    });
    //console.log(datadownload);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(datadownload);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `Download_Summary.xlsx`);
  };

  const handleSendNote = async (data) => {
    try {
      const body = [
        {
          clientId: data?.client_fk,
          startDate: data?.start_date?.split("T")[0],
          endDate: data?.end_date?.split("T")[0],
          invoiceDate: data?.invoice_sent_date?.split("T")[0],
          user_email: email,
        },
      ];
      //console.log(body);
      //   setNoteRes({
      //     "client_fk": 2503,
      //     "summary_data": [
      //         {
      //             "Details": "Invoice Value  INV/5C/23-24/1-31/5446",
      //             "SAC/HSN": "999321",
      //             "Amount": 63380.0
      //         },
      //         {
      //             "Details": "Credit Note -  CRN/5C/23-24/1-30/5446",
      //             "SAC/HSN": "999321",
      //             "Amount": -14446.25
      //         },
      //         {
      //             "Details": "Debit Note -  DBN/5C/23-24/1-30/5446",
      //             "SAC/HSN": "999321",
      //             "Amount": 337.5
      //         },
      //         {
      //             "Details": "Net Payable for Teleradiology services From October 1 2023 To October 31 2023",
      //             "SAC/HSN": "",
      //             "Amount": 49271.25
      //         }
      //     ]
      // })

      const { REACT_APP_CLIENT_SEND_NOTE } = process.env;
      const res = await axios.post(REACT_APP_CLIENT_SEND_NOTE, body);
      if (res?.data?.length > 0) {
        if (res.data[0] === "Email sent successfully") {
          setNoteRes(res?.data[1]);
        } else {
          setError("Sent Note Failed");
        }
      }
      //console.log(res);
    } catch (Err) {
      setError("Sent Note Failed");
    }
  };

  const classes = useStyles();

  return (
    <div className="table-main">
      <TableContainer
        component={Paper}
        style={{ height: "calc(100vh - 350px)" }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow className={classes.root}>
              <TableCell className="table-size">Invoice Number</TableCell>
              <TableCell className="table-size">Month</TableCell>
              <TableCell className="table-size">Start Date</TableCell>
              <TableCell className="table-size">End Date</TableCell>
              <TableCell className="table-size">Invoice Sent Date</TableCell>
              <TableCell className="table-size">Invoice</TableCell>

              <TableCell className="table-size">Net Pay</TableCell>

              <TableCell className="table-size">Note Sent</TableCell>
              <TableCell className="table-size">Pending Note</TableCell>
              <TableCell className="table-size">Email</TableCell>
              <TableCell className="table-size">WhatsApp</TableCell>
              <TableCell className="table-size">Sent By</TableCell>
              <TableCell className="table-size">Download Invoice</TableCell>
              <TableCell sx={{backgroundColor:'#324BB2  !important'}}  className="table-size">Status</TableCell>
              <TableCell sx={{backgroundColor:'#324BB2  !important'}}  className="table-size">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((data, id) => (
              <TableRow key={id}>
                <TableCell className="table-size">{data.invoice_num}</TableCell>
                <TableCell className="table-size">
                  {handleMonth(data.invoice_month)}
                </TableCell>
                <TableCell className="table-size">
                  {data.start_date.split("T")[0]}
                </TableCell>
                <TableCell className="table-size">
                  {data.end_date.split("T")[0]}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }} className="table-size">
                  {data.invoice_sent_date.split("T")[0]}
                </TableCell>

                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      width: "100px",
                      textAlign: "center",
                    }}
                  >
                    <div> </div>
                    <div>F - Rs . {data?.first_invoice_amount}</div>
                  </div>
                  <div style={{ display: "flex", width: "100px" }}>
                    <div> </div>
                    <div>R - Rs . {data?.revision}</div>
                  </div>
                  <div style={{ display: "flex", width: "100px" }}>
                    <div> </div>
                    <div>L - Rs . {data?.last_invoice_amount}</div>
                  </div>
                </TableCell>

                <TableCell>
                  {data?.last_invoice_amount +
                    data?.sent_notes +
                    data?.pending_note}
                </TableCell>

                <TableCell sx={{ width: "250px" }} className="table-size">
                  <div>{data?.sent_notes}</div>
                  <Button
                    onClick={() => setNote(data)}
                    variant="contained"
                    style={{ marginTop: "5px", fontSize: "10px" }}
                  >
                    ViewNotes
                  </Button>
                </TableCell>
                <TableCell className="table-size">
                  <div>
                  {data?.pending_note}
                  </div>
                  <Button
                    onClick={() => {
                      if (handleAccess("Hospital", "Send Note") === true) {
                        setSendNote(data);
                      } else {
                        setAuthPop(true);
                      }
                    }}
                    variant="contained"
                    style={{ marginTop: "5px", fontSize: "10px" }}
                  >
                    SendNote
                  </Button>
                </TableCell>

                <TableCell sx={{ width: "250px" }} className="table-size">
                  {data?.email_invoice_sent_date && (
                    <div>
                      <HiOutlineMail size={20} style={{ color: "red" }} />
                      <div>
                        {" "}
                        {dateBodyTemplate(data?.email_invoice_sent_date)}
                      </div>
                    </div>
                  )}
                </TableCell>
                <TableCell sx={{ width: "250px" }} className="table-size">
                  {data?.watsapp_invoice_sent_date && (
                    <div>
                      <IoLogoWhatsapp size={20} style={{ color: "green" }} />
                      <div>
                        {" "}
                        {dateBodyTemplate(data?.watsapp_invoice_sent_date)}
                      </div>
                    </div>
                  )}
                </TableCell>
                <TableCell className="table-size">
                  {data.invoice_sent_by}
                </TableCell>
                <TableCell sx={{ width: "250px" }} className="table-size">
                  <DownloadInvoice
                    handleAccess={handleAccess}
                    handleSelfSend={handleSelfSend}
                    invoice_date={data.invoice_sent_date.split("T")[0]}
                    start={data.start_date.split("T")[0]}
                    end={data.end_date.split("T")[0]}
                    clientid={data.client_fk}
                    clientname={clientname}
                    userAccess={userAccess}
                    setAuthPop={setAuthPop}
                  />

                  <Button
                    onClick={() => setSendNote(data)}
                    variant="contained"
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "10px",
                    }}
                  >
                    Resend Note
                  </Button>
                </TableCell>
                <TableCell className="table-size">
                { data?.flag==='PAID' &&
                                <div style={{backgroundColor:'rgb(187 247 208)', padding:'2px',borderRadius:'3px' }} >{data?.flag}</div>
                                 }
                                   {data?.flag==='UNPAID' &&
                              <div style={{backgroundColor:'rgb(253 164 175)', padding:'2px',borderRadius:'3px' }} >{data?.flag}</div>
                               }
                               {data?.flag==='PARTIALLY PAID' &&
                              <div style={{backgroundColor:'rgb(226 232 240)', padding:'2px',borderRadius:'3px' }} >{data?.flag}</div>
                               }
                  </TableCell>
                <TableCell className="table-size">{new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(
                      data?.balance_amount
                      // handleTotal(mapData, "sent_notes")
                    )}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
       
      </TableContainer>
      <TableContainer component={Paper}>
        <Typography variant="body2" align="end" style={{ padding: '8px' }}>
          Total Balance - {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(data?.reduce((total,data)=>total+data?.balance_amount,0))}
        </Typography>
      </TableContainer>

      <ViewNote
        note={note}
        setNote={setNote}
        setApiResponse={setApiResponse}
        setErrorAlert={setErrorAlert}
        email={email}
        handleAccess={handleAccess}
        setAuthPop={setAuthPop}
      />

      <Dialog open={noteRes} onClose={() => setNoteRes("")}>
        <DialogContent style={{ width: "500px" }}>
          <div style={{ textAlign: "center" }}>
            <div style={{ marginTop: "20px" }}>
              <img style={{ width: "150px" }} src={tick} />
            </div>
            <div style={{ marginTop: "20px", fontSize: "18px", color: "gray" }}>
              {" "}
              Email Sent Successfully
            </div>

            <div>
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                onClick={() => handleDownload(noteRes)}
                color="primary"
              >
                Download Summary Data
              </Button>
            </div>

            <Button
              style={{ marginTop: "20px" }}
              variant="contained"
              onClick={() => setNoteRes("")}
              color="primary"
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={sendNote}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSendNote("")}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirmation !!!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Do you want to send the Note
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSendNote("")}>Disagree</Button>
          <Button
            onClick={() => {
              // handleSendNote(resendNote)
              handleSendNote(sendNote);
              setSendNote("");
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Alerts warning={error} setWarning={setError} />
    </div>
  );
}

export default InvoiceTable;
