import React, { useEffect, useState } from "react";
import "./Payer.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, Button } from "@mui/material";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Link } from "react-router-dom";
import axios from "axios";
import { IoLogoWhatsapp } from "react-icons/io";
import * as XLSX from "xlsx";
function Payer({handleAccess,setAuthPop}) {

  const [data,setData]=useState([])
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [payerCheck,setPayerCheck]=useState(false)
  const [search,setSearch]=useState('')
  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(
    (data?.filter((data, id) => {
    //   return data;
      if(search===null){
          return data
      }
      if (data.client_name?.toString().toLowerCase().includes(search?.toString().toLowerCase())||
        data.name?.toString().toLowerCase().includes(search?.toString().toLowerCase())||
      data?.type?.toString().toLowerCase().includes(search?.toString().toLowerCase())||
      data?.email?.toString().toLowerCase().includes(search?.toString().toLowerCase())||
      data?.unique_id?.toString().toLowerCase().includes(search?.toString().toLowerCase())
      ) {
        return data
      }
    }).length || 0) / itemsPerPage
  );

  const handleDownload =()=>{
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `CLients_data.xlsx`);
  }


  const dateBodyTemplate = (rowData) => {
    const formattedDate = new Date(rowData).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const datePart = formattedDate.slice(0, -8);
    const timePart = formattedDate.slice(-8);
    return (
      <div>
        <div className="date-part">
          {datePart}
          {timePart}{" "}
        </div>
        {/* <div className="time-part">{timePart}</div> */}
      </div>
    );
  };  

  useEffect(()=>{
    handleGetPayerInfo()
  },[payerCheck])

  const handleGetPayerInfo =async()=>{
    try{
        let check;
      if(payerCheck==='on'){
    check=true
      }else{
        check=false
      }
    const {REACT_APP_GET_COLLECTION_PAYER_CONTACT} = process.env
    const res = await axios.get(REACT_APP_GET_COLLECTION_PAYER_CONTACT+`payer_filter=${payerCheck}`)
    if(res?.data?.data?.length>0){
        setData(res?.data?.data)
    }
}catch(err){

}
  }


  const handleChangePayer =(e)=>{
    //  console.log(e.target.value)
      setPayerCheck(prev=>!prev)
  }

  return (
    <div className="payer-tab">
      <div className="payer-header">
      <span style={{fontWeight:'bold',fontSize:'25px'}} >Payer</span>
        <div>
           
          <input
            className="payer-input"
            type="text"
            placeholder="Global Search"
            onChange={(e)=>setSearch(e.target.value)}
          ></input>
          <FormControlLabel
            control={<Checkbox checked={payerCheck} onChange={handleChangePayer} className="payer-checkbox"  />}
            label="Payer"
          />
        </div>
        <Button 
         onClick={()=>{
          if(handleAccess('Collection','Download Clients')){
            handleDownload()
          }else{
            setAuthPop(true)
          }
         }}
        variant="contained" size="small">
          Download CSV
        </Button>
      </div>

      <div className="payer-table">
        <div className="payer-table-head">
        <div>Client Name</div>
          <div>Name</div>
          <div>Designation</div>
          <div>Number</div>
          <div>WhatsApp</div>
          <div>Email</div>
          <div>Last Contacted</div>
          <div>ReadMore</div>
        </div>

        {data?.filter((data, id) => {
    //   return data;
      if(search===null){
          return data
      }
      if (data.client_name?.toString().toLowerCase().includes(search?.toString().toLowerCase())||
        data.name?.toString().toLowerCase().includes(search?.toString().toLowerCase())||
      data?.type?.toString().toLowerCase().includes(search?.toString().toLowerCase())||
      data?.email?.toString().toLowerCase().includes(search?.toString().toLowerCase())||
      data?.phone?.toString().toLowerCase().includes(search?.toString().toLowerCase())||
      data?.unique_id?.toString().toLowerCase().includes(search?.toString().toLowerCase())
      ) {
        return data
      }
    })?.slice(indexOfFirstItem, indexOfLastItem)?.map((data, id) => (
          <div key={id} className="payer-table-body">
            <div>{data?.client_name}</div>
            <div>{data?.name}</div>
            <div>{data?.type}</div>
            <div>{data?.phone}</div>
            <div>{data?.type==='PAYER' && <IoLogoWhatsapp size={20} color="green" />}</div>
            <div >{data?.email}</div>
            <div>{data?.last_contacted?dateBodyTemplate(data?.last_contacted):''}</div>
            <div >
              <Link to={`/Collections/payer/${data?.id}`} className="firstreadmoreIcon">
                <ArrowCircleRightIcon
                
                  sx={{ color: "blue",fontSize: "36px" }}
                />{" "}
              </Link>
              <ReadMoreIcon
          className="readmoreIcon"
          style={{
            fontSize: "36px",
            display: "block",
            margin: "auto",
            color: "blue",
          }}
        />
            </div>
          </div>
        ))}
      </div>
      <div style={{ float: "right", marginRight: "20px" }}>
        <Button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </Button>
        <span>{`${currentPage} of ${totalPages}`}</span>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default Payer;
