import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import * as XLSX from 'xlsx';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, styled, DialogActions, DialogTitle, DialogContent,TextField,InputAdornment } from '@mui/material';
import { ErrorModel } from '../Hospital/TransactionsTab';
import { set } from 'date-fns/fp';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function Notify({  setResponse }) {
    const [sendType, setSendType] = useState(null)
    const [selectedFile, setSelectedFile] = useState('')
    const [excelFileName, setExcelFileName] = useState('')
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [error, SetError] = useState(null)
    const [previewData, setPreviewData] = useState([])
    const [selectedClients, setSelectedClients] = useState([]);
    const [search,setSearch]=useState('')


    const handleDownload = () => {
        const data = [{
            'Unique Id': '5C0001',
            'Client Name': 'Jeevan Hospital',
            'Message': `Dear Customer, 

                You have an outstanding amount of Rs {amount} as of {month]. Kindly pay as early as possible to avoid service interruption.

                Kindly contact us at +91 6366433919 / 6366433905 / 9900892536 / 8431688274 immediately for any queries 
              `,
        }]
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, 'NotificationSheet');
        XLSX.writeFile(wb, `Notification.xlsx`);
    }


    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        //console.log('Selected file:', selectedFile);
        setSelectedFile(selectedFile);
        setExcelFileName(selectedFile.name);
        setOpenConfirmationDialog(true);
        // handleClose()
    }

    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);

    };

    const handleCancelUpload = () => {
        setOpenConfirmationDialog(false);
        setSelectedFile(null); // Reset selectedFile state when canceling the upload
    };


    const handleConfirmUpload = () => {
        setOpenConfirmationDialog(false);
        handleReadExcel(); // Call handleReadExcel to process the selected file after confirmation
        // handleClose(); // Close the UploadCost modal after confirming the upload
    };

    const handleReadExcel = () => {
        if (selectedFile) {
            setExcelFileName(selectedFile.name);


            const reader = new FileReader();

            reader.onload = (event) => {
                const data = event.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                const sheetName = 'NotificationSheet';
                const sheet = workbook.Sheets[sheetName];

                if (!sheet) {
                    //console.log('Incorrect file or format".');
                    SetError('Incorrect Sheet Name')
                    return;
                }

                const headers = [];
                for (const cell in sheet) {
                    //console.log(sheet[cell].v)
                    if (sheet[cell].v !== undefined) {
                        headers.push(sheet[cell].v);
                    }
                }

                //console.log(headers)
                if (headers[0] !== 'Unique Id' && headers[1] !== 'Client Name' && headers[2] !== 'Message' ) {
                    SetError('Incorrect file or format')
                    return;
                }

                const jsonData = XLSX.utils.sheet_to_json(sheet, {
                    raw: false, // Keep cell values as string
                });

                if (jsonData.length === 0) {
                    // setErrorPop('No data found in the selected file.')
                    //console.log('No data found in the selected file.');
                    SetError('No data found in the selected file.')
                    return;
                }
                if (jsonData.length === 1) {
                    if (jsonData[0]['Unique Id'] === '' && jsonData[0]['Client Name'] === '' && jsonData[0]['Message'] === '') {
                        //console.log('No data found in the selected file.');
                        SetError('No data found in the selected file.')
                        return;
                    }
                }

                const dataToSend = jsonData.filter((row) => row['Unique Id'] !== undefined && row['Unique Id'] !== '' && row['Client Name'] !== undefined && row['Client Name'] !== '' && row['Message'] !== undefined && row['Message'] !== '').map((row) => {
                    return {
                        unique_id: row['Unique Id'],
                        client_name: row['Client Name'],
                        message: row['Message'],

                    }
                });

                if (jsonData.length !== dataToSend.length) {
                    SetError("Data's Missing!!! Please fill all the value")
                    return;
                }

                //console.log(jsonData)
                //console.log(dataToSend)
                setPreviewData(dataToSend)
                // onFileUpload(dataToSend)
            }
            reader.readAsBinaryString(selectedFile);
        }
    };

    const handleSelectAllClick = (isChecked) => {
        if (isChecked) {
          // If the checkbox is checked, select all visible clients in the table
          const visibleClientIds = previewData.map((item) => item.unique_id);
          setSelectedClients(visibleClientIds);
        } else {
          // If the checkbox is unchecked, clear all selected clients
          setSelectedClients([]);
        }
      };



    const handleRowSelect = (clientId) => {
        setSelectedClients((prevSelectedClients) =>
          prevSelectedClients.includes(clientId)
            ? prevSelectedClients.filter((unique_id) => unique_id !== clientId)
            : [...prevSelectedClients, clientId]
        );
      };


      const handleSend = ()=>{
        if(previewData.length===0){
            SetError('No data to send')
        }
        const selectedData = previewData.filter((item) => selectedClients.includes(item.unique_id));
        //console.log(selectedData)
        if(selectedData.length===0){
            SetError('Select Atleast one Clients To Send Notification')
            return
        }else{
            handleFileUpload(selectedData)
            setSendType(null)
            setPreviewData([])
            setSelectedClients([])
            // handleClose()
        }
      }


       const handleFileUpload=async(data)=>{
          try{
            const {REACT_APP_PUSH_NOTIFICATION_URL} = process.env
            const res = await axios.post(REACT_APP_PUSH_NOTIFICATION_URL,data)
            if(res?.status===200){
                if(res?.data?.length>0){
                if(res?.data[0]==="Notification sent successfully"){
                    setResponse('Notification sent successfully')
                }else{
                  SetError('Send Notification Failed')
                }
                }else{
                    SetError('Send Notification Failed')

                }
            }else{
                SetError('Send Notification Failed')

            }


          }catch(err){
            SetError('Send Notification Failed')

          }
       }
    

    return ( <div>
            <div className='popUptab' style={{alignItems:'center'}}>
                <div>
                    <Button onClick={handleDownload}
                        style={{ fontSize: '11px', marginLeft: '10px', padding: '10px', borderRadius: '30px', width: '150px' }} variant="contained" size='small' color="primary" component="span">

                        Download Sample
                    </Button>
                </div>
                <div>
                    <Button style={{ fontSize: '11px', marginLeft: '10px', padding: '10px', borderRadius: '30px', width: '150px' }} component="label" variant="contained" >
                        Upload file
                        {(!openConfirmationDialog) && <VisuallyHiddenInput type="file" onChange={handleFileSelect} />}
                    </Button>

                </div>

                <div>
                    <Button onClick={()=>{
                        handleSend()
                    }} style={{ fontSize: '11px', marginLeft: '10px', padding: '10px', borderRadius: '30px', width: '150px' }} size='small' color="primary"  variant='contained' >
                        Send
                    </Button>

                </div>

                {previewData.length>0 &&
                <div><TextField className='search-div' style={{marginTop:'7px'}}
                 value={search} onChange={(event) => setSearch(event.target.value)} id="outlined-basic" label="Search" variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    /></div>
                }

            </div>
         {previewData.length>0 &&
            <div className='remainder-bulk-table'>
                <div className='remainder-bulk-table-head'>
                <div >
                    <input 
                    style={{cursor:'pointer'}}
                      type="checkbox"
                      checked={selectedClients.length === previewData.length}
                      onChange={(e) => handleSelectAllClick(e.target.checked)}
                    />
                    </div>
                    <div>Client ID</div>
                    <div>Client Name</div>
                    <div className='remainder-bulk-message'>Message</div>
                </div>
                {previewData?.filter((data, id) => {
                    if(search===null){
                        return data
                    }
                    if (data.client_name?.toString().toLowerCase().includes(search?.toString().toLowerCase())||data?.unique_id?.toString().toLowerCase().includes(search?.toString().toLowerCase())) {
                      return data
                    }
                  })?.map((data, id) => (
                    <div key={id} className='remainder-bulk-table-body'>
                        <div  >
                        <input
                        style={{cursor:'pointer'}}
                        type="checkbox"
                        checked={selectedClients.includes(data.unique_id)}
                        onChange={() => handleRowSelect(data.unique_id)}
                      /></div>
                        <div>{data?.unique_id}</div>
                        <div>{data?.client_name}</div>
                        <div className='remainder-bulk-message'>{data?.message}</div>
                    </div>
                ))
                }

                
            </div>
}

            <Dialog width={500} open={openConfirmationDialog} onClose={handleCloseConfirmationDialog}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    {excelFileName && <p>Selected Excel File: {excelFileName}</p>}
                    {uploadStatus && <p style={{ color: 'red' }}>{uploadStatus}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelUpload} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmUpload} color="primary" autoFocus> {/* Add autoFocus attribute here */}
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>


            <ErrorModel data={error} onClose={() => SetError('')} />
        

            </div>

    );
}