import React, { useEffect } from 'react';
import { Modal, Box, Button } from '@mui/material';
import Slide from '@mui/material/Slide';
import Zoom from '@mui/material/Zoom';
import EmailIcon from '@mui/icons-material/Email';
import tick from '../../Images/tick.gif'
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper } from '@mui/material'
import * as XLSX from 'xlsx';

const ApiResponsePopup = ({ apiResponse, onClose, data }) => {

    const NotSentinvoice = () => {
      const data = apiResponse.map(data => (
            {
              ...data,
              'status': 'Not Sent'
            }
      ))
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `InvoiceNotSent.xlsx`);
    }


  const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) scale(1.2)',

        bgcolor: 'background.paper',
        border: '1px solid lightblue',
        boxShadow: 24,
        borderRadius: '2px',
        p: 4,
  };

  return (

    <Modal
      open={!!apiResponse}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >


        <Box sx={style}>
          {(apiResponse) ? (
              <div>
                  {(apiResponse?.toString().toLowerCase() === 'Invoice sent successfully'?.toString().toLowerCase() || apiResponse?.toString().toLowerCase() === 'Email sent successfully'?.toString().toLowerCase()) ?
                    <div style={{ textAlign: 'center', width: '300px' }} className="api-response-content">

                          <img style={{ width: '100px' }} src={tick} />
                          <pre className="api-response-json" style={{ color: 'gray' }}>
                            {apiResponse}
                          </pre>
                          <Button variant='contained' size='small' style={{ fontSize: '9px', marginTop: '10px' }} className="close-button" onClick={onClose}>
                              Close
                          </Button>
                    </div>
                    : (apiResponse?.length > 0) &&
                    <div style={{ textAlign: 'center', }} className="api-response-content">
                        {(apiResponse[0]?.toString().toLowerCase() === 'Invoice sent successfully'?.toString().toLowerCase()) ?
                            <div style={{ width: '300px' }}>
                                  <img style={{ width: '100px' }} src={tick} />
                                  <pre className="api-response-json" style={{ color: 'gray' }}>{apiResponse ? 'Invoice Sent Successfully' : ''}</pre>
                                  <Button variant='contained' size='small' style={{ fontSize: '9px', marginTop: '10px' }} className="close-button" onClick={onClose}>
                                    Close
                                  </Button>
                            </div>
                            :
                            <div style={{ width: '500px' }}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <pre className="api-response-json" style={{ color: 'gray', fontSize: '15px' }}>Email Partially Sent</pre>
                                    <Button variant='contained' size='small' style={{ fontSize: '9px', marginTop: '10px', float: 'right', marginBottom: '10px' }} className="close-button" onClick={NotSentinvoice}>
                                      Download
                                    </Button>
                                  </div>
                                  <TableContainer component={Paper} className='invoice-container' style={{ height: 'calc(100vh - 330px)', marginTop: '5px' }} >
                                        <Table stickyHeader>
                                              <TableHead  >
                                                  <TableRow className='invoice-thead' >
                                                    <TableCell>Client ID</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Status</TableCell>

                                                  </TableRow>
                                              </TableHead>

                                              <TableBody>
                                                    {
                                                      apiResponse?.map((data, id) => (

                                                        <TableRow key={id} className='invoice-tbody'>
                                                     
                                                              <TableCell>{data.client_fk}</TableCell>
                                                              <TableCell>{data.client_name}</TableCell>
                                                              <TableCell>Not Sent</TableCell>

                                                        </TableRow>
                                                      ))}
                                              </TableBody>
                                        </Table>
                                  </TableContainer>
                                  <Button variant='contained' size='small' style={{ fontSize: '9px', marginTop: '20px' }} className="close-button" onClick={onClose}>
                                    Close
                                  </Button>
                            </div>
                        }
                    </div>
                  }
              </div>
              )
                :
              <div>Invoice Not Sent</div>
          }
        </Box>


    </Modal>

  );
};

export default ApiResponsePopup;
