import React, { useState } from 'react'
import AuthErro from '../../Images/AuthError.gif'
import { Dialog, DialogTitle, DialogContent, DialogActions, Box,Slide,DialogContentText,Button } from '@mui/material';
function AuthErrorPop({authPop,setAuthPop}) {
    
    return (
        <div>
            <Dialog open={authPop} onClose={() => setAuthPop('')}>

                <DialogContent style={{ width: '500px', }}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ marginTop: '20px' }}><img style={{ width: '150px' }} src={AuthErro} /></div>
                        <div style={{ marginTop: '20px', fontSize: '18px', color: 'gray' }}>Not Authorized</div>
                        <Button style={{ marginTop: '20px' }} variant='contained' onClick={() => setAuthPop('')} color="primary">
                            Close
                        </Button>
                    </div>
                </DialogContent>

            </Dialog>
        </div>
    )
}

export default AuthErrorPop
