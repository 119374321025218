import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Paper,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  GridToolbarExport,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import { dateBodyTemplate } from "../Invoice/Invoices";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";



function RadOverallInvoice({ isSidebarOpen, handleAccess, setAuthPop }) {
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().subtract(1, "month")
  );
  const [searchMonth, setSearchMonth] = useState(null);
  const [radData, setRadData] = useState([]);
  const [showTable, setShowTable] = useState("");
  const [popSearch, setPopSearch] = useState("");
  const [popTable, setPopTable] = useState([]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDateChange = (e) => {
    // setSelectedDate(e.target.value);
    //console.log(months[e.$d.getMonth()], e.$d.getFullYear());
    const monthYear = String(
      months[e.$d.getMonth()] + " " + String(e.$d.getFullYear())
    );
    console.log(monthYear);
    //console.log(e);
    setSearchMonth(monthYear);
    setSelectedDate(e);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [pagePop, setPagePop] = React.useState(0);
  const [rowsPerPagePop, setRowsPerPagePop] = React.useState(20);

  const handleChangePagePop = (event, newPage) => {
    setPagePop(newPage);
  };

  const handleChangeRowsPerPagePop = (event) => {
    setRowsPerPagePop(+event.target.value);
    setPagePop(0);
  };

  useEffect(() => {
    if (selectedDate) {
      handleGetInvoice();
      handleRadGetPayable();
    }
  }, [selectedDate]);

  const handleInvoiceDownload = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `Rad_Invoice_list.xlsx`);
  };

  const handleRadGetPayable = async () => {
    try {
      setRadData([]);
      //console.log(selectedDate.$d);
      const inputDate = new Date(selectedDate.$d);
      const year = inputDate.getFullYear();
      const month = inputDate.getMonth() + 1; // Note: months are zero-based in JavaScript
      const startOfMonth = `${year}-${month.toString().padStart(2, "0")}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      const endOfMonth = `${year}-${month.toString().padStart(2, "0")}-${lastDay
        .toString()
        .padStart(2, "0")}`;
      const { REACT_APP_RAD_PAYABLE_DUMP_REPORT_URL } = process.env;

      const res = await axios.get(
        REACT_APP_RAD_PAYABLE_DUMP_REPORT_URL +
          `?dump_name=Radiologist_Payable_Data&start_date=${startOfMonth}&end_date=${endOfMonth}`
      );
      if (res.status === 200) {
        if (res.data.data.length > 0) {
          setRadData(res.data.data);
        }
      }
    } catch (err) {}
  };

  const handleGetInvoice = async () => {
    // //console.log(selectedDate)
    try {
      setData([]);
      //console.log(selectedDate.$d.getMonth(), selectedDate.$d.getFullYear());
      const month = selectedDate.$d.getMonth() + 1;
      const year = selectedDate.$d.getFullYear();
      const { REACT_APP_RAD_GET_INVOICE_URL } = process.env;
      const res = await axios.get(
        REACT_APP_RAD_GET_INVOICE_URL + `?month=${month}&year=${year}`
      );
      if (res.status === 200) {
        if (res.data.show_invoice_data.length > 0) {
          setData(res.data.show_invoice_data);
        }
      }
    } catch (err) {}
  };

  const handleTotal = (value) => {
    return data?.reduce((total, row) => total + row[value], 0);
  };

  const handlePendingRad = (data) => {
    const list = [...new Set(data.map((item) => item.rad_fk))];
    const radList = [...new Set(radData.map((item) => item.rad_fk))];
    //   let l;
    //   data?.map(item=>l?.push(item.rad_fk))
    //   //console.log(list)

    const pandingData = radList?.filter((data) => {
      if (!list?.includes(data)) {
        return data;
      }
    });
    //console.log(pandingData);

    return pandingData;
  };

  return (
    <div style={{ marginLeft: "30px" }}>
      <div className="invoice-header" style={{ marginTop: "-60px" }}>
        <div></div>
        <div className="invoice-header-filter" style={{ zIndex: "100" }}>
          <div>
            {" "}
            <Button onClick={()=>{
                if (handleAccess("Radiologists", "Download Invoice")) {
                    handleInvoiceDownload()
                  } else {
                    setAuthPop(true);
                  }
                
            }} variant="contained" style={{ fontSize: "12px" }}>
              Download
            </Button>
          </div>
          <div>
            <TextField
              className="search-div"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              id="outlined-basic"
              label="Search"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {/* <div>  */}
          {/* <input type="month" id="start" className='monthInput' label='Select Month'  name="start"  /> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={["month", "year"]}
              label="Select Month and Year"
              value={selectedDate}
              onChange={handleDateChange}
              inputFormat="MM/yyyy"
              renderInput={(params) => (
                <TextField className="monthPicker" value={selectedDate} />
              )}
            />
          </LocalizationProvider>
          {/* </div> */}
          <ClearIcon />
        </div>
      </div>

      <div className="invoice-content">
        <div className="invoice-table">
          <Paper
            sx={{ overflow: "hidden" }}
            components={{
              Toolbar: () => {
                return (
                  <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
                    <GridToolbarDensitySelector
                      sx={{ width: "100px", color: "black" }}
                    />
                    <GridToolbarColumnsButton
                      sx={{ margin: "10px", color: "black" }}
                    />
                    <GridToolbarQuickFilter />
                    <GridToolbarExport
                      sx={{ width: "100px", color: "black" }}
                    />
                  </GridToolbarContainer>
                );
              },
            }}
          >
            <TableContainer
              component={Paper}
              className="invoice-container"
              style={{ height: "calc(100vh - 230px)" }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="invoice-thead">
                    <TableCell>
                      <TableSortLabel>Rad Name</TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>Invoice Number</TableSortLabel>
                    </TableCell>
                    <TableCell>Month</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Invoice Generated Date</TableCell>
                    <TableCell
                    //  key={'first_invoice_amount'}
                    // sortDirection={orderBy === 'first_invoice_amount' ? order : false}
                    >
                      <TableSortLabel>First Invoice Amount</TableSortLabel>
                    </TableCell>

                    <TableCell
                    //  key={'first_invoice_amount'}
                    // sortDirection={orderBy === 'first_invoice_amount' ? order : false}
                    >
                      <TableSortLabel>Revision</TableSortLabel>
                    </TableCell>

                    <TableCell
                    //  key={'first_invoice_amount'}
                    // sortDirection={orderBy === 'first_invoice_amount' ? order : false}
                    >
                      <TableSortLabel>Last Invoice Amount</TableSortLabel>
                    </TableCell>

                    <TableCell>Generated By</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .filter((data, id) => {
                      if (
                        data.rad_name
                          ?.toString()
                          .toLowerCase()
                          .includes(search?.toString().toLowerCase())
                      ) {
                        return data;
                      }
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, id) => (
                      <TableRow key={id} className="invoice-tbody">
                        <TableCell>{data?.rad_name}</TableCell>
                        <TableCell>{data?.invoice_num}</TableCell>
                        <TableCell>
                          {/* {handleMonth(data?.invoiceGeneratedDate)} */}
                          {data?.invoice_month}
                        </TableCell>
                        <TableCell>
                          {dateBodyTemplate(data?.start_date)}
                        </TableCell>
                        <TableCell>
                          {dateBodyTemplate(data?.end_date)}
                        </TableCell>
                        <TableCell>
                          {dateBodyTemplate(data?.invoice_sent_date)}
                        </TableCell>
                        <TableCell>{data?.first_invoice_amount}</TableCell>
                        <TableCell>{data?.revision}</TableCell>
                        <TableCell>{data?.last_invoice_amount}</TableCell>

                        <TableCell>{data?.invoice_sent_by}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
        <div className="invoice-summary">
          <div>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => {
                  setShowTable("first_invoice_amount");
                  setPopTable(data);
                }}
                className="invoice-clients"
              >
                <div style={{ fontSize: "1.2vw" }}>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(handleTotal("first_invoice_amount"))}
                </div>
                <div
                  style={{
                    color: "darkblue",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  First Invoice Amount
                </div>
              </div>

              <div
                onClick={() => {
                  setShowTable("last_invoice_amount");
                  setPopTable(data);
                }}
                className="invoice-clients"
              >
                <div style={{ fontSize: "1.2vw" }}>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(handleTotal("last_invoice_amount"))}
                </div>
                <div
                  style={{
                    color: "darkblue",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  Total Invoice Amount
                </div>
              </div>
            </div>
            <div>
              <div
                onClick={() => {
                  setShowTable("revision");
                  setPopTable(data);
                }}
                className="invoice-clients"
              >
                <div style={{ fontSize: "1.2vw" }}>
                  {new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(handleTotal("revision"))}
                </div>
                <div
                  style={{
                    color: "darkblue",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  Revision
                </div>
              </div>
            </div>
          </div>

          <div>
            <div style={{ display: "flex" }}>
              <div className="invoice-clients">
                <div style={{ fontSize: "1.2vw" }}>
                  {[...new Set(radData.map((item) => item.rad_fk))].length}
                </div>
                <div
                  style={{
                    color: "darkblue",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  Billled Rads
                </div>
              </div>

              <div className="invoice-clients">
                <div style={{ fontSize: "1.2vw" }}>{data?.length}</div>
                <div
                  style={{
                    color: "darkblue",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  No of Invoice Sent
                </div>
              </div>
            </div>
            <div>
              <div className="invoice-clients">
                <div style={{ fontSize: "1.2vw" }}>
                  {handlePendingRad(data).length}
                </div>
                <div
                  style={{
                    color: "darkblue",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  Pending Invoice
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={showTable}
        onClose={() => {
          setShowTable("");
          setPopSearch("");
        }}
        aria-labelledby="responsive-dialog-title"
        sx={{ overflow: "auto" }}
        width={200}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "550px",
            color: "darkblue",
          }}
          id="responsive-dialog-title"
        >
          Radiologists
          <div>
            <TextField
              className="search-div"
              value={popSearch}
              onChange={(event) => setPopSearch(event.target.value)}
              id="outlined-basic"
              label="Search"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </DialogTitle>
        <div>
          {" "}
          <Button
            onClick={() => {
              if (handleAccess("Radiologists", "Download Invoice")) {
                //   handleDownload();
              } else {
                setAuthPop(true);
              }
            }}
            style={{ float: "right", marginRight: "30px", fontSize: "11px" }}
            variant="contained"
            size="small"
          >
            Download
          </Button>
        </div>
        <DialogContent>
          <TableContainer
            component={Paper}
            className="invoice-container"
            style={{ height: "calc(100vh - 300px)" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow className="invoice-thead">
                  <TableCell>UniqueId</TableCell>
                  <TableCell>Name</TableCell>
                  {showTable === "count" ? (
                    <TableCell>Billing Type</TableCell>
                  ) : (
                    <TableCell>Amount</TableCell>
                  )}
                  <TableCell>ReadMore</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {popTable
                  ?.filter((data, id) => {
                    if (
                      data?.rad_name
                        ?.toString()
                        .toLowerCase()
                        .includes(popSearch?.toString().toLowerCase())
                    ) {
                      return data;
                    }
                  })
                  .sort((a, b) => {
                    return b[showTable] - a[showTable];
                  })
                  .slice(
                    pagePop * rowsPerPagePop,
                    pagePop * rowsPerPagePop + rowsPerPagePop
                  )
                  .map((data, id) => (
                    <TableRow key={id} className="invoice-tbody">
                      <TableCell>
                        {data?.rad_fk ? data?.rad_fk : data?.rad_fk}
                      </TableCell>
                      <TableCell>{data?.rad_name}</TableCell>
                      {showTable === "count" ? (
                        <TableCell>{/* {data?.billing_type} */}</TableCell>
                      ) : (
                        <TableCell>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(data[showTable])}
                        </TableCell>
                      )}
                      <TableCell>
                        {/* <ArrowCircleRightIcon sx={{color:'blue',cursor:'pointer'}} onClick={()=>navigateHospital(data?.unique_id)} /> */}
                        <strong>
                          <Link
                            to={`/radiologists/${
                              data?.client_unique_id
                                ? data?.client_unique_id
                                : data?.unique_id
                            }/details`}
                            className="firstreadmoreIcon"
                          >
                            <ArrowCircleRightIcon
                              sx={{
                                color: "blue",
                                width: "80px",
                                height: "30px",
                              }}
                            />{" "}
                          </Link>
                          <ReadMoreIcon
                            className="readmoreIcon"
                            style={{
                              fontSize: "36px",
                              display: "block",
                              margin: "auto",
                              color: "blue",
                            }}
                          />
                        </strong>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 100]}
            component="div"
            count={popTable?.length}
            rowsPerPage={rowsPerPagePop}
            page={pagePop}
            onPageChange={handleChangePagePop}
            onRowsPerPageChange={handleChangeRowsPerPagePop}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
                    Disagree
                </Button>
                <Button onClick={handleClose} autoFocus>
                    Agree
                </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RadOverallInvoice;
