import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as XLSX from "xlsx";
import "./Radiologistics.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  // height:'500px',
  bgcolor: "background.paper",
  border: "2px solid lightgray",
  boxShadow: 24,
  borderRadius: "30px",
  p: 4,
};
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function UploadSalary({ modalOpen, onClose, SetError, onFileUpload }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [excelFileName, setExcelFileName] = useState("");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const fileNameRef = useRef(null);

  // Function to handle closing the confirmation dialog
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  // Function to handle confirming the upload
  const handleConfirmUpload = () => {
    setOpenConfirmationDialog(false);
    handleReadExcel(); // Call handleReadExcel to process the selected file after confirmation
    onClose(); // Close the UploadCost modal after confirming the upload
  };

  const handleCancelUpload = () => {
    setOpenConfirmationDialog(false);
    setSelectedFile(null); // Reset selectedFile state when canceling the upload
  };

  const handleDateChange = (e) => {
    fileNameRef.current.innerHTML = "";
    setSelectedDate(e);
    //console.log(e.$d.getMonth(), e.$d.getFullYear());
    var month = e.$d.getMonth();
    var year = e.$d.getFullYear();
    setMonth(String(months[e.$d.getMonth()]));
    setYear(parseInt(e.$d.getFullYear()));
    // var startDate = new Date(year, month , 1); // Month is zero-based, so we subtract 1
    // var endDate = new Date(year, month+1, 0); // Get the last day of the previous month (end of the current month)

    // // Format the dates as 'dd-MM-yyyy'
    // var startDateFormatted = ('0' + startDate.getDate()).slice(-2) + '-' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '-' + startDate.getFullYear();
    // var endDateFormatted = ('0' + endDate.getDate()).slice(-2) + '-' + ('0' + (endDate.getMonth() + 1)).slice(-2) + '-' + endDate.getFullYear();
    // // console.log('Start Date: ' + startDateFormatted);
    // setMonth(startDateFormatted)
    // setYear(endDateFormatted)
    // console.log('End Date: ' + endDateFormatted);
  };

  useEffect(() => {
    if (jsonData.length > 0) {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(jsonData);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet");
      XLSX.writeFile(wb, `Rad_Inhouse_salary_${month}_${year}.xlsx`);
    }
  }, [jsonData]);
  const handleFileSelect = (event) => {
    fileNameRef.current.innerHTML = "";
    const selectedFile = event.target.files[0];
    //console.log("Selected file:", selectedFile);
    setSelectedFile(selectedFile);
    setExcelFileName(selectedFile.name);
    setOpenConfirmationDialog(true);
    onClose();
  };
  const handleReadExcel = () => {
    if (selectedFile) {
      setExcelFileName(selectedFile.name);

      const reader = new FileReader();

      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });

        // Assuming sheet name is "Cost_sheet"
        const sheetName = "Sheet";
        const sheet = workbook.Sheets[sheetName];
        // if(month===null){
        //   console.log('Please select Month');

        //   SetError('Please Select Month')
        //   return;
        // }
        if (!sheet) {
          // setErrorPop('Sheet name should be "Cost_sheet".')
          //console.log('Incorrect file or format".');
          SetError("Incorrect file or format");
          return;
        }

        const headers = [];
        for (const cell in sheet) {
          //console.log(sheet[cell].v);
          if (sheet[cell].v !== undefined) {
            headers.push(sheet[cell].v);
          }
        }

        ////console.log(headers);
        if (
          headers[0] !== "unique_id" &&
          headers[1] !== "rad_name" &&
          headers[2] !== "month" &&
          (headers[3] !== "year") & (headers[4] !== "amount")
        ) {
          SetError("Incorrect file or format");
          return;
        }

        const jsonData = XLSX.utils.sheet_to_json(sheet);

        if (jsonData.length === 0) {
          // setErrorPop('No data found in the selected file.')
          ////console.log("No data found in the selected file.");
          SetError("No data found in the selected file.");
          return;
        }
        if (jsonData.length === 1) {
          if (
            jsonData[0]["unique_id"] === "" &&
            jsonData[0]["rad_name"] === "" &&
            jsonData[0]["month"] === "" &&
            jsonData[0]["year"] === "" &&
            jsonData[0]["amount"]
          ) {
            //console.log("No data found in the selected file.");
            SetError("No data found in the selected file.");
            return;
          }
        }

        const dataToSend = jsonData
          .filter(
            (row) =>
              row["unique_id"] !== "" &&
              row["rad_name"] !== "" &&
              row["month"] !== "" &&
              row["year"] !== "" &&
              row["amount"] !== ""
          )
          .map((row) => row);
        //console.log(jsonData);
        //console.log(dataToSend);
        onFileUpload(dataToSend);
      };
      reader.readAsBinaryString(selectedFile);
    }
  };

  const downloadSample = async () => {
    fileNameRef.current.innerHTML = "";
    setJsonData([]);
    if (month === null) {
      fileNameRef.current.innerHTML = "Please select Month";
      //console.log("hello");
      return;
    }
    try {
      //console.log(typeof year);
      const res = await axios.get(
        `https://api.dhan.5cnetwork.com/api/rad_inhouse_pay?month=${month}&year=${year}`
      );
      //console.log(res);
      if (res.data.data?.length > 0) {
        const data = res.data.data.map((data) => {
          return {
            unique_id: data.unique_id,
            rad_name: data.rad_name,
            month: data.month,
            year: data.year,
            amount: data.amount,
          };
        });
        setJsonData(res.data.data);
      } else {
        const jsonData1 = [
          {
            unique_id: "",
            rad_name: "",
            month: "",
            year: "",
            amount: "",
          },
        ];
        setJsonData(jsonData1);
      }
    } catch (err) {}
  };

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid gray",
              paddingBottom: "10px",
            }}
          >
            <Typography
              style={{}}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Upload Salary
            </Typography>
            <AiOutlineCloseCircle
              onClick={() => onClose()}
              size={25}
              style={{ color: "red", cursor: "pointer" }}
            />
          </div>
          <div style={{ marginTop: "60px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "70px",
                  fontSize: "20px",
                  color: "gray",
                }}
              >
                Select Month{" "}
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={["month", "year"]}
                    label="Select Month and Year"
                    value={selectedDate}
                    onChange={handleDateChange}
                    inputFormat="MM/yyyy"
                    renderInput={(params) => (
                      <TextField className="monthPicker" value={selectedDate} />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div
              style={{ marginTop: "50px", textAlign: "center", color: "red" }}
              ref={fileNameRef}
            ></div>
            <div
              className="pop-btn-rad"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <input
                type="file"
                accept=".xlsx"
                onChange={handleFileSelect}
                style={{ display: "none" }}
                id="file-input-price3"
              />

              <Button
                onClick={() => downloadSample()}
                style={{
                  fontSize: "11px",
                  marginLeft: "10px",
                  padding: "20px",
                  borderRadius: "30px",
                  width: "200px",
                }}
                variant="contained"
                size="small"
                color="primary"
                component="span"
              >
                {/* <CloudUploadIcon style={{ marginRight: '5px',width:'10px !important'}} />  */}
                Download Sample
              </Button>

              <label htmlFor="file-input-price3">
                <Button
                  onClick={() => handleFileSelect}
                  style={{
                    fontSize: "11px",
                    marginLeft: "10px",
                    padding: "20px",
                    borderRadius: "30px",
                    width: "200px",
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                  component="span"
                >
                  Upload Salary
                </Button>
              </label>
            </div>
          </div>
        </Box>
      </Modal>
      <Dialog
        width={500}
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          {excelFileName && <p>Selected Excel File: {excelFileName}</p>}
          {uploadStatus && <p style={{ color: "red" }}>{uploadStatus}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpload} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmUpload} color="primary" autoFocus>
            {" "}
            {/* Add autoFocus attribute here */}
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UploadSalary;
