import React,{useState,useEffect,useContext} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import HospitalNotices from './HospitalNotices';
import HospitalRemainder from './HospitalRemainder';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AuthErrorPop from '../Util/AuthErrorPop';
import { UserContext } from '../../App';
import Notify from './Notify';
import { Response } from '../Radiologists/Radiologists';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function HospitalMainNotice({isSidebarOpen,email}) {
    const [value, setValue] = React.useState('notification');
    const navigate = useNavigate()
    const location = useLocation()
    const [warning, setWarning] = useState('')
    const [alert, setAlert] = useState('')
    const pathnameParts = location.pathname.split('/');
    const radId = pathnameParts[pathnameParts.length - 2];
    const tabname = pathnameParts[pathnameParts.length - 1];
    const [authPop, setAuthPop] = useState(false)
    const {user} = useContext(UserContext);
    const {handleAccess} = useContext(UserContext);
    const [response,setResponse]=useState('')

    const handleChange = (event, newValue) => {
      setValue(newValue);
      navigate(`/hospital-notices/${newValue}`)

    };

    useEffect(()=>{
      setValue(tabname)
   },)
  return ( <div className={`${isSidebarOpen ? 'notice-main' : 'notice-min'}`}>

    <div className={`${isSidebarOpen ? 'notice-div' : 'notice-div-min'}`}>
     <div className='title-report'>
      <TabContext  value={value}>
        
          <TabList className='notice-tab' onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Platform Notification" value="notification" />
            <Tab label="Reminders" value="reminders" />
            <Tab label="Notification" value="reminder-notification" />
          </TabList>
      </TabContext>
      </div>
      {
        value==='notification' &&
        <HospitalNotices user={user} handleAccess={handleAccess} authPop={authPop} setAuthPop={setAuthPop} setWarning={setWarning} setAlert={setAlert} email={email} />
      }

     {
        value==='reminders' &&
        <HospitalRemainder user={user} handleAccess={handleAccess} authPop={authPop} setAuthPop={setAuthPop} setWarning={setWarning} setAlert={setAlert} alert={alert} email={email} />
      }

      {
        value==='reminder-notification' && 
        <Notify setResponse={setResponse} />
      }
    </div>



    <Snackbar sx={{ top: '50px !important' }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={warning} autoHideDuration={6000} onClose={() => setWarning('')}>
      <Alert onClose={() => setWarning('')} severity="error" sx={{ width: '100%', }}>
        {warning}
      </Alert>
    </Snackbar>

    <Snackbar sx={{ top: '50px !important',zIndex:9999 }} anchorOrigin={{ vertical: 'top', horizontal: 'center',zIndex:9999  }} open={alert} autoHideDuration={6000} onClose={() => setAlert('')}>
      <Alert onClose={() => setAlert('')} severity="success" sx={{ width: '100%',zIndex:9999 }}>
        {alert}
      </Alert>
    </Snackbar>

    <Response data={response} setData={setResponse} />
    <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop} />
    </div>
  )
}

export default HospitalMainNotice
