import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Lock from '../../Images/lock.gif'
import Unlock from '../../Images/unlock.gif'

const style = {
  position: 'absolute',
  top: '37%',
  left: '37%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid lightblue',
  borderRadius:'20px',
  boxShadow: 24,
  p: 4,
  
};

export default function AccountConfirmation({open,setOpen,setPop,confirmPop,setConfirmPop,setChecked}) {

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setChecked('On')
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        
        <Zoom in={open}>
          <Box sx={style}>
          
            <Typography sx={{textAlign:'center'}} id="transition-modal-title" variant="h6" component="h2">
              Confirmation !!!
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            Deactivating the account will restrict the client's access to the website. We advise against doing this unless you intend to halt their access entirely.
            </Typography>

            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Instead, consider disabling "Case Activation." This will permit the client to log in and view invoices but will prevent them from submitting new cases.
            </Typography>

            <div style={{textAlign:'center',marginTop:'20px'}}>
                 <button onClick={()=>{
                    setOpen('')
                    setPop('comment')}
                    } class="custom-btn btn-3"><span>Confirm</span></button>
                 </div>

          </Box>
   
        </Zoom>
      </Modal>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={confirmPop}
        onClose={()=>{setConfirmPop('')}}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        
          <Box sx={{...style,top:'50%',left:'50%',bgcolor:'transparent',border:'none',p:0}}>
            {
               confirmPop==='INACTIVE' ?
            <img style={{width:'100%',position:'relative',borderRadius:'20px'}} src={'https://cdn.dribbble.com/users/846207/screenshots/17484538/media/32de5311b18501ff62be3ca5c0724ec2.gif'}></img>
            
            :
            <img style={{width:'100%',position:'relative',borderRadius:'20px'}} src={'https://i.pinimg.com/originals/13/d0/2a/13d02ad0776fbb737e126383937ba034.gif'}></img>
}
          </Box>
   

      </Modal>
    </div>
  );
}