import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TextField } from '@material-ui/core';
import Loading from '../Images/loader.gif'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Loader({ open, handleClose,children}) {
  
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
            <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
                <img style={{marginTop:'20px'}} src={Loading} />
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                     {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                   
                    {/* <Button onClick={handleClose}>Ok</Button> */}
                </DialogActions>
              </div>
            
      </Dialog>

    </div>
  )
}

export default Loader