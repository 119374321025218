// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.docu-head-row{
    background-color: #0059b2;
    border-radius: 90px !important;
    /* padding: 30px 0px; */
    width: 100px;
    color: white !important;
    height: 70px;
    position: sticky;
    z-index: 3;
}

.docu-add-doc{
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
}

.action>svg{
    margin: 0px 4px;
    cursor: pointer;
}

.docu-body-row>td{
   color: gray !important;
   border: none;

}

.table-docu{
    border-collapse:separate !important;
    border-spacing: 0px 15px !important;
    
}



.docu-body-row{
    border-radius: 110px !important;
    /* box-shadow: 0px 0px 5px lightcyan; */
    border-collapse: separate;
    border-spacing: 0px 15px;
    margin-top: 30px !important;
   
     box-shadow: 0px 0px 5px lightgray;
     border: 5px solid black;
}

.docu-body-row:hover{
   transform: scale(1.02) ;
   animation: 3s ease-in;
   /* background-color: gray; */
   /* font-weight: bold !important; */
}



.docu-table-container{

    border: none;
    box-shadow: none !important;
   
}`, "",{"version":3,"sources":["webpack://./src/components/Hospital/HospitalDocument.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,8BAA8B;IAC9B,uBAAuB;IACvB,YAAY;IACZ,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;GACG,sBAAsB;GACtB,YAAY;;AAEf;;AAEA;IACI,mCAAmC;IACnC,mCAAmC;;AAEvC;;;;AAIA;IACI,+BAA+B;IAC/B,uCAAuC;IACvC,yBAAyB;IACzB,wBAAwB;IACxB,2BAA2B;;KAE1B,iCAAiC;KACjC,uBAAuB;AAC5B;;AAEA;GACG,uBAAuB;GACvB,qBAAqB;GACrB,4BAA4B;GAC5B,kCAAkC;AACrC;;;;AAIA;;IAEI,YAAY;IACZ,2BAA2B;;AAE/B","sourcesContent":[".docu-head-row{\n    background-color: #0059b2;\n    border-radius: 90px !important;\n    /* padding: 30px 0px; */\n    width: 100px;\n    color: white !important;\n    height: 70px;\n    position: sticky;\n    z-index: 3;\n}\n\n.docu-add-doc{\n    display: flex;\n    justify-content: end;\n    margin-bottom: 10px;\n}\n\n.action>svg{\n    margin: 0px 4px;\n    cursor: pointer;\n}\n\n.docu-body-row>td{\n   color: gray !important;\n   border: none;\n\n}\n\n.table-docu{\n    border-collapse:separate !important;\n    border-spacing: 0px 15px !important;\n    \n}\n\n\n\n.docu-body-row{\n    border-radius: 110px !important;\n    /* box-shadow: 0px 0px 5px lightcyan; */\n    border-collapse: separate;\n    border-spacing: 0px 15px;\n    margin-top: 30px !important;\n   \n     box-shadow: 0px 0px 5px lightgray;\n     border: 5px solid black;\n}\n\n.docu-body-row:hover{\n   transform: scale(1.02) ;\n   animation: 3s ease-in;\n   /* background-color: gray; */\n   /* font-weight: bold !important; */\n}\n\n\n\n.docu-table-container{\n\n    border: none;\n    box-shadow: none !important;\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
