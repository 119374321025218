import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { convertToCSV, downloadCSV } from "./dataDownload";
import "./SendInvoices.css";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DatePicker from "react-datepicker";
import Button from "@mui/material/Button";
import ApiResponsePopup from "./ApiResponsePopup";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import CryptoJS from "crypto-js";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { WhatsApp } from "@material-ui/icons";
import AuthErrorPop from "../Util/AuthErrorPop";
import { format } from "date-fns";
import { BiSolidDollarCircle } from "react-icons/bi";
import { PiCurrencyInrFill } from "react-icons/pi";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

Modal.setAppElement("#root");

const SendInvoices = ({
  selectedRange,
  selectedBillingCategory,
  setIsButtonScaled,
  isButtonScaled,
  email,
  setWarning,
  userAccess,
  handleAccess,
  apiState,
  setApiState,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [authPop, setAuthPop] = useState(false);
  const [dollar, setDollar] = useState("");

  useEffect(() => {
    fetchDataForTable();
  }, []);

  useEffect(() => {
    setDollar("");
    if (invoiceDate && selectedBillingCategory === "dollar") {
      if (invoiceDate < new Date()) {
        handleDollar(DateFormatForDollar(invoiceDate));
      } else {
        //console.log("greater than today");
      }
    }
  }, [invoiceDate]);

  const handleDollar = async (date) => {
    try {
      const { REACT_APP_GET_DOLLAR_API_URL } = process.env;
      const res = await axios.get(
        REACT_APP_GET_DOLLAR_API_URL + `start_date=${date}&end_date=${date}`
      );
      //console.log(res.data);
      if (res?.data?.length > 0) {
        setDollar(res?.data[0]?.USD);
      } else {
        var parts = date?.split("/");
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[2], 10);
        var dateObject = new Date(year, month - 1, day);
        dateObject.setDate(dateObject.getDate() - 1);
        handleDollar(DateFormatForDollar(dateObject));
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const fetchDataForTable = async () => {
    try {
      const { REACT_APP_CLIENT_LISTING_FOR_SEND_INVOICE_URL } = process.env;
      const response = await axios.get(
        REACT_APP_CLIENT_LISTING_FOR_SEND_INVOICE_URL
      );
      const data = response.data[0];
      // Extracting only the required fields from the data
      const formattedData = data.map((item) => ({
        unique_id: item.unique_id,
        client_name: item.client_name,
        id: item.id,
        billing_type: item.billing_type, // Add the billing_type field to the formatted data
      }));
      //console.log(formattedData.length);
      setTableData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRowSelect = (clientId) => {
    setSelectedClients((prevSelectedClients) =>
      prevSelectedClients.includes(clientId)
        ? prevSelectedClients.filter((id) => id !== clientId)
        : [...prevSelectedClients, clientId]
    );
  };

  const handleSendInvoiceClick = () => {
    // Check if startdate and enddate are selected
    if (!selectedRange[0].startDate || !selectedRange[0].endDate) {
      // alert('Please select both start date and end date.');
      setWarning("Please select both start date and end date.");
      return;
    }

    // Check if startdate is month beginning date or enddate is month end date
    const startDate = selectedRange[0].startDate;
    const endDate = selectedRange[0].endDate;
    const isStartDateMonthBeginning = startDate.getDate() === 1;
    const isEndDateMonthEnd =
      endDate.getDate() ===
      new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();

    if (!isStartDateMonthBeginning || !isEndDateMonthEnd) {
      const confirmation = window.confirm(
        "Selected date range is not a month start and end date. Do you want to proceed?"
      );
      if (!confirmation) {
        return;
      }
    }

    // Check if billing category is selected
    if (!selectedBillingCategory) {
      // alert('Please select a billing category.');
      setWarning("Please select a billing category.");
      return;
    }

    // Filter the selected client IDs based on the filteredTableData
    const selectedData = filteredTableData.filter((item) =>
      selectedClients.includes(item.id)
    );
    setSelectedClients(selectedData.map((item) => item.id));

    // If all checks pass, proceed with the invoice sending logic
    setIsButtonScaled(true);
    setTimeout(() => {
      setIsButtonScaled(false);
      setIsModalOpen(true);

      // Filter the table data based on the selected billing category
      const filteredData = tableData.filter(
        (item) =>
          item.billing_type?.toLowerCase() ===
          selectedBillingCategory.toLowerCase()
      );
      setFilteredTableData(filteredData);
    }, 300);
  };

  //handling send invoice
  const handleSendButtonClick = (invoice) => {
    if (!invoiceDate) {
      alert("Please select an Invoice date.");
      return;
    }

    // Check if at least one client is selected
    if (selectedClients.length === 0) {
      alert("Please select at least one client.");
      return;
    }

    //   if(selectedBillingCategory==='dollar'){
    //     if(dollar===''){
    //        alert('Dollar rate is null')
    //        return
    //     }
    //  }

    const jsonData = generateJSONData();
    if (invoice === "Whatsapp") {
      sendWhatappInvoicesAPI(jsonData);
    } else if (invoice === "Email") {
      sendInvoicesAPI(jsonData);
    }
    setIsModalOpen(false);
  };

  const DateFormatForDollar = (date1) => {
    const date = new Date(date1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const funDate = (date1) => {
    const date = new Date(date1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const generateJSONData = () => {
    const timeZone = "Asia/Kolkata";

    //console.log("invoice", email);
    const selectedData = tableData.filter((item) =>
      selectedClients.includes(item.id)
    );

    let jsonData;
    if (selectedBillingCategory === "dollar") {
      jsonData = selectedData.map((item) => ({
        clientId: item.id,
        startDate: funDate(selectedRange[0].startDate),
        endDate: funDate(selectedRange[0].endDate),
        invoiceDate: invoiceDate ? funDate(invoiceDate) : null,
        user_email: email,
        dollar_rate: dollar,
      }));
    } else {
      jsonData = selectedData.map((item) => ({
        clientId: item.id,
        startDate: funDate(selectedRange[0].startDate),
        endDate: funDate(selectedRange[0].endDate),
        invoiceDate: invoiceDate ? funDate(invoiceDate) : null,
        user_email: email,
        // "startDate": zonedTimeToUtc(new Date(selectedRange[0].startDate), timeZone),
        // "endDate": zonedTimeToUtc(new Date(selectedRange[0].endDate), timeZone),
        // "invoiceDate": invoiceDate ? zonedTimeToUtc(new Date(invoiceDate), timeZone):null,
      }));
    }
    //console.log(jsonData);

    // const formattedStartDate = startDate ? startDate.toISOString().split('T')[0] : null;
    return jsonData;
  };

  const sendInvoicesAPI = async (jsonData) => {
    //console.log("Jsondata:", jsonData);

    try {
      setApiState((prevData) => ({
        ...prevData,
        send_invoice_email: "fetch",
      }));
      const { REACT_APP_CLIENT_SEND_INVOICE_URL } = process.env;
      const response = await axios.post(
        REACT_APP_CLIENT_SEND_INVOICE_URL,
        jsonData
      );
      //console.log("Invoices sent successfully:", response.data);
      //console.log(response);
      if (response.status === 200)
        if (response.data[0] === "Email sent successfully") {
          setApiResponse(["Invoice sent successfully"]);
        } else {
          setApiResponse(response.data);
        }
      else {
        setErrorAlert("Invoice Not Sent");
      }
      // Set the API response in the state
      setApiError(null); // Clear any previous error
    } catch (error) {
      console.error("Error sending invoices:", error);
      setErrorAlert("Invoice Not Sent");
      setApiResponse(null); // Clear any previous response
      setApiError(error); // Set the API error in the state
    } finally {
      setApiState((prevData) => ({
        ...prevData,
        send_invoice_email: null,
      }));
    }
  };

  const sendWhatappInvoicesAPI = async (jsonData) => {
    //console.log("Jsondata:", jsonData);
    setApiState((prevData) => ({
      ...prevData,
      send_invoice_whatsapp: "fetch",
    }));
    try {
      const { REACT_APP_CLIENT_SEND_WATSAPP_INVOICE_URL } = process.env;
      const response = await axios.post(
        REACT_APP_CLIENT_SEND_WATSAPP_INVOICE_URL,
        jsonData
      );
      //console.log("Invoices sent successfully:", response.data);
      //console.log(response);
      if (response.status === 200)
        if (response.data[0] === "watsapp message sent successfully") {
          setApiResponse(["Invoice sent successfully"]);
        } else {
          setApiResponse(response.data);
        }
      else {
        setErrorAlert("Invoice Not Sent");
      }
      // Set the API response in the state
      setApiError(null); // Clear any previous error
    } catch (error) {
      console.error("Error sending invoices:", error);
      setErrorAlert("Invoice Not Sent");
      setApiResponse(null); // Clear any previous response
      setApiError(error); // Set the API error in the state
    } finally {
      setApiState((prevData) => ({
        ...prevData,
        send_invoice_whatsapp: null,
      }));
    }
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
    filterTableData(selectedBillingCategory, e.target.value.toLowerCase()); // Convert the search input to lowercase before filtering
  };

  const filterTableData = (billingCategory, searchTerm) => {
    // Filter the table data based on the search term and selected billing category
    if (billingCategory) {
      const filteredData = tableData.filter(
        (item) =>
          item.billing_type?.toLowerCase() === billingCategory.toLowerCase() &&
          item.client_name?.toLowerCase().includes(searchTerm)
      );
      setFilteredTableData(filteredData);
    } else {
      // If billing category is not selected, only filter based on search term
      const filteredData = tableData.filter((item) =>
        item.client_name.toLowerCase().includes(searchTerm)
      );
      setFilteredTableData(filteredData);
    }
  };

  const handleSelectAllClick = (isChecked) => {
    if (isChecked) {
      // If the checkbox is checked, select all visible clients in the table
      const visibleClientIds = filteredTableData.map((item) => item.id);
      setSelectedClients(visibleClientIds);
    } else {
      // If the checkbox is unchecked, clear all selected clients
      setSelectedClients([]);
    }
  };

  const handleDefaultInvoiceDateClick = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleInvoiceDateChange = (date) => {
    setInvoiceDate(date);
  };

  return (
    <div>
      <div className="header-section">
        {/* <div className="send-invoice-button"> */}
        <Button
          size="small"
          variant="contained"
          // className={`button-effect ${isButtonScaled ? 'scale-effect' : ''}`}
          className="btn-effect"
          onClick={() => {
            // if(userAccess?.access==='admin'){
            //   handleSendInvoiceClick()
            // }
            if (handleAccess("Hospital", "Send Invoice") === true) {
              handleSendInvoiceClick();
            } else {
              //console.log("Not Authorized");
              setAuthPop(true);
            }
          }}
        >
          Send Invoice
        </Button>
        {/* </div> */}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Sending Invoices Confirmation"
        className="modal"
        overlayClassName="modal-overlay"
        style={{
          content: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%",
            width: "800px", // Adjust the width as needed
            maxHeight: "80vh", // Limit the height to 80% of the viewport height
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "20px",
            zIndex: 9999,
            overflow: "auto",
          },
        }}
      >
        <div className="modal-header">
          <h2>Sending Invoices Confirmation</h2>
          <button
            className="modal-close-button"
            onClick={() => setIsModalOpen(false)}
          >
            &times;
          </button>
        </div>
        <div className="modal-content">
          <p>
            Selected Date Range:{" "}
            {selectedRange[0].startDate?.toLocaleDateString()} to{" "}
            {selectedRange[0].endDate?.toLocaleDateString()}
          </p>

          <p>Selected Billing Category: {selectedBillingCategory}</p>

          {/* Invoice Date */}
          <div>
            <p className="invoice-date">Invoice Date:</p>
            <div style={{ display: "flex" }}>
              <DatePicker
                selected={invoiceDate}
                onChange={handleInvoiceDateChange}
                dateFormat="yyyy-MM-dd"
              />
              {/* { (dollar && selectedBillingCategory==='dollar')  &&
              <div className='dollor' style={{ marginLeft: '30px', display: 'flex', flexDirection: 'row' }}>
                <span style={{ color: 'red' }}>
                  <BiSolidDollarCircle size={20} />
                </span>
                <span style={{ color: 'red' }}>1</span>
                <span>-</span>
                <span style={{ color: 'green' }} >
                  <PiCurrencyInrFill size={20} />
                </span>
                <span style={{ color: 'green' }}>{dollar}</span>
              </div> 
            }


            {(invoiceDate>new Date()&&selectedBillingCategory==='dollar') &&
              <div style={{marginLeft: '30px',color:'red'}} >Selected Date is greater than Today </div>} */}
            </div>
          </div>

          <input
            type="text"
            value={searchInput}
            onChange={handleSearchInputChange}
            placeholder="Search by Client Name"
            className="search-input"
          />

          {/* Table Container */}
          <div className="table-container">
            <table className="modal-table">
              <thead>
                <tr>
                  <th>
                    {/* Select All Checkbox */}
                    <input
                      type="checkbox"
                      checked={
                        selectedClients.length === filteredTableData.length
                      }
                      onChange={(e) => handleSelectAllClick(e.target.checked)}
                    />
                  </th>
                  <th>Unique ID</th>
                  <th>Client Name</th>
                  <th>ID</th>
                </tr>
              </thead>
              <tbody>
                {filteredTableData.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedClients.includes(item.id)}
                        onChange={() => handleRowSelect(item.id)}
                      />
                    </td>
                    <td>{item.unique_id}</td>
                    <td>{item.client_name}</td>
                    <td>{item.id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="modal-send-button"
            disabled={apiState?.send_invoice_whatsapp === "fetch"}
            style={{ marginRight: "10px" }}
            onClick={() => handleSendButtonClick("Whatsapp")}
          >
            Send Whatsapp Invoice
          </button>
          <button
            className="modal-send-button"
            disabled={apiState?.send_invoice_email === "fetch"}
            onClick={() => handleSendButtonClick("Email")}
          >
            Send Email Invoice
          </button>
        </div>
      </Modal>

      {/* API Response */}
      <ApiResponsePopup
        apiResponse={apiResponse}
        onClose={() => setApiResponse(null)}
      />

      <Snackbar
        open={errorAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ zIndex: "6" }}
        onClose={() => {
          setErrorAlert(false);
        }}
      >
        <Alert
          onClose={() => {
            setErrorAlert(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>
      <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop} />
    </div>
  );
};

export default SendInvoices;
