import React, { useEffect, useState } from "react";
import { BorderLinearProgress, RedLinearProgress } from "./GmStudyOverall";
import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { FaFileDownload } from "react-icons/fa";

function InhouseRadpay({ gmData,handleDownload }) {
  const [gmActualData, setGmActualData] = useState([]);
  const [sampleData, setSampleData] = useState([]);
  const [expandData, setExpandData] = useState("");
  useEffect(() => {
    const idsToFilter = [448, 505, 1025, 1076, 1345, 1634, 1660];
    let filteredData = gmData.filter((item) =>
      idsToFilter.includes(item.rad_fk)
    );
    //console.log(filteredData);

    const result = filteredData?.reduce((acc, current) => {
      const rad_fk = current.rad_fk;

      // Initialize values for the group if not present
      if (!acc[rad_fk]) {
        acc[rad_fk] = {
          revenue: 0,
          payable: 0,
          No_of_report: 0,
          rad_name: current.rad_name,
        };
      }

      // Update values for the group
      acc[rad_fk].revenue += current.Revenue;
      acc[rad_fk].payable += current["Rad Payable"];
      acc[rad_fk].No_of_report += current["No_of_report"];

      return acc;
    }, {});

    //   Convert the result into an array of objects
    if (result) {
      const resultArray = Object?.entries(result)
        ?.map(
          ([grouped_fields, { revenue, payable, No_of_report, rad_name }]) => {
            const [rad_fk, date] = grouped_fields.split("to");
            return {
              rad_fk,
              rad_name,
              Date: "overall",
              revenue,
              payable,
              No_of_report,
            };
          }
        )
        ?.sort((a, b) => {
          return b.revenue - a?.revenue;
        });

      //console.log(resultArray, "hein");
      setGmActualData(resultArray);
      setSampleData(resultArray);
    }

    //    setGmActualData(filteredData)
  }, [gmData]);
  const handleGm = (reve, paya) => {
    const data = ((reve - paya) / reve) * 100;
    return parseFloat(data).toFixed(2);
  };

  useEffect(() => {
    const l = [];
    if (expandData) {
      
      const data1 = gmData?.filter((data) => {
        if (data?.rad_fk === parseInt(expandData)) {
          return {
            ...data,
            children: "yes",
          };
        }
      });

      gmActualData?.map((data) => {
        l.push(data);
        if (data?.rad_fk === expandData) {
          [...data1].map((data) => {
            l.push({
              Date: data?.Date,
              rad_fk: data?.rad_fk,
              rad_name: data?.rad_name,
              No_of_report: data?.No_of_report,
              revenue: data?.Revenue,
              payable: data["Rad Payable"],
              children: "yes",
            });
          });
        }
      });

      //console.log(l);
    //   setSampleData(l)
      setGmActualData(l);
    } else {
      if (sampleData.length > 0) {
        setGmActualData(sampleData);
      }
    }
  }, [expandData]);

  const averageReport = (count, revenue) => {
    const data = revenue / count;
    return parseInt(data);
  };

  const averageCost = (count, payable) => {
    const data = payable / count;
    return parseInt(data);
  };

  // console.log(gmData)

  const handleSendDownload=(data,filename)=>{
    const idsToFilter = [448, 505, 1025, 1076, 1345, 1634, 1660];
    const data1= data.filter((item) =>
    idsToFilter.includes(item.rad_fk)
  )?.map(data=>{
      return{
        'Radiologist Name':data?.rad_name,
        'Date':data?.Date,
        'No of Reports':data?.No_of_report,
        'Revenue':data?.Revenue,
        'Payable':data['Rad Payable'],
        'Gm':`${handleGm(data?.Revenue, data['Rad Payable'])}%` ,
        'Average Revenue per Report':averageReport(data?.No_of_report, data?.Revenue),
        'Average Cost per Report':averageCost(data?.No_of_report, data['Rad Payable'])
      }
    })

    handleDownload(data1,filename)
}

  return (
    <>
      {" "}
      {gmActualData.length === 0 ? (
        <div style={{ margin: "auto" }}>
          <ReactLoading
            type={"bars"}
            color={"#0059B2"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            borderRadius: "20px",
            padding: "20px",
          }}
        >
          <div style={{ fontSize: "25px", color: "#1B2559",display:'flex',justifyContent:'space-between' }}>
            <div>Inhouse RAD</div>
            <div><FaFileDownload onClick={()=>handleSendDownload(gmData,'Inhouse_Rad_Wise_Gm')} color="blue" style={{cursor:'pointer'}} /></div>
            
            </div>
          <div>
            <div className="inhouse-revenue-head">
              <div></div>
              <div>Rad Name</div>
              <div>Date</div>
              <div>No of Reports</div>
              <div>Revenue</div>
              <div>Payable</div>
              <div>GM</div>
              <div>Average Revenue per Report</div>
              <div>Average Cost per Report</div>
            </div>
            <div
              className="inhouse-revenue-table"
              style={{ height: "700px", overflowY: "scroll" }}
            >
              {gmActualData?.map((data, id) => (
                <div key={id} className="inhouse-revenue-body">
                  <div>
                    {data?.children !== "yes" && (
                      <div>
                        {expandData !== data?.rad_fk ? (
                          <AddIcon
                            onClick={() => setExpandData(data?.rad_fk)}
                          />
                        ) : (
                          <RemoveIcon onClick={() => setExpandData("")} />
                        )}
                      </div>
                    )}
                  </div>
                  <div>{data.rad_name}</div>
                  <div>{data.Date}</div>
                  <div>{data?.No_of_report}</div>
                  <div>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(data?.revenue)}
                  </div>
                  <div>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(data?.payable)}
                  </div>
                  <div style={{ textAlign: "end" }}>
                    {handleGm(data?.revenue, data.payable) > 0 ? (
                      <div className="table-revenue-body-gm-postive">
                        {`${handleGm(data?.revenue, data.payable)}%`}
                        <BorderLinearProgress
                          variant="determinate"
                          value={
                            handleGm(data?.revenue, data.payable) > 100
                              ? 100
                              : handleGm(data?.revenue, data.payable)
                          }
                        />
                      </div>
                    ) : (
                      <div className="table-revenue-body-gm-negative">
                        {`${handleGm(data?.revenue, data?.payable)}%`}
                        <RedLinearProgress
                          variant="determinate"
                          value={
                            Math.abs(handleGm(data?.revenue, data?.payable)) >
                            100
                              ? 100
                              : Math.abs(handleGm(data?.revenue, data?.payable))
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div>{averageReport(data?.No_of_report, data?.revenue)}</div>
                  <div>{averageCost(data?.No_of_report, data?.payable)}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InhouseRadpay;
