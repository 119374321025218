import React, { useRef, useState, useEffect,useContext } from 'react';
import './Report.css';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import AuthErrorPop from '../Util/AuthErrorPop';
import { UserContext } from '../../App';
import { differenceInMonths } from 'date-fns';
import { Box, } from '@mui/material';
import Modal from '@mui/material/Modal';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import axios from 'axios';

const ErrorModel = ({ data, onClose }) => {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid lightblue',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={!!data}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 450 }}>
        <div className='pop-edit'>

          <div style={{ display: 'flex', fontSize: '20px', color: 'red' }}>
            {/* <div>Error :</div> */}
            <div style={{ paddingLeft: '10px' }}> {data}</div>
          </div>

          <Button sx={{ marginTop: '10px', float: 'right', fontSize: '13px' }} onClick={onClose} variant="contained">Close</Button>

        </div>

        {/* <ChildModal /> */}
      </Box>
    </Modal>
  )
}



function Reports({ isSidebarOpen }) {
  const [data, setData] = useState([])
  const [report, setReport] = useState('');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef(null);
  const [error, setError] = useState(false)
  const [authPop,setAuthPop]=useState(false)
  const {user} = useContext(UserContext);
  const {handleAccess,apiState,setApiState}= useContext(UserContext);
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);
  const [currentFromDate, setCurrentFromDate] = useState('');
  const [currentToDate, setCurrentToDate] = useState('');
  const [download, setDownload] = useState(false);
  useEffect(() => {
    // //console.log('end',formatDate(selectedRange[0].endDate))
    // //console.log('start',formatDate(selectedRange[0].startDate))
    if (selectedRange[0].startDate) {
      const monthsDiff = differenceInMonths(selectedRange[0].endDate, selectedRange[0].startDate);
      if (monthsDiff >= 3) {
        // alert("maximum range is 3 month")
        setIsCalendarOpen(false)
        setError('Max Range should be less than 3 months !!!')
        setSelectedRange([
          {
            startDate: null,
            endDate: null,
            key: 'selection',
          },
        ]);
        setCurrentFromDate('')
        setCurrentToDate('')
        setIsCalendarOpen(true)
      }
    }
  }, [selectedRange[0].endDate, selectedRange[0].startDate])
  const handleDateChange = (ranges) => {
    setSelectedRange([ranges.selection]);
    setCurrentFromDate(ranges.selection.startDate);
    setCurrentToDate(ranges.selection.endDate);
  };

  const handleDownload = async () => {
    // if(apiState?.report==='fetch'){
    //   return
    // }
    setApiState((prevData) => ({
      ...prevData,
      report:'fetch'
    }));
    // if(user?.access!=='admin'){
    if(!handleAccess('Reports','Download')){
      setAuthPop(true)
      return
    }
    if (report === '') {
      setError('Please select the Report Name')
    }
    else if (currentFromDate === '' || currentToDate === '') {
      setError('Please select the Date Range')
    }
    else {
      try {
        setDownload(true)
        const start_date = formatDate(currentFromDate);
        const end_date = formatDate(currentToDate);
        //console.log(start_date, end_date)
        let res;
        if (report === 'Report Level Transaction Data') {
          const {REACT_APP_REPORT_LEVEL_DUMP_REPORT_URL} = process.env
          res = await axios.get(REACT_APP_REPORT_LEVEL_DUMP_REPORT_URL+`?dump_name=Report_Level_Transaction_Dump&start_date=${start_date}&end_date=${end_date}`)
          //console.log(res.data)
          if (res.data.data) {
            //console.log(res.data.data)
            const data = res?.data.data.map(item => {
              const jsonData = {
                'Billing_Report?': item.billing_report,
                'order_id': item.order_id,
                'study_fk': item.study_fk,
                'report_fk': item.report_fk,
                'study_status': item.study_status,
                'report_status': item.report_status,
                'transaction_status': item.transaction_status,
                'reportqc_status': item.reportqc_status,
                'Is_this_rework_report?': item.is_this_rework_report,
                'Report_created_by': item.report_created_by,
                'study_created_time': item.study_created_time,
                'report_submitted_time': item.report_submitted_time,
                'Study Final Completed Time': item.study_final_completed_time,
                'client_unique_id': item.client_unique_id,
                'client_name': item.client_name,
                'modality': item.modality,
                'Report Name': item.report_name,
                'patient_id': item.patient_id,
                'patient_name': item.patient_name,
                'image_count': item.image_count,
                'night_hawk_case_for_client': item.night_hawk_case_for_client,
                'is_per_view_case': item.is_per_view_case,
                'billing_type': item.billing_type,
                'client_payable': item.client_payable,
                'slab_price': item.slab_price,
                'client_status': item.client_status,
                'rad_unique_id': item.rad_unique_id,
                'rad_name': item.rad_name,
                'grade': item.grade,
                'rad_payable': item.rad_payable,
                'group_name': item.group_name,
                'reject_reason': item.reject_reason,
                'platform_link': item.platform_link,
                'Invoice Number': item.invoice_num,
                'Invoice Date': item.invoice_date,
                'Client Onboarded date':item?.client_onboarded_date,
                'Rad Onboarded date':item?.rad_onboarded_date,
                'City':item?.city,
                'State':item?.state,
                'Is Urgent':item?.is_urgent,
                'Is Pro_Rad':item?.is_pro_rad,
                "is_pro_insight_free":item?.is_pro_insight_free,
                'Revised Cost':item.revised_cost,
              }
              return jsonData;
            })
            // //console.log(data,'new')
            setData(data)
          }
        }
        else if (report === 'Case Level Transaction Data') {
          const {REACT_APP_CASE_LEVEL_DUMP_REPORT_URL} = process.env
          res = await axios.get(REACT_APP_CASE_LEVEL_DUMP_REPORT_URL+`?dump_name=Case_Level_Transaction_Dump&start_date=${start_date}&end_date=${end_date}`)
          if (res.data.data) {
            const data = res.data.data.map(item => {
              const jsonData = {
                'order_id': item.order_id,
                'study_fk': item.study_fk,
                'modality': item.modality,
                'study_name': item.study_name,
                'study_created_time': item.study_created_time,
                'report_submitted_time': item.report_submitted_time,
                'Study Final Completed Time': item.study_completed_time,
                'patient_id': item.patient_id,
                'patient_name': item.patient_name,
                'image_count': item.image_count,
                'report_count': item.report_count,
                'client_unique_id': item.client_unique_id,
                'client_name': item.client_name,
                'billing_type': item.billing_type,
                'client_payable': item.client_payable,
                'slab_price': item.slab_price,
                'night_hawk_case_for_client': item.night_hawk_case_for_client,
                'is_per_view_case': item.is_per_view_case,
                'rad_unique_id': item.rad_unique_id,
                'rad_name': item.rad_name,
                'rad_payable': item.rad_payable,
                'platform_link': item.platform_link,
                'Overall TAT': item.overall_tat_in_mins,
                'City':item?.city,
                'State':item?.state,
                'Is Urgent':item?.is_urgent,
                'Is Pro_Rad':item?.is_pro_rad,
                is_pro_insight_free:item?.is_pro_insight_free
              }
              return jsonData
            })
            //console.log(data, 'new')
            setData(data)
          }
        }
        else if (report === 'Radiologist Payable Data') {
          const {REACT_APP_RAD_PAYABLE_DUMP_REPORT_URL} = process.env
          res = await axios.get(REACT_APP_RAD_PAYABLE_DUMP_REPORT_URL+`?dump_name=Radiologist_Payable_Data&start_date=${start_date}&end_date=${end_date}`)
          if (res.data.data) {
            const data = res.data.data.map(item => {
              const jsonData = {
                'order_id': item.order_id,
                'study_fk': item.study_fk,
                'report_fk': item.report_fk,
                'rad_fk': item.rad_fk,
                'unique_id': item.unique_id,
                'rad_name': item.rad_name,
                'group_name': item.group_name,
                'patient_name': item.patient_name,
                'client_name': item.client_name,
                'modality': item.modality,
                'study_name': item.study_name,
                'report_submitted_time': item.report_submitted_time,
                'rad_payable': item.rad_payable,
                'is_night_hawk': item.is_night_hawk,
                'is_SIP': item.is_sip,
                'SIP Payable': item.sip_payable,
                'Is_Sunday': item.is_sunday,
                'Study Final Completed Time': item.study_final_completed_time,
                'Client Price':item.client_price,
                'Assignment Type':item.assignment_type,
                'Case Tag':item.case_tag,
                'Pre Read':item.pre_read,
                'inhouse_per_report_price':item.inhouse_per_report_price,
              }
              return jsonData
            })
            // //console.log(data,'new')
            setData(data)
          }
        }
        else if (report === 'Client Cost Data') {
          const {REACT_APP_CLIENT_LEVEL_DUMP_REPORT_URL} = process.env
          res = await axios.get(REACT_APP_CLIENT_LEVEL_DUMP_REPORT_URL+`?dump_name=Client_Level_Cost_Dump&start_date=${start_date}&end_date=${end_date}`)
          if (res.data.data) {
            const data = res.data.data.map(item => {
              const jsonData = {
                'client_fk': item.client_fk,
                'client_unique_id': item.client_unique_id,
                'client_name': item.client_name,
                'study_count': item.study_count,
                'report_count': item.report_count,
                'image_count': item.image_count,
                'client_payable': item.client_payable,
                'slab_price': item.slab_price,
                'billing_type': item.billing_type,
                'Rad Payable':item?.rad_payable,
                'Invoice Number': item.invoice_num,
                'Invoice Date': item.invoice_date,
                'Invoice Value': item.invoice_value,
                'Revised Cost':item.revised_cost,
                
              }

              return jsonData
            })
            // //console.log(data)
            setData(data)
          }
        }
        else if (report === 'Invoice Summary Data') {
          const {REACT_APP_INVOICE_SUMMARY_DUMP_REPORT_URL} = process.env
          res = await axios.get(REACT_APP_INVOICE_SUMMARY_DUMP_REPORT_URL+`?dump_name=Invoice_Summary_Dump&start_date=${start_date}&end_date=${end_date}`)
          // //console.log(res)
          if (res.data.data) {

            const data = res.data.data.map(item => {
              const jsonData = {
                'invoice_num': item.invoice_num,
                'client_fk': item.client_fk,
                'unique_id': item.unique_id,
                'client_name': item.client_name,
                'billing_type': item.billing_type,
                'start_date': item.start_date,
                'end_date': item.end_date,
                'invoice_sent_date': item.invoice_sent_date,
                'cost': item.cost,
                'mail_status': item.mail_status,
                'last_updated_at': item.last_updated_at,
                'Invoice Sent By': item.invoice_sent_by,
                'Total Credit Note': item["Credit (Rs)"],
                'Total Debit Note': item["Debit (Rs)"],
              }
              return jsonData
            })
            // //console.log(data,'new')
            setData(data)
          }
        }
        else if (report === 'Revision Summary Data'){
          const {REACT_APP_REVISION_INVOICE_SUMMARY_DUMP_REPORT_URL} = process.env
          res = await axios.get(REACT_APP_REVISION_INVOICE_SUMMARY_DUMP_REPORT_URL+`?dump_name=Revision_Summary_Dump&start_date=${start_date}&end_date=${end_date}`)
          if (res.data.data) {
            const data = res.data.data.map(item => {
              const jsonData = {
                'Report ID': item.report_id,
                'study_id': item.study_id,
                'client_fk': item.client_fk,
                'Unique ID': item.unique_id,
                'hospital_name': item.hospital_name,
                'Billing Type': item.billing_type,
                'patient_id': item.patient_id,
                'patient_name': item.patient_name,
                'Report Name': item.report_name,
                'Rad ID': item.rad_id,
                'radiologist_name': item.radiologist_name,
                'case_activated_time': item.case_activated_time,
                'first_invoice_date': item.first_invoice_date,
                'Invoice Number': item.invoice_num,
                'cost': item.cost,
                'variance': item.variance,
                'revised_cost': item.revised_cost,
                'By User ID': item.by_user_id,
                'updated_at': item.updated_at,
                'edit_type': item.edit_type,
                'reason': item.reason,
                'credit_note':item.credit_note,
                'credit_note_revised':item.credit_note_revised,
                'debit_note':item.debit_note,
                'debit_note_revised':item.debit_note_revised
              }
              return jsonData
            })


            setData(data)

          }
          //console.log(res.data)
        }
        else if(report === 'Double Report'){
          const {REACT_APP_DOUBLE_REPORT_DUMP_REPORT_URL} = process.env
           res = await axios.get(REACT_APP_DOUBLE_REPORT_DUMP_REPORT_URL+`?dump_name=double_reports_dump&start_date=${start_date}&end_date=${end_date}`)
           if (res.data.data) {
            setData(res.data.data)
           }
        } 

        else if(report === 'Rad Login Hours Data'){
          const {REACT_APP_RAD_LOGIN_HOURS_DUMP_URL} = process.env
           res = await axios.get(REACT_APP_RAD_LOGIN_HOURS_DUMP_URL+`dump_name=Rad_Login_Hours&start_date=${start_date}&end_date=${end_date}`)
           if (res.data.data) {
            setData(res.data.data)
           }
        } 

        else if(report === 'Rad Incentive'){
          const {REACT_APP_RAD_INCENTIVE_URL} = process.env
           res = await axios.get(REACT_APP_RAD_INCENTIVE_URL+`?dump_name=Radiologist_Incentive_Data&start_date=${start_date}&end_date=${end_date}`)
           
           if (res?.data?.data) {
            setData(res?.data?.data)
           }
        } 

        if (!res?.data?.data) {
          setError('Response Error')
        }
        else if (res.data.data.length === 0) {
          setError('No Report Data')
        }

        // const ws = XLSX.utils.aoa_to_sheet(data);
        // const wb = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // XLSX.writeFile(wb, 'data.xlsx');
      } catch (err) {
        console.log(err)
        setError('Response Error')
      }finally{
        setApiState((prevData) => ({
          ...prevData,
          report:null
        }));
      }

    }

    setDownload(false)

  }

  useEffect(() => {
    if (data.length > 0) {
      //console.log(data)
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${report}_${formatDate(currentFromDate)}_to_${formatDate(currentToDate)}.xlsx`);
      setData([])
    }
  }, [data])



  const handleOutsideClick = (event) => {
    // Check if the click is outside the date range picker
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      // setIsCalendarOpen(false); // Close the date range picker
      setIsCalendarOpen((prevIsCalendarOpen) => !prevIsCalendarOpen);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const formatDate = (date1) => {
    const date = new Date(date1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  };

  const handleClearSelection = () => {
    setSelectedRange([
      {
        startDate: null,
        endDate: null,
        key: 'selection',
      },

    ]);
    setCurrentFromDate('');
    setCurrentToDate('');


  };

  const handleSelectClick = () => {
    setIsCalendarOpen(false); 
  };

  const handleChange = (event) => {
    setReport(event.target.value);
  };
  return <div className={`${isSidebarOpen ? 'report-main' : 'report-min'}`}>

    <div className={`${isSidebarOpen ? 'report-div' : 'report-div-min'}`} >
      <div className='title-report'>Reports</div>
      <div className='report-content'>
        <div className='report-drop'>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Reports</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={report}
              label="Reports"
              onChange={handleChange}
            >
              <MenuItem value='Report Level Transaction Data'>Report Level Transaction Data</MenuItem>
              <MenuItem value='Case Level Transaction Data'>Case Level Transaction Data</MenuItem>
              <MenuItem value='Radiologist Payable Data'>Radiologist Payable Data</MenuItem>
              <MenuItem value='Client Cost Data'>Client Cost Data</MenuItem>
              <MenuItem value='Invoice Summary Data'>Invoice Summary Data</MenuItem>
              <MenuItem value='Revision Summary Data'>Revision Summary Data</MenuItem>
              <MenuItem value='Double Report'>Double Report</MenuItem>
              <MenuItem value='Rad Login Hours Data'>Rad Login Hours Data</MenuItem>
              <MenuItem value='Rad Incentive'>Rad Incentive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <Button size="small" variant="contained" className='btn-effect'
            // className={`date-field ${isCalendarOpen ? 'active' : ''}`}
            onClick={() => setIsCalendarOpen((prev => !prev))}
          >
            {selectedRange[0].startDate ? (<div>

              {formatDate(currentFromDate)} -  {formatDate(currentToDate)}

            </div>
            ) : 'Date Range'}
          </Button>

          {isCalendarOpen && (
            <div className="date-range-wrapper1">
              <div className="date-range-container1" ref={calendarRef}>
                <DateRangePicker
                  ranges={selectedRange}
                  onChange={handleDateChange}
                />

                <div className='select-btn-div'>
                  <Button size="small" variant="contained" className='clear-btn' onClick={handleClearSelection}>
                    Clear Date Selection
                  </Button>
                  <Button size="small" variant="contained" className='select-btn' onClick={handleSelectClick}>
                    Select
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          <Button disabled={apiState?.report==='fetch'} className='btn-download' size='small' variant="contained" onClick={handleDownload}>
            {download ? 'Downloading....' :
              'Download Report'}</Button>
        </div>
      </div>

      {/* <div>
         <div>

             <div>Report Name</div>
             <div> Download</div>
         </div>
      </div> */}



    </div>
    {
      error && <ErrorModel data={error} onClose={() => setError(false)} />
    }

     <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop}  />

  </div>;
}

export default Reports;
