import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import * as XLSX from 'xlsx';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, styled, DialogActions, DialogTitle, DialogContent,TextField,InputAdornment } from '@mui/material';
import { ErrorModel } from '../Hospital/TransactionsTab';
import { set } from 'date-fns/fp';
import SearchIcon from '@mui/icons-material/Search';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function RemainderUpload({ open, handleClose,handleFileUpload }) {
    const [sendType, setSendType] = useState(null)
    const [selectedFile, setSelectedFile] = useState('')
    const [excelFileName, setExcelFileName] = useState('')
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [error, SetError] = useState(null)
    const [previewData, setPreviewData] = useState([])
    const [selectedClients, setSelectedClients] = useState([]);
    const [search,setSearch]=useState('')


    const handleDownload = () => {
        if (sendType === null) {
            SetError('Please choose the Send From before downloading')
            return
        }
        const data = [{
            'Unique Id': '5C0001',
            'Client Name': 'Jeevan Hospital',
            'Message': `Dear Customer, 

                You have an outstanding amount of Rs {amount} as of {month]. Kindly pay as early as possible to avoid service interruption.

                Kindly contact us at +91 6366433919 / 6366433905 / 9900892536 / 8431688274 immediately for any queries 
              `,
            'Send From': sendType,
            'Type': 'Overdue'
        }]
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, 'RemainderSheet');
        XLSX.writeFile(wb, `RemainderUpload.xlsx`);
    }


    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        //console.log('Selected file:', selectedFile);
        setSelectedFile(selectedFile);
        setExcelFileName(selectedFile.name);
        setOpenConfirmationDialog(true);
        // handleClose()
    }

    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);

    };

    const handleCancelUpload = () => {
        setOpenConfirmationDialog(false);
        setSelectedFile(null); // Reset selectedFile state when canceling the upload
    };


    const handleConfirmUpload = () => {
        setOpenConfirmationDialog(false);
        handleReadExcel(); // Call handleReadExcel to process the selected file after confirmation
        // handleClose(); // Close the UploadCost modal after confirming the upload
    };

    const handleReadExcel = () => {
        if (selectedFile) {
            setExcelFileName(selectedFile.name);


            const reader = new FileReader();

            reader.onload = (event) => {
                const data = event.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                const sheetName = 'RemainderSheet';
                const sheet = workbook.Sheets[sheetName];

                if (!sheet) {
                    //console.log('Incorrect file or format".');
                    SetError('Incorrect Sheet Name')
                    return;
                }

                const headers = [];
                for (const cell in sheet) {
                    //console.log(sheet[cell].v)
                    if (sheet[cell].v !== undefined) {
                        headers.push(sheet[cell].v);
                    }
                }

                //console.log(headers)
                if (headers[0] !== 'Unique Id' && headers[1] !== 'Client Name' && headers[2] !== 'Message' && headers[3] !== 'Send From' & headers[4] !== 'Type') {
                    SetError('Incorrect file or format')
                    return;
                }

                const jsonData = XLSX.utils.sheet_to_json(sheet, {
                    raw: false, // Keep cell values as string
                });

                if (jsonData.length === 0) {
                    // setErrorPop('No data found in the selected file.')
                    //console.log('No data found in the selected file.');
                    SetError('No data found in the selected file.')
                    return;
                }
                if (jsonData.length === 1) {
                    if (jsonData[0]['Unique Id'] === '' && jsonData[0]['Client Name'] === '' && jsonData[0]['Message'] === '' && jsonData[0]['Send From'] === '' && jsonData[0]['Type'] === '') {
                        //console.log('No data found in the selected file.');
                        SetError('No data found in the selected file.')
                        return;
                    }
                }

                const dataToSend = jsonData.filter((row) => row['Unique Id'] !== undefined && row['Unique Id'] !== '' && row['Client Name'] !== undefined && row['Client Name'] !== '' && row['Message'] !== undefined && row['Message'] !== '' && row['Send From'] !== undefined && row['Send From'] !== '' && row['Type'] !== undefined && row['Type'] !== '').map((row) => {
                    return {
                        unique_id: row['Unique Id'],
                        client_name: row['Client Name'],
                        message: row['Message'],
                        sent_from: row['Send From'],
                        type: row['Type'],

                    }
                });

                if (jsonData.length !== dataToSend.length) {
                    SetError("Data's Missing!!! Please fill all the value")
                    return;
                }

                //console.log(jsonData)
                //console.log(dataToSend)
                setPreviewData(dataToSend)
                // onFileUpload(dataToSend)
            }
            reader.readAsBinaryString(selectedFile);
        }
    };

    const handleSelectAllClick = (isChecked) => {
        if (isChecked) {
          // If the checkbox is checked, select all visible clients in the table
          const visibleClientIds = previewData.map((item) => item.unique_id);
          setSelectedClients(visibleClientIds);
        } else {
          // If the checkbox is unchecked, clear all selected clients
          setSelectedClients([]);
        }
      };

    const handleRowSelect = (clientId) => {
        setSelectedClients((prevSelectedClients) =>
          prevSelectedClients.includes(clientId)
            ? prevSelectedClients.filter((unique_id) => unique_id !== clientId)
            : [...prevSelectedClients, clientId]
        );
      };


      const handleSend = ()=>{
        const selectedData = previewData.filter((item) => selectedClients.includes(item.unique_id));
        //console.log(selectedData)
        if(selectedData.length===0){
            SetError('Select Atleast one Clients To Send Notification')
            return
        }else{
            handleFileUpload(selectedData)
            setSendType(null)
            setPreviewData([])
            setSelectedClients([])
            handleClose()
        }
      }
    

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#1F2B8F', }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={()=>{
                                setSendType(null)
                                setPreviewData([])
                                setSelectedClients([])
                                handleClose()}
                            }
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2 }} variant="h6" component="div">
                            Bulk Send
                        </Typography>

                    </div>
                    <Button autoFocus style={{color:'blue'}} color="inherit" variant='contained' onClick={handleSend}>
                        Send
                    </Button>
                </Toolbar>
            </AppBar>
            <div className='popUptab'>
                <div>
                    <FormControl sx={{ m: 1, minWidth: 200, marginLeft: '30px' }} size="small">
                        <InputLabel id="demo-select-small-label">Send From</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={sendType}
                            label="Send From"
                            onChange={(e) => setSendType(e.target.value)}
                        >

                            <MenuItem value={'Email'}>Email</MenuItem>
                            {/* <MenuItem value={'WhatsApp'}>WhatsApp</MenuItem> */}

                        </Select>
                    </FormControl>
                </div>
                <div>
                    <Button onClick={handleDownload}
                        style={{ fontSize: '11px', marginLeft: '10px', padding: '20px', borderRadius: '30px', width: '200px' }} variant="contained" size='small' color="primary" component="span">

                        Download Sample
                    </Button>
                </div>
                <div>
                    <Button style={{ fontSize: '11px', marginLeft: '10px', padding: '20px', borderRadius: '30px', width: '200px' }} component="label" variant="contained" >
                        Upload file
                        {(!openConfirmationDialog) && <VisuallyHiddenInput type="file" onChange={handleFileSelect} />}
                    </Button>

                </div>


                <div><TextField className='search-div' style={{marginTop:'7px'}}
                 value={search} onChange={(event) => setSearch(event.target.value)} id="outlined-basic" label="Search" variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    /></div>

            </div>
         {previewData.length>0 &&
            <div className='remainder-bulk-table'>
                <div className='remainder-bulk-table-head'>
                <div >
                    <input 
                    style={{cursor:'pointer'}}
                      type="checkbox"
                      checked={selectedClients.length === previewData.length}
                      onChange={(e) => handleSelectAllClick(e.target.checked)}
                    />
                    </div>
                    <div>Client ID</div>
                    <div>Client Name</div>
                    <div className='remainder-bulk-message'>Message</div>
                    <div>Send From</div>
                    <div>Type</div>
                </div>
                {previewData?.filter((data, id) => {
                    if(search===null){
                        return data
                    }
                    if (data.client_name?.toString().toLowerCase().includes(search?.toString().toLowerCase())||data?.unique_id?.toString().toLowerCase().includes(search?.toString().toLowerCase())) {
                      return data
                    }
                  })?.map((data, id) => (
                    <div key={id} className='remainder-bulk-table-body'>
                        <div  >
                        <input
                        style={{cursor:'pointer'}}
                        type="checkbox"
                        checked={selectedClients.includes(data.unique_id)}
                        onChange={() => handleRowSelect(data.unique_id)}
                      /></div>
                        <div>{data?.unique_id}</div>
                        <div>{data?.client_name}</div>
                        <div className='remainder-bulk-message'>{data?.message}</div>
                        <div>{data?.sent_from}</div>
                        <div>{data?.type}</div>
                    </div>
                ))
                }
            </div>
}

            <Dialog width={500} open={openConfirmationDialog} onClose={handleCloseConfirmationDialog}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    {excelFileName && <p>Selected Excel File: {excelFileName}</p>}
                    {uploadStatus && <p style={{ color: 'red' }}>{uploadStatus}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelUpload} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmUpload} color="primary" autoFocus> {/* Add autoFocus attribute here */}
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>


            <ErrorModel data={error} onClose={() => SetError('')} />
        </Dialog>



    );
}