import React, { useEffect, useState, useRef } from 'react'
import { TbPercentage } from "react-icons/tb";
import { BsCurrencyDollar } from "react-icons/bs";
import { LiaRupeeSignSolid } from "react-icons/lia";
import './AgreementPricing.css'
import axios from 'axios';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { MdModeEdit } from "react-icons/md";
import { Box, Modal, TextField, Button, Switch } from '@mui/material';
import { checkAcessLevel,getDocumentYaakeUsers } from "../../permission";
import Popper from '@mui/material/Popper';
import AlertDialog from './AlertDialog';
import Alerts from '../Radiologists/Alerts';
import CustomAlertDialog from '../../Utils/CustomAlertDialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Loader from '../../Utils/Loader';
import { Response } from '../Radiologists/Radiologists';



const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[5],
        fontSize: 11,
    },
}));


function AgreementPricing({ modality, radioValue, clientid, email, clientEmail, clientname, user, uniqueId,hospitalData,handleAccess,setAuthPop }) {
    const [pricing, setPricing] = useState({})
    const [editValue, setEditValue] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = useState('')
    const [selectedFile, setSelectedFile] = useState(null)
    const [confirm, setConfirm] = useState('')
    const [detail, setDetails] = useState({})
    const [defaultPricing, setDefaultPricing] = useState({})
    const [edit, setEdit] = useState(true)
    const [defaultDetail, setDefaultDetail] = useState({})
    const [confirmPop, setCOnfirmPop] = useState(false)
    const [alert, setAlert] = useState('')
    const [warning, setWarning] = useState('')
    const [otherDefaultDetails, setOtherDefaultDetails] = useState({})
    const [approve, setApprove] = useState('')
    const [attachment, setAttachment] = useState('')
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [isapproved,setIsApproved]=useState(false)
    const [loader,setLoader]=useState()
    const [yaakeUser,setYaakeUser]=useState()
    const [source,setSource]=useState(null)
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const [docData,setDocData]=useState()

    //   const open = Boolean(anchorEl);
    //   const id = open ? 'simple-popper' : undefined;

    const handleGetAgreementPrice = async () => {
        try {
            const { REACT_APP_GET_AGREEMENT_API } = process.env
            const res = await axios.get(REACT_APP_GET_AGREEMENT_API + `client_fk=${clientid}`)
            if (res?.data?.data) {
                setPricing(res?.data?.data)
                setDefaultPricing(res?.data?.data)
            }
            const other_details = res?.data?.['other details']?.[0]
            // console.log(other_details)
            // setDetails({
            //     night_hawk_price:other_details?.night_hawk_price,
            //     holiday_price:other_details?.holiday_price,
            //     pan:other_details?.pan,
            //     tan:other_details?.tan,
            //     msme:other_details?.msme,
            //     gstin:other_details?.gstin,
            //     ct_tat:other_details?.ct_tat,
            //     xray_tat:other_details?.xray_tat,
            //     nm_tat:other_details?.nm_tat,
            //     mri_tat:other_details?.mri_tat,
            //     currency_type:other_details?.currency_type,
            //     additional_view_price:other_details?.additional_view_price,
            // })
            setDetails(other_details)
            setDefaultDetail(other_details)
            setOtherDefaultDetails(res?.data?.['other details default']?.[0])
            // console.log(res?.data?.status)
            setSource(res?.data?.source_name?.source_name)
            if(res?.data?.status?.[0]?.status==='approved'){
                setIsApproved(true)
            }
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        handleGetAgreementPrice()
    }, [])

    useEffect(() => {
             getDocumentYaakeUsers().then(data => {
                const user=data['yaake-users']?.map(data=>{
                    return data
                })
                setYaakeUser(user)
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });

    }, [email])


    useEffect(() => {
        apiCall()
      }, [])

      const apiCall = async () => {
            try {
                const { REACT_APP_CLIENT_DOCUMENT_URL } = process.env
                const res = await axios.get(REACT_APP_CLIENT_DOCUMENT_URL + `${clientid}`)
                setDocData(res.data)
            }
            catch (err) {
                 console.log(err)
            }
      }


    const handleChangeValue = (name, value) => {
        // console.log(name, value)
        setDetails(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleOtherDetailUpdate=async(onboard)=>{
        const body={
            "client_id": clientid,
            "msme_registered": detail?.msme ? 'YES' :'NO',
            "pan":detail?.pan,
            "tan_number":detail?.tan,
            "gstin_number":detail?.gstin
        }
        try{
        const { REACT_APP_CLIENT_EDIT_PAYMENT_URL } = process.env
        const res = await axios.put(REACT_APP_CLIENT_EDIT_PAYMENT_URL,
          body
        )
        if(res?.status===200){
            handleApproveMail()
        }else{
            setLoader(false)
            setWarning('Approving Failed')
        }
        }catch(err){
            setLoader(false)
            setWarning('Approving Failed')
        }
    }

    const handleEditValue = (modality, id, value) => {
        // console.log(modality, id, value)
        setAnchorEl(null)
        if (modality === 'XRAY') {
            setPricing((prev) => {
                return {
                    ...prev,
                    XRAY: pricing?.XRAY?.map(data => {
                        // console.log(id===data?.group_mod_id)
                        if (id === parseInt(data?.group_mod_id)) {
                            // console.log(data)
                            return { ...data, edited_price: parseInt(value) }
                        } else {
                            return { ...data }
                        }
                    }),

                }
            })


        } else if (modality === 'CT') {
            setPricing((prev) => {
                return {
                    ...prev,
                    CT: pricing?.CT?.map(data => {
                        if (id === data?.group_mod_id) {
                            return { ...data, edited_price: parseInt(value) }
                        } else {
                            return { ...data }
                        }
                    }),

                }
            })


        }
        if (modality === 'MRI') {
            setPricing((prev) => {
                return {
                    ...prev,
                    MRI: pricing?.MRI?.map(data => {
                        if (id === data?.group_mod_id) {
                            return { ...data, edited_price: parseInt(value) }
                        } else {
                            return { ...data }
                        }
                    }),

                }
            })

        }
        if (modality === 'NM') {
            setPricing((prev) => {
                return {
                    ...prev,
                    NM: pricing?.NM?.map(data => {
                        if (id === data?.group_mod_id) {
                            return { ...data, edited_price: parseInt(value) }
                        } else {
                            return { ...data }
                        }
                    }),

                }
            })



        }

        setEditValue('')
    }

    const handleUploadToProduction = async () => {
        try {
            // handleApproveMail()
            // return 
            setApprove(false)
            setLoader('uploading')
            const { REACT_APP_PRICE_UPLOAD_PRODUCTION_API } = process.env
            const res = await axios.post(REACT_APP_PRICE_UPLOAD_PRODUCTION_API + `?client_fk=${clientid}&client_email=${clientEmail}&user_by=${email}`)
            // console.log(res)
            if (res?.data == "Price updated successfully") {
               
                handleOtherDetailUpdate()
            }else{
                setLoader(false)
                setWarning('Price Updation Failed')
            }
        } catch (err) {
            setLoader(false)
            setWarning('Price Updation Failed')
        }
    }

    const handleApproveMail = async () => {
        try {

            const formData = new FormData();
            formData.append("file", selectedFile);
            const clientSource = source?.split(' and ')
            // console.log(clientSource)
            const sourceEmail = yaakeUser?.filter(data=>{
                if(clientSource?.includes(data?.name)){
                    return data
                }
            })?.map(data=>data?.email)
            // console.log(sourceEmail?.join(','))
            // const source='nikhil@5cnetwork.com'
            // const sender = 'Bhavya S'

            const { REACT_APP_PRICE_FINANCE_APPROVE_MAIL_URL } = process.env
            const response = await fetch(
                REACT_APP_PRICE_FINANCE_APPROVE_MAIL_URL + `?client_fk=${clientid}&client_name=${clientname}&sender=${user?.name}&unique_id=${uniqueId}&client_source=${sourceEmail?.join(',')}`,
                {
                    method: "POST",
                    body: formData,
                }
            );

            if(response?.ok){
                setLoader('Pricing Approved Successfully')
                handleGetAgreementPrice()
            }else{
                setWarning('Approving Failed')
            }
        } catch (err) {
            setWarning('Approving Failed')
            setLoader(false)
            console.log(err)
        }
    }


    const handleEditDetails = async () => {
        try {
            const { REACT_APP_EDIT_AGREEMENT_OTHER_DETAILS_API } = process.env
            const body = {
                night_hawk_price: detail?.night_hawk_price ? detail?.night_hawk_price : '',
                holiday_price: detail?.holiday_price ? detail?.holiday_price : '',
                pan: detail?.pan ? detail?.pan : '',
                tan: detail?.tan ? detail?.tan : '',
                msme: detail?.msme ? detail?.msme : '',
                gstin: detail?.gstin ? detail?.gstin : '',
                ct_tat: detail?.ct_tat ? detail?.ct_tat : '',
                xray_tat: detail?.xray_tat ? detail?.xray_tat : '',
                nm_tat: detail?.nm_tat ? detail?.nm_tat : '',
                mri_tat: detail?.mri_tat ? detail?.mri_tat : '',
                currency_type: detail?.currency_type ? detail?.currency_type : '',
                additional_view_price: detail?.additional_view_price ? detail?.additional_view_price : 0,
                is_additional_view_price: detail?.is_additional_view_price ? detail?.is_additional_view_price : '',
                off_hours: detail?.off_hours ? detail?.off_hours : '',
                discount: detail?.discount ? detail?.discount : 0,
                registration_number: detail?.registration_number ? detail?.registration_number : '',
                currency_type: detail?.currency_type ? detail?.currency_type : '',
                client_fk: detail?.client_fk ? detail?.client_fk : ''
            }
            const res = await axios.post(REACT_APP_EDIT_AGREEMENT_OTHER_DETAILS_API, body)
            if (res?.status === 200) {
                setAlert('Updated Successfully')
            } else {
                setWarning('Updation Failed')
            }
            // console.log(res)
        } catch (err) {
            setWarning('Updation Failed')
        } finally {
            // handleGetAgreementPrice()
            setCOnfirmPop(false)
        }
    }

    const handleEditApi = async () => {

        try {
            const { REACT_APP_EDIT_AGREEMENT_API } = process.env
            let price = []
            pricing.XRAY?.map(data => {

                price.push({
                    group_mod_id: data?.group_mod_id,
                    price: data?.edited_price
                })

            })
            pricing?.CT?.map(data => {
                price.push({
                    group_mod_id: data?.group_mod_id,
                    price: data?.edited_price
                })
            })
            pricing?.MRI?.map(data => {
                price.push({
                    group_mod_id: data?.group_mod_id,
                    price: data?.edited_price
                })
            })
            pricing?.NM?.map(data => {
                price.push({
                    group_mod_id: data?.group_mod_id,
                    price: data?.edited_price
                })
            })
            const data = {
                client_fk: clientid,
                user_by: email,
                price: price
            }
            const res = await axios.post(REACT_APP_EDIT_AGREEMENT_API, data)
            // console.log(res)
            if (res?.data?.message === 'Data inserted successfully') {
                setAlert('Updated Successfully')
            } else {
                setWarning('Updation Failed')
            }
        } catch (err) {
            setWarning('Updation Failed')
            console.log(err)

        } finally {
            setConfirm(false)
            handleGetAgreementPrice()
        }
    }
    return (
        <div className='agreement' style={{ display: 'flex' }}>
            <div style={{ width: '100%', marginRight: '30px' }}>
                <div>
                    <table border="0.5" cellspacing="0" cellpadding="15" style={{ boxShadow: '0px 0px 5px lightgrey', width: '100%' }} >
                        <tbody>
                            <tr style={{ backgroundColor: '#0059B2', color: 'white', position: 'sticky', top: '0', zIndex: '1' }}>
                                <td colSpan={5} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '16px' }}>
                                    STANDARD PRICING (8:00 AM to 7:59 PM) on Working Days (Mon-Sat)
                                </td>
                            </tr>
                            <tr style={{ position: 'sticky', top: '0', zIndex: '1', background: 'white' }}>
                                <td style={{ fontSize: '16px', fontWeight: 'bold' }}>Modality</td>
                                <td style={{ fontSize: '16px', fontWeight: 'bold' }}>Category Name</td>
                                <td style={{ fontSize: '16px', fontWeight: 'bold' }}>Select any from the following Studies</td>
                                <td style={{ fontSize: '16px', fontWeight: 'bold' }}>Default Price</td>
                                <td style={{ fontSize: '16px', fontWeight: 'bold' }}>Final Price</td>
                            </tr>
                            {pricing?.XRAY
                                ?.sort((a, b) => a?.group_mod_id - b?.group_mod_id)?.map((data, id) => (
                                    <tr key={id}>
                                        {id === 0 && <td rowSpan={pricing?.XRAY?.length==4 ? 4 : 3} >XRAY</td>}
                                        <td >{data?.category_name}</td>
                                        <td>{data?.organ}</td>
                                        <td >

                                            <div style={{ display: 'flex' }}>
                                                <div>{radioValue === 'Dollar' ?
                                                    <BsCurrencyDollar /> : <LiaRupeeSignSolid />
                                                }</div>
                                                <div> <span >{ data?.group_mod_id===28 ? 50 :data?.default_price}</span></div>
                                            </div>


                                        </td>
                                        <td >
                                           {data?.group_mod_id===28 ?
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div>{radioValue === 'Dollar' ?
                                                    <BsCurrencyDollar style={{ color: data.edited_price !== 50? 'red' : 'black' }} /> : <LiaRupeeSignSolid style={{ color: data.edited_price !== 50 ? 'red' : 'black' }} />
                                                }</div>
                                                <div> <span style={{ color: data.edited_price !== 50 ? 'red' : 'black' }}>{data?.edited_price}</span></div>
                                            </div>
                                            <div>

                                                <MdModeEdit className='edit-icon' onClick={() =>
                                                {
                                                    if(isapproved){
                                                        return
                                                    }
                                                    
                                                    setOpen({
                                                    id: data?.group_mod_id,
                                                    price: data?.edited_price,
                                                    type: 'XRAY'
                                                })}
                                                
                                                
                                            }/>





                                            </div>
                                        </div>
                                           
                                           :<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <div>{radioValue === 'Dollar' ?
                                                        <BsCurrencyDollar style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }} /> : <LiaRupeeSignSolid style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }} />
                                                    }</div>
                                                    <div> <span style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }}>{data?.edited_price}</span></div>
                                                </div>

                                                <div>

                                                    <MdModeEdit className='edit-icon' onClick={() =>
                                                    {
                                                        if(isapproved){
                                                            return
                                                        }
                                                        
                                                        setOpen({
                                                        id: data?.group_mod_id,
                                                        price: data?.edited_price,
                                                        type: 'XRAY'
                                                    })}
                                                    
                                                    
                                                }/>





                                                </div>
                                            </div>
}
                                        </td>

                                    </tr>
                                ))}



                            {pricing?.CT?.sort((a,b)=>a?.default_price-b?.default_price)?.map((data, id) => (
                                <tr key={id}>
                                    {id === 0 && <td rowSpan={13} >CT</td>}
                                    <td >{data?.category_name}</td>
                                    <td>{data?.organ}</td>
                                    <td >

                                        <div style={{ display: 'flex' }}>
                                            <div>{radioValue === 'Dollar' ?
                                                <BsCurrencyDollar /> : <LiaRupeeSignSolid />
                                            }</div>
                                            <div> <span >{data?.default_price}</span></div>
                                        </div>


                                    </td>
                                    <td >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div>{radioValue === 'Dollar' ?
                                                    <BsCurrencyDollar style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }} /> : <LiaRupeeSignSolid style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }} />
                                                }</div>
                                                <div> <span style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }}>{data?.edited_price}</span></div>
                                            </div>
                                            <div>
                                                <MdModeEdit className='edit-icon' onClick={() => 
                                                {
                                                    if(isapproved){
                                                        return
                                                    }
                                                    
                                                setOpen({
                                                    id: data?.group_mod_id,
                                                    price: data?.edited_price,
                                                    type: 'CT'
                                                })}
                                            }
                                                />



                                            </div>
                                        </div>
                                    </td>


                                </tr>
                            ))}
                            {pricing?.MRI?.sort((a,b)=>a?.default_price-b?.default_price)?.map((data, id) => (
                                <tr key={id}>
                                    {id === 0 && <td rowSpan={14} >MRI</td>}
                                    <td >{data?.category_name}</td>
                                    <td>{data?.organ}</td>
                                    <td >

                                        <div style={{ display: 'flex' }}>
                                            <div>{radioValue === 'Dollar' ?
                                                <BsCurrencyDollar /> : <LiaRupeeSignSolid />
                                            }</div>
                                            <div> <span >{data?.default_price}</span></div>
                                        </div>


                                    </td>
                                    <td >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div>{radioValue === 'Dollar' ?
                                                    <BsCurrencyDollar style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }} /> : <LiaRupeeSignSolid style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }} />
                                                }</div>
                                                <div> <span style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }}>{data?.edited_price}</span></div>
                                            </div>

                                            <div>
                                                <MdModeEdit className='edit-icon' onClick={() =>{
                                                     if(isapproved){
                                                        return
                                                    }
                                                    

                                                setOpen({
                                                    id: data?.group_mod_id,
                                                    price: data?.edited_price,
                                                    type: 'MRI'
                                                })} 
                                                
                                            }/>



                                            </div>
                                        </div>
                                    </td>


                                </tr>
                            ))}


                            {pricing?.NM?.sort((a,b)=>a?.default_price-b?.default_price)?.map((data, id) => (
                                <tr key={id}>
                                    {id === 0 && <td rowSpan={5} >NM</td>}
                                    <td >{data?.category_name}</td>
                                    <td>{data?.organ}</td>
                                    <td >

                                        <div style={{ display: 'flex' }}>
                                            <div>{radioValue === 'Dollar' ?
                                                <BsCurrencyDollar /> : <LiaRupeeSignSolid />
                                            }</div>
                                            <div> <span >{data?.default_price}</span></div>
                                        </div>


                                    </td>
                                    <td >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div>{radioValue === 'Dollar' ?
                                                    <BsCurrencyDollar style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }} /> : <LiaRupeeSignSolid style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }} />
                                                }</div>
                                                <div> <span style={{ color: data.edited_price !== data?.default_price ? 'red' : 'black' }}>{data?.edited_price}</span></div>
                                            </div>

                                            <div>
                                                <MdModeEdit className='edit-icon' onClick={() =>{
                                                     if(isapproved){
                                                        return
                                                    }
                                                    
                                                    
                                                    setOpen({
                                                    id: data?.group_mod_id,
                                                    price: data?.edited_price,
                                                    type: 'NM'
                                                })}} />



                                            </div>
                                        </div>
                                    </td>


                                </tr>
                            ))}


                            {/* Repeat the rest of the table rows here */}
                        </tbody>
                    </table>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}  >
                    <div className='agreement-button' >
                        <button />
                        <button 
                        disabled={isapproved}
                         onClick={() => {
                            if(handleAccess('Hospital','Agreement')!==true){
                                setAuthPop(true)
                                return
                              }
                            // if(!pricing?.XRAY&& !pricing?.CT&& !pricing?.MRI && !pricing?.NM  ){
                            //     return
                            // }
                            const dataDoc = docData?.map(data=>{
                                if(data?.type==='MOU'){
                                    return data
                                }
                            })
                            // console.log(dataDoc)
                            if(dataDoc?.length){
                            setAttachment(true) 
                            // handleApproveMail()
                         }else{
                            setWarning('Please Attach the MOU before approving')
                         }
                         
                         }}>Approve</button>
                    </div>

                    <div className='agreement-button'>
                        <button disabled={isapproved} onClick={() => { setPricing(defaultPricing) }}>Reset</button>
                        <button disabled={isapproved} onClick={() => {
                            if(handleAccess('Hospital','Agreement')!==true){
                                setAuthPop(true)
                                return
                          }
                           if(!pricing?.XRAY&& !pricing?.CT&& !pricing?.MRI && !pricing?.NM  ){
                                return
                            }
                            setConfirm(true) }}>Update</button>
                    </div>
                </div>
            </div>

            <div style={{ width: '30%' }}>

                <div className='other-details'>

                    <div>
                        <div className='label'> NightHawk Percentage </div>
                        <div ><input disabled={edit} style={{ color: detail?.night_hawk_price !== 25 ? 'red' : 'black' }} type='number' value={detail?.night_hawk_price ? detail?.night_hawk_price : ''} onChange={(e) => {
                            handleChangeValue('night_hawk_price', e.target.value)
                        }} className='input-agreement' placeholder='NightHawk Percentage' /> </div>
                    </div>
                    <div style={{ marginTop: '10px', width: '100%' }}>
                        <div className='box-shape'>
                            <div>Enable Additional View</div>
                            <div> <Switch disabled={edit} onChange={(e) => {
                                handleChangeValue('is_additional_view_price', e.target.checked)
                            }} checked={detail?.is_additional_view_price} /></div>
                        </div>
                    </div>
                    {detail?.is_additional_view_price &&
                        <div>
                            <div className='label'> Additional View Price </div>
                            <div ><input type='number' style={{ color: detail?.additional_view_price !== 50 ? 'red' : 'black' }} disabled={edit} value={detail?.additional_view_price ? detail?.additional_view_price : ''} onChange={(e) => {
                                handleChangeValue('additional_view_price', e.target.value)
                            }} className='input-agreement' placeholder='additional_view_price' /> </div>
                        </div>
                    }
                    <div>
                        <div className='label'> Holiday Percentage </div>
                        <div ><input style={{ color: detail?.holiday_price !== 25 ? 'red' : 'black' }} disabled={edit} type='number' value={detail?.holiday_price ? detail?.holiday_price : ''} onChange={(e) => {
                            handleChangeValue('holiday_price', e.target.value)
                        }} className='input-agreement' placeholder='Holiday Percentage' /> </div>
                    </div>
                    {/* <div>
                    <div className='label'> Perview Price </div>
                    <div ><input value={detail?.additional_view_price} className='input-agreement' placeholder='Perview Price'/> </div>
                </div> */}

                    <div>
                        <div className='label'> PAN </div>
                        <div ><input style={{ color: detail?.pan !== otherDefaultDetails?.pan ? 'red' : 'black' }} disabled={edit} value={detail?.pan ? detail?.pan : ''} onChange={(e) => {
                            handleChangeValue('pan', e.target.value)
                        }} className='input-agreement' placeholder='PAN' /> </div>
                    </div>

                    <div>
                        <div className='label'> TAN </div>
                        <div ><input style={{ color: detail?.tan !== otherDefaultDetails?.tan ? 'red' : 'black' }} disabled={edit} value={detail?.tan ? detail?.tan : ''} onChange={(e) => {
                            handleChangeValue('tan', e.target.value)
                        }} className='input-agreement' placeholder='TAN' /> </div>
                    </div>

                    <div>
                        <div className='label'> MSME </div>
                        <div ><input style={{ color: detail?.msme !== otherDefaultDetails?.msme ? 'red' : 'black' }} disabled={edit} value={detail?.msme ? detail?.msme : ''} onChange={(e) => {
                            handleChangeValue('msme', e.target.value)
                        }} className='input-agreement' placeholder='MSME' /> </div>
                    </div>

                    <div>
                        <div className='label'> GST </div>
                        <div ><input style={{ color: detail?.gst !== otherDefaultDetails?.gst ? 'red' : 'black' }} disabled={edit} value={detail?.gst ? detail?.gst : ''} onChange={(e) => {
                            handleChangeValue('gst', e.target.value)
                        }} className='input-agreement' placeholder='GST' /> </div>
                    </div>

                </div>

                <div className='agreement-button'>

                    <button disabled={isapproved} onClick={() => {
                        setDetails({ ...defaultDetail })
                    }} >Reset</button>
                    <button disabled={isapproved} onClick={() => {
                         if(handleAccess('Hospital','Agreement')!==true){
                            setAuthPop(true)
                            return
                        }

                        if(!pricing?.XRAY&& !pricing?.CT&& !pricing?.MRI && !pricing?.NM  ){
                            return
                        }
                        setCOnfirmPop(true) }}>Update</button>
                    <button disabled={isapproved} onClick={() => {
                        setEdit(prev => !prev)
                    }} > {edit ? 'Edit' : 'Unedit'}</button>
                </div>


            </div>



            <Alerts alert={alert} setAlert={setAlert} warning={warning} setWarning={setWarning} />



            <EditHandlePrice open={open} onClose={() => setOpen(false)} handleEditValue={handleEditValue} />

            <AlertDialog open={confirm} handleClose={() => setConfirm(false)} UpdateFunction={handleEditApi} />

            <AlertDialog open={confirmPop} handleClose={() => setCOnfirmPop(false)} UpdateFunction={handleEditDetails} />

            <Dialog
                open={attachment}
                // TransitionComponent={Transition}
                keepMounted
                onClose={() => setAttachment(false)}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
                fullWidth
            >
                <div>
                    <DialogTitle>Attachment</DialogTitle>
                    <DialogContent>
                        <DialogContentText style={{ display: 'flex', alignItems: 'center ' }} id="alert-dialog-slide-description">
                            <div> Please Attach the signed agreement document </div>
                            <div style={{ marginLeft: '10px' }}>
                                <label for="file-upload" class="custom-file-upload">
                                    Upload
                                </label>
                                <input
                                    className='attachment-button'
                                    type="file"
                                    // accept=".pdf"
                                    id="file-upload"
                                    multiple
                                    onChange={(el) => {
                                        setSelectedFile(el.target.files?.[0])
                                        el.target.files?.length &&
                                            setSelectedDocs(Array.from(el.target.files))
                                    }}
                                />
                            </div>
                        </DialogContentText>
                        <>
                            <DocViewer style={{ height: selectedDocs?.length > 0 ? '450px' : '0px' }}
                                documents={selectedDocs.map((file) => ({
                                    uri: window.URL.createObjectURL(file),
                                    fileName: 'MOU Document',
                                }))}
                                pluginRenderers={DocViewerRenderers}
                            />
                        </>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setSelectedDocs([])
                            setAttachment(false)
                        }}>Cancel</Button>
                        <Button onClick={() => {
                            if (selectedDocs?.length == 0) {
                                return
                            }
                            setAttachment(false)
                            setApprove(true)
                        }}>Next</Button>
                    </DialogActions>
                </div>

            </Dialog>



            <CustomAlertDialog open={approve} UpdateFunction={handleUploadToProduction} handleClose={() => {
                setAttachment(false)
                setApprove(false)
            }}  >
                <p>
                    Are you sure you want to approve the pricing
                </p>
                <p>
                    Once you click the approve the pricing info will be moved to production
                </p>
            </CustomAlertDialog>


            <Loader open={loader=='uploading'&&true}  handleClose={() => {
                   setAttachment(false)
                   setLoader(false)
            }}  >
                <div style={{fontWeight:'600'}}> Price Updating....</div>
               
            </Loader>


            <Response data={(loader && loader!=='uploading') && loader} setData={setLoader} />

        </div>
    )
}

export default AgreementPricing


const EditHandlePrice = ({ open, onClose, handleEditValue }) => {
    const [error, setError] = useState('')
    const erroRef = useRef()
    const [inputValue, setValue] = useState()

    // console.log(value)
    const handleSubmit = () => {
        handleEditValue(open?.type, open?.id, inputValue)
        onClose()
    }

    useEffect(() => {

        setValue(open?.price)

    }, [open])


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid lightblue',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >

            <Box sx={{ ...style }}>

                <div className='pop-edit'>
                    <div className='text-red-500' style={{ textAlign: 'center', fontSize: '23px', marginBottom: '30px', color: 'darkblue' }}>Edit Agreement Price</div>
                    <div style={{ textAlign: 'center', marginBottom: '30px' }}>

                        <TextField className='edit-label' value={inputValue} onChange={(event) => {
                            setValue(event.target.value)
                            erroRef.current.innerHTML = ''
                        }} id="outlined-basic" label={'Price'} variant="outlined" />

                    </div>
                    <div style={{ textAlign: 'center', color: 'red' }} ref={erroRef}></div>
                    <div style={{ textAlign: 'center', marginBottom: '5px', marginTop: '30px', }}>
                        <Button sx={{ marginRight: '30px', fontSize: '13px' }} onClick={() => {
                            setValue('')
                            onClose()
                        }} variant="contained">Close</Button>
                        <Button sx={{ fontSize: '13px' }} onClick={() => {
                            if (inputValue === null || inputValue === '') {
                                return
                            }
                            handleSubmit()
                        }} variant="contained">Save</Button>
                    </div>
                </div>

            </Box>
        </Modal>
    )
}
