import React, { useEffect, useState } from "react";
import PieChart from "../charts/PieChart";
import {
  revenueModality,
  revenueModalityOptions,
} from "../charts/variables/charts.js";
import axios from "axios";
import { BorderLinearProgress, RedLinearProgress } from "./GmStudyOverall";
import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";
import { FaFileDownload } from "react-icons/fa";
function RevenueTable({ revenueData ,handleDownload}) {
  const [revenueDataMap, setRevenueData] = useState([]);

  const handleGm = (reve, paya) => {
    const data = ((reve - paya) / reve) * 100;
    return parseFloat(data).toFixed(2)
  };

  useEffect(() => {
    setRevenueData(revenueData);
  }, [revenueData]);

  const handleSendDownload=(data,filename)=>{
    const data1= data?.map(data=>{
      return{
        'Client Name':data?.client_name,
        'Revenue':data?.Revenue,
        'Payable':data['Rad Payable'],
        'Gm':`${handleGm(data?.Revenue, data['Rad Payable'])}%` ,
      }
    })

    handleDownload(data1,filename)
}

  return (
    <>
      {" "}
      {revenueDataMap.length === 0 ? (
        <div style={{ margin: "auto" }}>
          <ReactLoading
            type={"bars"}
            color={"#0059B2"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "transparent",
            borderRadius: "20px",
            padding: "20px",
          }}
        >
          <div style={{ fontSize: "25px", color: "#1B2559",display:'flex',justifyContent:'space-between' }}>
            <div>Client</div>
            <div><FaFileDownload onClick={()=>handleSendDownload(revenueDataMap,'Client_Wise_Gm')} color="blue" style={{cursor:'pointer'}} /></div>
            
            </div>
          <div>
            <div className="table-revenue-head">
              <div>Client</div>
              <div>Revenue</div>
              <div>Payable</div>
              <div>GM</div>
            </div>
            <div
              className="table-revenue-table"
              style={{ height: "700px", overflowY: "scroll" }}
            >
              {revenueDataMap
                ?.sort((a, b) => b.revenue - a.revenue)
                .map((data, id) => (
                  <div key={id} className="table-revenue-body">
                    <div>{data.client_name}</div>

                    <div>
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(data?.Revenue)}
                    </div>

                    <div>
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(data["Rad Payable"])}
                    </div>

                    <div style={{ textAlign: "end" }}>
                      {handleGm(data?.Revenue, data["Rad Payable"]) > 0 ? (
                        <div className="table-revenue-body-gm-postive">
                          {`${handleGm(data?.Revenue, data["Rad Payable"])}%`}
                          <BorderLinearProgress
                            variant="determinate"
                            value={handleGm(data?.Revenue, data["Rad Payable"])}
                          />
                        </div>
                      ) : (
                        <div className="table-revenue-body-gm-negative">
                          {`${handleGm(data?.Revenue, data["Rad Payable"])}%`}
                          <RedLinearProgress
                            variant="determinate"
                            value={
                              Math.abs(
                                handleGm(data?.Revenue, data["Rad Payable"])
                              ) > 100
                                ? 100
                                : Math.abs(
                                    handleGm(data?.Revenue, data["Rad Payable"])
                                  )
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RevenueTable;
