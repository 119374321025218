import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function LineChart(props) {
      const [chartData,setChartData] = useState(props.chartData)
      const [chartOptions,setChartOption] = useState(props.chartOptions)
      //console.log(props)
        return (
          <ReactApexChart
            options={chartOptions}
            // options={{}}
            series={chartData}
            type='line'
            width='100%'
            // height='100%'
          />
        );
      }
    
    

    


export default LineChart
