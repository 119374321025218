import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
} from "@mui/material/";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import axios from "axios";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles, styled } from "@mui/styles";
import {
  getGridDateOperators,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarExport,

} from "@mui/x-data-grid";
import AddVendor from "./AddVendor";
import Alerts from "../Radiologists/Alerts";
import AuthErrorPop from "../Util/AuthErrorPop";
import { UserContext } from "../../App";

const columns = [
    {
      field: "unique_id",
      headerName: "Unique ID",
      flex: 1,
      minWidth: 100,
      // align:'center',
    },
    {
      field: "name",
      headerName: "Vendor Name",
      flex: 1,
      minWidth: 150,
      // align:'center',
    },
  
    {
      field: "onboarded_at",
      headerName: "Onboarding Date",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const date = params.value;
        if (date) {
          return <span>{date?.split(" ")[0]}</span>;
        } 
      },
    },
      // align:'center',
   
    {
      field: "registration_type",
      headerName: "Registeration Type",
      flex: 1,
      minWidth: 150,
      // align:'center',
    },
    {
      field: "vendor_type",
      headerName: "Vendor Type",
      flex: 1,
      minWidth: 150,
      // align:'center',
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const saas = params.value;
        if (saas) {
          return <span>{saas}</span>;
        } 
      },
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      minWidth: 150,
      // align:'center',
    },
    {
      field: "readmore",
      headerName: "ReadMore",
      textAlign: "center",
      flex: 1,
      minWidth: 150,
      type: "actions",
      // cellClassName: 'actions',
      renderCell: (props) => (
    
        <strong>
         
          <Link
            to={`/vendors/${props.row?.unique_id}/details`}
            className="firstreadmoreIcon"
          >
            <ArrowCircleRightIcon
         
              sx={{ color: "blue", width: "80px", height: "30px" }}
            />{" "}
          </Link>
          <ReadMoreIcon
            className="readmoreIcon"
            style={{
              fontSize: "36px",
              display: "block",
              margin: "auto",
              color: "blue",
            }}
          />
        </strong>
      ),
    },
  ];
  
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
  
      "& .css-204u17-MuiDataGrid-main": {
        borderRadius: "0px 0px 0px 0px",
      },
      "& .MuiDataGrid-cell": {
        textAlign: "center",
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      },
      "& .MuiDataGrid-filterForm": {},
      "& .MuiDataGrid-menuList": {
        fontSize: "10px",
      },
  
      "& .MuiList-root": {
        "& ..MuiDataGrid-menuList": {
          fontSize: "10px",
        },
      },
      "& .MuiDataGrid-footerContainer": {
        backgroundColor: "#FFFFFF",
      },
  
      "& .MuiDataGrid-row": {
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "lightblue !important",
          // transform: 'scale(1.01)',
          //'#d6d7f8', //'#d61e11',
          // borderRadius:'11px',
          // border:'solid 1px grey',
          transform: "scale(1.004)",
          fontWeight: "bold",
        },
        height: 60,
        border: "none",
        fontSize: "12px",
  
        "&:nth-child(2n)": {},
      },
      "& .MuiDataGrid-columnHeader": {
        textAlign: "center",
        backgroundColor: "#3C48AD",
        color: "#FFFFFF",
        fontSize: "0.875rem",
        borderColor: "black",
  
        // opacity:'0.7'
      },
      "& .MuiDataGrid-toolbarFilterList": {
        //  backgroundColor:'red'
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bolder",
        textAlign: "center",
      },
  
      "& .MuiDataGrid-sortIcon": {
        color: "white",
        "&:hover": {
          transform: "scale(1.6)",
        },
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
        },
      },
    },
  }));

function Vendors() {
    const classes = useStyles();
    const [mapData,setMapData]=useState([])
    const [vendorModel,setVendorModel]=useState('')
    const [alert,setAlert]=useState('')
    const [warning,setWarning]=useState('')
    const [data,setData]=useState([])
    const [authPop, setAuthPop] = useState(false);
    const { handleAccess, apiState, setApiState } = useContext(UserContext);

    const columns1 = React.useMemo(
        () =>
          columns.map((col) =>
            col.field === "rad_onboarding_date"
              ? {
                  ...col,
                  filterOperators: getGridDateOperators(),
                }
              : col
          ),
        [columns]
      );

      const getRowStyles = (params) => {
        const { grade } = params.row;
        //console.log(params.row);
        //  if(grade=='SILVER'){
        //   return { backgroundColor: 'black' };
        //  }
        return { backgroundColor: "lightgreen !important" };
      };

    const getVendors=async()=>{
      try{
        const {REACT_APP_GET_VENDORS_LIST}=process.env
        const res = await axios.get(REACT_APP_GET_VENDORS_LIST)
        if(res?.data?.data?.length>0){
          setData(res?.data?.data)
        }
      }catch(err){

      }
    }
    
    useState(()=>{
      getVendors()
    },[])


  return (
    <div>

        <Box
         sx={{
           height: "calc(100vh - 138px)",
           marginLeft: "-10px",
           padding: "10px 10px 10px 25px",
           borderRadius: "10px",
           overflow: "hidden",
         }}
       >
         <DataGrid
           //  {...data}
           slotProps={{
             toolbar: { printOptions: { disableToolbarButton: true } },
           }}
           getRowId={(data1) => data1.id}
           sx={{
             boxShadow: 1,
             borderRadius: "10px",
             // width:'calc(100vw - 250px)',
             // width:'100%',
             // border:1,
             borderColor: "#f5f5f5",
             backgroundColor: "#FFFFFF",
           }}
           components={{
             Toolbar: ({ printOptions }) => {
               return (
                 <GridToolbarContainer
                   sx={{ justifyContent: "space-between", marginBottom: "5px" }}
                 >
                   {" "}
                   <div
                     style={{
                       padding: "10px",
                       fontSize: "20px",
                       fontWeight: "bold",
                       display: "flex",
                       alignItems: "center",
                     }}
                   >
           
                    
 
                     <div style={{ marginLeft: "20px" }}>
                       Vendors
                     </div>
                   </div>
                   <div style={{ display: "flex" }}>
                     <GridToolbarQuickFilter
                       className="seacrh-filter"
                       style={{ margin: "15px 0px" }}
                     />
                     <div style={{ padding: "15px" }}>
                       <div
                         className="header-section"
                         style={{ marginTop: "4px", marginRight: "5px" }}
                       >
                         {/* <Tooltip title="Upload in House Salary" arrow> */}
                           <Button    size="small"
                         variant="contained"
                         className="btn-effect"
                            

                              onClick={() => {
                                if (
                                  handleAccess("Vendor", "Add vendor") ===
                                  true
                                ) {
                                  setVendorModel(true)
                                } else {
                                  setAuthPop(true);
                                }
                              }}

                             
                         
                       
                           > Add Vendors
                           </Button>
                         {/* </Tooltip> */}
                       </div>
                     </div>
                     <GridToolbarExport
                       printOptions={{ disableToolbarButton: true }}
                       style={{
                         width: "100px",
                         color: "black",
                         marginRight: "20px",
                         margin: "10px 0px",
                       }}
                     />
                   </div>
                 </GridToolbarContainer>
               );
             },
           }}
           density="standard"
           className={classes.root}
           rowSpacingType="border"
           columnHeaderHeight={60}
           rows={data}
           columns={columns1}
           getRowStyle={getRowStyles}
           initialState={{
             pagination: {
               paginationModel: {
                 pageSize: 20,
               },
             },
           }}
           pageSizeOptions={[5, 10, 20]}
           disableRowSelectionOnClick
         />
       </Box>


       <AddVendor open={vendorModel} getVendors={getVendors} setOpen={setVendorModel} setAlert={setAlert} setWarning={setWarning} />

       <Alerts alert={alert} setAlert={()=>setAlert('')} warning={warning} setWarning={()=>setWarning('')} />

       <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop} />
        
    </div>
  )
}

export default Vendors
