import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import * as XLSX from "xlsx";

const UploadRadCost = ({ onFileUpload, onClose, email, setErrorPop }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [excelFileName, setExcelFileName] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);

  // Function to handle file selection
  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    //console.log("Selected file:", selectedFile);
    setSelectedFile(selectedFile);
    setExcelFileName(selectedFile.name);
    setOpenConfirmationDialog(true); // Show the confirmation dialog after file selection
  };

  // Function to handle closing the confirmation dialog
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  // Function to handle confirming the upload
  const handleConfirmUpload = () => {
    setOpenConfirmationDialog(false);
    handleReadExcel(); // Call handleReadExcel to process the selected file after confirmation
    onClose(); // Close the UploadCost modal after confirming the upload
  };

  // Function to read the Excel file and process the data
  const handleReadExcel = () => {
    if (!selectedFile) {
      //console.log("No file selected.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      // Assuming sheet name is "Cost_sheet"
      const sheetName = "Cost_sheet";
      const sheet = workbook.Sheets[sheetName];

      if (!sheet) {
        setErrorPop('Sheet name should be "Cost_sheet".');
       
        return;
      }

      const jsonData = XLSX.utils.sheet_to_json(sheet);

      if (jsonData.length === 0) {
        setErrorPop("No data found in the selected file.");
        //console.log("No data found in the selected file.");
        return;
      }
      //console.log("first", jsonData);
      // Prepare the data to send through the API
      const dataToSend = jsonData
        .filter(
          (row) =>
            row["revised_cost"] !== undefined &&
            typeof row["revised_cost"] === "number" &&
            row["reason"] !== undefined &&
            row["reason"] !== ""
        )
        .map((row) => ({
          report_fk: row["report_fk"],
          revised_cost: row["revised_cost"],
          reason: row["reason"],
          by_user_id: email, // You can set the user ID here or get it from the backend
          edit_type: "Edited by Bulk Upload", // Set the edit type for bulk upload
        }));
      //console.log("second", dataToSend);
      if (dataToSend.length !== jsonData.length) {
        setErrorPop(
          "Reason is Mandatory and Revised Cost should be a Numeric Value"
        );
        return;
      }
      // Call the onFileUpload function to handle the data

      onFileUpload(dataToSend);
    };

    reader.readAsBinaryString(selectedFile);
  };

  const handleCancelUpload = () => {
    setOpenConfirmationDialog(false);
    setSelectedFile(null); // Reset selectedFile state when canceling the upload
  };

  return (
    <div>
      {/* File input to select the Excel file */}
      <input
        type="file"
        accept=".xlsx"
        onChange={handleFileSelect}
        style={{ display: "none" }}
        id="file-input"
      />
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <label htmlFor="file-input">
          <Button variant="contained" color="primary" component="span">
            <CloudUploadIcon style={{ marginRight: "5px" }} />
            Upload Excel File
          </Button>
        </label>
      </DialogActions>
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          {excelFileName && <p>Selected Excel File: {excelFileName}</p>}
          {uploadStatus && <p style={{ color: "red" }}>{uploadStatus}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelUpload} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmUpload} color="primary" autoFocus>
            {" "}
            {/* Add autoFocus attribute here */}
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadRadCost;
