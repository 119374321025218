
function CardContent(props) {
  const { variant, children, ...rest } = props;
  const styles = {
    bg:'#ffffff',
    p: "20px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    borderRadius: "20px",
    minWidth: "0px",
    wordWrap: "break-word",
    // bg: mode("#ffffff", "navy.800")(props),
    backgroundColor:'white',
    backgroundClip: "border-box",
  }

  return (
    <div __css={styles} {...rest}>
      {children}
    </div>
  );
}

export default CardContent;
