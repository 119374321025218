// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rad-transaction-main{
    width:100%;
    display: flex;
    flex-direction: column;
    height:calc(100vh - 36vh);
}



.transaction-content{
    display: flex;
    justify-content: center;
    margin-left: 100px;
    margin-top: 20px;
    align-items: center;
}

.search-btn{
    margin:0px 10px !important;
    font-size: 12px !important;
    height: -moz-fit-content;
    height: fit-content;
}

.total-payable{
    margin-left: 20px;
    display: flex;
    align-items: center;
    color: darkblue;
    font-weight: bold;
}

@media screen and (max-width:840px) {
    
    
}

.tb-head{
    text-align: center;
    /* display: flex;
    justify-content: center;  */
}

.rad-transaction-table td{
    text-align: center;
}

.rad-transaction-table th{
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Radiologists/RadTransaction.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,yBAAyB;AAC7B;;;;AAIA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,0BAA0B;IAC1B,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,iBAAiB;AACrB;;AAEA;;;AAGA;;AAEA;IACI,kBAAkB;IAClB;+BAC2B;AAC/B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".rad-transaction-main{\n    width:100%;\n    display: flex;\n    flex-direction: column;\n    height:calc(100vh - 36vh);\n}\n\n\n\n.transaction-content{\n    display: flex;\n    justify-content: center;\n    margin-left: 100px;\n    margin-top: 20px;\n    align-items: center;\n}\n\n.search-btn{\n    margin:0px 10px !important;\n    font-size: 12px !important;\n    height: fit-content;\n}\n\n.total-payable{\n    margin-left: 20px;\n    display: flex;\n    align-items: center;\n    color: darkblue;\n    font-weight: bold;\n}\n\n@media screen and (max-width:840px) {\n    \n    \n}\n\n.tb-head{\n    text-align: center;\n    /* display: flex;\n    justify-content: center;  */\n}\n\n.rad-transaction-table td{\n    text-align: center;\n}\n\n.rad-transaction-table th{\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
