import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material/';
import { db } from '../../permission'
import { doc, getDoc, setDoc } from "firebase/firestore";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function EditUser({ editUser, setEditUser,userList,setAlert,setWarning }) {
    const [role, setRole] = useState(
       editUser?.Permission?.level
)
    const permissionlist = {
        Hospital: ['Download',
            'Send Invoice',
            'Edit Details',
            'Price Download',
            'Price Upload',
            'Contact',
            'Cost Rerun',
            'Download Cost',
            'Bulk Upload Cost',
            'Manual Edit',
            'Send Note',
            'Download Invoice',
            'Document Tab',
            'Agreement'
        ],
        Radiologist: ['Upload Salary', 'Download Price', 'Upload Price', 'Download Transaction','Download Cost','Upload Cost','Manual Edit','Rad Invoice'],
        Invoices: ['Download','Send Note'],
        Reports: ['Download'],
        HospitalNotice: ['Edit Notice Text Box', 'Bulk Upload Notice', 'Send Notice', 'Notice Action', 'Send Reminder', 'Bulk Upload Reminder'],
        Collection:['Download Clients','Download Chat'],
        Vendor:['Add vendor','Edit Details','Contact','Invoice','Document']
    }
    const [accesslist, setAccesslist] = useState({
        Hospital: [],
        Radiologist: [],
        Invoices: [],
        Reports: [],
        HospitalNotice: [],
        Collection:[],
        Vendor:[]
    })
    const errorRef = useRef(null)


    const handleChange = (e) => {

        setRole(e.target.value)
    }

    useEffect(()=>{
       setRole(editUser?.Permission?.level)
       setAccesslist(editUser?.Permission?.Pages)
    },[editUser])


    const handleCheckChange = (value, page) => {
        //console.log(value.target.value, page)
        if (accesslist[page].includes(value.target.value)) {
            //console.log('value1')
            const updatedList = accesslist[page].filter(item => item !== value.target.value);
            setAccesslist({ ...accesslist, [page]: updatedList });
        } else {
            //console.log('value')
            setAccesslist({
                ...accesslist,
                [page]: [...accesslist[page], value.target.value],
            });
        }
        //console.log(accesslist.Hospital)
    }

    const handleEdit=()=>{
        const updatedList = userList?.filter(item => item.email !== editUser?.email);
        //console.log(updatedList)
        const newUser = {
            email: editUser?.email,
            name: editUser?.name,
            Permission: {
                level: role,
                Pages: accesslist
            }

        }
        const data = [newUser,...updatedList]
        //console.log(data)
        handleUpdate(data)
    }


    const handleUpdate = async (newData) => {
        try {
            //console.log(accesslist)
            //console.log(newData)
            const documentRef = doc(db, "Finance_Access", "Y5jz0XkKMbf4YtKg4ojW");
            await setDoc(documentRef, { user: newData });
            //console.log(`New chat entry added for user successfully.`);
            window.location.reload()
            setAlert('Updated Successfully')
            setEditUser('')
        } catch (error) {
            console.error('Error updating document:', error.message);
            setWarning('User Added,Permission access failed')
        }
    };

    return (
        <Dialog
            fullScreen
            open={editUser}
            onClose={setEditUser}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative',backgroundColor:'#0059B2 !important' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={setEditUser}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Edit User
                    </Typography>
                    {/* <Button autoFocus color="inherit" onClick={setEditUser}>
                save
            </Button> */}
                </Toolbar>
            </AppBar>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>

                <div className='add-user-form'>
                    <TextField value={editUser.name} name='name' disabled style={{ marginBottom: '20px', marginTop: '20px', width: '90%' }} id="standard-basic" label="Name" variant="standard" />
                    <TextField value={editUser.email} name='email' disabled  style={{ marginBottom: '20px', marginTop: '20px', width: '90%' }} id="standard-basic" label="Email Address" variant="standard" />

                    <FormControl style={{ marginBottom: '20px', marginTop: '20px' }} sx={{ m: 1, minWidth: 730 }} variant='standard'>
                        <InputLabel id="demo-select-small-label">Select Role</InputLabel>
                        <Select
                            value={role} name='role' onChange={handleChange}
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            // value={designation}
                            label="Select Role"
                        // onChange={(e) => setDesignation(e.target.value)}
                        >

                            <MenuItem value='view'>View</MenuItem>
                            <MenuItem value='admin'>Admin</MenuItem>
                            <MenuItem value='super_admin'>Super Admin</MenuItem>
                            <MenuItem value='selected_permission'>Selected Permission</MenuItem>
                            <MenuItem value='Dasboard'>Dashboard</MenuItem>
                        </Select>

                    </FormControl>
                    {role === 'selected_permission' &&
                        <div>
                            <div>
                                <div style={{ textAlign: 'left', margin: '30px',fontWeight:'bold' }}> HOSPITAL PAGE</div>
                                {permissionlist?.Hospital.map((data, id) => (
                                    <FormControlLabel
                                        control={<Checkbox label='data' key={id}
                                            value={data}
                                            checked={accesslist?.Hospital.includes(data)}
                                            onChange={(e) => handleCheckChange(e, 'Hospital')}
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label={data}
                                    />
                                ))

                                }
                            </div>

                            <div>
                                <div style={{ textAlign: 'left', margin: '30px',fontWeight:'bold' }}> RADIOLOGIST PAGE</div>
                                {permissionlist?.Radiologist.map((data, id) => (
                                    <FormControlLabel
                                        control={<Checkbox label='data' key={id}
                                            value={data}
                                            checked={accesslist?.Radiologist.includes(data)}
                                            onChange={(e) => handleCheckChange(e, 'Radiologist')}
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label={data}
                                    />
                                ))

                                }
                            </div>

                            <div>
                                <div style={{ textAlign: 'left', margin: '30px',fontWeight:'bold' }}> REPORTS PAGE</div>
                                {permissionlist?.Reports.map((data, id) => (
                                    <FormControlLabel
                                        control={<Checkbox label='data' key={id}
                                            value={data}
                                            checked={accesslist?.Reports.includes(data)}
                                            onChange={(e) => handleCheckChange(e, 'Reports')}
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label={data}
                                    />
                                ))

                                }
                            </div>

                            <div>
                                <div style={{ textAlign: 'left', margin: '30px',fontWeight:'bold' }}> INVOICE PAGE</div>
                                {permissionlist?.Invoices.map((data, id) => (
                                    <FormControlLabel
                                        control={<Checkbox label='data' key={id}
                                            value={data}
                                            checked={accesslist?.Invoices.includes(data)}
                                            onChange={(e) => handleCheckChange(e, 'Invoices')}
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label={data}
                                    />
                                ))
                                }
                            </div>

                            <div>
                                <div style={{ textAlign: 'left', margin: '30px',fontWeight:'bold' }}> HOSPITAL NOTICE PAGE</div>
                                {permissionlist?.HospitalNotice.map((data, id) => (
                                    <FormControlLabel
                                        control={<Checkbox label='data' key={id}
                                            value={data}
                                            checked={accesslist?.HospitalNotice.includes(data)}
                                            onChange={(e) => handleCheckChange(e, 'HospitalNotice')}
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label={data}
                                    />
                                ))

                                }
                            </div>

                            <div>
                                <div style={{ textAlign: 'left', margin: '30px',fontWeight:'bold' }}> COLECTION PAGE</div>
                                {permissionlist?.Collection.map((data, id) => (
                                    <FormControlLabel
                                        control={<Checkbox label='data' key={id}
                                            value={data}
                                            checked={accesslist?.Collection.includes(data)}
                                            onChange={(e) => handleCheckChange(e, 'Collection')}
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label={data}
                                    />
                                ))

                                }
                            </div>

                            <div>
                                <div style={{ textAlign: 'left', margin: '30px',fontWeight:'bold' }}> VENDOR PAGE</div>
                                {permissionlist?.Vendor.map((data, id) => (
                                    <FormControlLabel
                                        control={<Checkbox label='data' key={id}
                                            value={data}
                                            checked={accesslist?.Vendor?.includes(data)}
                                            onChange={(e) => handleCheckChange(e, 'Vendor')}
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label={data}
                                    />
                                ))

                                }
                            </div>
                        </div>

                    }

                    <p ref={errorRef} style={{ color: 'red' }}></p>

                    <Button className='submits1-button' variant='contained' onClick={handleEdit} > Submit</Button>
                </div>


            </div>

        </Dialog>
    )
}

export default EditUser
